import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { openLoginPrompt } from 'actions/loginPrompt';
import Icon from 'components/Icon';
import './TextButton.scss';

const TextButton = ({
  className,
  alt,
  destructive,
  icon,
  label,
  onClick,
  requiresLogin,
  requiresLoginMessage,
  requiresLoginUrl,
  to,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  function handleClick() {
    if (requiresLogin && !user) {
      dispatch(openLoginPrompt(requiresLoginMessage, requiresLoginUrl));
      return;
    }
    if (to) {
      history.push(to);
    } else if (onClick) {
      onClick();
    }
  }

  return (
    <button
      className={classNames('TextButton', className, {
        hasLabel: label !== undefined,
        hasIcon: icon !== undefined,
        destructive: destructive,
      })}
      onClick={handleClick}
    >
      {label ? <div className="TextButton-label">{label}</div> : null}
      {icon ? <Icon className="TextButton-icon" alt={alt} icon={icon} /> : null}
    </button>
  );
};

export default TextButton;
