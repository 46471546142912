import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { MatrixTourProvider } from './MatrixTourProvider';
import smoothscroll from 'smoothscroll-polyfill';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import configureStore, { history } from 'store';
import 'styles/index.scss';

// Smooth scroll polyfill
smoothscroll.polyfill();

ReactDOM.render(
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <MatrixTourProvider>
        <App />
      </MatrixTourProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
