import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import icons from 'img/icons';
import InputField from 'components/inputs/InputField';
import Spinner from 'components/Spinner';
import * as ImagesService from 'services/images';
import './ImageField.scss';

const ImageField = ({
  disabled,
  folder,
  label,
  onChange,
  value,
  maxHeight,
  maxWidth,
  description,
}) => {
  const { user } = useSelector(state => state.auth);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(null);
  const [uploading, setUploading] = useState(false);

  if (error) {
    console.warn(error);
  }

  if (progress) {
    // NB: we set progress here but generally image uploads might be too fast to
    // be worth the effort of making a progress bar
    console.info(progress);
  }

  const handleUpload = file => {
    const uploadTask = ImagesService.upload(file, folder, user);
    setError(null);
    setProgress(0);
    setUploading(true);

    uploadTask.on(
      'state_changed',
      snapshot => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      error => {
        setError(error);
        setProgress(0);
        setUploading(false);
      },
      () => {
        setError(null);
        setProgress(0);
        setUploading(false);
        uploadTask.snapshot.ref.getDownloadURL().then(url => onChange(url));
      }
    );
  };

  return (
    <InputField label={label}>
      <div className="ImageField" disabled={disabled}>
        <div
          className="ImageField-imageContainer"
          style={{
            ...(maxHeight && { maxHeight }),
            ...(maxWidth && { maxWidth }),
          }}
        >
          {uploading ? <Spinner /> : null}
          {value ? (
            <img className="ImageField-image" src={value} alt={label} />
          ) : (
            <img
              alt={'default'}
              className="ImageField-image-missing"
              src={icons['defaultImageBg']}
            />
          )}
        </div>
        <input
          accept="image/*"
          disabled={disabled}
          className="ImageField-input"
          id="ImageField-file"
          onChange={e => handleUpload(e.target.files[0])}
          type="file"
        />
        {description ? (
          <div className="ImageField-description">{description}</div>
        ) : null}
        <label
          className="secondary-button-dark-medium ImageField-button"
          htmlFor="ImageField-file"
        >
          Upload image
        </label>
      </div>
    </InputField>
  );
};

export default ImageField;
