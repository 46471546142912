import isProfane from './profanity';

const validateNode = (node, user) => {
  let messages = [];

  if (node.abundance < 0) {
    messages.abundance = 'Please enter a positive number for abundance';
  }

  if (!node.name || node.name === '') {
    messages.name = 'Please enter a valid name';
  } else if (!user && isProfane(node.name)) {
    messages.name = 'Please remove profanity from name';
  }

  if (!user && isProfane(node.description)) {
    messages.description = 'Please remove profanity from description';
  }

  if (!node.trophicLevel || node.trophicLevel === '') {
    messages.trophicLevel = 'Please enter a valid trophic level';
  }

  if (
    !node.abundanceExaggeration ||
    node.abundanceExaggeration === '' ||
    node.abundanceExaggeration <= 0
  ) {
    messages.abundanceExaggeration =
      'Please enter a positive number for abundance exaggeration';
  }

  return {
    valid: Object.keys(messages).length === 0,
    messages,
  };
};

export default validateNode;
