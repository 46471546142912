import React from 'react';
import classnames from 'classnames';
import './BuildMatrixModal.scss';
import Button from 'components/inputs/Button';
import ButtonNew from 'components/inputs/ButtonNew';

const BuildMatrixFooterButtons = ({
  skip,
  goBack,
  goNext,
  backText = 'Back',
  nextText = 'Next',
  skipText = 'Skip',
  disableNext = false,
  disableBack = false,
  disableSkip = false,
}) => {
  return (
    <div className="BuildMatrixFooterButtons">
      {skip ? (
        <ButtonNew
          buttonClasses="secondary-button-medium"
          onClick={skip}
          label={skipText}
          disabled={disableSkip}
        />
      ) : (
        <div />
      )}
      <div className="sequential-buttons-container">
        {goBack ? (
          <ButtonNew
            buttonClasses={classnames('primary-button-medium', {
              disabled: disableBack,
            })}
            onClick={goBack}
            label={backText}
          />
        ) : null}
        {goNext ? (
          <ButtonNew
            buttonClasses={classnames('primary-button-medium', {
              disabled: disableNext,
            })}
            onClick={goNext}
            label={nextText}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BuildMatrixFooterButtons;
