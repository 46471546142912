import {
  NODE_SETTINGS_CONNECTION_ADD,
  NODE_SETTINGS_CONNECTION_DELETE,
  NODE_SETTINGS_CONNECTION_UPDATE,
  NODE_SETTINGS_START_EDIT,
  NODE_SETTINGS_SET_FIELD,
  NODE_SETTINGS_CANCEL_EDIT,
  NODE_SETTINGS_FINISH_EDIT,
  NODE_SETTINGS_VALIDATE,
} from 'constants/action_names';
import validateNode from 'validations/nodes';

const initialState = {
  matrix: {},
  nodeId: null,
  valid: true,
  validationMessages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NODE_SETTINGS_CONNECTION_ADD: {
      const node = { ...state.matrix.nodes[action.nodeFrom] };
      node.influences = {
        ...node.influences,
        [action.nodeTo]: action.value,
      };

      return {
        ...state,
        matrix: {
          ...state.matrix,
          nodes: {
            ...state.matrix.nodes,
            [action.nodeFrom]: node,
          },
        },
      };
    }
    case NODE_SETTINGS_CONNECTION_DELETE: {
      const nodes = { ...state.matrix.nodes };
      const node = { ...state.matrix.nodes[action.nodeFrom] };
      const influences = Object.fromEntries(
        Object.entries(node.influences).filter(
          ([to, value]) => to !== action.nodeTo
        )
      );
      nodes[action.nodeFrom] = {
        ...node,
        influences,
      };

      return {
        ...state,
        matrix: {
          ...state.matrix,
          nodes,
        },
      };
    }
    case NODE_SETTINGS_CONNECTION_UPDATE: {
      const { oldConnection, newConnection } = action;

      let nodes = {
        ...state.matrix.nodes,
      };

      // Remove connection from old connection node
      if (oldConnection.nodeFrom !== newConnection.nodeFrom) {
        let node = nodes[oldConnection.nodeFrom];

        nodes[oldConnection.nodeFrom] = {
          ...node,
          influences: Object.fromEntries(
            Object.entries(node.influences).filter(
              ([to, value]) => to !== oldConnection.nodeTo
            )
          ),
        };
      }

      // Update current connection
      let node = nodes[newConnection.nodeFrom];
      let filteredInfluences = { ...node.influences };

      // If nodeTo was updated, remove old nodeTo connection
      if (oldConnection.nodeTo !== newConnection.nodeTo) {
        filteredInfluences = Object.fromEntries(
          Object.entries(filteredInfluences).filter(
            ([to, value]) => to !== oldConnection.nodeTo
          )
        );
      }

      const influences = {
        ...filteredInfluences,
        [newConnection.nodeTo]: newConnection.value,
      };
      nodes[newConnection.nodeFrom] = { ...node, influences };

      return {
        ...state,
        matrix: {
          ...state.matrix,
          nodes,
        },
      };
    }
    case NODE_SETTINGS_START_EDIT:
      return {
        ...state,
        matrix: action.matrix,
        nodeId: action.nodeId,
        valid: true,
      };
    case NODE_SETTINGS_SET_FIELD:
      const nodes = { ...state.matrix.nodes };
      nodes[state.nodeId] = {
        ...nodes[state.nodeId],
        [action.field]: action.value,
      };
      return {
        ...state,
        matrix: {
          ...state.matrix,
          nodes,
        },
      };
    case NODE_SETTINGS_CANCEL_EDIT:
      return { ...initialState };
    case NODE_SETTINGS_FINISH_EDIT:
      return { ...initialState };
    case NODE_SETTINGS_VALIDATE:
      const node = state.matrix.nodes[state.nodeId];
      const { valid, messages } = validateNode(node, action.user);
      return {
        ...state,
        valid,
        validationMessages: messages,
      };
    default:
      return state;
  }
};
