import React, { useState, useEffect } from 'react';
import { startEdit } from 'actions/matrixSettings';
import { useSelector, useDispatch } from 'react-redux';
import ConnectionsTable from 'components/connectionsView/ConnectionsTable';
import Button from 'components/inputs/Button';
import ButtonNew from 'components/inputs/ButtonNew';
import ImportConnections from './ImportConnections';
import { exportConnections, getFilename } from 'export/export_connections';
import * as urls from 'constants/urls';
import './ConnectionsView.scss';

const ConnectionsView = ({ matrix, isExperiment, exitModal, onSave }) => {
  const [descriptor, setDescriptor] = useState(null);
  const dispatch = useDispatch();

  const editingMatrix = useSelector(state => state.matrixSettings.matrix);

  useEffect(() => {
    if (isExperiment && !matrix?.parentId) return;
    if (editingMatrix) return;
    dispatch(startEdit(matrix));
  }, [dispatch, isExperiment, matrix, editingMatrix]);

  if (isExperiment && !matrix?.parentId) return null;

  const handleExport = () => {
    exportConnections(matrix, getFilename(matrix));
  };

  return (
    <div className="ConnectionsView">
      <div className="ConnectionsView-header">Connections table</div>
      {isExperiment ? (
        <div className="ConnectionsView-description text-interface-medium-500">
          Add and remove connections between nodes in the table. Adjust the
          interaction strength.
        </div>
      ) : null}
      <div className="ConnectionsView-button-container">
        <div className="ConnectionsView-actions">
          <ButtonNew
            buttonClasses="text-button"
            label="Export"
            iconLeft="exportIcon"
            onClick={handleExport}
          />
          <ImportConnections matrix={matrix} isExperiment={isExperiment} />
        </div>
        <div className="ConnectionsView-descriptor-container">{descriptor}</div>
        <ButtonNew
          buttonClasses="secondary-button-small interactiveTourCreatingExperiment-11"
          label="Sensitivity Analysis"
          iconLeft="graphBar"
          onClick={onSave}
          to={
            isExperiment
              ? `${urls.experiment(matrix)}/uncertainty`
              : `${urls.matrix(matrix)}/uncertainty`
          }
        />
      </div>
      <div className="ConnectionsView-content">
        {editingMatrix ? (
          <ConnectionsTable
            matrix={editingMatrix}
            isExperiment={isExperiment}
            setDescriptor={setDescriptor}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ConnectionsView;
