import { getNode } from 'constants/nodes';

export const getConnectionsTo = (matrix, nodeId) => {
  const connections = [];
  Object.values(matrix.nodes).forEach(fromNode => {
    if (fromNode.influences[nodeId] !== undefined) {
      connections.push({
        name: fromNode.name,
        id: fromNode.id,
        value: fromNode.influences[nodeId],
      });
    }
  });
  return connections;
};

export const getConnectionsFrom = (matrix, nodeId) => {
  const node = getNode(matrix, nodeId);
  if (!node || !node.influences) return [];

  return Object.entries(node.influences)
    .filter(([id]) => Object.values(matrix.nodes).find(n => n.id === id))
    .map(([id, value]) => {
      return {
        name: getNode(matrix, id).name,
        id,
        value,
      };
    });
};

/*
 * Get a copy of the given node with no connections to the given nodeId.
 */
const nodeDeleteConnectionsTo = (node, nodeId) => {
  return {
    ...node,
    influences: Object.fromEntries(
      Object.entries(node.influences).filter(([id, value]) => id !== nodeId)
    ),
  };
};

/*
 * Get a copy of matrix with no connections to the given nodeId.
 */
export const deleteConnectionsTo = (matrix, nodeId) => {
  const newNodes = Object.fromEntries(
    Object.entries(matrix.nodes).map(([id, node]) => [
      id,
      nodeDeleteConnectionsTo(node, nodeId),
    ])
  );

  return {
    ...matrix,
    nodes: newNodes,
  };
};

/*
 * Update all of the connections from a given node at once.
 *
 * Expects an object where the keys are influenced node ids and the values are
 * the connection strengths.
 */
export const nodeUpdateConnections = (node, influences) => {
  return {
    ...node,
    influences,
  };
};

/*
 * Update all connections for a matrix based on an input object
 *
 * Expects an object where the keys are node ids and the values are objects,
 * those object keys are node ids of the influenced node and the values are the
 * values of the connection strengths.
 */
export const updateConnections = (matrix, connections) => {
  const newNodes = Object.fromEntries(
    Object.entries(matrix.nodes).map(([id, node]) => [
      id,
      nodeUpdateConnections(node, connections[id]),
    ])
  );

  return {
    ...matrix,
    nodes: newNodes,
  };
};
