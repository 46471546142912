import isProfane from './profanity';

const validateMatrix = (matrix, user) => {
  let messages = [];

  if (
    !matrix.name ||
    matrix.name === '' ||
    matrix.name === 'New Experiment' ||
    matrix.name === 'New Matrix'
  ) {
    messages.name = 'Please enter a valid name';
  } else if (!user && isProfane(matrix.name)) {
    messages.name = 'Please remove profanity from name';
  }

  if (!user && isProfane(matrix.description)) {
    messages.description = 'Please remove profanity from description';
  }

  if (isNaN(matrix.curveSteepness) || matrix.curveSteepness === '') {
    messages.curveSteepness = 'Please enter a valid curve steepness';
  }

  if (
    !(matrix.lambda >= 0 && matrix.lambda <= 1) ||
    matrix.lambda === '' ||
    isNaN(matrix.lambda)
  ) {
    messages.lambda = 'Please enter a valid lambda';
  }

  if (isNaN(matrix.tolerance) || matrix.tolerance === '') {
    messages.tolerance = 'Please enter a valid tolerance';
  }

  return {
    valid: Object.keys(messages).length === 0,
    messages,
  };
};

export default validateMatrix;
