export const sortTypes = [
  { label: 'Name', by: 'name', direction: 'ascending' },
  { label: 'User', by: 'createdBy', direction: 'ascending' },
  { label: '# Nodes', by: 'numberNodes', direction: 'descending' },
  {
    label: '# Connections',
    by: 'numberConnections',
    direction: 'descending',
  },
  { label: 'Date', by: 'modified', direction: 'descending' },
  { label: 'Sort by', by: 'order', direction: 'ascending' },
];

// Labels minus default
export const sortOptions = sortTypes
  .filter(obj => obj.by !== 'order')
  .map(obj => {
    return {
      label: obj.label,
      value: obj.by,
    };
  });
