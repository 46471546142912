import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './BuildMatrixModal.scss';
import {
  ActivationFunction,
  DensitiesInput,
  PopulationUnitInput,
} from 'components/nodes/DensitiesContent';
import { setField } from 'actions/nodeSettings';
import { populationUnits } from 'constants/nodes';
import AbundanceSlider from 'components/inputs/AbundanceSlider';

import text from 'constants/text';

const steps = ['abundanceRange', 'abundances', 'activationFunction'];

const EditAbundanceRange = ({ node }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="column EditAbundanceRange">
        <div className="header text-interface-large">Abundance Range</div>
        <div className="description text-interface-medium">
          {text.buildAMatrixGuide.editNodes.populationDensities.abundanceRange}
        </div>
      </div>
      <div className="column EditAbundanceRange NodeSettingsSectionConnections">
        <DensitiesInput
          membershipFunctions={node.membershipFunctions}
          mean={node.mean}
        />
        <PopulationUnitInput
          onChange={inputValue => {
            dispatch(
              setField(
                'populationUnit',
                populationUnits.find(obj => {
                  return obj.displayName === inputValue;
                }).name
              )
            );
          }}
          value={node.populationUnit}
        />
      </div>
    </>
  );
};

const EditAbundances = ({ node }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="column EditAbundanceRange">
        <div className="header text-interface-large">Assign abundances</div>
        <div className="description text-interface-medium">
          {text.buildAMatrixGuide.editNodes.basicSettings.abundances}
        </div>
      </div>
      <div className="column EditAbundanceRange NodeSettingsSectionConnections">
        <AbundanceSlider
          changeable
          node={node}
          onChange={value => dispatch(setField('abundance', value))}
          value={node?.abundance}
          label={'Abundance'}
        />
      </div>
    </>
  );
};

const EditActivationFunction = ({ node }) => {
  return (
    <>
      <div className="column EditActivationFunction">
        <div className="header text-interface-large">Activation Function</div>
        <div className="description text-interface-medium">
          {
            text.buildAMatrixGuide.editNodes.populationDensities
              .activationFunction
          }
        </div>
      </div>
      <div className="column EditActivationFunction">
        <ActivationFunction node={node} />
      </div>
    </>
  );
};

const BuildMatrixEditNodesPopulationDensities = ({ step }) => {
  const editMatrix = useSelector(state => state.nodeSettings.matrix);
  const editNodeId = useSelector(state => state.nodeSettings.nodeId);

  const node = useMemo(() => {
    return editMatrix?.nodes?.[editNodeId];
  }, [editMatrix, editNodeId]);

  const returnComponentForStep = step => {
    switch (step) {
      case 'abundanceRange': {
        return <EditAbundanceRange node={node} />;
      }
      case 'abundances': {
        return <EditAbundances node={node} />;
      }
      case 'activationFunction': {
        return <EditActivationFunction node={node} />;
      }
      default: {
        return null;
      }
    }
  };

  if (!node) return null;

  return (
    <div className="BuildMatrixEditNodesPopulationDensities BuildMatrixEditNodesContent">
      {returnComponentForStep(step)}
    </div>
  );
};

export default BuildMatrixEditNodesPopulationDensities;
