import { MODAL_ENTER, MODAL_EXIT } from 'constants/action_names';

const initialState = {
  enabled: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ENTER:
      return {
        ...state,
        enabled: true,
      };
    case MODAL_EXIT:
      return {
        ...state,
        enabled: false,
      };
    default:
      return state;
  }
};
