import { LOGIN_PROMPT_CLOSE, LOGIN_PROMPT_OPEN } from 'constants/action_names';

export const openLoginPrompt = (message, destinationUrl) => {
  return dispatch => {
    dispatch({
      type: LOGIN_PROMPT_OPEN,
      destinationUrl,
      message,
    });
  };
};

export const closeLoginPrompt = () => {
  return dispatch => {
    dispatch({
      type: LOGIN_PROMPT_CLOSE,
    });
  };
};
