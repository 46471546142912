import get from 'lodash.get';
import set from 'lodash.set';
import textInputs from './inputs.json';

const parseTextInputs = () => {
  const dictionary = {};
  textInputs.forEach(({ id, text }) => set(dictionary, id, text));
  return dictionary;
};

const allText = parseTextInputs();

export default allText;

export const getTextByPath = path => get(allText, path);
