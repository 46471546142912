import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getExperiment } from 'constants/matrices';
import ExperimentResults from 'components/ExperimentResults';
import './StatsView.scss';
import StatsPage from './StatsPage';
import { allStats } from 'constants/stats';
import SectionHeader from 'components/SectionHeader';

// This one does have the modification sidebar
export const StatsExperimentView = ({ matrix }) => {
  const { experimentId } = useParams();
  const matrices = useSelector(state => state.matrices.matrices);
  const experiment = getExperiment(matrices, matrix, experimentId);
  const view = 'statistics';

  return (
    <div className="StatsView">
      <ExperimentResults matrix={matrix} experiment={experiment} view={view} />
    </div>
  );
};

// This one does not have the modification sidebar
export const StatsMatrixView = ({ matrix }) => {
  return (
    <div className="StatsView">
      <StatsPage allStats={allStats(matrix)} />
    </div>
  );
};
