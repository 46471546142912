import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { update as updateMatrix } from 'actions/matrixSettings';
import './ConnectionCellInput.scss';

const validNumberRegex = `^-?[0-9]?\\d*(\\.\\d+)?$`;

const ConnectionCellInput = ({
  highlightedColumn,
  highlightedRow,
  influencerNode,
  influencedNode,
  matrix,
}) => {
  const dispatch = useDispatch();

  const isViewOnly = useMemo(() => {
    return !!matrix?.isViewOnly;
  }, [matrix]);

  const value = influencerNode.influences[influencedNode.id];
  const highlighted =
    influencerNode.id === highlightedRow ||
    influencedNode.id === highlightedColumn;
  const selected =
    influencerNode.id === highlightedRow &&
    influencedNode.id === highlightedColumn;

  const [cellInput, setCellInput] = useState(
    value !== undefined ? Number(value) : 0
  );
  const [tempCellInput, setTempCellInput] = useState(null);

  const validateValue = value => {
    if (isNaN(parseFloat(value))) return value;
    if (value > 1) return 1;
    if (value < -1) return -1;
    return value;
  };

  useEffect(() => {
    setCellInput(value !== undefined ? Number(value) : 0);
  }, [value]);

  useEffect(() => {
    const comparisonValue = value !== undefined ? Number(value) : 0;

    if (
      !isNaN(cellInput) &&
      !isNaN(comparisonValue) &&
      cellInput !== comparisonValue
    ) {
      const nextValue = validateValue(cellInput);

      let nextMatrix = { ...matrix };

      if (nextValue === 0) {
        delete nextMatrix?.nodes?.[influencerNode.id]?.influences?.[
          influencedNode.id
        ];
      } else {
        nextMatrix = {
          ...nextMatrix,
          nodes: {
            ...nextMatrix.nodes,
            [influencerNode.id]: {
              ...nextMatrix.nodes[influencerNode.id],
              influences: {
                ...(nextMatrix.nodes[influencerNode.id]?.influences ?? {}),
                [influencedNode.id]: nextValue,
              },
            },
          },
        };
      }

      dispatch(updateMatrix(nextMatrix));
    }
  }, [dispatch, matrix, value, influencedNode, influencerNode, cellInput]);

  // Handle the input
  const onChange = useCallback(val => {
    setTempCellInput(val);
    const matches = val.match(validNumberRegex);
    if (!matches) return;
    const boundedVal = Math.min(Math.max(-1, val), 1);
    setCellInput(Number(boundedVal));
  }, []);

  const onFocus = useCallback(() => {
    setTempCellInput(cellInput);
  }, [cellInput]);

  const onBlur = useCallback(() => {
    setTempCellInput(null);
  }, []);

  const onButtonChange = useCallback(
    val => {
      let boundedVal = Math.min(Math.max(-1, val), 1);
      boundedVal = Math.round(boundedVal * 100) / 100;
      onChange(`${boundedVal}`);
    },
    [onChange]
  );

  return (
    <div className="ConnectionCell">
      <input
        type="text"
        pattern={validNumberRegex}
        className={classNames('connection-value', {
          positive: cellInput > 0,
          negative: cellInput < 0,
          zero: cellInput === 0,
          highlighted,
          selected,
        })}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={isViewOnly}
        value={tempCellInput !== null ? `${tempCellInput}` : `${cellInput}`}
      />
      {selected ? (
        <div className="ConnectionCell-button-container">
          <div
            className={classNames('ConnectionCell-button', {
              disabled: cellInput >= 1,
            })}
            onClick={() => onButtonChange(`${cellInput + 0.01}`)}
          >
            <div
              className={classNames('ConnectionCell-button-icon-up', {
                disabled: cellInput >= 1,
              })}
            ></div>
          </div>
          <div
            className={classNames('ConnectionCell-button', {
              disabled: cellInput <= -1,
            })}
            onClick={() => onButtonChange(`${cellInput - 0.01}`)}
          >
            <div
              className={classNames('ConnectionCell-button-icon-down', {
                disabled: cellInput <= -1,
              })}
            ></div>
          </div>
        </div>
      ) : null}
      {/* <input
        id={`${influencerNode.id}-${influencedNode.id}`}
        className={classNames('connection-value', {
          positive: cellInput > 0,
          negative: cellInput < 0,
          zero: cellInput === 0,
          highlighted,
          selected,
        })}
        value={`${cellInput}`}
        onChange={e => setCellInput(Number(e.target.value))}
        type="number"
        disabled={isViewOnly}
        min={-1}
        max={1}
        step={0.01}
      ></input> */}
    </div>
  );
};

export default ConnectionCellInput;
