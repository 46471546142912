import React from 'react';
import './GalleryFavoriteList.scss';
import icons from 'img/icons';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import * as urls from 'constants/urls';
import Icon from 'components/Icon';
import ViewOnlyBadge from './matrices/ViewOnlyBadge';

const GalleryFavoriteItem = ({ favorite, onClick }) => {
  const { isFolder } = favorite;
  const history = useHistory();

  const click = () => {
    if (isFolder) {
      history.push(urls.folder(favorite));
    } else {
      onClick();
    }
  };

  return (
    <div className="GalleryFavoriteItem" onClick={click}>
      <div
        className={classNames('GalleryFavoriteItem-favorite-image', {
          'GalleryFavoriteItem-favorite-image-bg-color': !isFolder,
        })}
      >
        <img src={isFolder ? icons.folder : favorite.imageUrl ?? null} alt="" />
      </div>
      <div className="GalleryFavoriteItem-text-container">
        <div className="GalleryFavoriteItem-favorite-name">{favorite.name}</div>
        <div className="GalleryFavoriteItem-bottom-row">
          <div className="text-interface-plain GalleryFavoriteItem-modified-date-text">
            {favorite.modified?.format('MM/DD/YY')}
          </div>
          {favorite?.isViewOnly ? <ViewOnlyBadge /> : null}
        </div>
      </div>
    </div>
  );
};

const GalleryFavoriteList = ({ favorites, onClick }) => {
  return (
    <>
      <div className="GalleryFavoriteList">
        <div className="GalleryFavoriteList-favorite-header">
          <div className="GalleryFavoriteList-favorite-icon">
            <Icon className="icon-green" icon="filledStar" alt="favorite" />
          </div>
          Favorites
        </div>
        <div className="GalleryFavoriteList-favorites-row">
          {!favorites.length ? (
            <div className="GalleryFavoriteList-empty-state">
              Favorites will appear here
            </div>
          ) : (
            favorites.map((item, i) => (
              <GalleryFavoriteItem
                key={i}
                favorite={item}
                onClick={() => onClick(item)}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default GalleryFavoriteList;
