import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import InputField from 'components/inputs/InputField';
import './TextField.scss';
import Icon from 'components/Icon';

const TextField = ({
  className,
  disabled,
  isFocused,
  label,
  onBlur,
  onChange,
  placeholder,
  tooltip,
  type,
  step,
  value,
  validationMessage,
  theme,
  ...restProps
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (isFocused) {
      inputRef.current.select();
    }
  }, [isFocused, inputRef]);

  return (
    <div className={classnames('TextField', { dark: theme === 'dark' })}>
      <InputField
        className={className}
        label={label}
        tooltip={tooltip}
        validationMessage={validationMessage}
      >
        <input
          className="TextField-input text-interface-medium"
          disabled={disabled}
          onBlur={e => (onBlur ? onBlur(e.target.value) : null)}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          ref={inputRef}
          type={type}
          step={step}
          value={value == null ? '' : value}
          {...restProps}
        />
        {theme === 'dark' ? (
          <Icon icon="pencilGray" className="icon-white" />
        ) : null}
      </InputField>
    </div>
  );
};

export default TextField;
