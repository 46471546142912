import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router';
import { useTour } from '@reactour/tour';
import MatrixView from 'components/MatrixView';
import NoAccessView from 'components/NoAccessView';
import MainMenu from 'components/menus/MainMenu';
import SplashPage from 'components/About/SplashPage';
import GalleryView from 'components/GalleryView';
import AboutPage from 'components/About/AboutPage';
import Feedback from 'components/Feedback';
import SmallScreenWarning from 'components/SmallScreenWarning';
import MiniModal from 'components/MiniModal';
import Icon from 'components/Icon';
import { loadUserData, setUser } from 'actions/auth';
import firebase from 'constants/firebase';

import './App.scss';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const HowToModal = ({
  closeModal,
  onClickGettingStarted,
  onClickCreateExperiment,
}) => {
  return (
    <MiniModal dark={true} onExit={closeModal}>
      <div className="HowToModal">
        <div className="HowToModal-title">How to use guides</div>
        <div className="HowToModal-description">
          Learn how to use the Matrix by stepping through one of our
          walkthroughs. If you have a specific question you need clarifying, try
          searching our FAQs.
        </div>
        <div className="HowToModal-buttons-container">
          <div className="HowToModal-button" onClick={onClickGettingStarted}>
            <Icon icon="howToGettingStarted" />
            <div className="HowToModal-button-title">Getting started</div>
            <div className="HowToModal-button-description">
              Start here if you’re new to the MPG Matrix or need a refresher on
              how it works
            </div>
          </div>
          <div className="HowToModal-button" onClick={onClickCreateExperiment}>
            <Icon icon="howToCreatingAnExperiment" />
            <div className="HowToModal-button-title">
              Creating an experiment
            </div>
            <div className="HowToModal-button-description">
              Learn how to take the next step by creating your own experiments
              here
            </div>
          </div>
        </div>
      </div>
    </MiniModal>
  );
};

function App() {
  const dispatch = useDispatch();

  const modalEnabled = useSelector(state => state.modal.enabled);

  const { user } = useSelector(state => state.auth);

  const [whichTourModal, setWhichTourModal] = useState(false);

  useEffect(() => {
    if (modalEnabled) {
      document.body.classList.add('modalEnabled');
    } else {
      document.body.classList.remove('modalEnabled');
    }
  }, [modalEnabled]);

  const auth = useSelector(state => state.auth);

  useEffect(() => {
    let unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (auth.user && !user) {
        dispatch(setUser(null));
      }

      if (!user) return;

      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(async idTokenResult => {
          if (!auth.user || idTokenResult.token !== auth.user.token) {
            dispatch(setUser(idTokenResult));
            dispatch(loadUserData(idTokenResult.claims.user_id));
          }
        });
    });

    return () => {
      unregisterAuthObserver();
    };
  }, [dispatch, auth]);

  const location = useLocation();
  const {
    currentStep,
    isOpen,
    setCurrentStep,
    steps,
    setWhichTour,
    setIsOpen,
  } = useTour();
  useEffect(() => {
    const currentStepPathname = steps[currentStep].pathname;
    if (
      isOpen &&
      location.pathname === '/gallery' &&
      currentStepPathname &&
      currentStepPathname !== location.pathname
    ) {
      setCurrentStep(1);
    }
  }, [currentStep, isOpen, location.pathname, setCurrentStep, steps]);

  const openGettingStartedTour = useCallback(() => {
    setWhichTour('get-started');
    setCurrentStep(0);
    setIsOpen(true);
    setWhichTourModal(false);
  }, [setWhichTour, setCurrentStep, setIsOpen]);

  const openCreatingAnExperimentTour = useCallback(() => {
    setWhichTour('make-experiment');
    setCurrentStep(0);
    setIsOpen(true);
    setWhichTourModal(false);
  }, [setWhichTour, setCurrentStep, setIsOpen]);

  return (
    <div className="App">
      <>
        <ScrollToTop />
        <SmallScreenWarning />
        <div className="App-content">
          <MainMenu setWhichTourModal={setWhichTourModal} />

          <Route path="/matrices/:matrix" component={MatrixView} />
          <Route path="/no-access" component={NoAccessView} />
          <Route path="/" exact>
            <SplashPage setWhichTourModal={setWhichTourModal} />
          </Route>
          <Route path="/gallery" component={GalleryView} />
          <Route path="/about" exact component={AboutPage} />
          {user ? <Route path="/feedback" exact component={Feedback} /> : null}
        </div>
        {whichTourModal ? (
          <HowToModal
            closeModal={() => setWhichTourModal(false)}
            onClickGettingStarted={openGettingStartedTour}
            onClickCreateExperiment={openCreatingAnExperimentTour}
          />
        ) : null}
      </>
    </div>
  );
}

export default App;
