import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './BuildMatrixModal.scss';
import Icon from 'components/Icon';
import ImageField from 'components/inputs/ImageField';
import TextArea from 'components/inputs/TextArea';
import { setField } from 'actions/nodeSettings';
import { trophicLevels } from 'constants/nodes';
import ButtonSet from 'components/inputs/ButtonSet';
import text from 'constants/text';

// Need better connection (constants) to parent component substeps
const settingsSteps = ['photo', 'description', 'trophicLevel', 'nodeType'];

const isPastStep = (check, step) => {
  const index = settingsSteps.findIndex(s => s === check);
  const isPast = settingsSteps.slice(index).includes(step);
  return isPast;
};

const BuiltNode = ({ node, step }) => {
  const { name, displayImageUrl, description, trophicLevel, fixed } = node;
  const level = trophicLevels.find(tl => tl.name === trophicLevel);
  return (
    <div className="BuiltNode">
      <div className="BuiltNode-header text-display">{name}</div>
      <div className="BuiltNode-container">
        <div className="BuiltNode-image-container">
          <img src={displayImageUrl ? displayImageUrl : null} alt="" />
        </div>

        <div className="BuiltNode-info text-interface-medium">
          {description}
        </div>
      </div>
      <div className="BuiltNode-badge-container">
        {isPastStep('trophicLevel', step) ? (
          <h4
            className="TrophicLevelLabel EditTrophicLevel-button text-interface-small"
            style={{ '--color': level?.color }}
          >
            <img
              className="TrophicLevelLabelIcon"
              src={level?.icon}
              alt={level?.name}
            />
            {level?.name}
          </h4>
        ) : null}
        {isPastStep('nodeType', step) ? (
          <div className="BuiltNode-button text-interface-small">
            {fixed ? 'Input' : 'Output'}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const EditPhoto = ({ node }) => {
  const dispatch = useDispatch();
  return (
    <div className="EditPhoto">
      <div className="header text-interface-large">Add a photo</div>
      <div className="description text-interface-medium">
        {text.buildAMatrixGuide.editNodes.basicSettings.photo}
      </div>
      <div className="upload-button">
        <ImageField
          disabled={false}
          folder="nodes"
          onChange={value => {
            dispatch(setField('imageUrl', value));
            dispatch(setField('displayImageUrl', value));
          }}
          value={node?.displayImageUrl}
        />
      </div>
    </div>
  );
};

const EditDescription = ({ node }) => {
  const dispatch = useDispatch();
  const { validationMessages } = useSelector(state => state.nodeSettings);

  return (
    <div className="EditDescription">
      <div className="header text-interface-large">Add a description</div>
      <div className="description text-interface-medium">
        {text.buildAMatrixGuide.editNodes.basicSettings.description}
      </div>
      <div className="text-container">
        <TextArea
          disabled={false}
          onChange={value => dispatch(setField('description', value))}
          placeholder={'Add a short description of your node.'}
          value={node?.description}
          validationMessage={validationMessages.description}
        />
      </div>
    </div>
  );
};

const EditTrophicLevel = ({ node }) => {
  const dispatch = useDispatch();
  return (
    <div className="EditTrophicLevel">
      <div className="header text-interface-large">Assign a trophic level</div>
      <div className="description text-interface-medium">
        {text.buildAMatrixGuide.editNodes.basicSettings.trophicLevel}
      </div>
      {trophicLevels.map(level => {
        const { color, icon, name } = level;
        return (
          <h4
            key={name}
            className="TrophicLevelLabel EditTrophicLevel-button"
            style={{ '--color': color }}
            onClick={() => dispatch(setField('trophicLevel', name))}
          >
            <img className="TrophicLevelLabelIcon" src={icon} alt={name} />
            {name}
          </h4>
        );
      })}
    </div>
  );
};

const EditNodeType = ({ node }) => {
  const dispatch = useDispatch();
  return (
    <div className="EditNodeType">
      <div className="header text-interface-large">Assign a node type</div>
      <div className="description text-interface-medium">
        {text.buildAMatrixGuide.editNodes.basicSettings.nodeType}
      </div>
      <ButtonSet
        disabled={false}
        options={[
          { value: 'Input', label: 'Input', icon: 'inputicon' },
          { value: 'Output', label: 'Output', icon: 'outputicon' },
        ]}
        onChange={value => dispatch(setField('fixed', value === 'Input'))}
        value={node?.fixed ? 'Input' : 'Output'}
      />
    </div>
  );
};

const BuildMatrixEditNodesBasicSettings = ({ step }) => {
  const editMatrix = useSelector(state => state.nodeSettings.matrix);
  const editNodeId = useSelector(state => state.nodeSettings.nodeId);

  const node = useMemo(() => {
    return editMatrix?.nodes?.[editNodeId];
  }, [editMatrix, editNodeId]);

  const returnComponentForStep = step => {
    switch (step) {
      case 'photo': {
        return <EditPhoto node={node} />;
      }
      case 'description': {
        return <EditDescription node={node} />;
      }
      case 'trophicLevel': {
        return <EditTrophicLevel node={node} />;
      }
      case 'nodeType': {
        return <EditNodeType node={node} />;
      }

      default: {
        return null;
      }
    }
  };

  if (!node) return null;

  return (
    <>
      <div className="BuildMatrixEditNodesBasicSettings BuildMatrixEditNodesContent">
        <div className="column">
          <BuiltNode node={node} step={step} />
        </div>
        <div className="column">{returnComponentForStep(step)}</div>
      </div>
    </>
  );
};

export default BuildMatrixEditNodesBasicSettings;
