import classNames from 'classnames';
import React from 'react';
import ConnectionsHistogram from './ConnectionsHistogram';
import StrengthsHistogram from './StrengthsHistogram';
import text from 'constants/text';
import './StatsView.scss';

//make this update with changes in modifications
const StatsPage = ({ allStats, experimentName }) => {
  //definitions and labels for all stats
  const connectanceCell = {
    name: 'CONNECTANCE',
    value: allStats.advanced.connectance.toFixed(2),
    def: text.statsView.connectance,
  };
  const modularityCell = {
    name: 'MODULARITY',
    value: allStats.advanced.modularity.toFixed(2),
    def:
      'Degree to which subsets of species are highly connected or independent of other species.',
  };
  const characteristicPathLengthCell = {
    name: 'CHARACTERISTIC PATH LENGTH',
    value:
      allStats.advanced.characteristicPathLength === 'Infinity'
        ? '∞'
        : allStats.advanced.characteristicPathLength.toFixed(2),
    def: text.statsView.pathlength,
  };
  const connectionsHistogramInputs = {
    title: 'Node Connections',
    avgCell: {
      name: 'AVERAGE CONNECTIONS PER NODE',
      value: allStats.connection.avg.toFixed(2),
      def: text.statsView.avgConnections,
    },
    stdevCell: {
      name: 'NODE CONNECTIONS STDEV',
      value: allStats.connection.stdev.toFixed(2),
      def: text.statsView.stdevConnections,
    },
    histTitle: 'NODE CONNECTION DISTRIBUTION',
    histDesc: 'Distribution of connections per node for the matrix.',
    histID: 'connections-hist',
    histArray: allStats.connection.histogram,
    histAxisText: text.statsView.distributionConnections,
  };
  const strengthsHistogramInputs = {
    title: 'Interaction Strengths',
    avgCell: {
      name: 'AVERAGE INTERACTION STRENGTH',
      value: allStats.interactionStrength.avg.toFixed(2),
      def: text.statsView.avgStrengths,
    },
    stdevCell: {
      name: 'INTERACTION STRENGTH STDEV',
      value: allStats.interactionStrength.stdev.toFixed(2),
      def: text.statsView.stdevStrengths,
    },
    histTitle: 'NODE INTERACTION STRENGTH DISTRIBUTION',
    histDesc: 'Distribution of interaction strengths for the matrix.',
    histID: 'strengths-hist',
    histArray: allStats.interactionStrength.histogram,
    histAxisText: text.statsView.distributionStrengths,
  };

  return (
    <div className="stats">
      <div className="stats-layout">
        <div className={classNames('stats-column', 'non-histogram-flex')}>
          {/* basic stats */}
          <div className="stats-section">
            <div className="stats-section-title">At a Glance</div>
            <div className="stats-row">
              <div className="stats-subgroup">
                <div className="stats-value">{allStats.basic.numberNodes}</div>
                <div className="stats-name">TOTAL NODES</div>
              </div>
              <div className="stats-subgroup">
                <div className="stats-value">
                  {allStats.basic.numberConnections}
                </div>
                <div className="stats-name">TOTAL CONNECTIONS</div>
              </div>
            </div>
          </div>
          {/* advanced stats */}
          <div className="stats-section">
            <div className="stats-section-title">Deeper Dive</div>
            <div className="stats-column">
              {statCell(connectanceCell)}
              <div className="stats-dividing-bar"></div>
              {statCell(modularityCell)}
              <div className="stats-dividing-bar"></div>
              {statCell(characteristicPathLengthCell)}
            </div>
          </div>
        </div>
        {/* histograms */}
        <div className={classNames('stats-column', 'histogram-flex')}>
          {histogramCell(connectionsHistogramInputs)}
          {histogramCell(strengthsHistogramInputs)}
        </div>
      </div>
    </div>
  );

  function histogramCell({
    title,
    avgCell,
    stdevCell,
    histTitle,
    histDesc,
    histID,
    histArray,
    histAxisText,
  }) {
    return (
      <div className="stats-section">
        <div className="stats-section-title">{title}</div>
        <div className="stats-row">
          <div className="stats-column">
            {statCell(avgCell)}
            <div className="stats-dividing-bar"></div>
            {statCell(stdevCell)}
          </div>
          <div className="stats-dividing-bar-vertical"></div>
          <div id={histID} className="stats-histogram">
            {histID === 'connections-hist' ? (
              <ConnectionsHistogram
                data={histArray}
                type={histID}
                axisText={histAxisText}
              />
            ) : null}
            {histID === 'strengths-hist' ? (
              <StrengthsHistogram
                data={histArray}
                type={histID}
                axisText={histAxisText}
              />
            ) : null}
            <div className="stats-name">{histTitle}</div>
            <div className="stats-definition">{histDesc}</div>
          </div>
        </div>
      </div>
    );
  }

  function statCell({ name, value, def }) {
    return (
      <div className="stats-advanced-subgroup">
        <div
          className={classNames(
            'stats-value',
            value === '∞' ? 'infinity' : null
          )}
        >
          {value}
        </div>
        <div className="stats-name">{name}</div>
        <div className="stats-definition">{def}</div>
      </div>
    );
  }
};

export default StatsPage;
