import React from 'react';
import { components } from 'react-select';
import Icon from 'components/Icon';
import Dropdown from 'components/inputs/Dropdown';
import './ConnectionDropdown.scss';

const ConnectionSingleValue = ({ children, ...props }) => {
  const { direction, currentNode, isPositive } = props.selectProps;

  let icon;
  if (isPositive) {
    icon = (
      <Icon
        className="ConnectionSingleValue-icon"
        alt="positively influences"
        icon="rightArrowPositive"
      />
    );
  } else {
    icon = (
      <Icon
        className="ConnectionSingleValue-icon"
        alt="negatively influences"
        icon="rightArrowNegative"
      />
    );
  }

  let inner;
  if (direction === 'to') {
    inner = (
      <div className="ConnectionSingleValue">
        {children}
        {icon}
        <span className="ConnectionSingleValue-currentNode">{currentNode}</span>
      </div>
    );
  } else {
    inner = (
      <div className="ConnectionSingleValue">
        <span className="ConnectionSingleValue-currentNode">{currentNode}</span>
        {icon}
        {children}
      </div>
    );
  }
  return <components.SingleValue {...props}>{inner}</components.SingleValue>;
};

const ConnectionDropdown = ({
  connection,
  disabled,
  isFocused,
  node,
  nodes,
  direction,
  isPositive,
  onChange,
}) => {
  const modalRef = document.getElementById('matrix-modal');

  const makeOption = value => ({ label: value, value });

  return (
    <Dropdown
      components={{ SingleValue: ConnectionSingleValue }}
      disabled={disabled}
      isFocused={isFocused}
      options={nodes
        .map(node => node.name)
        .sort()
        .map(makeOption)}
      value={connection.name}
      currentNode={node.name}
      direction={direction}
      isPositive={isPositive}
      onChange={value => {
        const id = nodes.filter(n => n.name === value)[0].id;
        onChange(id, connection.value);
      }}
      menuPortalTarget={document.body}
      closeMenuOnScroll={event =>
        modalRef && modalRef.firstChild.contains(event.target)
      }
    />
  );
};

export default ConnectionDropdown;
