import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { openLoginPrompt } from 'actions/loginPrompt';
import Icon from 'components/Icon';
import './ButtonNew.scss';

const ButtonNew = ({
  alt,
  buttonClasses,
  iconClasses,
  imgClasses,
  icon,
  iconLeft,
  img,
  imgLeft,
  label,
  onClick,
  requiresLogin,
  requiresLoginMessage,
  requiresLoginUrl,
  to,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  function handleClick(e) {
    e.stopPropagation();
    if (requiresLogin && !user) {
      dispatch(openLoginPrompt(requiresLoginMessage, requiresLoginUrl));
      return;
    }
    if (to) {
      history.push(to);
    }
    if (onClick) {
      onClick();
    }
  }

  const displayIcon = (
    <Icon
      className={classNames(`${iconClasses}`, {
        'ButtonNew-icon-left': iconLeft,
        'ButtonNew-icon-right': !iconLeft,
      })}
      alt={alt}
      icon={iconLeft ? iconLeft : icon}
    />
  );

  const displayImg = (
    <img
      className={classNames(`${imgClasses}`, {
        'ButtonNew-icon-left': imgLeft,
        'ButtonNew-icon-right': !imgLeft,
      })}
      alt={alt}
      src={imgLeft ? imgLeft : img}
    />
  );

  return (
    <div
      onClick={
        buttonClasses && buttonClasses.includes('disabled')
          ? () => false
          : handleClick
      }
      className={classNames('ButtonNew', buttonClasses, {
        hasLabel: label !== undefined,
        hasIcon: icon !== undefined,
      })}
    >
      {iconLeft ? displayIcon : null}
      {imgLeft ? displayImg : null}
      {label ? <div className="ButtonNew-label">{label}</div> : null}
      {icon ? displayIcon : null}
      {img ? displayImg : null}
    </div>
  );
};

export default ButtonNew;
