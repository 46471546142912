import uuidv4 from 'uuid/v4';
import { getValueForAbundance } from 'constants/fcm';
import icons from 'img/icons';

export const getNode = (matrix, id) => matrix.nodes[id];

export const populationUnits = [
  {
    name: 'individuals',
    displayName: 'Individuals',
    precision: 0,
  },
  {
    name: 'in/year',
    displayName: 'in/Year',
    precision: 1,
  },
  {
    name: 'percent cover',
    displayName: 'Percent Cover',
    precision: 1,
  },
  {
    name: 'density',
    displayName: 'Density',
    precision: 2,
  },
];

export const trophicLevels = [
  {
    name: 'Anthropogenic Factors',
    color: '#D6E4EE',
    icon: icons.trophicAnthropogenic,
  },
  {
    name: 'Secondary Consumers',
    color: '#FAE3DE',
    icon: icons.trophicSecondaryConsumers,
  },
  {
    name: 'Primary Consumers',
    color: '#E6DEED',
    icon: icons.trophicPrimaryConsumers,
  },
  {
    name: 'Primary Producers',
    color: '#DEECDC',
    icon: icons.trophicPrimaryProducers,
  },
  {
    name: 'Decomposers',
    color: '#E3E2E0',
    icon: icons.trophicDecomposers,
  },
  {
    name: 'Abiotic Factors',
    color: '#FAF3CC',
    icon: icons.trophicAbiotic,
  },
];

export const activationFunctions = [
  { name: 'logistic', displayName: 'Logistic' },
  { name: 'exponential', displayName: 'Exponential' },
];

export const defaultActivationFunction = 'exponential';

const getInfluenced = (matrix, id) => {
  let influenced = {};

  Object.keys(matrix.nodes).forEach(id1 => {
    if (matrix.nodes[id1].influences.hasOwnProperty(id)) {
      influenced[id1] = matrix.nodes[id1].influences[id];
    }
  });

  return influenced;
};

export const createNode = (trophicLevel = trophicLevels[0].name) => ({
  id: uuidv4().slice(0, 6),
  abundance: 0.5,
  abundanceExaggeration: 1,
  activationFunction: defaultActivationFunction,
  fixed: false,
  imageUrl: null,
  influences: {},
  mean: 50,
  membershipFunctions: {
    HIGH: [
      [45, 0],
      [75, 1],
      [100, 1],
    ],
    LOW: [
      [0, 1],
      [25, 1],
      [55, 0],
    ],
    MOD: [
      [0, 0],
      [50, 1],
      [100, 0],
    ],
  },
  name: 'Edit Node',
  populationUnit: 'individuals',
  trophicLevel,
});

export const compareNodes = (matrixA, matrixB, id) => {
  const overrides = [];

  const simpleComparisonFields = [
    'abundanceExaggeration',
    'activationFunction',
    'fixed',
    'imageUrl',
    'mean',
    'name',
    'populationUnit',
    'trophicLevel',
  ];

  simpleComparisonFields.forEach(f => {
    if (matrixB.nodes[id][f] !== matrixA.nodes[id][f]) {
      overrides.push({
        type: f,
        to: matrixB.nodes[id][f],
        from: matrixA.nodes[id][f],
        id,
        revert: {
          ...matrixB,
          nodes: {
            ...matrixB.nodes,
            [id]: { ...matrixB.nodes[id], [f]: matrixA.nodes[id][f] },
          },
        },
      });
    }
  });

  //create defuzzified abundance overrrides
  if (matrixB.nodes[id]['abundance'] !== matrixA.nodes[id]['abundance']) {
    overrides.push({
      type: 'abundance',
      to: getValueForAbundance(
        matrixB.nodes[id],
        matrixB.nodes[id]['abundance']
      ),
      from: getValueForAbundance(
        matrixA.nodes[id],
        matrixA.nodes[id]['abundance']
      ),
      id,
      unit: matrixB.nodes[id]['populationUnit'],
      revert: {
        ...matrixB,
        nodes: {
          ...matrixB.nodes,
          [id]: {
            ...matrixB.nodes[id],
            abundance: matrixA.nodes[id]['abundance'],
          },
        },
      },
    });
  }

  const oldInfluences = matrixA.nodes[id].influences;
  const newInfluences = matrixB.nodes[id].influences;
  const allInfluences = Object.keys({ ...oldInfluences, ...newInfluences });

  allInfluences.forEach(influenceId => {
    if (!matrixB.nodes.hasOwnProperty(influenceId)) return;
    if (oldInfluences[influenceId] !== newInfluences[influenceId]) {
      let newNodes = {
        ...matrixB.nodes,
        [id]: {
          ...matrixB.nodes[id],
          influences: {
            ...matrixB.nodes[id].influences,
            [influenceId]: oldInfluences[influenceId] || 0,
          },
        },
      };

      if (!oldInfluences[influenceId]) {
        delete newNodes[id].influences[influenceId];
      }

      overrides.push({
        type: 'connection',
        connection: {
          from: id,
          to: influenceId,
        },
        id,
        from: oldInfluences[influenceId] || 0,
        to: newInfluences[influenceId] || 0,
        revert: {
          ...matrixB,
          nodes: newNodes,
        },
      });
    }
  });

  const oldInfluenced = getInfluenced(matrixA, id);
  const newInfluenced = getInfluenced(matrixB, id);
  const allInfluenced = Object.keys({ ...oldInfluenced, ...newInfluenced });

  allInfluenced.forEach(influenceId => {
    if (!matrixB.nodes.hasOwnProperty(influenceId)) return;
    if (oldInfluenced[influenceId] !== newInfluenced[influenceId]) {
      let newNodes = {
        ...matrixB.nodes,
        [influenceId]: {
          ...matrixB.nodes[influenceId],
          influences: {
            ...matrixB.nodes[influenceId].influences,
            [id]: oldInfluenced[influenceId] || 0,
          },
        },
      };

      if (!oldInfluenced[influenceId]) {
        delete newNodes[influenceId].influences[id];
      }

      overrides.push({
        type: 'connection',
        connection: {
          from: influenceId,
          to: id,
        },
        id,
        from: oldInfluenced[influenceId] || 0,
        to: newInfluenced[influenceId] || 0,
        revert: {
          ...matrixB,
          nodes: newNodes,
        },
      });
    }
  });

  const matrixMembershipFunctionStr = [
    matrixA.nodes[id].membershipFunctions['LOW'][0][0],
    matrixA.nodes[id].mean,
    matrixA.nodes[id].membershipFunctions['HIGH'][2][0],
  ].join('/');

  const experimentMembershipFunctionStr = [
    matrixB.nodes[id].membershipFunctions['LOW'][0][0],
    matrixB.nodes[id].mean,
    matrixB.nodes[id].membershipFunctions['HIGH'][2][0],
  ].join('/');

  if (
    matrixB.nodes[id].mean !== matrixA.nodes[id].mean ||
    matrixMembershipFunctionStr !== experimentMembershipFunctionStr
  ) {
    overrides.push({
      type: 'membership',
      id,
      from: matrixMembershipFunctionStr,
      to: experimentMembershipFunctionStr,
      revert: {
        ...matrixB,
        nodes: {
          ...matrixB.nodes,
          [id]: {
            ...matrixB.nodes[id],
            mean: matrixA.nodes[id].mean,
            membershipFunctions: matrixA.nodes[id].membershipFunctions,
          },
        },
      },
    });
  }

  return overrides;
};

export const nodeSortFunction = (a, b) => {
  const trophicLevelIndex = searchLevel =>
    trophicLevels.findIndex(level => level.name === searchLevel);
  return trophicLevelIndex(a.trophicLevel) - trophicLevelIndex(b.trophicLevel);
};

export const getNodeAbundanceResults = node => {
  // For fixed nodes, show the abundance rather than the abundance result
  // This takes care of situations where there's exaggeration
  return node?.fixed ? Number(node?.abundance) : Number(node?.abundanceResult);
};
