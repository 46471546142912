import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './BuildMatrixModal.scss';
import { saveMatrix } from 'actions/matrices';
import BuildMatrixEditNodesBasicSettings from './BuildMatrixEditNodesBasicSettings';
import BuildMatrixFooterButtons from './BuildMatrixFooterButtons';
import BuildMatrixEditNodesConnections from './BuildMatrixEditNodesConnections';
import BuildMatrixEditNodesPopulationDensities from './BuildMatrixEditNodesPopulationDensities';
import {
  startEdit as startEditNode,
  finishEdit as finishEditNode,
} from 'actions/nodeSettings';
import BuildMatrixEditNodesProgressLine from './BuildMatrixEditNodesProgressLine';

const buildSteps = [
  {
    id: 'basicSettings',
    label: 'Basic Settings',
    subSteps: ['photo', 'description', 'trophicLevel', 'nodeType'],
  },
  { id: 'connections', label: 'Connections' },
  {
    id: 'populationDensities',
    label: 'Population Densities',
    subSteps: ['abundanceRange', 'abundances', 'activationFunction'],
  },
];

const BuildMatrixEditNodes = ({
  matrix,
  toPrev,
  toNext,
  setEditNodeIndex,
  editNodeIndex,
}) => {
  const dispatch = useDispatch();

  const editMatrix = useSelector(state => state.nodeSettings.matrix);

  const [editStep, setEditStep] = useState({
    step: buildSteps[0]?.id,
    subStep: buildSteps[0]?.subSteps?.[0] ?? null,
  });

  const nodes = useMemo(
    () => (matrix?.nodes ? Object.values(matrix.nodes) : []),
    [matrix]
  );

  useEffect(() => {
    dispatch(startEditNode(matrix, nodes[editNodeIndex]?.id));
  }, [editNodeIndex, nodes]);

  // TODO consolidate with goBackStep
  const goForwardStep = () => {
    const { step: currentStep, subStep: currentSubStep } = editStep;

    const buildStepIndex = buildSteps.findIndex(s => s.id === currentStep);
    const buildStep = buildSteps[buildStepIndex];
    const subSteps = buildStep.subSteps;

    let nextStep = buildStep?.id;
    let nextSubStep = null;

    if (!currentSubStep || currentSubStep === subSteps[subSteps.length - 1]) {
      const nextBuildStep = buildSteps?.[buildStepIndex + 1];
      nextStep = nextBuildStep?.id;
      nextSubStep = nextBuildStep?.subSteps?.[0] ?? null;
    } else {
      const subStepIndex = subSteps.findIndex(s => s === currentSubStep);
      nextSubStep = subSteps?.[subStepIndex + 1] ?? null;
    }

    if (nextStep) {
      setEditStep({ step: nextStep, subStep: nextSubStep });
    } else {
      // Finish editing node
      dispatch(finishEditNode(editMatrix));
      dispatch(saveMatrix(editMatrix.id));

      if (editNodeIndex === nodes.length - 1) {
        setEditNodeIndex(0);
        toNext();
      } else {
        setEditNodeIndex(editNodeIndex + 1);
        setEditStep({
          step: buildSteps[0]?.id,
          subStep: buildSteps[0]?.subSteps?.[0] ?? null,
        });
      }
    }
  };

  const goBackStep = () => {
    const { step: currentStep, subStep: currentSubStep } = editStep;

    const buildStepIndex = buildSteps.findIndex(s => s.id === currentStep);
    const buildStep = buildSteps[buildStepIndex];
    const subSteps = buildStep.subSteps;

    let lastStep = buildStep?.id;
    let lastSubStep = null;

    if (!currentSubStep || currentSubStep === subSteps?.[0]) {
      const lastBuildStep = buildSteps?.[buildStepIndex - 1];
      lastStep = lastBuildStep?.id;
      lastSubStep =
        lastBuildStep?.subSteps?.[lastBuildStep?.subSteps.length - 1] ?? null;
    } else {
      const subStepIndex = subSteps.findIndex(s => s === currentSubStep);
      lastSubStep = subSteps?.[subStepIndex - 1] ?? null;
    }

    if (lastStep) {
      setEditStep({ step: lastStep, subStep: lastSubStep });
    } else {
      // Finish editing node
      dispatch(finishEditNode(editMatrix));
      dispatch(saveMatrix(editMatrix.id));

      if (editNodeIndex === 0) {
        setEditNodeIndex(0);
        toPrev();
      } else {
        setEditNodeIndex(editNodeIndex - 1);
        setEditStep({
          step: buildSteps[buildSteps.length - 1]?.id,
          subStep:
            buildSteps[buildSteps.length - 1]?.subSteps?.[
              buildSteps[buildSteps.length - 1]?.subSteps?.length - 1
            ] ?? null,
        });
      }
    }
  };

  const returnComponentForStep = step => {
    switch (step) {
      case 'basicSettings': {
        return <BuildMatrixEditNodesBasicSettings step={editStep?.subStep} />;
      }
      case 'connections': {
        return <BuildMatrixEditNodesConnections />;
      }
      case 'populationDensities': {
        return (
          <BuildMatrixEditNodesPopulationDensities step={editStep?.subStep} />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <div className="BuildMatrixEditNodes">
        <div className="BuildMatrixEditNodes-body">
          {returnComponentForStep(editStep.step)}
        </div>
        <BuildMatrixEditNodesProgressLine
          buildSteps={buildSteps}
          editStep={editStep}
          setEditStep={setEditStep}
        />
      </div>
      <BuildMatrixFooterButtons
        skip={goForwardStep}
        goNext={goForwardStep}
        goBack={goBackStep}
      />
    </>
  );
};

export default BuildMatrixEditNodes;
