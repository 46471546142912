import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import _ from 'lodash';
import { startEdit, cancelEdit, finishEdit } from 'actions/nodeSettings';
import { getNode } from 'constants/nodes';
import { deleteConnectionsTo } from 'constants/connections';
import SettingsModal from 'components/SettingsModal';
import NodeSettingsSection from 'components/nodes/NodeSettingsSection';
import { saveMatrix } from 'actions/matrices';
import { deleteNode } from 'constants/matrices';
import './NodeSettingsView.scss';
import ModalFooter from '../ModalFooter';

const NodeSettingsView = ({ cancelUrl, matrix, readOnly, saveUrl }) => {
  const node = getNode(matrix, useParams().node);
  const dispatch = useDispatch();
  const history = useHistory();

  const editMatrix = useSelector(state => state.nodeSettings.matrix);
  const editNodeId = useSelector(state => state.nodeSettings.nodeId);

  const { valid: nodeIsValid } = useSelector(state => state.nodeSettings);

  let editNode = null;
  if (editMatrix.nodes) {
    editNode = editMatrix.nodes[editNodeId];
  }

  useEffect(() => {
    dispatch(startEdit(matrix, node?.id));
  }, [dispatch, matrix, node]);

  const onSave = useCallback(() => {
    dispatch(finishEdit(editMatrix));
    if (nodeIsValid) {
      dispatch(saveMatrix(editMatrix.id));
    }
    history.push(saveUrl);
  }, [editMatrix, dispatch, history, nodeIsValid, saveUrl]);

  const onDeleteNode = useCallback(() => {
    let newMatrix = deleteNode(matrix, editNodeId);
    newMatrix = deleteConnectionsTo(newMatrix, editNodeId);
    dispatch(finishEdit(newMatrix));
    dispatch(saveMatrix(newMatrix.id));
    // Uses replace because push would create a state where
    // pressing back throws an error.
    history.replace(saveUrl);
  }, [dispatch, history, editNodeId, saveUrl, matrix]);

  if (!editNode) {
    return null;
  }

  return (
    <SettingsModal className="NodeSettingsView" onExit={onSave}>
      <NodeSettingsSection
        matrix={editMatrix}
        node={editNode}
        readOnly={readOnly}
        onDelete={onDeleteNode}
      />

      <ModalFooter
        secondaryAction={() => {
          dispatch(cancelEdit());
          history.push(cancelUrl);
        }}
        secondaryActionLabel={'Cancel'}
        primaryAction={onSave}
        primaryActionDisabled={!nodeIsValid}
        primaryActionLabel={'OK'}
      />
    </SettingsModal>
  );
};

export default NodeSettingsView;
