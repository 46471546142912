import React, { useMemo } from 'react';
import classnames from 'classnames';
import NodePill from 'components/NodePill';
import ButtonNew from 'components/inputs/ButtonNew';
import { SettingsControlLabel } from 'components/Settings';
import './SelectedConnections.scss';

const SelectedConnection = ({
  connection,
  matrix,
  removeConnection,
  setEditConnection,
  selected,
}) => {
  const fromNode = useMemo(() => matrix.nodes[connection.fromNode], [
    matrix,
    connection,
  ]);
  const toNode = useMemo(() => matrix.nodes[connection.toNode], [
    matrix,
    connection,
  ]);

  return (
    <div
      className={classnames('SelectedConnection', {
        selected,
      })}
    >
      <div className="SelectedConnection-connection">
        <NodePill node={fromNode} /> to
        <NodePill node={toNode} /> from
        <span className="strength-value">{connection.strengthRange[0]}</span> to
        <span className="strength-value">{connection.strengthRange[1]}</span>
      </div>
      <div className="SelectedConnection-buttons">
        <ButtonNew
          icon="pencilGray"
          onClick={setEditConnection}
          buttonClasses="icon-button-small"
        />
        <ButtonNew
          icon="trash"
          onClick={removeConnection}
          buttonClasses="icon-button-small"
        />
      </div>
    </div>
  );
};

const SelectedConnections = ({
  connections,
  matrix,
  setConnections,
  setEditConnection,
  activeConnection,
}) => {
  const removeConnection = connection => {
    const { fromNode, toNode } = connection;
    const nextConnections = [...connections].filter(c => {
      const isConnection = c.fromNode === fromNode && c.toNode === toNode;
      return !isConnection;
    });
    setConnections(nextConnections);
  };

  return (
    <div className="SelectedConnections">
      <SettingsControlLabel label="Selected connections" />
      <div className="SelectedConnections-scroll-container">
        {connections.map(connection => (
          <SelectedConnection
            key={`${connection.fromNode}-${connection.toNode}`}
            connection={connection}
            matrix={matrix}
            removeConnection={() => removeConnection(connection)}
            setEditConnection={() => setEditConnection(connection)}
            selected={
              activeConnection?.fromNode === connection?.fromNode &&
              activeConnection?.toNode === connection?.toNode
            }
          />
        ))}
      </div>{' '}
    </div>
  );
};

export default SelectedConnections;
