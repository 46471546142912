import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import ButtonNew from 'components/inputs/ButtonNew';
import { useDispatch, useSelector } from 'react-redux';
import { saveFolder, removeFolder, createFolder } from 'actions/folders';
import {
  setField,
  startEdit,
  cancelEdit,
  finishEdit,
} from 'actions/folderSettings';
import { useValidateFolder } from 'hooks/folders';
import TextField from 'components/inputs/TextField';
import './FolderEditPopover.scss';

const FolderEditPopover = ({ folder, onClose, isNew }) => {
  const dispatch = useDispatch();

  const { valid, validationMessages } = useSelector(
    state => state.folderSettings
  );
  const validateFolder = useValidateFolder();
  const editingFolder = useSelector(state => state.folderSettings.folder);

  useEffect(() => {
    if (folder) {
      dispatch(startEdit(folder));
    }
  }, [dispatch, folder]);

  useEffect(() => {
    validateFolder();
  }, [editingFolder, validateFolder]);

  const onSave = useCallback(() => {
    dispatch(finishEdit(editingFolder));
    if (valid) {
      if (isNew) {
        dispatch(createFolder(editingFolder));
      }
      dispatch(saveFolder(editingFolder.id));
    }
    onClose();
  }, [isNew, onClose, dispatch, editingFolder, valid]);

  const onCancel = useCallback(() => {
    dispatch(cancelEdit());
    onClose();
  }, [onClose, dispatch]);

  if (!folder || !editingFolder) return null;

  return (
    <div className="FolderEditPopover">
      <TextField
        theme="dark"
        onChange={value => dispatch(setField('name', value))}
        placeholder="Folder Name"
        validationMessage={validationMessages?.name}
        value={editingFolder?.name}
      />
      <div className="FolderEditPopover-text">
        {isNew
          ? 'Create a new folder to organize your matrices and experiments. Update file locations in the quick settings menu to add or remove them from existing folders.'
          : 'Edit the name of your folder.'}
      </div>

      <div className="FolderEditPopover-buttons-container">
        <ButtonNew
          buttonClasses="secondary-button-dark-small"
          onClick={onCancel}
          label="Cancel"
        />
        <ButtonNew
          label={isNew ? 'Create' : 'Update'}
          onClick={onSave}
          buttonClasses={classnames('primary-button-small', {
            disabled: !valid,
          })}
        />
      </div>
    </div>
  );
};

export default FolderEditPopover;
