import React, { useState, useEffect } from 'react';
import ConnectionInput from './ConnectionInput';
import SelectedConnections from './SelectedConnections';
import './UncertaintyInputs.scss';

const UncertaintyInputs = ({
  matrix,
  connectionsToVary,
  onConnectionsToVaryChange,
}) => {
  const [editConnection, setEditConnection] = useState(null);

  const saveConnections = connections => {
    let nextConnections = [...connectionsToVary];

    for (const connection of connections) {
      const { fromNode, toNode } = connection;
      if (
        connectionsToVary.some(
          v => v.fromNode === fromNode && v.toNode === toNode
        )
      ) {
        continue;
      }
      nextConnections.push(connection);
    }

    onConnectionsToVaryChange(nextConnections);
  };

  const updateConnection = connection => {
    const { fromNode, toNode } = connection;

    let nextConnections = [...connectionsToVary];
    const index = nextConnections.findIndex(
      v => v.fromNode === fromNode && v.toNode === toNode
    );
    nextConnections.splice(index, 1, connection);

    onConnectionsToVaryChange(nextConnections);
    setEditConnection(null);
  };

  return (
    <div className="UncertaintyInputs">
      <div className="UncertaintyInputs-column">
        <ConnectionInput
          matrix={matrix}
          onAddConnections={saveConnections}
          onUpdateConnection={updateConnection}
          connections={connectionsToVary}
          activeConnection={editConnection}
        />
      </div>
      <div className="UncertaintyInputs-column">
        <SelectedConnections
          connections={connectionsToVary}
          matrix={matrix}
          setConnections={onConnectionsToVaryChange}
          setEditConnection={setEditConnection}
          activeConnection={editConnection}
        />
      </div>
    </div>
  );
};

export default UncertaintyInputs;
