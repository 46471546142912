import uuidv4 from 'uuid/v4';

export const createFolder = user => {
  const folder = {
    id: uuidv4().slice(0, 6),
    name: 'New Folder',
    createdBy: user.claims.name,
    isDeleted: false,
    isPublic: false,
    roles: {
      [user.claims.user_id]: 'owner',
    },
  };

  return folder;
};

export const hasRole = (folder, user, role) => {
  try {
    return folder.roles[user.claims.user_id] === role;
  } catch (e) {
    return false;
  }
};

export const isOwner = (folder, user) => {
  return hasRole(folder, user, 'owner');
};

export const canDelete = (folder, user, userData) => {
  return user && (userData.isAdmin || isOwner(folder, user));
};

export const shouldFetchFolder = (
  folderId,
  folder,
  foldersLoaded,
  foldersLoading
) =>
  folderId &&
  !folder &&
  !(foldersLoaded && foldersLoaded[folderId]) &&
  !(foldersLoading && foldersLoading[folderId]);
