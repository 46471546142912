import {
  MAIL_CREATE,
  MAIL_SAVE_REQUEST,
  MAIL_SAVE_RESPONSE,
  MAIL_SAVE_ERROR_RESPONSE,
  MAIL_CLEAR,
  MAIL_RESET_SENT_FLAG,
} from 'constants/action_names';
import text from 'constants/text';

const initialState = {
  mail: null,
  saving: false,
  errors: [],
  hasSentInSession: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MAIL_CREATE:
      return {
        ...state,
        mail: action.newMail,
      };
    case MAIL_CLEAR:
      return {
        ...state,
        mail: null,
      };
    case MAIL_SAVE_REQUEST:
      return {
        ...state,
        errors: [],
        saving: true,
      };
    case MAIL_SAVE_RESPONSE:
      return {
        ...state,
        errors: [],
        saving: false,
        hasSentInSession: true,
      };
    case MAIL_RESET_SENT_FLAG:
      return {
        ...state,
        hasSentInSession: false,
      };
    case MAIL_SAVE_ERROR_RESPONSE:
      return {
        ...state,
        saving: false,
        errors: [
          {
            title: text.errors.mailSend.title,
            details: text.errors.mailSend.details,
            type: 'mail',
          },
        ],
      };
    default:
      return state;
  }
};
