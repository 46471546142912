import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearErrors } from 'actions/matrices';
import Errors from 'components/Errors';

const MatrixErrors = () => {
  const { errors } = useSelector(state => state.matrices);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearErrors());
  }, [dispatch]);

  return errors ? <Errors errors={errors} /> : null;
};

export default MatrixErrors;
