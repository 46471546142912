import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, setUser } from 'actions/auth';
import './MainMenu.scss';
import classNames from 'classnames';
import TextButton from 'components/inputs/TextButton';
import Icon from 'components/Icon';
import Popover from 'components/Popover';
import LoginPopoverContent from 'components/LoginPopoverContent';

const MainMenu = ({ setWhichTourModal }) => {
  const signInRef = useRef(null);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const loginPromptOpen = useSelector(state => state.loginPrompt.open);

  const onLogout = () => {
    dispatch(logout);
    dispatch(setUser(null));
    history.push('/');
  };

  return (
    <>
      <div className="MainMenu">
        <div
          className="GalleryAndList-header"
          onClick={() => history.push('/')}
        >
          <Icon className="matrix-logo" alt="MPG logo" icon="matrixLogo" />
          <h1 className="matrix-title">MPG Matrix</h1>
        </div>
        <div className="MainMenu-section"></div>
        <div className="MainMenu-section">
          <div className="MainMenu-link text-interface-medium-500 interactiveTourGettingStarted-0">
            <Link to="/gallery" className="main-menu-gallery-link">
              Gallery
            </Link>
          </div>
          <div className="MainMenu-link text-interface-medium-500 interactiveTourGettingStarted-15">
            <Link to="/about">About</Link>
          </div>
          <div
            className="MainMenu-link text-interface-medium-500 interactiveTourGettingStarted-17 interactiveTourCreatingExperiment-17"
            data-tour="users-guide"
          >
            <TextButton
              onClick={() => setWhichTourModal(v => !v)}
              label="How to Use"
              invert
            />
          </div>
          <div className="MainMenu-link text-interface-medium-500 interactiveTourGettingStarted-16">
            <a
              href="https://docs.google.com/document/d/1HGGWkx9PZrsdBR3CNIeZvDfOc0IhHvV7jFi56v4PGjg/edit"
              target="_blank"
            >
              FAQs
            </a>
          </div>
          {user ? (
            <div className="MainMenu-link text-interface-medium-500 interactiveTourGettingStarted-14">
              <Link to="/feedback">Feedback</Link>
            </div>
          ) : null}
          {user ? (
            <React.Fragment>
              <div className="MainMenu-user interactiveTourGettingStarted-3">
                <div className="MainMenu-user-image">
                  <img src={user.claims.picture} alt="" />
                </div>
                <div className="MainMenu-link text-interface-medium-500">
                  {user.claims.name}
                </div>
              </div>
              <div
                className="MainMenu-link text-interface-medium-500"
                data-tour="2"
              >
                <button
                  className="text-interface-medium-500"
                  onClick={onLogout}
                >
                  Log Out
                </button>
              </div>
            </React.Fragment>
          ) : (
            <div
              className="MainMenu-link text-interface-medium-500"
              data-tour="2"
            >
              <div ref={signInRef}>
                <TextButton
                  className={classNames(
                    'sign-in-button interactiveTourGettingStarted-3'
                  )}
                  label="Sign in"
                  requiresLogin
                  requiresLoginMessage="Sign in to build a gallery of your own private matrices. Continue without signing in and you may retrieve sharable links to your work."
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Popover
        title="SIGN IN"
        content={<LoginPopoverContent />}
        isOpen={loginPromptOpen}
        parentRef={signInRef.current}
      />
    </>
  );
};

export default MainMenu;
