import React, { useEffect } from 'react';
import classNames from 'classnames';
import './StickySideModal.scss';

const StickySideModal = ({ children, className, onExit }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div id="matrix-modal" className={classNames('StickySideModal', className)}>
      <div className="StickySideModal-main">
        <div className="StickySideModal-outside" onClick={onExit} />
        <div className="StickySideModal-content interactiveTourCreatingExperiment-1 interactiveTourCreatingExperiment-5 interactiveTourCreatingExperiment-12">
          {children}
        </div>
      </div>
    </div>
  );
};

export default StickySideModal;
