import * as d3 from 'd3';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import slugify from 'slugify';
import { nodeSortFunction } from 'constants/nodes';

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: false,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  showColumnHeaders: true,
};

export const getFilename = matrix => {
  const date = d3.timeFormat('%Y%m%d')(new Date());
  return `matrix_export_${date}_${slugify(matrix.name).slice(
    0,
    50
  )}_connections`;
};

export const getExportableConnections = matrix => {
  const nodes = Object.values(matrix.nodes);

  return nodes.map(influencerNode => {
    return {
      influencer: influencerNode.name,
      ...Object.fromEntries(
        nodes.map(influencedNode => {
          return [
            influencedNode.name,
            influencerNode.influences[influencedNode.id] || '',
          ];
        })
      ),
    };
  });
};

export const exportConnections = (matrix, filename) => {
  const order = Object.values(matrix.nodes)
    .sort(nodeSortFunction)
    .map(n => n.name);

  const options = {
    ...csvOptions,
    filename,
    columnHeaders: ['influencer', ...order],
  };

  let next = getExportableConnections(matrix);

  // Sort rows
  next = order
    .map(name => {
      return next.find(n => n.influencer === name);
    })
    // Shouldn't happen, but failsafe is good
    .filter(Boolean);

  const csvConfig = mkConfig(options);

  const csv = generateCsv(csvConfig)(next);

  return download(csvConfig)(csv);
};
