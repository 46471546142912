import isProfane from './profanity';

const validateFolder = (folder, user) => {
  let messages = [];
  if (!folder) return { valid: false, messages };

  if (!folder.name || folder.name === '' || folder.name === 'New Folder') {
    messages.name = 'Please enter a valid name';
  } else if (!user && isProfane(folder.name)) {
    messages.name = 'Please remove profanity from name';
  }

  return {
    valid: Object.keys(messages).length === 0,
    messages,
  };
};

export default validateFolder;
