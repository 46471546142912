import React, {
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  calculateNewAbundances,
  getFuzzyCategory,
  getValueForAbundance,
} from 'constants/fcm';
import { augmentedMembership } from 'fcm/fcm';
import { getExperiment } from 'constants/matrices';
import ExperimentResults from 'components/ExperimentResults';
import { getFilename, exportCsvData } from 'export/export_csv_data';
import { toDecimalPlace } from 'constants/basic_util';
import ButtonNew from 'components/inputs/ButtonNew';
import './AbundanceView.scss';

const AbundanceView = forwardRef(({ matrix }, ref) => {
  const { experimentId } = useParams();
  const matrices = useSelector(state => state.matrices.matrices);
  const experiment = getExperiment(matrices, matrix, experimentId);
  const view = 'abundance';

  const abundanceData = useMemo(() => {
    if (!experiment || !matrix) return [];

    const matrixOldAbundance = Object.fromEntries(
      Object.values(matrix.nodes).map(({ id, abundance }) => [id, abundance])
    );
    const matrixNewAbundances = calculateNewAbundances(
      matrix,
      matrixOldAbundance
    );

    const experimentOldAbundances = Object.fromEntries(
      Object.values(experiment.nodes).map(({ id, abundance }) => [
        id,
        abundance,
      ])
    );
    const experimentNewAbundances = calculateNewAbundances(
      experiment,
      experimentOldAbundances
    );

    let nodes = {};

    Object.values(matrix.nodes).forEach(node => {
      const matrixAbundance = matrixNewAbundances[node.id];

      nodes[node.id] = {
        id: node.id,
        name: node.name,
        populationUnit: node.populationUnit,
        fixed: node.fixed,
        trophicLevel: node.trophicLevel,
        matrixAbundance,
        matrixCategory: getFuzzyCategory(node, matrixAbundance),
        matrixPopulation: getValueForAbundance(
          node,
          matrixNewAbundances[node.id]
        ),
      };
    });

    Object.values(experiment.nodes).forEach(node => {
      const experimentAbundance = experimentNewAbundances[node.id];

      nodes[node.id] = {
        ...nodes[node.id],
        id: node.id,
        name: node.name,
        populationUnit: node.populationUnit,
        fixed: node.fixed,
        trophicLevel: node.trophicLevel,
        experimentAbundance,
        experimentCategory: getFuzzyCategory(node, experimentAbundance),
        experimentPopulation: getValueForAbundance(
          node,
          experimentNewAbundances[node.id]
        ),
      };
    });

    const expandCategoryNames = name => {
      if (name === augmentedMembership[2]) {
        return 'Moderate';
      } else {
        return name;
      }
    };

    const fuzzyCatChange = (matrixCategory, experimentCategory, change) => {
      if (change === 'node-added') {
        return 'Added';
      } else if (change === 'node-deleted') {
        return 'Removed';
      } else if ((matrixCategory != null) & (experimentCategory != null)) {
        let expandedMatrixCat = expandCategoryNames(matrixCategory);
        let expandedExperimentCat = expandCategoryNames(experimentCategory);

        expandedMatrixCat =
          expandedMatrixCat.charAt(0).toUpperCase() +
          expandedMatrixCat.slice(1).toLowerCase();

        expandedExperimentCat =
          expandedExperimentCat.charAt(0).toUpperCase() +
          expandedExperimentCat.slice(1).toLowerCase();

        return !(matrixCategory === experimentCategory)
          ? expandedMatrixCat + '→' + expandedExperimentCat
          : 'No Change';
      } else {
        return matrixCategory != null ? 'Node Removed' : ' Node Added';
      }
    };

    const determineChange = node => {
      if (node.matrixPopulation === node.experimentPopulation) {
        return {
          change: 'no-change',
          changeDisplay: fuzzyCatChange(
            node.matrixCategory,
            node.experimentCategory,
            'no-change'
          ),
        };
      } else if (node.matrixAbundance < node.experimentAbundance) {
        return {
          change: 'increase',
          changeDisplay: fuzzyCatChange(
            node.matrixCategory,
            node.experimentCategory,
            'increase'
          ),
        };
      } else if (node.matrixAbundance > node.experimentAbundance) {
        return {
          change: 'decrease',
          changeDisplay: fuzzyCatChange(
            node.matrixCategory,
            node.experimentCategory,
            'decrease'
          ),
        };
      } else if (node.matrixPopulation == null) {
        return {
          change: 'node-added',
          changeDisplay: fuzzyCatChange(
            node.matrixCategory,
            node.experimentCategory,
            'node-added'
          ),
          matrixPopulation: 0,
          matrixAbundance: 0,
        };
      } else if (node.experimentPopulation == null) {
        return {
          change: 'node-deleted',
          changeDisplay: fuzzyCatChange(
            node.matrixCategory,
            node.experimentCategory,
            'node-deleted'
          ),
          experimentPopulation: 0,
          experimentAbundance: 0,
        };
      } else {
        return { change: 'no-change' };
      }
    };

    return Object.values(nodes).map(node => ({
      ...node,
      ...determineChange(node),
    }));
  }, [matrix, experiment]);

  const sortedAbundanceData = useMemo(() => {
    const getSortProperties = node => {
      const absoluteAbundanceDiff = Math.abs(
        node.matrixAbundance - node.experimentAbundance
      );
      const absolutePopulationDiff = Math.abs(
        node.matrixPopulation - node.experimentPopulation
      );

      const populationChanged =
        absolutePopulationDiff != null && absolutePopulationDiff !== 0;

      return {
        absoluteAbundanceDiff,
        absolutePopulationDiff,
        populationChanged,
      };
    };

    const sorted = Object.values(abundanceData).map(node => ({
      ...node,
      ...getSortProperties(node),
    }));

    const sortBy = 'absoluteAbundanceDiff';

    return sorted
      .sort((a, b) => d3.ascending(a['name'], b['name']))
      .sort((a, b) => d3.descending(a[sortBy], b[sortBy]))
      .sort((a, b) =>
        d3.descending(a['populationChanged'], b['populationChanged'])
      );
  }, [abundanceData]);

  const downloadData = useCallback(() => {
    const dataDownload = sortedAbundanceData.map(node => {
      return {
        Name: node.name,
        Units: node.populationUnit,
        'Original absolute abundance': toDecimalPlace(node.matrixPopulation, 4),
        'Modified absolute abundance': toDecimalPlace(
          node.experimentPopulation,
          4
        ),
        'Original relative abundance': toDecimalPlace(node.matrixAbundance, 4),
        'Modified relative abundance': toDecimalPlace(
          node.experimentAbundance,
          4
        ),
        'Fuzzy change': node.changeDisplay,
      };
    });

    const filename = getFilename(experiment, 'abundance_changes');

    const columnOrder = [
      'Name',
      'Units',
      'Original absolute abundance',
      'Modified absolute abundance',
      'Original relative abundance',
      'Modified relative abundance',
      'Fuzzy change',
    ];

    exportCsvData(dataDownload, filename, columnOrder);
  }, [experiment, sortedAbundanceData]);

  useImperativeHandle(
    ref,
    () => ({
      downloadData,
    }),
    [downloadData]
  );

  return (
    <div id="AbundanceView" className="AbundanceView">
      <div className="AbundanceView-content">
        <ExperimentResults
          matrix={matrix}
          experiment={experiment}
          view={view}
          abundanceData={sortedAbundanceData}
        />
      </div>
    </div>
  );
});

export default AbundanceView;
