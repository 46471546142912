import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import rootReducer from 'reducers';

export const history = createHashHistory();

export default function configureStore() {
  const middleware = [
    routerMiddleware(history),
    thunk,
    // process.env.NODE_ENV === 'development' && logger,
  ].filter(Boolean);

  const store = createStore(
    rootReducer(history),
    {},
    compose(applyMiddleware(...middleware))
  );
  return store;
}
