import firebase from '../constants/firebase';

export const save = async mail => {
  const db = firebase.firestore();

  // Create mail
  const doc = await db.collection('mail').doc(mail.id);

  doc.set(mail);
};
