import {
  FOLDER_CREATE,
  FOLDER_ERROR_RESPONSE,
  FOLDER_REQUEST,
  FOLDER_RESPONSE,
  FOLDER_SAVE_REQUEST,
  FOLDER_SAVE_RESPONSE,
  FOLDER_SAVE_ERROR_RESPONSE,
  FOLDER_SET_CURRENT,
  FOLDER_SETTINGS_FINISH_EDIT,
  FOLDER_REMOVE_REQUEST,
  FOLDER_REMOVE_RESPONSE,
  FOLDER_REMOVE_ERROR_RESPONSE,
  FOLDER_ADD_FAVORITE,
  FOLDER_REMOVE_FAVORITE,
} from 'constants/action_names';
import text from 'constants/text';

const initialState = {
  folders: [],
  _loading: {}, // Folders we are loading
  _loaded: {}, // Folders we have tried to load
  removing: false,
  saving: false,
  selected: null,
  resetToInitial: false,
  errors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FOLDER_CREATE:
      return {
        ...state,
        folders: [...state.folders, action.newFolder],
      };
    case FOLDER_ADD_FAVORITE:
      return {
        ...state,
        folders: state.folders.map(folder => {
          if (folder.id === action.folderId) {
            const favorites = folder.favorites ?? [];
            return {
              ...folder,
              favorited: favorites.concat([action.userId]),
            };
          }
          return folder;
        }),
      };

    case FOLDER_REMOVE_FAVORITE:
      return {
        ...state,
        folders: state.folders.map(folder => {
          if (folder.id === action.folderId) {
            const favorites = folder.favorites ?? [];
            return {
              ...folder,
              favorited: favorites.filter(id => id !== action.userId),
            };
          }
          return folder;
        }),
      };
    case FOLDER_REQUEST:
      return {
        ...state,
        errors: [],
        _loading: {
          ...state._loading,
          [action.folderIdentifier]: true,
        },
        _loaded: {
          ...state._loaded,
          [action.folderIdentifier]: false,
        },
      };
    case FOLDER_RESPONSE:
      return {
        ...state,
        folders: [...state.folders, ...action.json],
        errors: [],
        _loading: {
          ...state._loading,
          [action.folderIdentifier]: false,
        },
        _loaded: {
          ...state._loaded,
          [action.folderIdentifier]: true,
        },
      };
    case FOLDER_ERROR_RESPONSE:
      return {
        ...state,
        errors: [
          {
            title: 'Failed to fetch folder',
            details: 'Try again later',
            type: 'fetch',
          },
        ],
        _loading: {
          ...state._loading,
          [action.folderIdentifier]: false,
        },
        _loaded: {
          ...state._loaded,
          [action.folderIdentifier]: true,
        },
      };
    case FOLDER_SAVE_REQUEST:
      return {
        ...state,
        errors: [],
        saving: true,
      };
    case FOLDER_SAVE_RESPONSE:
      return {
        ...state,
        errors: [],
        saving: false,
      };
    case FOLDER_SAVE_ERROR_RESPONSE:
      return {
        ...state,
        saving: false,
        errors: [
          {
            title: text.errors.folderSave.title,
            details: text.errors.folderSave.details,
            type: 'save',
          },
        ],
      };
    case FOLDER_REMOVE_REQUEST:
      return {
        ...state,
        removing: true,
        errors: [],
      };
    case FOLDER_REMOVE_RESPONSE:
      return {
        ...state,
        errors: [],
        folders: state.folders.filter(
          folders => folders.id !== action.folderId
        ),
        removing: false,
      };
    case FOLDER_REMOVE_ERROR_RESPONSE:
      return {
        ...state,
        removing: false,
        errors: [
          {
            title: text.errors.folderRemove.title,
            details: text.errors.folderRemove.details,
            type: 'remove',
          },
        ],
      };
    case FOLDER_SET_CURRENT:
      return {
        ...state,
        selected: action.folder,
      };
    case FOLDER_SETTINGS_FINISH_EDIT:
      return {
        ...state,
        folders: state.folders.map(folder => {
          if (folder.id === action.folder.id) {
            return {
              ...action.folder,
            };
          }
          return folder;
        }),
      };
    default:
      return state;
  }
};
