import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import ModalFooter from '../ModalFooter';
import { startEdit, cancelEdit, finishEdit } from 'actions/matrixSettings';
import { saveMatrix } from 'actions/matrices';
import SettingsModal from 'components/SettingsModal';
import { getMatrix, shouldFetchMatrix } from 'constants/matrices';
import { fetchMatrix } from 'actions/matrices';
import MatrixSettingsSection from './MatrixSettingsSection';
import * as urls from 'constants/urls';
import './MatrixSettingsModal.scss';

const MatrixSettingsModal = ({ cancelUrl, matrix, saveUrl }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { matrixId } = useParams();

  const matrices = useSelector(state => state.matrices.matrices);

  if (!matrix) {
    matrix = getMatrix(matrices, matrixId);
  }

  const base = useMemo(() => {
    if (!matrix || !matrix?.parentId) return;
    return getMatrix(matrices, matrix.parentId);
  }, [matrix, matrices]);

  useEffect(() => {
    if (matrix) {
      dispatch(startEdit(matrix));
    }
  }, [dispatch, matrix]);

  useEffect(() => {
    if (shouldFetchMatrix(matrix?.id, matrix)) {
      dispatch(fetchMatrix(matrix.id));
    }
  }, [dispatch, matrix]);

  const editingMatrix = useSelector(state => state.matrixSettings.matrix);

  const { valid: matrixIsValid } = useSelector(state => state.matrixSettings);

  const onSave = useCallback(() => {
    editingMatrix.savedByUser = true;
    dispatch(finishEdit(editingMatrix));
    if (matrixIsValid) {
      dispatch(saveMatrix(editingMatrix.id));
    }
    history.push(saveUrl);
  }, [editingMatrix, history, matrixIsValid, dispatch, saveUrl]);

  const onCancel = useCallback(() => {
    dispatch(cancelEdit());
    history.push(cancelUrl);
  }, [history, dispatch, cancelUrl]);

  const onDelete = useCallback(() => {
    dispatch(cancelEdit());
    history.push(urls.gallery());
  }, [history, dispatch, cancelUrl]);

  if (!editingMatrix) return null;

  return (
    <SettingsModal className="MatrixSettingsModal" onExit={onSave}>
      <MatrixSettingsSection
        matrix={editingMatrix}
        base={base}
        onSave={onSave}
        onCancel={onCancel}
        onDelete={onDelete}
      />
      <ModalFooter
        secondaryAction={onCancel}
        secondaryActionLabel={'Cancel'}
        primaryAction={onSave}
        primaryActionDisabled={!matrixIsValid}
        primaryActionLabel={`OK`}
      />
    </SettingsModal>
  );
};

export default MatrixSettingsModal;
