import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startEdit } from 'actions/matrixSettings';
import ButtonNew from 'components/inputs/ButtonNew';
import {
  canDelete,
  shouldFetchMatrix,
  convertExperimentToMatrix as convertToMatrix,
} from 'constants/matrices';
import {
  removeMatrix,
  fetchMatrix,
  convertExperimentToMatrix,
  saveMatrix,
} from 'actions/matrices';
import './MatrixDeletePopoverContent.scss';

const MatrixDeletePopoverContent = ({
  matrix,
  children,
  onDelete,
  onCancel,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startEdit(matrix));
  }, [dispatch, matrix]);

  const { user, userData } = useSelector(state => state.auth);
  const editingMatrix = useSelector(state => state.matrixSettings.matrix);
  const deletable = [matrix, ...children].every(item =>
    canDelete(item, user, userData)
  );

  useEffect(() => {
    if (shouldFetchMatrix(matrix.id, matrix)) {
      dispatch(fetchMatrix(matrix.id));
    }
  }, [dispatch, matrix]);

  if (!editingMatrix) return null;

  const handleDelete = () => {
    if (!deletable) return;
    dispatch(removeMatrix(matrix.id));
    for (const item of children) {
      const nextMatrix = convertToMatrix(item, user);
      dispatch(convertExperimentToMatrix(nextMatrix));
      dispatch(saveMatrix(nextMatrix.id));
    }

    onDelete();
  };

  const childrenWarning =
    'Experiments based on this matrix will be promoted to matrices.';

  return (
    <div className="MatrixDeleteModalContent">
      <div className="Popover-section">{`Deleting this ${
        matrix?.parentId ? 'experiment' : 'matrix'
      } will remove it from your files permanently. It may no longer be accessed by a previous link.`}</div>
      {children.length ? (
        <div className="Popover-section">{childrenWarning}</div>
      ) : null}
      <div className="MatrixDeleteModalContent-buttons-container">
        <ButtonNew
          buttonClasses="secondary-button-dark-small"
          onClick={onCancel}
          label="Cancel"
        />
        {deletable ? (
          <ButtonNew
            label="Delete"
            onClick={handleDelete}
            buttonClasses="destructive-button-small"
          />
        ) : null}
      </div>
    </div>
  );
};

export default MatrixDeletePopoverContent;
