import React, { useMemo, useRef } from 'react';
import { MenuTabs } from 'components/menus/MenuTabs';
import { Tab } from 'components/menus/Tab';
import ButtonNew from './inputs/ButtonNew';
import Popover from './Popover';
import TextField from 'components/inputs/TextField';
import ImageField from './inputs/ImageField';
import TextArea from './inputs/TextArea';
import NumberField from './inputs/NumberField';
import ButtonSet from './inputs/ButtonSet';
import Dropdown from './inputs/Dropdown';
import MultiRange from './inputs/MultiRange';
import './Settings.scss';

const SettingsControlLabel = ({ label }) => {
  return <div className="Settings-label">{label}</div>;
};

const SettingsControlDescription = ({ description }) => {
  return <div className="Settings-description">{description}</div>;
};

const SettingsDropdown = ({
  disabled,
  label,
  onChange,
  options,
  value,
  description,
  ...restProps
}) => {
  return (
    <div className="SettingsDropdown">
      {label ? <SettingsControlLabel label={label} /> : null}
      <div className="SettingsDropdown-container">
        <Dropdown
          disabled={disabled}
          onChange={onChange}
          options={options}
          value={value}
          {...restProps}
        />
      </div>
      {description ? (
        <SettingsControlDescription description={description} />
      ) : null}
    </div>
  );
};

const SettingsButtonSet = ({
  label,
  disabled,
  onChange,
  options,
  value,
  description,
}) => {
  return (
    <div className="SettingsButtonSet">
      {label ? <SettingsControlLabel label={label} /> : null}
      <div className="SettingsButtonSet-container">
        <ButtonSet
          onChange={onChange}
          value={value}
          options={options}
          disabled={disabled}
        />
      </div>
      {description ? (
        <SettingsControlDescription description={description} />
      ) : null}
    </div>
  );
};

const SettingsDescription = ({
  label,
  onChange,
  placeholder,
  value,
  validationMessage,
}) => {
  return (
    <div className="SettingsDescription">
      {label ? <SettingsControlLabel label={label} /> : null}
      <div className="SettingsDescription-text-container">
        <TextArea
          onChange={onChange}
          value={value}
          validationMessage={validationMessage}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

const SettingsViewOnlyText = ({ label, text }) => {
  return (
    <div className="SettingsViewOnlyText">
      <div className="Settings-label">{label}</div>
      <div className="SettingsViewOnlyText-text">{text}</div>
    </div>
  );
};

const SettingsTextField = ({
  label,
  onChange,
  placeholder,
  validationMessage,
  value,
}) => {
  return (
    <div className="SettingsTextField">
      {label ? <SettingsControlLabel label={label} /> : null}
      <TextField
        onChange={onChange}
        placeholder={placeholder}
        validationMessage={validationMessage}
        value={value}
        className="SettingsTextField-input"
      />
    </div>
  );
};

const SettingsNumberField = ({
  label,
  onChange,
  validationMessage,
  value,
  step,
  description,
  ...restProps
}) => {
  return (
    <div className="SettingsNumberField">
      {label ? <SettingsControlLabel label={label} /> : null}
      <NumberField
        onChange={onChange}
        validationMessage={validationMessage}
        step={step ?? '0.001'}
        value={value}
        className="SettingsNumberField-input"
        {...restProps}
      />
      {description ? (
        <SettingsControlDescription description={description} />
      ) : null}
    </div>
  );
};

const SettingsImage = ({ disabled, onChange, value, label, ...restProps }) => {
  return (
    <div className="SettingsImage">
      {label ? <SettingsControlLabel label={label} /> : null}
      <ImageField
        disabled={disabled}
        onChange={onChange}
        value={value}
        maxHeight={'100%'}
        {...restProps}
      />
    </div>
  );
};

const SettingsMultiRange = ({
  label,
  disabled,
  value,
  min,
  max,
  step,
  onChange,
  showValues,
}) => {
  return (
    <div className="SettingsMultiRange">
      {label ? <SettingsControlLabel label={label} /> : null}
      <MultiRange
        disabled={disabled}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        showValues={showValues !== undefined ? showValues : true}
      />
    </div>
  );
};

const Settings = ({
  menuOptions,
  setMenuOption,
  activeMenuOption,
  deletePopoverTitle,
  deletePopoverContent,
  setDeletePopoverOpen,
  deletePopoverOpen,
  header,
  footer,
}) => {
  const deleteRef = useRef(null);

  const content = useMemo(
    () => menuOptions.find(o => o.value === activeMenuOption)?.content,
    [activeMenuOption, menuOptions]
  );

  return (
    <div className="Settings">
      <div className="Settings-header">
        {header ?? 'Settings'}{' '}
        {deletePopoverContent ? (
          <div ref={deleteRef} onClick={setDeletePopoverOpen}>
            <ButtonNew
              buttonClasses="icon-button"
              onClick={() => setDeletePopoverOpen(!deletePopoverOpen)}
              icon="trash"
            />
          </div>
        ) : null}
      </div>
      {menuOptions ? (
        <div className="Settings-menu">
          <MenuTabs>
            {menuOptions.map((option, i) => (
              <Tab
                key={i}
                label={option.label}
                onClick={() => setMenuOption(option.value)}
                selected={activeMenuOption === option.value}
              />
            ))}
          </MenuTabs>
        </div>
      ) : null}

      <div className="Settings-content">{content}</div>

      {footer ? <div className="Settings-footer">{footer}</div> : null}

      <Popover
        title={deletePopoverTitle}
        content={deletePopoverContent}
        isOpen={deletePopoverOpen}
        parentRef={deleteRef.current}
      />
    </div>
  );
};

export {
  Settings,
  SettingsDescription,
  SettingsViewOnlyText,
  SettingsTextField,
  SettingsImage,
  SettingsButtonSet,
  SettingsNumberField,
  SettingsDropdown,
  SettingsControlDescription,
  SettingsControlLabel,
  SettingsMultiRange,
};
