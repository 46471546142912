import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import GalleryFavoriteList from '../GalleryFavoriteList';
import FolderListItem from 'components/matrices/FolderListItem';
import MatrixListItem from 'components/matrices/MatrixListItem';
import { fetchMatrix, saveMatrix } from 'actions/matrices';
import { finishEdit } from 'actions/matrixSettings';
import { createFolder as createNewFolder } from 'constants/folders';
import { createFolder, saveFolder } from 'actions/folders';
import { shouldFetchMatrix } from 'constants/matrices';
import * as urls from 'constants/urls';
import ButtonNew from 'components/inputs/ButtonNew';
import Popover from 'components/Popover';
import FolderEditPopover from 'components/folders/FolderEditPopover';
import './MatrixList.scss';

const EducationModuleListItem = ({ setEducationTab }) => {
  const fakeMatrix = {
    imageUrl: 'images/education-modules.png',
    name: 'Education modules',
  };
  return (
    <div className="interactiveTourGettingStarted-2">
      <MatrixListItem
        draggable={false}
        matrix={fakeMatrix}
        onClick={setEducationTab}
        showCreatedBy={false}
        showModifiedDate={false}
      />
    </div>
  );
};

const MatrixSubList = ({
  experiments,
  matrix,
  dragEnd,
  dragStart,
  dragOver,
  enableReorder,
  onClick,
  folderId,
  setDeletePopoverOpen,
  deletePopoverOpen,
  setQuickSettingsPopoverOpen,
  quickSettingsPopoverOpen,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <li
      id={matrix.id}
      className={classNames('MatrixSubList', { expanded })}
      draggable={enableReorder ? 'true' : 'false'}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      onDragStart={dragStart}
    >
      <MatrixListItem
        draggable={enableReorder}
        matrix={matrix}
        onClick={() => {
          onClick(matrix);
          setExpanded(true);
        }}
        onExpanderClicked={() => {
          setExpanded(!expanded);
        }}
        showCreatedBy={!matrix.isPublic && !matrix.isEducation}
        showModifiedDate={true}
        folderId={folderId}
        setDeletePopoverOpen={setDeletePopoverOpen}
        deletePopoverOpen={deletePopoverOpen === matrix.id}
        setQuickSettingsPopoverOpen={setQuickSettingsPopoverOpen}
        quickSettingsPopoverOpen={quickSettingsPopoverOpen === matrix.id}
      />
      {experiments.length ? (
        <ul className="MatrixSubList-experiments">
          {experiments.map(experiment => (
            <MatrixListItem
              key={experiment.id}
              matrix={experiment}
              onClick={() => onClick(experiment)}
              showCreatedBy={true}
              showImage={true}
              showModifiedDate={true}
              folderId={folderId}
              setDeletePopoverOpen={setDeletePopoverOpen}
              deletePopoverOpen={deletePopoverOpen === experiment.id}
              setQuickSettingsPopoverOpen={setQuickSettingsPopoverOpen}
              quickSettingsPopoverOpen={
                quickSettingsPopoverOpen === experiment.id
              }
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

const MatrixList = ({
  experiments,
  matrices,
  enableReorder,
  folders,
  showFavorites,
  folderId,
  showEducation,
  setEducationTab,
  galleryTab,
}) => {
  const [dragId, setDragId] = useState();
  const [matricesOrder, setMatrices] = useState();
  const [deletePopoverOpen, setDeletePopoverOpen] = useState(null);
  const [quickSettingsPopoverOpen, setQuickSettingsPopoverOpen] = useState(
    null
  );
  const newFolderRef = useRef(null);
  const currentFolderId = useSelector(state => state.folders.selected);
  const [pendingFolder, setPendingFolder] = useState(null);

  const visibleMatrices = useMemo(() => {
    return matrices.filter(m => !folders.map(f => f.id).includes(m?.folder));
  }, [matrices, folders]);

  const visibleExperiments = useMemo(() => {
    return experiments.filter(m => !folders.map(f => f.id).includes(m?.folder));
  }, [experiments, folders]);

  useEffect(() => setMatrices(visibleMatrices), [visibleMatrices]);
  const dispatch = useDispatch();

  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);

  const auth = useSelector(state => state.auth);
  const userId = useMemo(() => auth.user?.claims?.user_id ?? null, [auth.user]);

  const favorites = useMemo(() => {
    return matrices
      .concat(experiments)
      .concat(folders.map(f => ({ ...f, isFolder: true })))
      .filter(item => item.favorited && item.favorited.includes(userId));
  }, [userId, matrices, experiments, folders]);

  const history = useHistory();
  const goToMatrix = matrix => history.push(urls.matrix(matrix));
  const goToExperiment = experiment =>
    history.push(urls.experiment(experiment));

  const onClick = item => {
    // Experiments have parentId
    if (item.parentId) {
      goToExperiment(item);
    } else {
      goToMatrix(item);
    }
  };

  const handleDragStart = e => {
    setDragId(e.currentTarget.id);
  };

  useEffect(() => {
    matrices.forEach(matrix => {
      if (
        enableReorder &&
        shouldFetchMatrix(matrix.id, matrix, matricesLoaded, matricesLoading)
      ) {
        dispatch(fetchMatrix(matrix.id));
      }
    });
  }, [dispatch, enableReorder, matrices, matricesLoaded, matricesLoading]);

  const handleDragEnd = () => {
    if (matricesOrder) {
      const dragMatrix = matricesOrder.find(d => d.id === dragId);
      const reorderMatrices = matricesOrder
        .filter(d => d.id !== dragId)
        .map((d, i) => ({
          ...(d.type === 'dragDivider' ? dragMatrix : d),
          order: i,
        }));
      setMatrices(reorderMatrices);

      reorderMatrices.forEach(matrix => {
        matrix.savedByUser = true;
        dispatch(finishEdit(matrix, false));
        dispatch(saveMatrix(matrix.id));
      });
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    if (e.currentTarget.id !== dragId) {
      const currDivider =
        matricesOrder && matricesOrder.findIndex(d => d.type === 'dragDivider');
      const reorderMatrices = (matricesOrder || visibleMatrices).filter(
        d => d.type !== 'dragDivider'
      );
      const dragOverId = e.currentTarget.id;
      const dragOverBounds = e.currentTarget.getBoundingClientRect();
      const isDragBefore =
        e.clientY < dragOverBounds.top + dragOverBounds.height / 2;
      const dragIndex =
        reorderMatrices.findIndex(d => d.id === dragOverId) +
        (isDragBefore ? 0 : 1);
      if (currDivider !== dragIndex) {
        reorderMatrices.splice(dragIndex, 0, { type: 'dragDivider' });
        setMatrices(reorderMatrices);
      }
    }
  };

  // System to only allow one popover open at a time
  const handleDeletePopover = id => {
    if (deletePopoverOpen === id) {
      setDeletePopoverOpen(null);
    } else {
      setDeletePopoverOpen(id);
      setQuickSettingsPopoverOpen(null);
    }
  };

  const handleQuickSettingsPopover = id => {
    if (quickSettingsPopoverOpen === id) {
      setQuickSettingsPopoverOpen(null);
    } else {
      setQuickSettingsPopoverOpen(id);
      setDeletePopoverOpen(null);
    }
  };

  const onCreateNewFolder = useCallback(() => {
    if (pendingFolder) {
      setPendingFolder(null);
    } else {
      const newFolder = createNewFolder(auth.user);
      setPendingFolder(newFolder);
    }
  }, [pendingFolder, auth]);

  return (
    <>
      {showFavorites ? (
        <GalleryFavoriteList favorites={favorites} onClick={onClick} />
      ) : null}
      <ul
        className="MatrixList interactiveTourGettingStarted-1"
        onDragOver={e => e.preventDefault()}
      >
        {showEducation ? (
          <EducationModuleListItem setEducationTab={setEducationTab} />
        ) : null}

        {auth.user && galleryTab.name === 'private' && !currentFolderId ? (
          <div
            className="MatrixList-new-folder-button-container"
            ref={newFolderRef}
          >
            <ButtonNew
              buttonClasses={'text-button'}
              onClick={onCreateNewFolder}
              label="New folder"
              iconLeft="plus"
              requiresLogin
              requiresLoginMessage="Sign in to add a new folder."
            />{' '}
          </div>
        ) : null}

        <div className="MatrixSubList">
          {folders.map(folder => (
            <FolderListItem
              key={folder.id}
              folder={folder}
              setDeletePopoverOpen={handleDeletePopover}
              deletePopoverOpen={deletePopoverOpen === folder.id}
              setQuickSettingsPopoverOpen={handleQuickSettingsPopover}
              quickSettingsPopoverOpen={quickSettingsPopoverOpen === folder.id}
            />
          ))}
        </div>
        {(matricesOrder || visibleMatrices).map(matrix =>
          matrix.type === 'dragDivider' ? (
            <div className="MatrixListDivider" key="dragDivider" />
          ) : (
            <MatrixSubList
              key={matrix.id}
              experiments={visibleExperiments.filter(
                ({ parentId }) => parentId === matrix.id
              )}
              matrix={matrix}
              dragEnd={handleDragEnd}
              dragStart={handleDragStart}
              dragOver={handleDragOver}
              enableReorder={enableReorder}
              onClick={onClick}
              folderId={folderId}
              setDeletePopoverOpen={handleDeletePopover}
              deletePopoverOpen={deletePopoverOpen}
              setQuickSettingsPopoverOpen={handleQuickSettingsPopover}
              quickSettingsPopoverOpen={quickSettingsPopoverOpen}
            />
          )
        )}
      </ul>
      <Popover
        title={`New folder`}
        content={
          <FolderEditPopover
            folder={pendingFolder}
            onClose={() => setPendingFolder(null)}
            isNew={true}
          />
        }
        isOpen={!!pendingFolder}
        parentRef={newFolderRef.current}
      />
    </>
  );
};

export default MatrixList;
