import React, { useState, useCallback, useEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { calculateNewAbundances } from 'constants/fcm';
import { useDispatch, useSelector } from 'react-redux';
import { setField } from 'actions/nodeSettings';
import {
  activationFunctions,
  trophicLevels,
  populationUnits,
} from 'constants/nodes';
import {
  Settings,
  SettingsTextField,
  SettingsImage,
  SettingsDescription,
  SettingsButtonSet,
  SettingsNumberField,
  SettingsDropdown,
  SettingsControlDescription,
  SettingsControlLabel,
} from 'components/Settings';
import AbundanceSlider from 'components/inputs/AbundanceSlider';
import { sortedMembership, rescale, descale } from 'fcm/fcm';
import { getAbundanceForValue, getValueForAbundance } from 'constants/fcm';
import NodeSettingsMembershipFunctionShapeGraph from 'components/nodes/NodeSettingsMembershipFunctionShapeGraph';
import text from 'constants/text';
import ButtonNew from 'components/inputs/ButtonNew';
import './NodeSettingsSection.scss';

const makeOption = value => ({ label: value, value });

const DeletePopoverContent = ({
  text,
  primaryAction,
  primaryActionLabel,
  secondaryAction,
  secondaryActionLabel,
}) => {
  return (
    <div className="MatrixSettings-DeletePopoverContent">
      <div className="Popover-section">{text}</div>

      <div className="MatrixSettings-DeletePopoverContent-buttons-container">
        <ButtonNew
          buttonClasses="secondary-button-dark-small"
          onClick={secondaryAction}
          label={secondaryActionLabel}
        />

        <ButtonNew
          label={primaryActionLabel}
          onClick={primaryAction}
          buttonClasses="destructive-button-small"
        />
      </div>
    </div>
  );
};

// Advanced settings
const MatrixAdvancedSettings = ({ matrix, readOnly, node }) => {
  const dispatch = useDispatch();
  const { validationMessages } = useSelector(state => state.matrixSettings);
  // TODO check this, no longer valid
  const modalRef = document.getElementById('matrix-modal');

  const [graphWidth, setGraphWidth] = useState(400);

  const getGraphWidth = useCallback(e => {
    const { contentRect } = e;
    const { width } = contentRect;
    setGraphWidth(width);
  }, []);

  useResizeObserver(
    document?.getElementById('NodeSettingsMembershipFunctionShapeGraph'),
    getGraphWidth
  );

  useEffect(() => {
    const el = document?.getElementById(
      'NodeSettingsMembershipFunctionShapeGraph'
    );
    setGraphWidth(el.offsetWidth);
  }, [getGraphWidth]);

  return (
    <div className="MatrixSettings">
      <div className="MatrixSettings-row">
        <div
          id="NodeSettingsMembershipFunctionShapeGraph"
          className="MatrixSettings-column"
        >
          <SettingsControlLabel label="Fuzzy functions" />
          <NodeSettingsMembershipFunctionShapeGraph
            membershipFunctions={node.membershipFunctions}
            mean={node.mean}
            readOnly={readOnly}
            width={graphWidth}
            height={175}
          />
        </div>
      </div>
      <div className="MatrixSettings-row MatrixSettings-half">
        <SettingsNumberField
          label="Abundance exaggeration"
          disabled={readOnly}
          onChange={value =>
            dispatch(setField('abundanceExaggeration', +value))
          }
          validationMessage={validationMessages.abundanceExaggeration}
          tooltip={text.nodeSettingsToolTip['abundanceExaggeration']}
          value={node.abundanceExaggeration}
          description="Set the multiplicative factor to increase this node's abundance in the system. This should only be used for basal nodes in cases where the original basal node abundance is not large enough to ensure that the stable state of other nodes within the system is above 0."
        />
      </div>
      <div className="MatrixSettings-row MatrixSettings-half">
        <SettingsDropdown
          label="Activation function"
          disabled={readOnly}
          options={activationFunctions
            .map(af => af.displayName)
            .map(makeOption)}
          onChange={value =>
            dispatch(
              setField(
                'activationFunction',
                activationFunctions.find(obj => {
                  return obj.displayName === value;
                }).name
              )
            )
          }
          placeholder="Select Activation Function"
          value={
            activationFunctions.find(obj => {
              return obj.name === node.activationFunction;
            }).displayName
          }
          menuPortalTarget={document.body}
          closeMenuOnScroll={event =>
            modalRef && modalRef.firstChild.contains(event.target)
          }
          description="The standard activation function is exponential, so if you aren't sure, use that. Logistic generally applies to basal resources such as precipitation or sun."
        />
      </div>
    </div>
  );
};

// Population and abundance settings
const MatrixPopulationAbundanceSettings = ({ matrix, node, readOnly }) => {
  const dispatch = useDispatch();
  // TODO check this, no longer valid
  const modalRef = document.getElementById('matrix-modal');

  const [minValue, setMin] = useState(
    node?.membershipFunctions[sortedMembership[0]][0][0]
  );
  const [meanValue, setMean] = useState(node?.mean);
  const [maxValue, setMax] = useState(
    node?.membershipFunctions[sortedMembership[2]][2][0]
  );

  const conditionalUpdate = () => {
    if (isNaN(minValue) || isNaN(meanValue) || isNaN(maxValue)) return;
    if (minValue >= meanValue || minValue >= maxValue) return;
    if (maxValue <= meanValue || maxValue <= minValue) return;

    let scaledFunctions = rescale(
      JSON.parse(JSON.stringify(node?.membershipFunctions)),
      node?.mean
    );
    let newFunctions = descale(scaledFunctions, meanValue, minValue, maxValue);

    dispatch(setField('membershipFunctions', newFunctions));
    dispatch(setField('mean', meanValue));
  };

  useEffect(conditionalUpdate, [minValue, meanValue, maxValue]);

  return (
    <div className="MatrixSettings">
      {/* TODO This row needs a description */}
      <div className="MatrixSettings-row">
        <div className="MatrixSettings-column">
          <div className="MatrixSettings-number-input-row">
            <SettingsNumberField
              disabled={readOnly}
              key={0}
              label="Min"
              onBlur={v => setMin(+v)}
              onChange={v => setMin(+v)}
              value={minValue}
              validationMessage={
                +minValue >= +meanValue ||
                +minValue >= +maxValue ||
                isNaN(minValue)
                  ? 'Invalid min'
                  : ''
              }
            />
            <SettingsNumberField
              disabled={readOnly}
              key={1}
              label="Mode"
              onBlur={v => setMean(+v)}
              onChange={v => setMean(+v)}
              validationMessage={
                +minValue >= +meanValue ||
                +meanValue >= +maxValue ||
                isNaN(meanValue)
                  ? 'Invalid mode'
                  : ''
              }
              value={meanValue}
            />
            <SettingsNumberField
              disabled={readOnly}
              key={2}
              label="Max"
              onBlur={v => setMax(+v)}
              onChange={v => setMax(+v)}
              validationMessage={
                +minValue >= +maxValue ||
                +meanValue >= +maxValue ||
                isNaN(meanValue)
                  ? 'Invalid max'
                  : ''
              }
              value={maxValue}
            />
          </div>
          <SettingsControlDescription description="Set the range at which the population could fluctuate within the matrix. The population mode should reflect the initial equilibrium or an experimental abundance." />
        </div>
      </div>
      <div className="MatrixSettings-row">
        <div className="MatrixSettings-column">
          <div className="MatrixSettings-half">
            <SettingsNumberField
              disabled={readOnly}
              label="Abundance"
              onChange={value =>
                dispatch(
                  setField('abundance', getAbundanceForValue(node, value))
                )
              }
              value={getValueForAbundance(node, node?.abundance)}
              description="Set the starting abundance for this node in the matrix. The matrix will run to equilibrium by default, so if this node is set as an output, this abundance may change slightly as it reacts to the other nodes it’s connected to."
            />
          </div>
          <AbundanceSlider
            changeable
            node={node}
            onChange={value => dispatch(setField('abundance', value))}
            value={node.abundance}
          />
        </div>
      </div>
      <div className="MatrixSettings-row">
        <SettingsDropdown
          disabled={readOnly}
          label="Abundance units"
          onChange={inputValue => {
            dispatch(
              setField(
                'populationUnit',
                populationUnits.find(obj => {
                  return obj.displayName === inputValue;
                }).name
              )
            );
          }}
          options={populationUnits
            .map(({ displayName }) => displayName)
            .map(makeOption)}
          value={
            populationUnits.find(obj => {
              return obj.name === node.populationUnit;
            })?.displayName || node.populationUnit
          }
          menuPortalTarget={document.body}
          closeMenuOnScroll={event =>
            modalRef && modalRef.firstChild.contains(event.target)
          }
          description="Specify how the population is being calculate in the matrix"
        />
      </div>
    </div>
  );
};

// Basic info settings
const MatrixBasicSettings = ({ matrix, node, readOnly }) => {
  const dispatch = useDispatch();
  const { validationMessages } = useSelector(state => state.matrixSettings);
  const { user } = useSelector(state => state.auth);
  // TODO see if this is valid
  const modalRef = document.getElementById('matrix-modal');

  const availableTrophicLevels = Array.from(
    new Set([
      ...trophicLevels.map(({ name }) => name),
      ...Object.values(matrix.nodes).map(node => node.trophicLevel),
    ])
  );

  return (
    <div className="MatrixSettings">
      <div className="MatrixSettings-row">
        <SettingsTextField
          label="Node name"
          onChange={value => dispatch(setField('name', value))}
          placeholder="Node name"
          validationMessage={validationMessages.name}
          value={node.name}
        />
      </div>

      <div className="MatrixSettings-row">
        <SettingsImage
          disabled={readOnly || !user}
          onChange={value => {
            dispatch(setField('imageUrl', value));
            dispatch(setField('displayImageUrl', value));
          }}
          value={node.displayImageUrl}
          description="Recommended photo size is no less than 450 x 300px"
        />
      </div>

      <div className="MatrixSettings-row">
        <SettingsDescription
          label="Description"
          disabled={readOnly}
          onChange={value => dispatch(setField('description', value))}
          placeholder={'Add a short description of your node.'}
          value={node.description}
          validationMessage={validationMessages.description}
        />
      </div>
      <div className="MatrixSettings-row">
        <div className="MatrixSettings-row MatrixSettings-half">
          <SettingsButtonSet
            disabled={readOnly}
            label="Type"
            onChange={value => {
              dispatch(setField('fixed', value === 'Input'));

              if (value === 'Input') {
                let modifiedMatrix = {
                  ...matrix,
                  nodes: {
                    ...matrix.nodes,
                    [node.id]: { ...matrix.nodes[node.id], fixed: false },
                  },
                };

                const matrixOldAbundance = Object.fromEntries(
                  Object.values(matrix.nodes).map(({ id, abundance }) => [
                    id,
                    abundance,
                  ])
                );

                const matrixNewAbundances = calculateNewAbundances(
                  modifiedMatrix,
                  matrixOldAbundance
                );

                dispatch(setField('abundance', matrixNewAbundances[node.id]));
              }
            }}
            options={[
              { value: 'Input', label: 'Input', icon: 'inputicon' },
              { value: 'Output', label: 'Output', icon: 'outputicon' },
            ]}
            value={node.fixed ? 'Input' : 'Output'}
            description={
              'Input abundances stay fixed and will not react to other nodes when you run the model'
            }
          />
        </div>
        <div className="MatrixSettings-row MatrixSettings-half">
          <SettingsDropdown
            creatable
            disabled={readOnly}
            label="Trophic Level"
            onChange={value => dispatch(setField('trophicLevel', value))}
            options={availableTrophicLevels.map(makeOption)}
            placeholder="Select Trophic Level"
            validationMessage={validationMessages.trophicLevel}
            value={node.trophicLevel}
            menuPortalTarget={document.body}
            closeMenuOnScroll={event =>
              modalRef && modalRef.firstChild.contains(event.target)
            }
            description="Based on the position the node occupies in a food web"
          />
        </div>
      </div>
    </div>
  );
};

const MatrixFooter = () => {
  return (
    <div>
      Not sure which settings to choose? Learn more about how the MPG matrix
      works in our{' '}
      <a
        href="https://docs.google.com/document/d/1HGGWkx9PZrsdBR3CNIeZvDfOc0IhHvV7jFi56v4PGjg/edit"
        target="_blank"
      >
        FAQs
      </a>
    </div>
  );
};

// Node settings
const MatrixSettingsSection = ({ onDelete, matrix, node, readOnly }) => {
  const [settingsState, setSettingsState] = useState('basic_info');

  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);

  const menuOptions = [
    {
      label: 'Basic info',
      value: 'basic_info',
      content: (
        <MatrixBasicSettings matrix={matrix} node={node} readOnly={readOnly} />
      ),
    },
    {
      label: 'Population / abundance',
      value: 'population_abundance',
      content: (
        <MatrixPopulationAbundanceSettings
          matrix={matrix}
          node={node}
          readOnly={readOnly}
        />
      ),
    },
    {
      label: 'Advanced',
      value: 'advanced',
      content: (
        <MatrixAdvancedSettings
          matrix={matrix}
          node={node}
          readOnly={readOnly}
        />
      ),
    },
  ];

  return (
    <Settings
      menuOptions={menuOptions}
      setMenuOption={setSettingsState}
      activeMenuOption={settingsState}
      header="Edit Node"
      footer={<MatrixFooter />}
      deletePopoverTitle="Delete node"
      deletePopoverContent={
        <DeletePopoverContent
          text={
            'Deleting this node will impact the rest of the matrix. You may undo this action in the modifications panel.'
          }
          primaryAction={onDelete}
          primaryActionLabel={'Delete'}
          secondaryAction={() => setDeletePopoverOpen(false)}
          secondaryActionLabel={'Cancel'}
        />
      }
      deletePopoverOpen={deletePopoverOpen}
      setDeletePopoverOpen={setDeletePopoverOpen}
    />
  );
};

export default MatrixSettingsSection;
