import React from 'react';
import { useTour } from '@reactour/tour';

export const TourContentComponent = ({ currentStep }) => {
  const { steps } = useTour();
  const { content, header } = steps[currentStep];

  return (
    <div className="TourContentComponent">
      <strong>{header}</strong>
      <div className="TourContentComponent-content">{content}</div>
    </div>
  );
};
