import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import MiniModal from './MiniModal';
import ButtonNew from './inputs/ButtonNew';
import Icon from './Icon';
import Checkbox from './inputs/Checkbox';
import { setNodeFixedWarning } from 'actions/viewStates';
import './OutputToInputWarningModal.scss';

const OutputToInputWarningModal = ({ onExit, onCancel }) => {
  const dispatch = useDispatch();

  const [dontShowChecked, setDontShowChecked] = useState(false);

  const onConfirm = useCallback(() => {
    dispatch(setNodeFixedWarning({ userDeclined: dontShowChecked }));
    onExit();
  }, [dispatch, dontShowChecked, onExit]);

  return (
    <MiniModal onExit={onExit} dark={true}>
      <div className="OutputToInputWarningModal">
        <div className="OutputToInputWarningModal-image">
          <Icon icon="inputOutputInfo" />
        </div>
        <div className="OutputToInputWarningModal-text">
          This node is currently an output. By adjusting the abundance of an
          output, you are changing this node to an input. This node abundance
          will stay fixed, and other changes to the ecosystem will no longer
          impact this node when you run the model.
        </div>
        <div className="OutputToInputWarningModal-text">
          Learn more about how inputs and outputs work in our{' '}
          <a
            href="https://docs.google.com/document/d/1HGGWkx9PZrsdBR3CNIeZvDfOc0IhHvV7jFi56v4PGjg/edit"
            target="_blank"
          >
            FAQs
          </a>
          .
        </div>
        <div className="OutputToInputWarningModal-checkbox-container">
          <Checkbox
            id="dontShowWarning"
            label={'Don’t show this message again'}
            value={dontShowChecked}
            onChange={() => setDontShowChecked(!dontShowChecked)}
          />
        </div>
        <div className="OutputToInputWarningModal-buttons-container">
          <div className="OutputToInputWarningModal-button-container">
            <ButtonNew
              buttonClasses="secondary-button-dark-small OutputToInputWarningModal-button"
              onClick={onCancel}
              label="Cancel"
            />
          </div>
          <div className="OutputToInputWarningModal-button-container">
            <ButtonNew
              buttonClasses="primary-button-small OutputToInputWarningModal-button"
              onClick={onConfirm}
              label="Ok"
            />
          </div>
        </div>
      </div>
    </MiniModal>
  );
};

export default OutputToInputWarningModal;
