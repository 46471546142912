import React from 'react';
import text from 'constants/text';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="text-interface-plain description">
        <span>{text.privacyPolicy.text}</span>{' '}
        <a href={`mailto:${text.privacyPolicy.email}`}>
          {text.privacyPolicy.email}
        </a>
        .
      </div>
    </div>
  );
};

export default PrivacyPolicy;
