import {
  MATRIX_ADD_NODE,
  MATRIX_CONVERT_EXPERIMENT_TO_MATRIX,
  MATRIX_CREATE,
  MATRIX_CREATE_EXPERIMENT,
  MATRIX_DELETE_NODE,
  MATRIX_SELECT,
  MATRIX_RESET,
  MATRIX_REMOVE_REQUEST,
  MATRIX_REMOVE_RESPONSE,
  MATRIX_REMOVE_ERROR_RESPONSE,
  MATRIX_SAVE_REQUEST,
  MATRIX_SAVE_RESPONSE,
  MATRIX_SAVE_ERROR_RESPONSE,
  MATRIX_REQUEST,
  MATRIX_RESPONSE,
  MATRIX_ERROR_RESPONSE,
  MATRIX_ERRORS_CLEAR,
  MATRIX_ADD_FAVORITE,
  MATRIX_REMOVE_FAVORITE,
  CONNECTIONS_IMPORT_ERROR_RESPONSE,
  MATRIX_ADD_SESSION_STATE,
} from 'constants/action_names';
import * as MatrixService from 'services/matrices';

const fetchMatrices = (fetcher, matrixIdentifier) => {
  return dispatch => {
    dispatch({
      type: MATRIX_REQUEST,
      matrixIdentifier,
    });

    return fetcher
      .then(json => {
        dispatch({
          type: MATRIX_RESPONSE,
          json,
          matrixIdentifier,
        });
      })
      .catch(error => {
        dispatch({
          type: MATRIX_ERROR_RESPONSE,
          error,
          matrixIdentifier,
        });
      });
  };
};

export const fetchPublicMatrices = matrices => {
  return fetchMatrices(MatrixService.listPublic(matrices), 'public');
};

export const fetchUserMatrices = (user, userData, matrices) => {
  return fetchMatrices(
    MatrixService.listUser(user, userData, matrices),
    'user'
  );
};

export const fetchMatrix = matrixId => {
  return fetchMatrices(MatrixService.get(matrixId), matrixId);
};

export const addNode = (matrixId, node = null) => ({
  type: MATRIX_ADD_NODE,
  matrixId,
  node,
});

export const convertExperimentToMatrix = newMatrix => ({
  type: MATRIX_CONVERT_EXPERIMENT_TO_MATRIX,
  newMatrix,
});

export const createMatrix = newMatrix => ({
  type: MATRIX_CREATE,
  newMatrix,
});

export const createExperiment = newExperiment => ({
  type: MATRIX_CREATE_EXPERIMENT,
  newExperiment,
});

export const deleteNode = (matrixId, nodeId) => ({
  type: MATRIX_DELETE_NODE,
  matrixId: matrixId,
  nodeId,
});

export const selectMatrix = id => ({
  type: MATRIX_SELECT,
  id,
});

export const resetMatrix = reset_boolean => ({
  type: MATRIX_RESET,
  reset: reset_boolean,
});

export const addFavoriteMatrix = (matrixId, userId) => ({
  type: MATRIX_ADD_FAVORITE,
  matrixId,
  userId,
});

export const removeFavoriteMatrix = (matrixId, userId) => ({
  type: MATRIX_REMOVE_FAVORITE,
  matrixId,
  userId,
});

export const clearErrors = () => ({
  type: MATRIX_ERRORS_CLEAR,
});

export const removeMatrix = matrixId => {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const matrix = state.matrices.matrices.filter(e => e.id === matrixId)[0];
    matrix.isDeleted = true;

    dispatch({
      type: MATRIX_REMOVE_REQUEST,
      matrixId,
    });

    return MatrixService.save(matrix, user)
      .then(() => {
        dispatch({
          type: MATRIX_REMOVE_RESPONSE,
          matrixId,
        });
      })
      .catch(error => {
        dispatch({
          type: MATRIX_REMOVE_ERROR_RESPONSE,
          error,
        });
      });
  };
};

export const saveMatrix = matrixId => {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const matrix = {
      ...state.matrices.matrices.filter(e => e.id === matrixId)[0],
    };

    // Add key to matrix before saving, if it exists
    const key = state.anonymousEditingKeys.keys[matrixId];
    if (key) matrix.key = key;

    dispatch({
      type: MATRIX_SAVE_REQUEST,
    });

    return MatrixService.save(matrix, user)
      .then(() => {
        dispatch({
          type: MATRIX_SAVE_RESPONSE,
        });
      })
      .catch(error => {
        console.warn(error);
        dispatch({
          type: MATRIX_SAVE_ERROR_RESPONSE,
          error,
        });
      });
  };
};

export const importConnectionsError = error => {
  return dispatch => {
    dispatch({
      type: CONNECTIONS_IMPORT_ERROR_RESPONSE,
      error,
    });
  };
};

export const addSessionMatrix = matrix => ({
  type: MATRIX_ADD_SESSION_STATE,
  matrix,
});
