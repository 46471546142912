import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as d3 from 'd3';
import NodePill from 'components/NodePill';
import { nodeSortFunction } from 'constants/nodes';
import { setNodeHover } from 'actions/viewStates';
import './MatrixSidebarConnections.scss';

const SIDEBAR_WIDTH = 320;
const NODE_HEIGHT = 24;
const NODE_PADDING_HORIZONTAL = 5;
const NODE_PADDING_VERTICAL = 10;
const OUTER_PADDING = 12;
const HORIZONTAL_LINE_LENGTH = 50;

const TOTAL_HORIZONTAL_PADDING =
  OUTER_PADDING + HORIZONTAL_LINE_LENGTH + NODE_PADDING_HORIZONTAL;
const NODE_WIDTH = SIDEBAR_WIDTH - TOTAL_HORIZONTAL_PADDING * 2;
const NODE_SPACING_HEIGHT = NODE_HEIGHT + NODE_PADDING_VERTICAL;

const NodeWeightLabel = ({ x, y, color, weight }) => (
  <g transform={`translate(${x}, ${y})`}>
    <rect
      x={-(HORIZONTAL_LINE_LENGTH * 0.66) / 2}
      y={-(NODE_HEIGHT * 0.75) / 2}
      width={HORIZONTAL_LINE_LENGTH * 0.66}
      height={NODE_HEIGHT * 0.75}
      rx={4}
      ry={4}
      className={color}
    />
    <text className="weight-label">{d3.format('.2')(parseFloat(weight))}</text>
  </g>
);

const ArrowHead = ({ x, y, direction, color, scale = 1 }) => (
  <g
    transform={`translate(${x}, ${y}) rotate(${
      { left: 0, right: 180, up: 90, down: 270 }[direction]
    })`}
  >
    <path
      d={`M ${(NODE_HEIGHT / 5) * scale} ${(-NODE_HEIGHT / 5) *
        scale} L 0 0 L ${(NODE_HEIGHT / 5) * scale} ${(NODE_HEIGHT / 5) *
        scale}`}
      className={color}
    />
  </g>
);

const AllNodeConnections = ({ nodesSorted, currentHoverId }) => {
  // Generates a map of node ids to their index position
  const nodesIndexMap = nodesSorted.reduce(
    (acc, node, i) => ({ ...acc, [node.id]: i }),
    {}
  );

  const connections = nodesSorted
    .reduce((acc, node) => {
      return [
        ...acc,
        ...Object.keys(node.influences).map(nodeId => ({
          from: nodesIndexMap[node.id],
          fromId: node.id,
          to: nodesIndexMap[nodeId],
          toId: nodeId,
          weight: node.influences[nodeId],
        })),
      ];
    }, [])
    .filter(({ from, to }) => from !== to);

  const pathGen = ({ from, to }) => {
    const xBase = SIDEBAR_WIDTH - OUTER_PADDING - HORIZONTAL_LINE_LENGTH;
    const y1 = Math.min(from, to) * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2;
    const y2 = Math.max(from, to) * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2;
    const xOffset =
      (Math.abs(to - from) / nodesSorted.length) * HORIZONTAL_LINE_LENGTH +
      NODE_PADDING_HORIZONTAL * 1.5;
    const yOffset = NODE_HEIGHT * Math.min(4, Math.abs(to - from) / 2);

    return `
      M ${xBase}, ${y1} 
      C ${xBase + xOffset}, ${y1} ${xBase + xOffset}, ${y1} ${xBase +
      xOffset}, ${y1 + yOffset} 
      L ${xBase + xOffset}, ${y2 - yOffset} 
      C ${xBase + xOffset}, ${y2} ${xBase + xOffset}, ${y2} ${xBase}, ${y2}
    `;
  };

  return (
    <g className="all-connections">
      {connections.map(({ from, fromId, to, toId, weight }) => (
        <g
          key={`from-${from}-to-${to}`}
          opacity={
            !currentHoverId ||
            fromId === currentHoverId ||
            toId === currentHoverId
              ? 1
              : 0.1
          }
          strokeWidth={
            currentHoverId &&
            (fromId === currentHoverId || toId === currentHoverId)
              ? 2
              : 1.5
          }
        >
          <path
            className={weight >= 0 ? 'blue' : 'red'}
            transform={
              weight >= 0 ? '' : `scale(-1, 1) translate(${-SIDEBAR_WIDTH}, 0)`
            }
            d={pathGen({ from, to })}
          />
          <ArrowHead
            color={weight >= 0 ? 'blue' : 'red'}
            direction={weight >= 0 ? 'left' : 'right'}
            x={
              weight >= 0
                ? SIDEBAR_WIDTH - OUTER_PADDING - HORIZONTAL_LINE_LENGTH
                : OUTER_PADDING + HORIZONTAL_LINE_LENGTH
            }
            y={to * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
          />
        </g>
      ))}
    </g>
  );
};

const IndividualNodeConnections = ({
  nodesSorted,
  currentHoverId,
  hoverInfluences,
  hoverInfluenced,
}) => {
  // Helper function to count how many positive or negative connections exist
  // in a list of nodes.
  const weightCount = (nodes, weightFn) =>
    nodes.reduce(
      (acc, nodeId) => {
        if (weightFn(nodeId) >= 0) acc.positive += 1;
        else acc.negative += 1;
        return acc;
      },
      { positive: 0, negative: 0 }
    );

  // Helper function to generate the data needed for the colored side line segments
  const getSegmentRanges = (nodes, weightFn, weightCounts) => {
    const postiveWeightNodes = [
      ...nodes.filter(id => weightFn(id) >= 0),
      currentHoverId,
    ];
    const negativeWeightNodes = [
      ...nodes.filter(id => weightFn(id) < 0),
      currentHoverId,
    ];

    const positiveRange = {
      direction: 'positive',
      color: 'blue',
      range: d3.extent(postiveWeightNodes, id =>
        nodesSorted.findIndex(d => d.id === id)
      ),
    };
    const negativeRange = {
      direction: 'negative',
      color: 'red',
      range: d3.extent(negativeWeightNodes, id =>
        nodesSorted.findIndex(d => d.id === id)
      ),
    };

    // Reorder so that the one related to the hover is the last one.
    if (weightCounts.positive >= weightCounts.negative)
      return [negativeRange, positiveRange];
    else return [positiveRange, negativeRange];
  };

  const getHoverInfluencesWeight = id =>
    nodesSorted.find(d => d?.id === currentHoverId)?.influences?.[id];
  const hoverInfluencesWeightCount = weightCount(
    hoverInfluences,
    getHoverInfluencesWeight
  );
  const hoverInfluencesRange = getSegmentRanges(
    hoverInfluences,
    getHoverInfluencesWeight,
    hoverInfluencesWeightCount
  );

  const getHoverInfluencedWeight = id =>
    nodesSorted.find(d => d?.id === id)?.influences?.[currentHoverId];
  const hoverInfluencedWeightCount = weightCount(
    hoverInfluenced,
    getHoverInfluencedWeight
  );
  const hoverInfluencedRange = getSegmentRanges(
    hoverInfluenced,
    getHoverInfluencedWeight,
    hoverInfluencedWeightCount
  );

  const lineStrokeColor = (node, weightFn, weightCount, selfReference) => {
    // If the line is not the currentHover, return the color based on its weight
    if (currentHoverId !== node.id || selfReference)
      return weightFn(node.id) >= 0 ? 'blue' : 'red';

    // Otherwise, use its positive and negative connection counts
    return weightCount.positive >= weightCount.negative ? 'blue' : 'red';
  };

  return (
    <>
      {nodesSorted.map((node, i) => (
        <React.Fragment key={i}>
          {/* The lines that come out from the left or right of the node. */}
          {(currentHoverId === node.id && hoverInfluences.length) ||
          hoverInfluences.indexOf(node.id) >= 0 ? (
            <line
              x1={SIDEBAR_WIDTH - OUTER_PADDING - HORIZONTAL_LINE_LENGTH}
              x2={SIDEBAR_WIDTH - OUTER_PADDING}
              y1={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              y2={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              className={lineStrokeColor(
                node,
                getHoverInfluencesWeight,
                hoverInfluencesWeightCount
              )}
            />
          ) : null}
          {(currentHoverId === node.id &&
            hoverInfluenced.filter(id => id !== currentHoverId).length) ||
          hoverInfluenced
            .filter(id => id !== currentHoverId)
            .indexOf(node.id) >= 0 ? (
            <line
              x1={OUTER_PADDING}
              x2={OUTER_PADDING + HORIZONTAL_LINE_LENGTH}
              y1={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              y2={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              className={lineStrokeColor(
                node,
                getHoverInfluencedWeight,
                hoverInfluencedWeightCount
              )}
            />
          ) : null}

          {/* Self referencing node lines */}
          {currentHoverId === node.id &&
          hoverInfluences.length &&
          hoverInfluences.indexOf(node.id) >= 0 ? (
            <>
              <line
                x1={SIDEBAR_WIDTH - OUTER_PADDING}
                x2={SIDEBAR_WIDTH - OUTER_PADDING}
                y1={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
                y2={
                  i * NODE_SPACING_HEIGHT +
                  NODE_HEIGHT +
                  NODE_PADDING_VERTICAL / 2
                }
                className={lineStrokeColor(
                  node,
                  getHoverInfluencesWeight,
                  hoverInfluencesWeightCount,
                  true
                )}
              />
              <line
                x1={SIDEBAR_WIDTH / 2}
                x2={SIDEBAR_WIDTH - OUTER_PADDING}
                y1={
                  i * NODE_SPACING_HEIGHT +
                  NODE_HEIGHT +
                  NODE_PADDING_VERTICAL / 2
                }
                y2={
                  i * NODE_SPACING_HEIGHT +
                  NODE_HEIGHT +
                  NODE_PADDING_VERTICAL / 2
                }
                className={lineStrokeColor(
                  node,
                  getHoverInfluencesWeight,
                  hoverInfluencesWeightCount,
                  true
                )}
              />
              <line
                x1={SIDEBAR_WIDTH / 2}
                x2={SIDEBAR_WIDTH / 2}
                y1={i * NODE_SPACING_HEIGHT + NODE_HEIGHT - 3}
                y2={
                  i * NODE_SPACING_HEIGHT +
                  NODE_HEIGHT +
                  NODE_PADDING_VERTICAL / 2
                }
                className={lineStrokeColor(
                  node,
                  getHoverInfluencesWeight,
                  hoverInfluencesWeightCount,
                  true
                )}
              />
              <ArrowHead
                x={SIDEBAR_WIDTH / 2}
                y={i * NODE_SPACING_HEIGHT + NODE_HEIGHT - 3}
                direction="up"
                scale={0.7}
                color={lineStrokeColor(
                  node,
                  getHoverInfluencedWeight,
                  hoverInfluencedWeightCount,
                  true
                )}
              />
            </>
          ) : null}

          {/* Line arrowheads */}
          {hoverInfluences.indexOf(node.id) >= 0 &&
          currentHoverId !== node.id ? (
            <ArrowHead
              x={SIDEBAR_WIDTH - OUTER_PADDING - HORIZONTAL_LINE_LENGTH}
              y={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              direction="left"
              color={lineStrokeColor(
                node,
                getHoverInfluencesWeight,
                hoverInfluencesWeightCount
              )}
            />
          ) : null}
          {currentHoverId === node.id &&
          hoverInfluenced.filter(id => id !== currentHoverId).length ? (
            <ArrowHead
              x={OUTER_PADDING + HORIZONTAL_LINE_LENGTH}
              y={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              direction="right"
              color={lineStrokeColor(
                node,
                getHoverInfluencedWeight,
                hoverInfluencedWeightCount
              )}
            />
          ) : null}

          {/* Connection weight labels. */}
          {!isNaN(getHoverInfluencesWeight(node.id)) &&
          currentHoverId !== node.id ? (
            <NodeWeightLabel
              x={SIDEBAR_WIDTH - OUTER_PADDING - HORIZONTAL_LINE_LENGTH / 2}
              y={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              color={lineStrokeColor(
                node,
                getHoverInfluencesWeight,
                hoverInfluencesWeightCount
              )}
              weight={getHoverInfluencesWeight(node.id)}
            />
          ) : null}
          {!isNaN(getHoverInfluencedWeight(node.id)) &&
          currentHoverId !== node.id ? (
            <NodeWeightLabel
              x={OUTER_PADDING + HORIZONTAL_LINE_LENGTH / 2}
              y={i * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
              color={lineStrokeColor(
                node,
                getHoverInfluencedWeight,
                hoverInfluencedWeightCount
              )}
              weight={getHoverInfluencedWeight(node.id)}
            />
          ) : null}

          {/* Self referencing node label */}
          {!isNaN(getHoverInfluencesWeight(node.id)) &&
          currentHoverId === node.id ? (
            <NodeWeightLabel
              x={SIDEBAR_WIDTH - OUTER_PADDING - HORIZONTAL_LINE_LENGTH / 2}
              y={i * NODE_SPACING_HEIGHT + NODE_HEIGHT + 1}
              color={lineStrokeColor(
                node,
                getHoverInfluencesWeight,
                hoverInfluencesWeightCount,
                true
              )}
              weight={getHoverInfluencesWeight(node.id)}
            />
          ) : null}
        </React.Fragment>
      ))}

      {/* The lines that span the range of the nodes influenced or influencing. */}
      {hoverInfluencesRange.map(({ range, color, direction }) =>
        range[0] !== range[1] ? (
          <line
            key={direction}
            x1={SIDEBAR_WIDTH - OUTER_PADDING}
            x2={SIDEBAR_WIDTH - OUTER_PADDING}
            y1={range[0] * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
            y2={range[1] * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
            className={color}
          />
        ) : null
      )}
      {hoverInfluencedRange.map(({ range, color, direction }) =>
        range[0] !== range[1] ? (
          <line
            key={direction}
            x1={OUTER_PADDING}
            x2={OUTER_PADDING}
            y1={range[0] * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
            y2={range[1] * NODE_SPACING_HEIGHT + NODE_HEIGHT / 2}
            className={color}
          />
        ) : null
      )}
    </>
  );
};

const MatrixSidebarConnections = ({ nodes, showAllConnections }) => {
  const dispatch = useDispatch();
  const currentHoverId = useSelector(state => state.viewStates.hoverNode);

  const initialSort = Object.values(nodes).sort(nodeSortFunction);

  // Note: We are creating items for nodes and for trophic level labels so we
  // can continue using the existing SVG arrow drawing system
  const nodesSorted = initialSort
    // Filter out node influences that may not exist
    .reduce((acc, node, i) => {
      // Trophic level labels for connections table
      // const isFirstTrophicLevel =
      //   initialSort.findIndex(n => n.trophicLevel === node.trophicLevel) === i;

      // if (isFirstTrophicLevel) {
      //   acc.push({
      //     type: 'trophicLevel',
      //     name: node.trophicLevel.toUpperCase(),
      //   });
      // }

      const next = {
        type: 'node',
        ...node,
        influences: Object.keys(node.influences).reduce((acc, nodeId) => {
          if (Object.values(nodes).find(n => n.id === nodeId))
            return { ...acc, [nodeId]: node.influences[nodeId] };
          return acc;
        }, {}),
      };
      acc.push(next);
      return acc;
    }, []);

  // The height of the entire SVG
  const graphicHeight =
    nodesSorted.length * NODE_SPACING_HEIGHT + NODE_PADDING_VERTICAL;

  // Get the node ids of the nodes we influence
  const hoverInfluences = Object.keys(
    nodesSorted.find(n => n.id === currentHoverId)?.influences || {}
  );

  // Get the node ids of the nodes that influence us
  const hoverInfluenced = nodesSorted
    .filter(n => n.type !== 'trophicLevel')
    .reduce(
      (acc, node) =>
        Object.keys(node.influences).indexOf(currentHoverId) >= 0
          ? [...acc, node.id]
          : acc,
      []
    );

  // Display helper functions
  const nodeOpaque = node =>
    !currentHoverId ||
    currentHoverId === node.id ||
    hoverInfluences.indexOf(node.id) >= 0 ||
    hoverInfluenced.indexOf(node.id) >= 0;

  useEffect(() => {
    const nowrap = text => {
      text.each(function() {
        var text = d3.select(this);
        var chars = text.text().split('');

        const textWidth = NODE_WIDTH;

        var ellipsis = text
          .text('')
          .append('tspan')
          .attr('class', 'elip')
          .text('...');

        var width =
          parseFloat(textWidth) - ellipsis.node().getComputedTextLength();

        var numChars = chars.length;

        var tspan = text.insert('tspan', ':first-child').text(chars.join(''));

        while (tspan.node().getComputedTextLength() > width && chars.length) {
          chars.pop();
          tspan.text(chars.join(''));
        }

        if (chars.length === numChars) {
          ellipsis.remove();
        }
      });
    };

    d3.selectAll('.MatrixSidebarConnections-trophic-level-name').call(nowrap);
  }, []);

  return (
    <svg
      className="connections-graphic interactiveTourGettingStarted-7"
      viewBox={`0 ${-NODE_PADDING_VERTICAL} ${SIDEBAR_WIDTH} ${graphicHeight}`}
      onMouseOut={() => dispatch(setNodeHover())}
    >
      {nodesSorted.map((node, i) => {
        return (
          <React.Fragment key={i}>
            <svg
              x={TOTAL_HORIZONTAL_PADDING}
              y={i * NODE_SPACING_HEIGHT}
              width={NODE_WIDTH}
              height={NODE_HEIGHT}
              onMouseMove={() => {
                if (currentHoverId !== node.id) dispatch(setNodeHover(node.id));
              }}
              onMouseOut={e => e.stopPropagation()}
            >
              {node.type === 'trophicLevel' ? (
                <svg width={NODE_WIDTH} height={NODE_HEIGHT}>
                  <text
                    className="MatrixSidebarConnections-trophic-level-name"
                    x={0}
                    y={NODE_HEIGHT / 2 + 10}
                  >
                    {node.name}
                  </text>
                </svg>
              ) : (
                <NodePill
                  node={node}
                  connectionsCount={Object.keys(node.influences).length}
                  opacity={nodeOpaque(node) ? 1 : 0.3}
                  width={NODE_WIDTH}
                  height={NODE_HEIGHT}
                  hover={currentHoverId === node.id}
                />
              )}
            </svg>
          </React.Fragment>
        );
      })}

      {showAllConnections ? (
        <AllNodeConnections
          nodesSorted={nodesSorted}
          currentHoverId={currentHoverId}
        />
      ) : (
        <IndividualNodeConnections
          currentHoverId={currentHoverId}
          nodesSorted={nodesSorted}
          hoverInfluences={hoverInfluences}
          hoverInfluenced={hoverInfluenced}
        />
      )}
    </svg>
  );
};

export default MatrixSidebarConnections;
