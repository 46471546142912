import React from 'react';
import MiniModal from './MiniModal';
import ButtonNew from './inputs/ButtonNew';
import NodePill from 'components/NodePill';
import './NodeDescriptionModal.scss';

const NodeDescriptionModal = ({
  node,
  name,
  imageUrl,
  description,
  trophicLevelInfo,
  onExit,
}) => {
  const { name: trophicLevelName, icon: trophicLevelIcon } = trophicLevelInfo;
  return (
    <MiniModal onExit={onExit}>
      <div className="NodeDescriptionModal">
        <div className="NodeDescriptionModal-image-container">
          <img alt={name} src={imageUrl} />
        </div>

        <div className="NodeDescriptionModal-text-container">
          <div className="NodeDescriptionModal-name">{name}</div>
          <div className="NodeDescriptionModal-trophic-level">
            <img
              className="NodeDescriptionModal-trophic-level-icon"
              src={trophicLevelIcon}
              alt={trophicLevelName}
            />
            {trophicLevelName}
          </div>
          {/* <NodePill node={node} /> */}
          <div className="NodeDescriptionModal-description">{description}</div>
          <div className="NodeDescriptionModal-button-container">
            <ButtonNew
              buttonClasses="primary-button-small"
              onClick={onExit}
              label="Ok"
            />
          </div>
        </div>
      </div>
    </MiniModal>
  );
};

export default NodeDescriptionModal;
