import {
  FOLDER_SETTINGS_START_EDIT,
  FOLDER_SETTINGS_SET_FIELD,
  FOLDER_SETTINGS_CANCEL_EDIT,
  FOLDER_SETTINGS_FINISH_EDIT,
  FOLDER_SETTINGS_VALIDATE,
} from 'constants/action_names';
import validateFolder from 'validations/folders';

const initialState = {
  folder: null,
  valid: true,
  validationMessages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FOLDER_SETTINGS_START_EDIT:
      return {
        ...state,
        folder: action.folder,
        valid: true,
        validationMessages: [],
      };
    case FOLDER_SETTINGS_SET_FIELD:
      return {
        ...state,
        folder: {
          ...state.folder,
          [action.field]: action.value,
        },
      };
    case FOLDER_SETTINGS_CANCEL_EDIT:
      return { ...initialState };
    case FOLDER_SETTINGS_FINISH_EDIT:
      return { ...initialState };
    case FOLDER_SETTINGS_VALIDATE:
      const { valid, messages } = validateFolder(state.folder, action.user);
      return {
        ...state,
        valid,
        validationMessages: messages,
      };
    default:
      return state;
  }
};
