import * as d3 from 'd3';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { fetchUserFolders } from 'actions/folders';
import {
  fetchPublicMatrices,
  fetchUserMatrices,
  fetchMatrix,
} from 'actions/matrices';
import GalleryMenu from 'components/menus/GalleryMenu';
import FolderView from 'components/FolderView';
import MatrixSettingsModal from 'components/matrices/MatrixSettingsModal';
import MatrixList from 'components/matrices/MatrixList';
import { getRootMatrices, shouldFetchMatrix } from 'constants/matrices';
import './GalleryView.scss';
import { setGalleryTab } from 'actions/viewStates';

const adminManualReorderablePages = ['public', 'education'];

const GalleryView = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);
  const galleryTab = useSelector(state => state.viewStates.lastGalleryTabOpen);

  const sortBy = useSelector(
    state => state.viewStates.sortBy?.[galleryTab?.name]?.by
  );
  const sortDirection = useSelector(
    state => state.viewStates.sortBy?.[galleryTab?.name]?.direction
  );

  const { isAdmin } = useSelector(state => state.auth.userData);

  const allMatricesAndExperiments = useSelector(
    state => state.matrices.matrices
  ).filter(({ savedByUser }) => savedByUser);

  const matrices = getRootMatrices(allMatricesAndExperiments);
  const experiments = allMatricesAndExperiments.filter(
    ({ parentId }) => parentId !== null
  );

  const folders = useSelector(state => state.folders.folders);
  const foldersLoading = useSelector(state => state.folders._loading);
  const foldersLoaded = useSelector(state => state.folders._loaded);

  const userId = useMemo(() => auth.user?.claims?.user_id ?? null, [auth.user]);

  const userExperiments = useMemo(() => {
    if (!userId) return [];
    return experiments.filter(({ roles }) => roles[userId] === 'owner');
  }, [experiments, userId]);

  const userMatrices = useMemo(() => {
    if (!userId) return [];

    return matrices.filter(({ id, isPublic, roles }) => {
      const ownedByUser = !isPublic && roles[userId] === 'owner';
      const experimentByUser =
        userExperiments.map(({ parentId }) => parentId).indexOf(id) >= 0;
      return ownedByUser || experimentByUser;
    });
  }, [matrices, userId, userExperiments]);

  // Fetch matrices user does not own, but has experiment based on
  useEffect(() => {
    const nonUserMatrixIds = experiments
      .filter(e => !matrices.some(m => m.id === e.parentId))
      .map(e => e.parentId);

    for (const id of nonUserMatrixIds) {
      if (shouldFetchMatrix(id, null, matricesLoaded, matricesLoading)) {
        try {
          dispatch(fetchMatrix(id));
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [dispatch, matricesLoaded, matricesLoading, experiments, matrices]);

  const userFolders = useMemo(() => {
    if (!userId) return [];
    return folders.filter(
      ({ id, isPublic, roles }) => !isPublic && roles[userId] === 'owner'
    );
  }, [folders, userId]);

  useEffect(() => {
    if (
      auth.user &&
      Object.keys(auth.userData).length > 0 &&
      !matricesLoading.user &&
      !matricesLoaded.user &&
      adminManualReorderablePages.indexOf('private') < 0
    ) {
      dispatch(
        fetchUserMatrices(auth.user, auth.userData, allMatricesAndExperiments)
      );
    }
  }, [
    dispatch,
    auth,
    matricesLoaded,
    matricesLoading,
    allMatricesAndExperiments,
  ]);

  useEffect(() => {
    if (
      !matricesLoading.public &&
      !matricesLoaded.public &&
      adminManualReorderablePages.indexOf('public') >= 0
    ) {
      dispatch(fetchPublicMatrices(allMatricesAndExperiments));
    }
  }, [dispatch, matricesLoading, matricesLoaded, allMatricesAndExperiments]);

  useEffect(() => {
    if (
      auth.user &&
      Object.keys(auth.userData).length > 0 &&
      !foldersLoading.user &&
      !foldersLoaded.user
    ) {
      dispatch(fetchUserFolders(auth.user, auth.userData, folders));
    }
  }, [dispatch, auth, foldersLoaded, foldersLoading, folders]);

  const visibleMatrices = useMemo(() => {
    const publicMatrices = matrices.filter(
      ({ isPublic, isEducation }) => isPublic && !isEducation
    );
    const educationMatrices = matrices.filter(
      ({ isPublic, isEducation }) => isPublic && isEducation
    );

    switch (galleryTab.name) {
      case 'private':
        return userMatrices.filter(m => {
          // Filter out any matrices that meet both conditions:
          // - user does not own (shown because of experiments)
          // - experiments are foldered
          const isNotOwner = m?.roles?.[userId] !== 'owner';
          const experimentsAreFoldered = userExperiments
            .filter(e => e.parentId === m.id)
            .every(e => !!e.folder === true);

          if (isNotOwner && experimentsAreFoldered) return false;
          return true;
        });
      case 'all':
        return matrices;
      case 'education':
        return educationMatrices;
      default:
      case 'public':
        return publicMatrices;
    }
  }, [matrices, userMatrices, galleryTab, userExperiments, userId]);

  const visibleExperiments = useMemo(() => {
    const publicExperiments = experiments.filter(
      ({ isPublic, isEducation }) => isPublic && !isEducation
    );
    const educationExperiments = experiments.filter(
      ({ isPublic, isEducation }) => isPublic && isEducation
    );
    switch (galleryTab.name) {
      case 'private':
        return userExperiments;
      case 'all':
        return experiments;
      case 'education':
        return educationExperiments;
      default:
      case 'public':
        return publicExperiments;
    }
  }, [experiments, userExperiments, galleryTab]);

  const visibleFolders = useMemo(() => {
    const publicFolders = folders.filter(({ isPublic }) => isPublic);

    switch (galleryTab.name) {
      case 'private':
        return userFolders;
      case 'all':
        return folders;
      default:
      case 'public':
        return publicFolders;
    }
  }, [folders, userFolders, galleryTab]);

  const sortMarices = (matrices, by, direction) => {
    if (by === 'numberNodes' || by === 'numberConnections') {
      return matrices.sort((a, b) =>
        direction === 'ascending'
          ? d3.ascending(
              a?.basicStats ? a?.basicStats[by] : null,
              b?.basicStats ? b?.basicStats[by] : null
            )
          : d3.descending(
              a?.basicStats ? a?.basicStats[by] : null,
              b?.basicStats ? b?.basicStats[by] : null
            )
      );
    } else {
      return matrices.sort((a, b) =>
        direction === 'ascending'
          ? d3.ascending(a[by], b[by])
          : d3.descending(a[by], b[by])
      );
    }
  };

  const sortFolders = (folders, by, direction) => {
    return folders.sort((a, b) => d3.ascending(a['name'], b['name']));
  };

  const sortedExperiments = useMemo(() => {
    return sortMarices(visibleExperiments, sortBy, sortDirection);
  }, [visibleExperiments, sortBy, sortDirection]);

  const sortedMatrices = useMemo(() => {
    return sortMarices(visibleMatrices, sortBy, sortDirection);
  }, [visibleMatrices, sortBy, sortDirection]);

  const sortedFolders = useMemo(() => {
    return sortFolders(visibleFolders, sortBy, sortDirection);
  }, [visibleFolders, sortBy, sortDirection]);

  let { path } = useRouteMatch();

  return (
    <>
      <div>
        <GalleryMenu galleryTab={galleryTab} />

        <Switch>
          <Route
            exact
            path={[
              '/gallery/folders/:folder',
              '/gallery/folders/:folder/:matrixId/edit',
              '/gallery/folders/:folder/:matrixId/delete',
            ]}
          >
            <FolderView
              matrices={userMatrices}
              experiments={userExperiments}
              galleryTab={galleryTab}
            />
          </Route>
          <Route path="/gallery">
            <MatrixList
              matrices={sortedMatrices}
              experiments={sortedExperiments}
              folders={sortedFolders}
              enableReorder={
                adminManualReorderablePages.indexOf(galleryTab.name) >= 0 &&
                isAdmin
              }
              showFavorites={galleryTab?.name === 'private'}
              // TODO temporary before public gallery redesign
              showEducation={galleryTab?.name === 'public'}
              setEducationTab={() =>
                dispatch(
                  setGalleryTab({ name: 'education', userInitiated: true })
                )
              }
              galleryTab={galleryTab}
            />
          </Route>
        </Switch>
      </div>

      <Route path={`${path}/:matrixId/edit`}>
        <MatrixSettingsModal cancelUrl={path} saveUrl={path} />
      </Route>
    </>
  );
};
export default GalleryView;
