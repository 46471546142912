import React from 'react';
import { useSelector } from 'react-redux';
import TextButton from './inputs/TextButton';
import './NoAccessView.scss';

const NoAccessView = () => {
  const { user } = useSelector(state => state.auth);

  return (
    <div className="NoAccessView">
      <h2>Access Denied</h2>
      <p>You don't have access to see this page.</p>
      {!user ? (
        <p>
          <div className="MainMenu-link text-interface-medium-500">
            <TextButton
              label="Sign in"
              requiresLogin
              requiresLoginMessage="Sign in to build a gallery of your own private matrices. Continue without signing in and you may retrieve sharable links to your work."
            />
          </div>
        </p>
      ) : null}
    </div>
  );
};

export default NoAccessView;
