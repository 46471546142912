import React, { useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as d3 from 'd3';

import { saveMatrix, importConnectionsError } from 'actions/matrices';
import { updateConnections } from 'constants/connections';
import { startEdit, finishEdit } from 'actions/matrixSettings';
import Tooltip from 'components/Tooltip';
import Button from 'components/inputs/Button';
import ButtonNew from 'components/inputs/ButtonNew';
import text from 'constants/text';
import './ImportConnections.scss';

const ImportConnections = ({ matrix, isExperiment }) => {
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const isViewOnly = useMemo(() => {
    return !!matrix?.isViewOnly;
  }, [matrix]);

  const handleImport = (matrix, csvContents) => {
    const byId = {};
    try {
      const parsed = d3.csvParse(csvContents, d3.autoType);

      // For each row
      parsed.forEach(row => {
        // Iterate over keys
        const influencerNode = Object.values(matrix.nodes).find(
          ({ name }) => name === row.influencer
        );
        if (!influencerNode) {
          throw new Error('ID changed');
        }
        byId[influencerNode.id] = {};

        Object.entries(row).forEach(([key, value]) => {
          if (key === 'influencer' || !key || !value) return;
          if (value < -1 || value > 1) throw new Error('value out of bounds');
          const influencedNode = Object.values(matrix.nodes).find(
            ({ name }) => name === key
          );

          byId[influencerNode.id][influencedNode.id] = value;
        });
      });
    } catch (err) {
      if (err.message === 'ID changed') {
        dispatch(
          importConnectionsError({
            title: text.errors.connectionsImport.title,
            details: text.errors.connectionsImport.idChanged.details,
            type: 'import',
          })
        );
      } else if (err.message === 'value out of bounds') {
        dispatch(
          importConnectionsError({
            title: text.errors.connectionsImport.title,
            details: text.errors.connectionsImport.valueBounds.details,
            type: 'import',
          })
        );
      } else console.error(err);
      return;
    }

    // Update matrix
    dispatch(startEdit(matrix));
    const newMatrix = updateConnections(matrix, byId);
    dispatch(finishEdit(newMatrix));
    dispatch(saveMatrix(newMatrix.id));
  };

  const handleFileChange = e => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => handleImport(matrix, reader.result),
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  };

  return (
    <div className="ImportConnections">
      <label>
        <input type="file" ref={fileInput} onChange={handleFileChange} />
        <ButtonNew
          buttonClasses="text-button"
          label="Import"
          onClick={() => {
            fileInput.current.click();
          }}
          iconLeft="importIcon"
          disabled={isViewOnly || !isExperiment}
        />
      </label>
      <Tooltip>
        Uploaded file must be a CSV and node names must match exactly.
      </Tooltip>
    </div>
  );
};

export default ImportConnections;
