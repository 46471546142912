import {
  MATRIX_SETTINGS_START_EDIT,
  MATRIX_SETTINGS_SET_FIELD,
  MATRIX_SETTINGS_CANCEL_EDIT,
  MATRIX_SETTINGS_FINISH_EDIT,
  MATRIX_SETTINGS_VALIDATE,
  MATRIX_SETTINGS_UPDATE,
} from 'constants/action_names';
import { basicStats } from 'constants/stats';
import moment from 'moment';

export const startEdit = matrix => ({
  type: MATRIX_SETTINGS_START_EDIT,
  matrix,
});

export const setField = (field, value) => ({
  type: MATRIX_SETTINGS_SET_FIELD,
  field,
  value,
});

export const update = matrix => {
  return {
    type: MATRIX_SETTINGS_UPDATE,
    matrix,
  };
};

export const cancelEdit = () => ({
  type: MATRIX_SETTINGS_CANCEL_EDIT,
});

export const finishEdit = (matrix, updateModified = true) => {
  const modifiedDate = updateModified ? moment() : matrix.modified;
  const stats = basicStats(matrix);
  return {
    type: MATRIX_SETTINGS_FINISH_EDIT,
    matrix,
    modifiedDate,
    stats,
  };
};

export const validate = user => ({
  type: MATRIX_SETTINGS_VALIDATE,
  user,
});
