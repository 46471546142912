import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FolderDeletePopoverContent from 'components/folders/FolderDeletePopoverContent';
import './FolderDeletePopoverWrapper.scss';

const FolderDeletePopoverWrapper = ({
  completionUrl,
  folderId,
  closePopover,
}) => {
  const folders = useSelector(state => state.folders.folders);
  const folder = useMemo(() => folders.find(f => f.id === folderId), [
    folders,
    folderId,
  ]);

  if (!folders.length || !folder) return null;

  return (
    <div className="FolderDeletePopoverWrapper">
      <FolderDeletePopoverContent
        completionUrl={completionUrl}
        folder={folder}
        closePopover={closePopover}
      />
    </div>
  );
};

export default FolderDeletePopoverWrapper;
