import React, { useState } from 'react';
import classNames from 'classnames';
import Button from 'components/inputs/Button';
import ConnectionDropdown from 'components/inputs/ConnectionDropdown';
import Icon from 'components/Icon';
import text from 'constants/text';
import InputField from 'components/inputs/InputField';
import NumberField from 'components/inputs/NumberField';
import TextButton from 'components/inputs/TextButton';
import ButtonNew from './ButtonNew';
import './ConnectionEditor.scss';
import icons from 'img/icons';
import Tooltip from 'components/Tooltip';

const ConnectionField = ({
  direction,
  isFocused,
  node,
  nodes,
  connection,
  onChange,
  onDelete,
  readOnly,
}) => {
  const isPositive = connection.value >= 0;
  const validateValue = value => {
    if (isNaN(parseFloat(value))) return value;
    if (value > 1) return 1;
    if (value < -1) return -1;
    return value;
  };

  const [focusNumberField, setFocusNumberField] = useState(false);

  return (
    <div className="ConnectionField">
      <div className="ConnectionField-otherNode">
        <ConnectionDropdown
          disabled={readOnly}
          onChange={(id, value) => {
            onChange(id, value);
            setFocusNumberField(true);
          }}
          {...{
            direction,
            node,
            nodes,
            connection,
            isFocused,
            isPositive,
          }}
        />
      </div>
      <div
        className={classNames('ConnectionField-influence', {
          positive: isPositive,
          negative: !isPositive,
        })}
      >
        <NumberField
          disabled={readOnly}
          isFocused={focusNumberField}
          max={1}
          min={-1}
          onChange={value => onChange(connection.id, validateValue(value))}
          step={0.01}
          value={connection.value}
        />
        <Icon icon={isPositive ? 'upArrowPositive' : 'downArrowNegative'} />
      </div>
      {!readOnly ? (
        <div className="ConnectionField-delete">
          <Button alt="Delete connection" icon="trash" onClick={onDelete} />
        </div>
      ) : null}
    </div>
  );
};

const ConnectionEditor = ({
  connections,
  direction,
  node,
  nodes,
  onAdd,
  onChange,
  onDelete,
  readOnly,
}) => {
  const [focusConnection, setFocusConnection] = useState(null);

  const unconnectedNodes = Object.values(nodes)
    .filter(
      node => connections.map(connection => connection.id).indexOf(node.id) < 0
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(node => node.id);

  const addConnection = () => {
    if (unconnectedNodes[0]) {
      setFocusConnection(unconnectedNodes[0]);
      onAdd(unconnectedNodes[0], 0);
    }
  };

  const sortedConnections = connections
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="ConnectionEditor">
      <div className="ConnectionEditor-head">
        <div className="text-interface-subtext">
          Nodes that {direction === 'to' ? 'influence' : 'are influenced by'}{' '}
          {node.name} abundance
        </div>
        {text.nodeSettingsToolTip[
          direction === 'to' ? 'influences' : 'influencedBy'
        ] ? (
          <Tooltip>
            {
              text.nodeSettingsToolTip[
                direction === 'to' ? 'influences' : 'influencedBy'
              ]
            }
          </Tooltip>
        ) : null}
      </div>
      <div className="ConnectionEditor-body">
        <InputField>
          {sortedConnections.map(connection => (
            <ConnectionField
              key={connection.id}
              direction={direction}
              connection={connection}
              isFocused={connection.id === focusConnection}
              node={node}
              nodes={Object.values(nodes)}
              onChange={(nodeId, value) => {
                setFocusConnection(null);
                const oldConnection = {
                  nodeFrom: direction === 'from' ? node.id : connection.id,
                  nodeTo: direction === 'to' ? node.id : connection.id,
                  value: connection.value,
                };
                const newConnection = {
                  nodeFrom: direction === 'from' ? node.id : nodeId,
                  nodeTo: direction === 'to' ? node.id : nodeId,
                  value,
                };
                onChange(oldConnection, newConnection);
              }}
              onDelete={() => onDelete(connection.id)}
              readOnly={readOnly}
            />
          ))}
        </InputField>
      </div>
      {!readOnly ? (
        <div className="ConnectionEditor-button-container">
          <ButtonNew
            buttonClasses="secondary-button-medium"
            label="Add new connection"
            onClick={addConnection}
            iconLeft="plus"
          />
        </div>
      ) : null}
    </div>
  );
};

export default ConnectionEditor;
