import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';

import { addKey } from 'actions/anonymousEditingKeys';
import * as urls from 'constants/urls';
import { getExperiment, shouldFetchMatrix } from 'constants/matrices';
import NodesView from 'components/NodesView';
import NodeSettingsView from 'components/nodes/NodeSettingsView';
import { fetchMatrix, addSessionMatrix } from '../actions/matrices';
import './MatrixChartContainer.scss';

const MatrixChartContainer = ({ matrix, viewMode, setViewMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { url, path } = useRouteMatch();
  const { experimentId, anonymousEditingKey } = useParams();
  const matrices = useSelector(state => state.matrices.matrices);
  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);
  const experiment = getExperiment(matrices, matrix, experimentId);
  const initialSessionMatrices = useSelector(
    state => state.matrices.sessionMatrices
  );

  const initialSessionMatrix = useMemo(() => {
    const id = experimentId ? experiment?.id : matrix?.id;
    return initialSessionMatrices?.[id];
  }, [matrix, experimentId, experiment, initialSessionMatrices]);

  useEffect(() => {
    if (anonymousEditingKey && experiment) {
      dispatch(addKey(experiment.id, anonymousEditingKey));
      history.push(urls.experiment(experiment));
    }
  }, [anonymousEditingKey, dispatch, experiment, history]);

  const cancelUrl = useMemo(() => {
    if (experiment) return urls.experiment(experiment);
    if (!experimentId) return urls.matrix(matrix);
    return null;
  }, [experimentId, matrix, experiment]);

  const saveUrl = useMemo(() => {
    if (experiment) return urls.experiment(experiment);
    if (!experimentId) return urls.matrix(matrix);
    return null;
  }, [experimentId, matrix, experiment]);

  const nodes = useMemo(() => {
    if (experimentId) {
      return experiment?.nodes;
    } else {
      return matrix?.nodes;
    }
  }, [experimentId, matrix, experiment]);

  // If this is an experiment and the base matrix
  // needs to be fetched, fetch it
  useEffect(() => {
    if (
      experimentId &&
      shouldFetchMatrix(
        experimentId,
        experiment,
        matricesLoaded,
        matricesLoading
      )
    ) {
      dispatch(fetchMatrix(experimentId));
    }
  }, [dispatch, experiment, experimentId, matricesLoaded, matricesLoading]);

  const sessionMatrix = useMemo(() => {
    return experimentId ? experiment : matrix;
  }, [experimentId, experiment, matrix]);

  // Run on mount matrix, do not update matrix again within session
  useEffect(() => {
    const sessionMatrixId = sessionMatrix?.id;
    if (sessionMatrixId && !initialSessionMatrix) {
      dispatch(addSessionMatrix(sessionMatrix));
    }
  }, [dispatch, sessionMatrix, initialSessionMatrix]);

  useEffect(() => {
    if (url.includes('overrides') && viewMode !== 'edit') {
      setViewMode('edit');
    }
  }, [setViewMode, url, viewMode]);

  useEffect(() => {
    if (matrix?.isViewOnly && viewMode === 'edit') {
      setViewMode('play');
    }
  }, [setViewMode, matrix, viewMode]);

  return nodes ? (
    <>
      <div className="MatrixChartContainer">
        <NodesView
          matrix={experimentId ? experiment : matrix}
          viewMode={viewMode}
        />
      </div>
      <Route path={`${path}/:node`}>
        <NodeSettingsView
          matrix={experimentId ? experiment : matrix}
          saveUrl={saveUrl}
          cancelUrl={cancelUrl}
        />
      </Route>
    </>
  ) : null;
};

export default MatrixChartContainer;
