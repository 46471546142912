export const matrix = matrix => `/matrices/${matrix.id}`;
export const experiment = experiment =>
  `/matrices/${experiment.parentId}/experiments/${experiment.id}`;
export const experimentAnonymousEdit = experiment =>
  `/matrices/${experiment.parentId}/experiments/${experiment.id}/key/${experiment.key}`;

//Tour URLS
export const experimentNodeById = ([matrix, experimentId, node]) =>
  `/matrices/${matrix.id}/experiments/${experimentId}/overrides/${node.id}`;
export const experimentView = (experimentInstance, view) =>
  experiment(experimentInstance) + '/' + view;

export const noAccess = () => '/no-access';
export const gallery = () => '/gallery';
export const folder = folder => `/gallery/folders/${folder.id}`;
export const splash = () => '/';
