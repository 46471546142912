import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './BuildMatrixModal.scss';
import { getConnectionsFrom, getConnectionsTo } from 'constants/connections';
import ConnectionEditor from 'components/inputs/ConnectionEditor';
import {
  addConnection,
  deleteConnection,
  updateConnection,
} from 'actions/nodeSettings';
import '../nodes/NodeSettingsSectionConnections.scss';
import text from 'constants/text';

const BuildMatrixEditNodesConnections = () => {
  const dispatch = useDispatch();
  const editMatrix = useSelector(state => state.nodeSettings.matrix);
  const editNodeId = useSelector(state => state.nodeSettings.nodeId);

  const node = useMemo(() => {
    return editMatrix?.nodes?.[editNodeId];
  }, [editMatrix, editNodeId]);

  if (!node) return null;

  return (
    <>
      <div className="BuildMatrixEditNodesConnections BuildMatrixEditNodesContent">
        <div className="column">
          <div className="header text-interface-large">Assign Connections</div>
          <div className="description text-interface-medium">
            {text.buildAMatrixGuide.editNodes.connections}
          </div>
        </div>
        <div className="column NodeSettingsSectionConnections">
          <ConnectionEditor
            connections={getConnectionsTo(editMatrix, node.id)}
            direction="to"
            node={node}
            nodes={editMatrix.nodes}
            onAdd={(nodeFrom, value) =>
              dispatch(addConnection(nodeFrom, node.id, value))
            }
            onChange={(oldConnection, newConnection) =>
              dispatch(updateConnection(oldConnection, newConnection))
            }
            onDelete={nodeFrom => dispatch(deleteConnection(nodeFrom, node.id))}
          />
          <ConnectionEditor
            connections={getConnectionsFrom(editMatrix, node.id)}
            direction="from"
            node={node}
            nodes={editMatrix.nodes}
            onAdd={(nodeTo, value) =>
              dispatch(addConnection(node.id, nodeTo, value))
            }
            onChange={(oldConnection, newConnection) =>
              dispatch(updateConnection(oldConnection, newConnection))
            }
            onDelete={nodeTo => dispatch(deleteConnection(node.id, nodeTo))}
          />
        </div>
      </div>
    </>
  );
};

export default BuildMatrixEditNodesConnections;
