import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from './Icon';
import './Tooltip.scss';

const Tooltip = ({ children, text, left }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={classNames('Tooltip', { active: isActive })}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <Icon icon="questionMark" />
      {isActive ? (
        <div className={classNames('Tooltip-text', left ? 'left' : null)}>
          {children || text}
        </div>
      ) : null}
    </div>
  );
};

export default Tooltip;
