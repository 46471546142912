import {
  MATRIX_SET_NODE_HOVER,
  MATRIX_TOGGLE_MODIFICATIONS_SIDEBAR,
  GALLERY_SET_TAB,
  GALLERY_SET_SORTBY,
  NODE_SET_FIXED_WARNING,
} from 'constants/action_names';

export const setNodeHover = hoverNode => ({
  type: MATRIX_SET_NODE_HOVER,
  hoverNode,
});

export const toggleModificationsSidebar = () => ({
  type: MATRIX_TOGGLE_MODIFICATIONS_SIDEBAR,
});

export const setGalleryTab = tab => ({
  type: GALLERY_SET_TAB,
  tab,
});

export const setGallerySortBy = ({ visibility, sortBy }) => ({
  type: GALLERY_SET_SORTBY,
  visibility,
  sortBy,
});

export const setNodeFixedWarning = ({ userDeclined }) => {
  return {
    type: NODE_SET_FIXED_WARNING,
    userDeclined,
  };
};
