import * as d3 from 'd3';
import * as math from 'mathjs';

export const HIGH = 'HIGH';
export const MOD = 'MOD';
export const LOW = 'LOW';
export const sortedMembership = [LOW, MOD, HIGH];
export const augmentedMembership = [
  'VERY LOW',
  ...sortedMembership,
  'VERY HIGH',
];

export const fuzzify = (membershipFunctions, values, options = {}) => {
  if (options.rescale) {
    membershipFunctions = rescale(membershipFunctions, options.mean);
  }

  return values.map(value => {
    let membership = sortedMembership.reduce((acc, m) => {
      const fuzzyValue = interpolate(membershipFunctions[m])(value);
      return { ...acc, [m]: fuzzyValue };
    }, {});

    return membership;
  });
};

export const defuzzify = (membershipFunctions, memberships, options = {}) => {
  const nInt = options.nInt || 100;
  let max, dx, min;

  const values = Object.values(membershipFunctions).reduce(
    (acc, cur) => [...acc, ...cur.map(v => v[0])],
    []
  );
  max = d3.max(values);
  min = d3.min(values);
  dx = max / nInt;

  let defuzzied = memberships.map(membership => {
    let numerator = 0;
    let denominator = 0;
    for (let x = 0; x <= max; x += dx) {
      let v = d3.max(
        sortedMembership.map(m =>
          Math.min(membership[m], interpolate(membershipFunctions[m])(x))
        )
      );

      numerator += v * x;
      denominator += v;
    }

    return numerator / denominator;
  });

  if (options.rescale) {
    defuzzied = defuzzied.map(d =>
      d < options.mean
        ? (0.5 * (d - min)) / (options.mean - min)
        : 0.5 + (0.5 * (d - options.mean)) / (max - options.mean)
    );
  }

  return defuzzied;
};

export const interpolate = xy => {
  return v => {
    let out = 0;
    if (v <= xy[0][0]) {
      out = xy[0][1];
    } else if (v >= xy[xy.length - 1][0]) {
      out = xy[xy.length - 1][1];
    } else {
      for (let i = 0; i < xy.length - 1; i++) {
        if (v >= xy[i][0] && v < xy[i + 1][0]) {
          let gamma = (v - xy[i][0]) / (xy[i + 1][0] - xy[i][0]);
          out = gamma * xy[i + 1][1] + (1 - gamma) * xy[i][1];
        }
      }
    }

    return out;
  };
};

export const minmax = membershipFunctions => {
  const values = Object.values(membershipFunctions).reduce(
    (acc, cur) => [...acc, ...cur.map(v => v[0])],
    []
  );
  const min = d3.min(values);
  const max = d3.max(values);
  return { min, max };
};

export const rescale = (membershipFunctions, mean) => {
  let scaledFunctions = JSON.parse(JSON.stringify(membershipFunctions));
  const values = Object.values(scaledFunctions).reduce(
    (acc, cur) => [...acc, ...cur.map(v => v[0])],
    []
  );
  const min = d3.min(values);
  const max = d3.max(values);

  sortedMembership.forEach(m => {
    scaledFunctions[m] = scaledFunctions[m].map(v =>
      v[0] < mean
        ? [(0.5 * (v[0] - min)) / (mean - min), v[1]]
        : [0.5 + (0.5 * (v[0] - mean)) / (max - mean), v[1]]
    );
  });

  return scaledFunctions;
};

export const descale = (membershipFunctions, mean, min, max) => {
  let scaledFunctions = JSON.parse(JSON.stringify(membershipFunctions));

  sortedMembership.forEach(m => {
    scaledFunctions[m] = scaledFunctions[m].map(v =>
      v[0] < 0.5
        ? [v[0] * 2 * mean + (1 - v[0] * 2) * min, v[1]]
        : [(v[0] - 0.5) * 2 * max + (1 - (v[0] - 0.5) * 2) * mean, v[1]]
    );
  });

  return scaledFunctions;
};

export const fcm = (initialVector, matrix, fixedBoundary, options = {}) => {
  const maxIter = options.maxIter || 500;
  const lambda = options.lambda || 0.5;
  const tolerance = options.tolerance || 0.001;
  const curveSteepness = options.curveSteepness || 1;
  let activationFunctions = Array(initialVector.length).fill(
    logisticActivation(curveSteepness)
  );

  if (options.activationFunctions) {
    activationFunctions = options.activationFunctions.map(i => {
      switch (i) {
        case 'logistic':
          return logisticActivation(curveSteepness);
        case 'exponential':
          return exponentialActivation(curveSteepness);
        default:
          return logisticActivation(curveSteepness);
      }
    });
  }

  const mat = math.matrix(matrix);
  let diff = 1;

  let states = [];
  let state = initialVector.slice();
  states.push(state);

  for (let i = 0; i < maxIter && diff > tolerance; i++) {
    // apply matrix
    let stateVector = math.matrix(state);
    let tmp = math.multiply(stateVector, math.transpose(mat))._data;

    // apply activation function
    tmp = tmp
      .map((x, i) => activationFunctions[i](x))
      // eslint-disable-next-line
      .map((x, i) => lambda * x + (1 - lambda) * state[i]);

    // reset boundary conditions
    // eslint-disable-next-line
    fixedBoundary.forEach((f, i) => {
      if (f) {
        tmp[i] = state[i];
      }
    });

    // check tolerance
    diff = tmp.reduce(
      // eslint-disable-next-line
      (acc, cur, i) => Math.max(acc, Math.abs(cur - state[i])),
      0
    );

    state = tmp;
    states.push(state);
  }

  return state;
};

const logisticActivation = c => x => 1 / (1 + Math.exp(-c * x));

const exponentialActivation = c => x => Math.max(0, 1 - Math.exp(-c * x));
