import moment from 'moment';
import {
  MATRIX_ADD_NODE,
  MATRIX_CONVERT_EXPERIMENT_TO_MATRIX,
  MATRIX_CREATE,
  MATRIX_CREATE_EXPERIMENT,
  MATRIX_DELETE_NODE,
  MATRIX_REMOVE_REQUEST,
  MATRIX_REMOVE_RESPONSE,
  MATRIX_REMOVE_ERROR_RESPONSE,
  MATRIX_SELECT,
  MATRIX_RESET,
  MATRIX_SAVE_REQUEST,
  MATRIX_SAVE_RESPONSE,
  MATRIX_SAVE_ERROR_RESPONSE,
  MATRIX_SETTINGS_FINISH_EDIT,
  MATRIX_REQUEST,
  MATRIX_RESPONSE,
  MATRIX_ERROR_RESPONSE,
  MATRIX_ERRORS_CLEAR,
  NODE_SETTINGS_FINISH_EDIT,
  MATRIX_ADD_FAVORITE,
  MATRIX_REMOVE_FAVORITE,
  CONNECTIONS_IMPORT_ERROR_RESPONSE,
  MATRIX_ADD_SESSION_STATE,
} from 'constants/action_names';
import { deleteNode } from 'constants/matrices';
import { deleteConnectionsTo } from 'constants/connections';
import { createNode } from 'constants/nodes';
import { fixUrl } from 'transforms/matrices';
import text from 'constants/text';

const initialState = {
  matrices: [],
  _loading: {}, // Matrices (or types of matrices) we are loading
  _loaded: {}, // Matrices (or types of matrices) we have tried to load
  removing: false,
  saving: false,
  selected: null,
  resetToInitial: false,
  errors: [],
  sessionMatrices: {}, // This tracks initial state of a given matrix in a single session, does not persist
};

const transformMatrix = matrix => ({
  ...matrix,
  modified: moment(matrix.modified),
  nodes:
    matrix.nodes &&
    Object.fromEntries(
      Object.entries(matrix.nodes).map(([id, node]) => [
        id,
        {
          ...node,
          imageUrl: fixUrl(node.imageUrl),
        },
      ])
    ),
  imageUrl: fixUrl(matrix.imageUrl),
});

const deduplicateMatrices = matrices => {
  const hash = Object.fromEntries(matrices.map(matrix => [matrix.id, matrix]));
  return Object.values(hash);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MATRIX_ADD_NODE:
      return {
        ...state,
        matrices: state.matrices.map(matrix => {
          if (matrix.id === action.matrixId) {
            const newNode = action.node || createNode();
            return {
              ...matrix,
              nodes: {
                ...matrix.nodes,
                [newNode.id]: newNode,
              },
            };
          }
          return matrix;
        }),
      };
    case MATRIX_CONVERT_EXPERIMENT_TO_MATRIX: {
      return {
        ...state,
        matrices: [...state.matrices, action.newMatrix],
      };
    }
    case MATRIX_CREATE:
      return {
        ...state,
        matrices: [...state.matrices, action.newMatrix],
      };
    case MATRIX_CREATE_EXPERIMENT:
      return {
        ...state,
        matrices: [...state.matrices, action.newExperiment],
      };
    case MATRIX_DELETE_NODE:
      return {
        ...state,
        matrices: state.matrices.map(matrix => {
          if (matrix.id === action.matrixId) {
            const withoutNode = deleteNode(matrix, action.nodeId);
            return deleteConnectionsTo(withoutNode, action.nodeId);
          }
          return matrix;
        }),
      };
    case MATRIX_SELECT:
      return {
        ...state,
        selected: action.id,
      };
    case MATRIX_RESET:
      return {
        ...state,
        resetToInitial: action.reset,
      };
    case MATRIX_ADD_FAVORITE:
      return {
        ...state,
        matrices: state.matrices.map(matrix => {
          if (matrix.id === action.matrixId) {
            const favorites = matrix.favorites ?? [];
            return {
              ...matrix,
              favorited: favorites.concat([action.userId]),
            };
          }
          return matrix;
        }),
      };

    case MATRIX_REMOVE_FAVORITE:
      return {
        ...state,
        matrices: state.matrices.map(matrix => {
          if (matrix.id === action.matrixId) {
            const favorites = matrix.favorites ?? [];
            return {
              ...matrix,
              favorited: favorites.filter(id => id !== action.userId),
            };
          }
          return matrix;
        }),
      };
    case MATRIX_REQUEST:
      return {
        ...state,
        errors: [],
        _loading: {
          ...state._loading,
          [action.matrixIdentifier]: true,
        },
        _loaded: {
          ...state._loaded,
          [action.matrixIdentifier]: false,
        },
      };
    case MATRIX_RESPONSE:
      return {
        ...state,
        matrices: deduplicateMatrices([
          ...state.matrices,
          ...action.json.map(transformMatrix),
        ]),
        errors: [],
        _loading: {
          ...state._loading,
          [action.matrixIdentifier]: false,
        },
        _loaded: {
          ...state._loaded,
          [action.matrixIdentifier]: true,
        },
      };
    case MATRIX_ERROR_RESPONSE:
      return {
        ...state,
        errors: [
          {
            title: text.errors.matrixFetch.title,
            details: text.errors.matrixFetch.details,
            type: 'fetch',
          },
        ],
        _loading: {
          ...state._loading,
          [action.matrixIdentifier]: false,
        },
        _loaded: {
          ...state._loaded,
          [action.matrixIdentifier]: true,
        },
      };
    case MATRIX_ERRORS_CLEAR:
      return {
        ...state,
        errors: [],
      };
    case MATRIX_REMOVE_REQUEST:
      return {
        ...state,
        removing: true,
        errors: [],
      };
    case MATRIX_REMOVE_RESPONSE:
      return {
        ...state,
        errors: [],
        matrices: state.matrices.filter(
          matrix => matrix.id !== action.matrixId
        ),
        removing: false,
      };
    case MATRIX_REMOVE_ERROR_RESPONSE:
      return {
        ...state,
        removing: false,
        errors: [
          {
            title: text.errors.matrixRemove.title,
            details: text.errors.matrixRemove.details,
            type: 'remove',
          },
        ],
      };
    case MATRIX_SAVE_REQUEST:
      return {
        ...state,
        errors: [],
        saving: true,
      };
    case MATRIX_SAVE_RESPONSE:
      return {
        ...state,
        errors: [],
        saving: false,
      };
    case MATRIX_SAVE_ERROR_RESPONSE:
      return {
        ...state,
        saving: false,
        errors: [
          {
            title: text.errors.matrixSave.title,
            details: text.errors.matrixSave.details,
            type: 'save',
          },
        ],
      };
    case CONNECTIONS_IMPORT_ERROR_RESPONSE:
      return {
        ...state,
        saving: false,
        errors: [action.error],
      };
    case MATRIX_SETTINGS_FINISH_EDIT:
      return {
        ...state,
        matrices: state.matrices.map(matrix => {
          if (matrix.id === action.matrix.id) {
            return {
              ...action.matrix,
              modified: action.modifiedDate,
              basicStats: action.stats,
            };
          }
          return matrix;
        }),
      };
    case NODE_SETTINGS_FINISH_EDIT:
      return {
        ...state,
        matrices: state.matrices.map(matrix => {
          if (matrix.id === action.matrix.id) {
            return {
              ...action.matrix,
            };
          }
          return matrix;
        }),
      };
    case MATRIX_ADD_SESSION_STATE: {
      return {
        ...state,
        sessionMatrices: {
          [action.matrix?.id]: action.matrix,
          ...state.sessionMatrices,
        },
      };
    }
    default:
      return state;
  }
};
