import { LOGIN_PROMPT_CLOSE, LOGIN_PROMPT_OPEN } from 'constants/action_names';

const INITIAL_STATE = {
  destinationUrl: null,
  message: null,
  open: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_PROMPT_OPEN:
      return {
        ...state,
        destinationUrl: action.destinationUrl,
        message: action.message,
        open: true,
      };
    case LOGIN_PROMPT_CLOSE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
