import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import * as urls from 'constants/urls';
import { startEdit } from 'actions/matrixSettings';
import StickySideModal from 'components/StickySideModal';
import {
  getExperiment,
  getMatrix,
  shouldFetchMatrix,
} from 'constants/matrices';
import { fetchMatrix } from 'actions/matrices';
import UncertaintyAnalysis from 'components/uncertainty/UncertaintyAnalysis';
import './UncertaintyModal.scss';

const UncertaintyModal = ({ matrix }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { matrixId, experimentId } = useParams();
  const matrices = useSelector(state => state.matrices.matrices);

  if (!matrix) {
    matrix = getMatrix(matrices, matrixId);
  }

  const experiment = getExperiment(matrices, matrix, experimentId);

  const closeUrl = useMemo(() => {
    if (experimentId && experiment) return urls.experiment(experiment);
    return urls.matrix(matrix);
  }, [matrix, experimentId, experiment]);

  useEffect(() => {
    if (experimentId && experiment) {
      dispatch(startEdit(experiment));
    } else if (matrix && !experimentId) {
      dispatch(startEdit(matrix));
    }
  }, [dispatch, matrix]);

  useEffect(() => {
    if (shouldFetchMatrix(matrix?.id, matrix)) {
      dispatch(fetchMatrix(matrix.id));
    }
  }, [dispatch, matrix]);

  return (
    <StickySideModal onExit={() => history.push(closeUrl)}>
      <div className="UncertaintyModal">
        <UncertaintyAnalysis
          matrix={experimentId && experiment ? experiment : matrix}
          closeUrl={closeUrl}
        />
      </div>
    </StickySideModal>
  );
};

export default UncertaintyModal;
