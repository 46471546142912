import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import './BuildMatrixModal.scss';
import { startEdit, finishEdit } from 'actions/matrixSettings';
import BuildMatrixAddNodes from './BuildMatrixAddNodes';
import BuildMatrixEditNodes from './BuildMatrixEditNodes';
import BuildMatrixReview from './BuildMatrixReview';
import { saveMatrix } from 'actions/matrices';

const buildSteps = ['addNodes', 'editNodes', 'review', 'reviewEdit'];

const BuildMatrixTab = ({ number, label, selected }) => {
  return (
    <div
      className={classNames('BuildMatrixCreateMatrix-tab', {
        invert: selected,
      })}
    >
      <div className="number-label">{number}</div>
      {label}
    </div>
  );
};

const BuildMatrixCreateMatrix = ({ matrix, finishStep, goBackStep }) => {
  const dispatch = useDispatch();

  // onMount
  useEffect(() => {
    dispatch(startEdit(matrix));
  }, []);

  const [buildStep, setBuildStep] = useState('addNodes');

  const [editNodeIndex, setEditNodeIndex] = useState(0);

  // If last node is deleted, change the index to the last available node
  useEffect(() => {
    if (editNodeIndex === 0) return;
    const nodesLen = Object.keys(matrix?.nodes ?? {}).length;
    if (nodesLen - 1 < editNodeIndex) {
      setEditNodeIndex(nodesLen - 1);
    }
  }, [editNodeIndex, matrix]);

  const returnComponentForStep = step => {
    switch (step) {
      case 'addNodes': {
        return (
          <BuildMatrixAddNodes
            matrix={matrix}
            toNext={() => {
              setBuildStep('editNodes');
              dispatch(finishEdit(matrix));
              dispatch(saveMatrix(matrix.id));
            }}
            toPrev={goBackStep}
          />
        );
      }
      case 'editNodes': {
        return (
          <BuildMatrixEditNodes
            matrix={matrix}
            toNext={() => setBuildStep('review')}
            toPrev={() => setBuildStep('addNodes')}
            setEditNodeIndex={setEditNodeIndex}
            editNodeIndex={editNodeIndex}
          />
        );
      }
      case 'review': {
        return (
          <BuildMatrixReview
            matrix={matrix}
            toNext={finishStep}
            toPrev={() => setBuildStep('editNodes')}
            setEditNodeIndex={setEditNodeIndex}
            editNodeIndex={editNodeIndex}
            toEdit={() => setBuildStep('reviewEdit')}
          />
        );
      }
      case 'reviewEdit': {
        return (
          <BuildMatrixEditNodes
            matrix={matrix}
            toNext={() => setBuildStep('review')}
            toPrev={null}
            setEditNodeIndex={() => setBuildStep('review')}
            editNodeIndex={editNodeIndex}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className="BuildMatrixCreateMatrix">
      <div className="BuildMatrixCreateMatrix-tabs">
        <BuildMatrixTab
          number={1}
          label="Add Nodes"
          selected={buildStep === 'addNodes'}
        />
        <BuildMatrixTab
          number={2}
          label={
            buildStep !== 'editNodes' || !matrix?.nodes
              ? `Edit Nodes`
              : `Edit Nodes (${editNodeIndex + 1} of ${
                  Object.keys(matrix?.nodes).length
                })`
          }
          selected={buildStep === 'editNodes'}
        />
        <BuildMatrixTab
          number={3}
          label="Review"
          selected={buildStep === 'review' || buildStep === 'reviewEdit'}
        />
      </div>
      <div className="BuildMatrixCreateMatrix-body">
        {returnComponentForStep(buildStep)}
      </div>
    </div>
  );
};

export default BuildMatrixCreateMatrix;
