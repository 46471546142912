import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  addFavoriteFolder,
  removeFavoriteFolder,
  saveFolder,
} from 'actions/folders';
import FolderDeletePopoverWrapper from '../folders/FolderDeletePopoverWrapper';
import Popover from '../Popover';
import * as urls from 'constants/urls';
import icons from 'img/icons';
import './FolderListItem.scss';
import ButtonNew from 'components/inputs/ButtonNew';
import FolderEditPopover from 'components/folders/FolderEditPopover';
import Icon from 'components/Icon';

const FolderListItem = ({
  folder,
  deletePopoverOpen,
  setDeletePopoverOpen,
  setQuickSettingsPopoverOpen,
  quickSettingsPopoverOpen,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const deleteRef = useRef(null);
  const quickSettingsRef = useRef(null);

  const goToFolder = () => history.push(urls.folder(folder));

  let { url } = useRouteMatch();

  const { user } = useSelector(state => state.auth);

  const [isFavoriteHovered, setFavoriteHovered] = useState(false);

  const [isListItemHovered, setListItemHovered] = useState(false);

  const clickItem = () => {
    if (isFavoriteHovered) return;
    goToFolder();
  };

  const favoriteIsAllowed = useMemo(() => {
    const isPrivate = !folder.isPublic;
    const userId = user?.claims?.user_id;
    const isOwner = folder?.roles[userId] === 'owner';
    return isPrivate && isOwner;
  }, [folder, user]);

  const favorited = useMemo(
    () =>
      folder?.favorited
        ? folder.favorited.includes(user.claims.user_id)
        : false,
    [folder, user]
  );

  const onClickFavorite = () => {
    if (!favoriteIsAllowed) return;
    if (favorited) {
      dispatch(removeFavoriteFolder(folder.id, user.claims.user_id));
    } else {
      dispatch(addFavoriteFolder(folder.id, user.claims.user_id));
    }
    dispatch(saveFolder(folder.id));
  };

  return (
    <>
      <li
        id={folder.id}
        className={classNames('FolderListItem')}
        draggable={false}
        onMouseEnter={() => setListItemHovered(true)}
        onMouseLeave={() => setListItemHovered(false)}
      >
        <div className="FolderListItem-main" onClick={clickItem}>
          <div className="FolderListItem-image">
            <img draggable="false" src={icons.folder} alt="" />
          </div>

          <div className="FolderListItem-background">
            {favoriteIsAllowed && (isListItemHovered || favorited) ? (
              <div
                className="MatrixListItem-favorite-icon"
                onClick={onClickFavorite}
                onMouseEnter={() => setFavoriteHovered(true)}
                onMouseLeave={() => setFavoriteHovered(false)}
              >
                <Icon
                  className="icon-green"
                  icon={favorited ? 'filledStar' : 'emptyStar'}
                  alt="favorite"
                />
              </div>
            ) : (
              <div className="MatrixListItem-favorite-icon hidden">
                <Icon
                  className="icon-green"
                  icon={'emptyStar'}
                  alt="favorite"
                />
              </div>
            )}
            <div className="FolderListItem-matrixDetails">
              <div className="FolderListItem-name">
                <div className="FolderListItem-matrixName">{folder.name}</div>
              </div>
              <div className="FolderListItem-text">
                <div className="FolderListItem-meta">
                  <div className="icon-container">
                    <div ref={quickSettingsRef}>
                      <ButtonNew
                        icon="gear"
                        onClick={() => setQuickSettingsPopoverOpen(folder.id)}
                        buttonClasses="icon-button"
                      />
                    </div>
                    <div ref={deleteRef}>
                      <ButtonNew
                        icon="trash"
                        onClick={() => setDeletePopoverOpen(folder.id)}
                        buttonClasses="icon-button"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <Popover
        title={`Delete folder`}
        content={
          <FolderDeletePopoverWrapper
            completionUrl={url}
            closePopover={() => setDeletePopoverOpen(folder.id)}
            folderId={folder.id}
          />
        }
        isOpen={deletePopoverOpen}
        parentRef={deleteRef.current}
      />

      <Popover
        title={`Rename folder`}
        content={
          <FolderEditPopover
            folder={folder}
            onClose={() => setQuickSettingsPopoverOpen(null)}
          />
        }
        isOpen={quickSettingsPopoverOpen}
        parentRef={quickSettingsRef.current}
      />
    </>
  );
};

export default FolderListItem;
