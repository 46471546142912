import * as UsersConnection from 'connections/users';

export const get = async (id, user, userData) => {
  return await UsersConnection.get(id, user, userData);
};

export const save = async (
  id,
  userData,
  requestingUser,
  requestingUserData
) => {
  return await UsersConnection.save(
    id,
    userData,
    requestingUser,
    requestingUserData
  );
};
