import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import './BuildMatrixModal.scss';
import { addNode } from 'actions/matrices';
import { createNode } from 'constants/nodes';
import TextField from 'components/inputs/TextField';
import ButtonNew from 'components/inputs/ButtonNew';
import text from 'constants/text';
import BuildMatrixFooterButtons from './BuildMatrixFooterButtons';

const BuildMatrixAddNodes = ({ matrix, toNext, toPrev }) => {
  const dispatch = useDispatch();

  const nodes = useMemo(() => {
    let matrixNodes = matrix?.nodes ?? {};
    return Object.values(matrixNodes);
  }, [matrix]);

  const [textField, setTextField] = useState('');

  const submitNode = () => {
    const matrixId = matrix?.id;
    if (!matrixId || !textField) return;
    const node = createNode();
    node.name = textField;
    dispatch(addNode(matrixId, node));
    setTextField('');
  };

  return (
    <>
      <div className="BuildMatrixAddNodes">
        <div className="BuildMatrixAddNodes-column">
          <div className="content">
            <div className="header text-interface-large">Nodes</div>
            <div className="description text-interface-medium">
              {text.buildAMatrixGuide.addNodes}
            </div>
            <div className="text-container">
              <TextField
                className="text-input"
                onChange={setTextField}
                value={textField}
              />

              <ButtonNew
                buttonClasses="icon-button"
                icon="rightArrow"
                onClick={submitNode}
              />
            </div>
          </div>
        </div>
        <div className="BuildMatrixAddNodes-column">
          <div className="node-container">
            {nodes.map(node => (
              <div className="node text-interface-medium" key={node.id}>
                {node.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <BuildMatrixFooterButtons
        disableNext={!nodes.length}
        disableSkip={!nodes.length}
        skip={toNext}
        goNext={toNext}
        goBack={toPrev}
      />
    </>
  );
};

export default BuildMatrixAddNodes;
