import {
  MAIL_CREATE,
  MAIL_CLEAR,
  MAIL_SAVE_REQUEST,
  MAIL_SAVE_RESPONSE,
  MAIL_SAVE_ERROR_RESPONSE,
  MAIL_RESET_SENT_FLAG,
} from 'constants/action_names';
import * as MailService from 'services/mail';

export const createMail = newMail => ({
  type: MAIL_CREATE,
  newMail,
});

export const clearMail = () => ({
  type: MAIL_CLEAR,
});

export const resetMailSentFlag = () => ({
  type: MAIL_RESET_SENT_FLAG,
});

export const saveMail = () => {
  return (dispatch, getState) => {
    const state = getState();
    const mail = state.mail.mail;

    if (!mail) return;

    dispatch({ type: MAIL_SAVE_REQUEST });

    return MailService.save(mail)
      .then(() => {
        dispatch({
          type: MAIL_SAVE_RESPONSE,
        });
      })
      .catch(error => {
        console.warn(error);
        dispatch({
          type: MAIL_SAVE_ERROR_RESPONSE,
          error,
        });
      });
  };
};
