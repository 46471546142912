function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// export default tourSteps;
const tourSteps = (
  text,
  dispatch,
  openLoginPrompt,
  closeLoginPrompt,
  modificationsOpen,
  toggleModificationsSidebar,
  galleryTab,
  setGalleryTab
) => {
  return [
    // Getting started
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-0', //works reliably, but not flexible.
      header: text.interactiveTour.gettingStarted[0].title,
      content: text.interactiveTour.gettingStarted[0].content,
      moveTo: 'splash',
      //This is needed one step early, not entirely sure why
      action: () => {
        if (galleryTab.name !== 'public') {
          dispatch(setGalleryTab({ name: 'public', userInitiated: true }));
        }
      },
    },
    // Public gallery
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-1',
      header: text.interactiveTour.gettingStarted[1].title,
      content: text.interactiveTour.gettingStarted[1].content,
      moveTo: 'gallery',
      action: () => {
        if (galleryTab.name !== 'public') {
          dispatch(setGalleryTab({ name: 'public', userInitiated: true }));
        }
      },
      matrixFinder: publicMatrices =>
        publicMatrices.find(
          ({ name }) => name.toLowerCase() === 'MPG Grasslands'.toLowerCase()
        ),
    },
    // Create experiment
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-0', //works reliably, but not flexible.
      header: text.interactiveTour.creatingExperiment[0].title,
      content: text.interactiveTour.creatingExperiment[0].content,
      moveTo: 'matrix',
      actionAfter: () => {
        const createExperimentButton = document.querySelector(
          '.interactiveTourCreatingExperiment-0'
        );
        if (createExperimentButton) {
          createExperimentButton.click();
        }

        sleep(300).then(() => {
          const createButton = document.querySelector(
            '.interactiveTourCreatingExperiment-0-1'
          );
          if (createButton) {
            createButton.click();
          }
        });
      },
    },
    // Matrix settings
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-1',
      header: text.interactiveTour.creatingExperiment[1].title,
      content: text.interactiveTour.creatingExperiment[1].content,
      action: () => {
        const settingsButton = document.querySelector(
          '.interactiveTourCreatingExperiment-1-1'
        );
        if (
          settingsButton &&
          window.location.hash.split('/').pop() !== 'edit'
        ) {
          settingsButton.click();
        }
      },
    },
    // Matrix name
    {
      selector: '.interactiveTourCreatingExperiment-2',
      header: text.interactiveTour.creatingExperiment[2].title,
      content: text.interactiveTour.creatingExperiment[2].content,
      action: () => {
        const cancelButton = document.querySelector(
          '.interactiveTourCreatingExperiment-2-1'
        );
        if (cancelButton) {
          cancelButton.click();
        }
      },
    },
    // Edit mode
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-3',
      header: text.interactiveTour.creatingExperiment[3].title,
      content: text.interactiveTour.creatingExperiment[3].content,
      action: () => {
        const editModeButton = document.querySelector(
          '.interactiveTourCreatingExperiment-3'
        );
        if (editModeButton && !editModeButton.className.includes('selected')) {
          editModeButton.click();
        }
      },
    },
    // Node edit button
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-4',
      header: text.interactiveTour.creatingExperiment[4].title,
      content: text.interactiveTour.creatingExperiment[4].content,
      actionAfter: () => {
        const editButton = document.querySelector(
          '.interactiveTourCreatingExperiment-4'
        );

        if (editButton) {
          editButton.click();
        }
      },
    },
    // Node settings
    {
      disableInteract: true,
      selector: '.MatrixView',
      mutationObservables: ['.interactiveTourCreatingExperiment-5'],
      header: text.interactiveTour.creatingExperiment[5].title,
      content: text.interactiveTour.creatingExperiment[5].content,
      action: () => {
        const panelIsOpen = document.querySelector(
          '.interactiveTourCreatingExperiment-5'
        );
        if (!panelIsOpen) {
          const editButton = document.querySelector(
            '.interactiveTourCreatingExperiment-4'
          );

          editButton.click();
        }
      },
      actionAfter: () => {
        const okButton = document.querySelector(
          '.interactiveTourCreatingExperiment-5-1'
        );

        if (okButton) {
          okButton.click();
        }
      },
    },
    // Changes saved badge
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-6',
      header: text.interactiveTour.creatingExperiment[6].title,
      content: text.interactiveTour.creatingExperiment[6].content,
    },
    // Modifications panel
    {
      selector: '.interactiveTourCreatingExperiment-7',
      header: text.interactiveTour.creatingExperiment[7].title,
      content: text.interactiveTour.creatingExperiment[7].content,
    },
    // Add a new node
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-8',
      header: text.interactiveTour.creatingExperiment[8].title,
      content: text.interactiveTour.creatingExperiment[8].content,
    },
    // Edit connections
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-9',
      header: text.interactiveTour.creatingExperiment[9].title,
      content: text.interactiveTour.creatingExperiment[9].content,
      action: () => {
        const connectionsTable = document.querySelector(
          '.interactiveTourCreatingExperiment-10'
        );
        if (connectionsTable) {
          const connectionsButton = document.querySelector(
            '.interactiveTourCreatingExperiment-9'
          );
          connectionsButton.click();
        }
      },
    },
    // Connections table
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-10',
      header: text.interactiveTour.creatingExperiment[10].title,
      content: text.interactiveTour.creatingExperiment[10].content,
      action: () => {
        const connectionsButton = document.querySelector(
          '.interactiveTourCreatingExperiment-9'
        );
        const panel = document.querySelector(
          '.interactiveTourCreatingExperiment-10'
        );
        if (connectionsButton && !panel) {
          connectionsButton.click();
        }
      },
    },
    // Sensitivity analysis button
    {
      disableInteract: true,
      selector: `.interactiveTourCreatingExperiment-11`,
      header: text.interactiveTour.creatingExperiment[11].title,
      content: text.interactiveTour.creatingExperiment[11].content,
      action: () => {
        const connectionsButton = document.querySelector(
          '.interactiveTourCreatingExperiment-9'
        );
        const panel = document.querySelector(
          '.interactiveTourCreatingExperiment-10'
        );
        if (connectionsButton && !panel) {
          connectionsButton.click();
        }
      },
    },
    // Sensitivity analysis
    {
      disableInteract: true,
      selector: `.interactiveTourCreatingExperiment-12`,
      header: text.interactiveTour.creatingExperiment[12].title,
      content: text.interactiveTour.creatingExperiment[12].content,
      action: () => {
        const connectionsButton = document.querySelector(
          '.interactiveTourCreatingExperiment-9'
        );
        const panel = document.querySelector(
          '.interactiveTourCreatingExperiment-10'
        );
        if (connectionsButton && !panel) {
          connectionsButton.click();
          sleep(300).then(() => {
            const sensitivityButton = document.querySelector(
              '.interactiveTourCreatingExperiment-11'
            );

            if (sensitivityButton) {
              sensitivityButton.click();
            }
          });
        } else {
          const sensitivityButton = document.querySelector(
            '.interactiveTourCreatingExperiment-11'
          );
          sensitivityButton.click();
        }
      },
      actionAfter: () => {
        const cancelButton = document.querySelector(
          '.interactiveTourCreatingExperiment-12-1'
        );
        if (cancelButton) {
          cancelButton.click();
        }
      },
    },
    // Compare to base matrix
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-13',
      header: text.interactiveTour.creatingExperiment[13].title,
      content: text.interactiveTour.creatingExperiment[13].content,
      action: () => {
        const backToEditingButton = document.querySelector(
          '.interactiveTourCreatingExperiment-15-2'
        );
        if (backToEditingButton) {
          backToEditingButton.click();
        }
      },
    },
    // Abundance chart
    {
      disableInteract: true,
      selector: '.MatrixView',
      mutationObservables: ['.interactiveTourCreatingExperiment-14'],
      header: text.interactiveTour.creatingExperiment[14].title,
      content: text.interactiveTour.creatingExperiment[14].content,
      action: () => {
        const abundanceButton = document.querySelector(
          '.interactiveTourCreatingExperiment-14'
        );

        if (
          abundanceButton &&
          !abundanceButton.className.includes('pill-button-selected')
        ) {
          abundanceButton.click();
        } else {
          const button = document.querySelector(
            '.interactiveTourCreatingExperiment-13'
          );
          if (button) {
            button.click();
          }
        }
      },
    },
    // Stats page
    {
      disableInteract: true,
      selector: '.MatrixView',
      mutationObservables: ['.interactiveTourCreatingExperiment-15'],
      header: text.interactiveTour.creatingExperiment[15].title,
      content: text.interactiveTour.creatingExperiment[15].content,
      action: () => {
        const compareToMatrixButton = document.querySelector(
          '.interactiveTourCreatingExperiment-13'
        );
        if (compareToMatrixButton) {
          compareToMatrixButton.click();

          sleep(300).then(() => {
            const statsButton = document.querySelector(
              '.interactiveTourCreatingExperiment-15'
            );
            if (statsButton) {
              statsButton.click();
            }
          });
        } else {
          const statsButton = document.querySelector(
            '.interactiveTourCreatingExperiment-15'
          );
          if (statsButton) {
            statsButton.click();
          }
        }
      },
    },
    // Convert to base matrix
    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-16',
      header: text.interactiveTour.creatingExperiment[16].title,
      content: text.interactiveTour.creatingExperiment[16].content,
      action: () => {
        const button = document.querySelector(
          '.interactiveTourCreatingExperiment-16-1'
        );
        if (button) {
          button.click();
        }
      },
    },
    // How to use guide

    {
      disableInteract: true,
      selector: '.interactiveTourCreatingExperiment-17',
      header: text.interactiveTour.creatingExperiment[17].title,
      content: text.interactiveTour.creatingExperiment[17].content,
    },
  ];
};

export default tourSteps;
