import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const ConnectionsHistogram = ({ data, type, axisText }) => {
  const svgElement = useRef(null);

  useEffect(() => {
    //remove previous svg container if it exits
    d3.select(svgElement.current)
      .selectAll('.histogram-container')
      .remove();

    const svg = d3
      .select(svgElement.current)
      .append('svg')
      .classed('histogram-container', true);

    const { width, height } = svg.node().getBoundingClientRect();
    const margin = { top: 0, right: 5, bottom: 11, left: 5 };

    const yMax = d3.max(data, function(d) {
      return d.length;
    });

    const y = d3
      .scaleLinear()
      .domain([0, yMax])
      .rangeRound([height - margin.bottom, margin.top]);

    const x = d3
      .scaleBand()
      .rangeRound([margin.left, width - margin.right])
      .domain(data.map(d => d.x0))
      .padding(0.1)
      .align(0.5);

    //bottom axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .attr('class', 'histogram-axis')
      .call(d3.axisBottom(x).tickSize(0));

    //bars
    svg
      .selectAll('rect')
      .data(data)
      .join('rect')
      .classed('histogram-bar', true)
      .attr('x', d => x(d.x0))
      .attr('y', d => y(d.length))
      .attr('height', d => y(0) - y(d.length))
      .attr('width', x.bandwidth());

    //bar labels
    svg
      .append('g')
      .attr('class', 'histogram-bar-label')
      .selectAll('text')
      .data(data)
      .join('text')
      .attr('x', d => x(d.x0))
      .attr('y', d => y(d.length))
      .attr('dx', x.bandwidth() / 2)
      .attr('dy', +12)
      .attr('text-anchor', 'middle')
      .text(d => d.length)
      .call(text =>
        text
          .filter(d => y(0) - y(d.length) < 10) // short bars
          .attr('dy', -5)
          .attr('class', 'histogram-bar-label-short')
      );
  }, [data, svgElement]);

  return (
    <div className="histogram-wrapper">
      <div ref={svgElement}></div>
      <div className="histogram-axis-label">{axisText}</div>
    </div>
  );
};

export default ConnectionsHistogram;
