import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import InputField from './InputField';
import './Dropdown.scss';

const dropdownStyles = {
  menuPortal: base => ({
    ...base,
    zIndex: 10000,
    fontFamily: '"Public Sans", sans-serif',
    fontWeight: '400',
    fontSize: '1.125em',
  }),
  control: (provided, state) => {
    const overrides = {
      backgroundColor: 'white',
      boxShadow: 'none',
      borderColor: '#DDDDDD',
      width: '100%',
      borderRadius: '4px',
    };
    if (state.isFocused || state.menuIsOpen) {
      overrides.borderColor = '#DDDDDD';
    }
    if (state.menuIsOpen) {
      overrides.borderRadius = '4px 4px 0 0';
      overrides.borderBottom = 'none';
    }
    if (state.isDisabled) {
      overrides.backgroundColor = '#f6faf9';
    }
    return {
      ...provided,
      ...overrides,
      ':hover': {
        ...provided[':hover'],
        borderColor: '#DDDDDD',
      },
    };
  },
  dropdownIndicator: (provided, state) => {
    const overrides = {
      color: '#999999',
    };
    if (state.isDisabled) {
      overrides.display = 'none';
    }
    return {
      ...provided,
      ...overrides,
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    borderWidth: '0',
    borderColor: '#DDDDDD',
    borderRadius: '0 0 4px 4px',
    borderStyle: 'solid',
    marginTop: 0,
  }),
  option: (provided, state) => {
    const overrides = {
      fontSize: '0.75em',
    };
    if (state.isFocused || state.isSelected) {
      overrides.backgroundColor = '#F6F6F6';
    }
    if (state.isSelected) {
      overrides.color = 'inherit';
    }
    return {
      ...provided,
      ...overrides,
    };
  },
  singleValue: provided => ({
    color: '#4A5568',
  }),
  valueContainer: provided => ({
    ...provided,
    flexWrap: 'nowrap',
  }),
};

const dropdownStylesDark = {
  menuPortal: base => ({
    ...base,
    zIndex: 10000,
    fontFamily: '"Public Sans", sans-serif',
    fontWeight: '400',
    fontSize: '1.125em',
  }),
  control: (provided, state) => {
    const overrides = {
      backgroundColor: '#445358',
      boxShadow: 'none',
      borderColor: '#707b7f',
      width: '100%',
      borderRadius: '4px',
    };
    if (state.isFocused || state.menuIsOpen) {
      overrides.borderColor = '#707b7f';
    }
    if (state.menuIsOpen) {
      overrides.borderRadius = '4px 4px 0 0';
      overrides.borderBottom = 'none';
    }
    return {
      ...provided,
      ...overrides,
      ':hover': {
        ...provided[':hover'],
        borderColor: '#707b7f',
      },
    };
  },
  dropdownIndicator: (provided, state) => {
    const overrides = {
      color: '#f4f7f7',
      ':hover': '#f4f7f7',
    };
    if (state.isDisabled) {
      overrides.display = 'none';
    }

    return {
      ...provided,
      ...overrides,
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    borderWidth: '0',
    borderColor: '#707b7f',
    borderRadius: '0 0 4px 4px',
    borderStyle: 'solid',
    marginTop: 0,
    color: '#f4f7f7',
    backgroundColor: '#445358',
  }),
  option: (provided, state) => {
    const overrides = {
      fontSize: '0.75em',
    };
    if (state.isFocused || state.isSelected) {
      overrides.backgroundColor = '#102026';
    }
    if (state.isSelected) {
      overrides.color = 'inherit';
    }
    return {
      ...provided,
      ...overrides,
    };
  },
  singleValue: provided => ({
    color: '#f4f7f7',
  }),
  placeholder: provided => ({ color: '#f4f7f7' }),
  valueContainer: provided => ({
    ...provided,
    flexWrap: 'nowrap',
  }),
};

const makeOption = value => ({ label: value, value });

const Dropdown = ({
  components,
  creatable,
  disabled,
  isFocused,
  label,
  onChange,
  options,
  placeholder,
  validationMessage,
  value,
  theme,
  ...props
}) => {
  const SelectComponent = creatable ? CreatableSelect : Select;

  const selectRef = useRef(null);
  useEffect(() => {
    if (isFocused) {
      selectRef.current.focus();
    }
  }, [isFocused, selectRef]);

  return (
    <InputField label={label} validationMessage={validationMessage}>
      <SelectComponent
        ref={selectRef}
        className={'Dropdown text-interface-medium Dropdown-input'}
        components={components}
        isDisabled={disabled}
        maxMenuHeight={150}
        menuPlacement="auto"
        onChange={({ value }) => onChange(value)}
        openMenuOnFocus={true}
        options={options}
        placeholder={placeholder}
        styles={theme === 'dark' ? dropdownStylesDark : dropdownStyles}
        value={value ? makeOption(value) : null}
        {...props}
      />
    </InputField>
  );
};

export default Dropdown;
