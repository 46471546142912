import moment from 'moment';
import uuidv4 from 'uuid/v4';

import { compareNodes } from './nodes';

export const defaultAbundanceExaggeration = 1;

export const defaultCurveSteepness = 1;
export const defaultLambda = 0.5;
export const defaultTolerance = 0.001;

export const getMatrix = (matrices, id) =>
  matrices.filter(matrix => matrix.id === id)[0];

export const getRootMatrices = matrices =>
  matrices.filter(matrix => matrix.parentId === null || matrix.parentId === '');

export const getExperiments = (matrices, root) =>
  matrices.filter(matrix => matrix.parentId === root.id);

export const getExperiment = (matrices, root, experimentId) =>
  getExperiments(matrices, root).filter(
    experiment => experiment.id === experimentId
  )[0];

export const deleteNode = (matrix, nodeId) => ({
  ...matrix,
  nodes: Object.fromEntries(
    Object.entries(matrix.nodes).filter(([id, value]) => id !== nodeId)
  ),
});

export const createMatrix = user => {
  const matrix = {
    id: uuidv4().slice(0, 6),
    name: 'New Matrix',
    createdBy: user.claims.name,
    description: '',
    isDeleted: false,
    isPublic: false,
    modified: moment(),
    overrides: 0,
    parentId: null,
    savedByUser: true,
    nodes: {},
    roles: {
      [user.claims.user_id]: 'owner',
    },
    lambda: 0.5,
    curveSteepness: 1,
    tolerance: 0.001,
  };

  window.gtag('event', 'create_matrix', {
    event_category: 'matrix',
    event_label: matrix.id,
  });

  return matrix;
};

export const createExperiment = (matrix, user) => {
  let eventType = 'create_experiment';

  let experiment = {
    ...matrix,
    id: uuidv4().slice(0, 6),
    name: 'New Experiment',
    description: '',
    isDeleted: false,
    isPublic: false,
    modified: moment(),
    overrides: 0,
    // If creating an experiment from an experiment, they should
    // share a base matrix rather than experiment being a base
    parentId: matrix?.parentId ?? matrix.id,
  };

  if (user?.claims) {
    experiment.createdBy = user.claims.name;
    experiment.roles = {
      [user.claims.user_id]: 'owner',
    };
    // With auto-saving, savedByUser is always true if user
    experiment.savedByUser = true;
  } else {
    eventType = 'create_experiment_anonymous';
    experiment.createdBy = 'anonymous user';

    // Set password for editing
    experiment.key = uuidv4();
    experiment.roles = {};
    experiment.savedByUser = false;
  }

  window.gtag('event', eventType, {
    event_category: 'matrix',
    event_label: experiment.id,
  });

  return experiment;
};

export const convertExperimentToMatrix = (experiment, user) => ({
  ...experiment,
  id: uuidv4().slice(0, 6),
  isPublic: false,
  modified: moment(),
  parentId: null,
  originalParentId: experiment.parentId,
  roles: {
    [user.claims.user_id]: 'owner',
  },
});

export const duplicateMatrix = (matrix, user) => {
  const matrixOwners = Object.entries(matrix?.roles)
    .filter(([k, v]) => v === 'owner')
    .map(([k, v]) => k);

  const isOwner = matrixOwners.includes(user.claims.user_id);

  const dupe = {
    ...matrix,
    name: `${matrix.name} (copy)`,
    id: uuidv4().slice(0, 6),
    isPublic: false,
    modified: moment(),
    duplicateOf: matrix.id,
    createdBy: user?.claims?.name,
    savedByUser: true,
    roles: {
      [user.claims.user_id]: 'owner',
    },
    ...(!isOwner && { folder: null }),
  };

  return dupe;
};

export const hasRole = (matrix, user, role) => {
  try {
    return matrix.roles[user.claims.user_id] === role;
  } catch (e) {
    return false;
  }
};

export const isOwner = (matrix, user) => {
  return hasRole(matrix, user, 'owner');
};

/*
 * Check if user can edit the given matrix
 *
 * NB: There is a useCanEdit hook in hooks/matrices that makes this more
 * convenient
 */
export const canEdit = (matrix, user, userData, key) => {
  // If user has a key for the experiment, assume they can edit and let the
  // server-side function reject if needed
  return !!key || (user && (userData.isAdmin || isOwner(matrix, user)));
};

export const canDelete = (matrix, user, userData) => {
  return user && (userData.isAdmin || isOwner(matrix, user));
};

/*
 * Get distances from the originNodeId to each node using breadth-first search
 */
export const getDistances = (matrix, originNodeId) => {
  let distances = Object.fromEntries(
    Object.keys(matrix.nodes).map(nodeId => [nodeId, Infinity])
  );

  distances[originNodeId] = 0;
  let toVisit = [originNodeId];
  let visited = [];
  while (toVisit.length > 0) {
    const visiting = toVisit.shift();
    const currentDistance = distances[visiting] + 1;

    Object.keys(matrix.nodes[visiting]?.influences || {}).forEach(nodeId => {
      // Going to visit, move on
      if (toVisit.indexOf(nodeId) >= 0) return;

      // Already visited, move on
      if (visited.indexOf(nodeId) >= 0) return;

      // Otherwise, set distance for node
      distances[nodeId] = currentDistance;
      toVisit.push(nodeId);
    });

    visited.push(visiting);
  }
  return distances;
};

export const getDistance = (matrix, originNodeId, destinationNodeId) => {
  return getDistances(matrix, originNodeId)[destinationNodeId];
};

export const compareMatrices = (matrix, experiment) => {
  let nodeList = Object.keys({ ...matrix.nodes, ...experiment.nodes });

  let overrides = {};

  nodeList.forEach(id => {
    overrides[id] = [];

    if (!matrix.nodes.hasOwnProperty(id)) {
      let newNodes = JSON.parse(JSON.stringify(experiment.nodes));
      delete newNodes[id];

      Object.keys(newNodes).forEach(n => {
        if (newNodes[n].influences.hasOwnProperty(id)) {
          delete newNodes[n].influences[id];
        }
      });

      overrides[id] = [
        {
          type: 'new',
          id,
          revert: {
            ...experiment,
            nodes: newNodes,
          },
        },
      ];
    } else if (!experiment.nodes.hasOwnProperty(id)) {
      let newNodes = JSON.parse(JSON.stringify(experiment.nodes));

      newNodes[id] = matrix.nodes[id];

      Object.keys(matrix.nodes).forEach(n => {
        if (matrix.nodes[n].influences.hasOwnProperty(id) && newNodes[n])
          newNodes[n].influences[id] = matrix.nodes[n].influences[id];
      });

      overrides[id] = [
        {
          type: 'removed',
          id,
          revert: {
            ...experiment,
            nodes: newNodes,
          },
        },
      ];
    } else {
      overrides[id] = [
        ...overrides[id],
        ...compareNodes(matrix, experiment, id),
      ];
    }
  });

  return overrides;
};

export const shouldFetchMatrix = (
  matrixId,
  matrix,
  matricesLoaded,
  matricesLoading
) =>
  matrixId &&
  !(matrix && matrix.nodes) &&
  !(matricesLoaded && matricesLoaded[matrixId]) &&
  !(matricesLoading && matricesLoading[matrixId]);
