import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getExperiment } from 'constants/matrices';
import * as urls from 'constants/urls';
import MatrixSettingsModal from 'components/matrices/MatrixSettingsModal';

const ExperimentSettingsModal = ({ matrix }) => {
  const matrices = useSelector(state => state.matrices.matrices);
  const { experimentId } = useParams();
  const experiment = getExperiment(matrices, matrix, experimentId);
  const completionUrl = useMemo(() => {
    if (!experiment) return null;
    return urls.experiment(experiment);
  }, [experiment]);

  return (
    <MatrixSettingsModal
      cancelUrl={completionUrl}
      matrix={experiment}
      saveUrl={completionUrl}
    />
  );
};

export default ExperimentSettingsModal;
