import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { MenuActions } from 'components/menus/Menu';
import ButtonNew from 'components/inputs/ButtonNew';
import TextArea from 'components/inputs/TextArea';
import {
  saveMail,
  createMail,
  clearMail,
  resetMailSentFlag,
} from 'actions/mail';
import { createNewMail } from 'constants/mail';
import ButtonSet from './inputs/ButtonSet';
import './Feedback.scss';

const Feedback = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const { hasSentInSession } = useSelector(state => state.mail);

  const [type, setType] = useState(null);
  const [message, setMessage] = useState('');

  const sendMail = () => {
    if (!type || !message || !user) return;
    const nextMail = createNewMail(type, message, user);
    dispatch(createMail(nextMail));
    dispatch(saveMail());
    // TODO only clear if there's no error
    dispatch(clearMail());
    setMessage('');
  };

  // Reset flag on unmount
  useEffect(() => {
    return () => dispatch(resetMailSentFlag());
  }, []);

  return (
    <div className="Feedback">
      <div className="Feedback-content">
        <div className="Feedback-header text-display-large">Leave Feedback</div>
        {hasSentInSession ? (
          <div className="Feedback-description text-interface-medium-500">
            Thanks for your feedback!
          </div>
        ) : (
          <>
            <div className="Feedback-type-buttons">
              <div className="Feedback-description text-interface-medium-500">
                What is this about?
              </div>
              <MenuActions>
                <div className="MatrixVisibilityPicker">
                  <ButtonSet
                    options={[
                      { value: 'research', label: 'Research' },
                      { value: 'education', label: 'Education' },
                      { value: 'other', label: 'Other' },
                    ]}
                    onChange={setType}
                    value={type}
                  />
                </div>
              </MenuActions>
            </div>
            <div className="Feedback-text-input">
              <div className="Feedback-description text-interface-medium-500">
                Tell us what can be improved:
              </div>
              <TextArea onChange={setMessage} value={message} />
            </div>
            <div className="Feedback-submit-button-container">
              <ButtonNew
                label="Submit"
                buttonClasses={classnames('primary-button-medium', {
                  disabled: !type || !message || !user,
                })}
                onClick={sendMail}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Feedback;
