import React from 'react';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import './InputField.scss';

const InputField = ({
  className,
  label,
  children,
  tooltip,
  validationMessage,
  dark,
}) => (
  <div
    className={classNames('InputField', className, {
      invalid: validationMessage && validationMessage !== '',
      'InputField-dark': dark,
    })}
  >
    {label ? (
      <div className="InputField-label text-interface-subtext">
        <span>{label}</span>
        {tooltip ? <Tooltip text={tooltip} /> : null}
      </div>
    ) : null}
    <div className="InputField-input">{children}</div>
    {validationMessage ? (
      <div className="InputField-validationMessage text-interface-plain">
        {validationMessage}
      </div>
    ) : null}
  </div>
);

export default InputField;
