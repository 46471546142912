import React from 'react';
import classnames from 'classnames';
import ButtonNew from 'components/inputs/ButtonNew';
import './ModalFooter.scss';

const ModalFooter = ({
  secondaryAction,
  secondaryActionLabel,
  secondaryActionDisabled,
  primaryAction,
  primaryActionLabel,
  primaryActionDisabled,
}) => {
  return (
    <div className="ModalFooter">
      <div className="ModalFooter-button-container">
        {secondaryAction ? (
          <ButtonNew
            buttonClasses={classnames(
              'secondary-button-small ModalFooter-cancel-button interactiveTourCreatingExperiment-2-1 interactiveTourCreatingExperiment-12-1',
              {
                disabled: secondaryActionDisabled,
              }
            )}
            label={secondaryActionLabel}
            onClick={secondaryAction}
          />
        ) : null}
        {primaryAction ? (
          <ButtonNew
            label={primaryActionLabel}
            buttonClasses={classnames(
              'primary-button-small interactiveTourCreatingExperiment-5-1',
              {
                disabled: primaryActionDisabled,
              }
            )}
            onClick={primaryAction}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ModalFooter;
