import React from 'react';

const Methodology = () => {
  return (
    <div className="Methodology">
      <div className="text-display-large title">Methodology</div>
      <div className="text-interface-plain description">
        The Matrix predicts how changes in certain community components, like
        changes in a species’ abundance resulting from management, may affect
        other species in a community using a qualitative modeling approach
        called fuzzy cognitive mapping (Özesmi and Özesmi 2004, Ramsey and
        Veltman 2005, Clark-Wolf et al. 2022, Pearson and Clark-Wolf 2023).
        Fuzzy cognitive mapping applies information (from quantitative and/or
        qualitative sources) about the basic components that define a food web
        or community interaction web (species identity, species linkages,
        linkage strengths, and linkage directions, i.e. +,-) to predict
        qualitative outcomes of community interactions, i.e. which species might
        increase or decrease by a small, medium, or large amount as a function
        of the proposed action. Predictions are not definitive as the quality of
        outputs depends on the quality of inputs and the extent to which
        important system components are captured. However, given inputs that at
        least reasonably reflect qualitative patterns and processes (e.g., they
        need not be exact expressions of real abundances or interaction
        strengths), this approach can generate fairly realistic qualitative
        predictions (e.g., Kosko 1992, Papageorgiou 2011, Clark-Wolf et al.
        2022) that can help to inform ecology and conservation management
        (Pearson et al. 2021).
      </div>
      <div className="text-interface-large subtitle">Sources</div>
      <ul className="article-list">
        <li className="article">
          Clark‐Wolf, T. J., Hahn, P. G., Brelsford, E., Francois, J., Hayes,
          N., Larkin, B., ... & Pearson, D. E. (2022). Preventing a series of
          unfortunate events: Using qualitative models to improve conservation.
          <span className="book">
            Journal of Applied Ecology, 59(9), 2322-2332.
          </span>
          .
        </li>
        <li className="article">
          Kosko, B. 1992.{' '}
          <span className="book">
            Neural networks and fuzzy systems: a dynamical systems approach to
            machine intelligence
          </span>{' '}
          (Prentice Hall, 1992).
        </li>
        <li className="article">
          Özesmi, U., and S. Özesmi. 2004. Ecological models based on people’s
          knowledge: a multi-step fuzzy cognitive mapping approach.{' '}
          <span className="book">Ecological Modelling</span> 176, 47–59.
        </li>
        <li className="article">
          Papageorgiou, E. I. 2011. Review study on fuzzy cognitive maps and
          their applications during the last decade in{' '}
          <span className="book">
            IEEE International Conference on Fuzzy Systems
          </span>
          , (2011), pp. 281–298.
        </li>
        <li className="article">
          Pearson, D. E., Clark, T. J., & Hahn, P. G. 2021. Evaluating
          unintended consequences of intentional species introductions and
          eradications for improved conservation management.{' '}
          <span className="book">Conservation Biology</span> 2021: 1-10.{' '}
          <a href="https://doi.org/10.1111/cobi.13734">
            https://doi.org/10.1111/cobi.13734
          </a>
        </li>
        <li className="article">
          Pearson, D. E., & Clark‐Wolf, T. J. (2023). Predicting ecological
          outcomes using fuzzy interaction webs.
          <span className="book">Ecology, e4072.</span>
        </li>
        <li className="article">
          Ramsey, D., and C. Veltman. 2005. Predicting the effects of
          perturbations on ecological communities: What can qualitative models
          offer? <span className="book">Journal of Animal Ecology</span> 74,
          905–916.
        </li>
      </ul>
    </div>
  );
};

export default Methodology;
