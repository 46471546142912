import firebase from 'firebase/compat/app';

export const get = async (id, requestingUser, requestingUserData = {}) => {
  if (!(requestingUser.claims.user_id === id || requestingUserData.isAdmin)) {
    return;
  }
  const db = firebase.firestore();

  const ref = await db.collection('users').doc(id);
  const snapshot = await ref.get();
  const data = await snapshot.data();
  return data;
};

export const save = async (
  id,
  userData,
  requestingUser,
  requestingUserData
) => {
  const db = firebase.firestore();
  const doc = await db.collection('users').doc(id);
  doc.set(userData);
};
