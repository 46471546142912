import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ModificationsSidebar from 'components/ModificationsSidebar';
import { getExperiment, shouldFetchMatrix } from 'constants/matrices';
import { fetchMatrix } from '../actions/matrices';
import { toggleModificationsSidebar } from 'actions/viewStates';
import Icon from './Icon';
import ButtonNew from './inputs/ButtonNew';
import Popover from './Popover';
import './MatrixSidebar.scss';

const ChangesToggleContent = ({ onToggle }) => {
  return (
    <div className="ChangesToggle-button-container">
      <ButtonNew
        buttonClasses="secondary-button-dark-small"
        onClick={() => onToggle('session')}
        label="Changes made this session"
      />
      <ButtonNew
        buttonClasses="secondary-button-dark-small"
        onClick={() => onToggle('comparison')}
        label="Changes from base matrix"
      />
    </div>
  );
};

const ChangesToggle = ({ onToggle, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);

  const onClick = useCallback(
    v => {
      onToggle(v);
      setIsOpen(false);
    },
    [onToggle]
  );

  return (
    <div>
      <div ref={popoverRef}>
        <ButtonNew
          buttonClasses={classnames('icon-button-small', { disabled })}
          onClick={() => setIsOpen(!isOpen)}
          icon="comparison"
        />
      </div>
      <Popover
        content={<ChangesToggleContent onToggle={onClick} />}
        isOpen={isOpen}
        parentRef={popoverRef.current}
      />
    </div>
  );
};

const MatrixSidebar = ({ matrix }) => {
  const dispatch = useDispatch();
  const matrices = useSelector(state => state.matrices.matrices);
  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);
  const { experimentId } = useParams();
  const experiment = getExperiment(matrices, matrix, experimentId);

  // Should always be 'session' or 'comparison'
  const [visibleChanges, setVisibleChanges] = useState('session');

  const initialSessionMatrices = useSelector(
    state => state.matrices.sessionMatrices
  );

  const initialSessionMatrix = useMemo(() => {
    const id = experimentId ? experiment?.id : matrix?.id;
    return initialSessionMatrices?.[id];
  }, [matrix, experimentId, experiment, initialSessionMatrices]);

  useEffect(() => {
    if (
      shouldFetchMatrix(
        experimentId,
        experiment,
        matricesLoaded,
        matricesLoading
      )
    ) {
      dispatch(fetchMatrix(experimentId));
    }
  }, [dispatch, experiment, experimentId, matricesLoaded, matricesLoading]);

  const modificationsOpen = useSelector(
    state => state.viewStates.modificationsSidebarOpen
  );

  const baseMatrix = useMemo(() => {
    if (visibleChanges === 'session') {
      return initialSessionMatrix;
    }
    return matrix;
  }, [initialSessionMatrix, matrix, visibleChanges]);

  const comparisonMatrix = useMemo(() => {
    if (visibleChanges === 'session') {
      return experimentId ? experiment : matrix;
    }
    return experiment;
  }, [experimentId, experiment, visibleChanges, matrix]);

  return (
    <>
      <div
        className={`MatrixSidebar MatrixSidebar-Modifications interactiveTourCreatingExperiment-7 ${
          !modificationsOpen ? 'disabled' : ''
        }`}
      >
        {!modificationsOpen ? (
          <div className="MatrixSidebar-stickyTopContainer">
            <div
              className="SectionHeader-icon"
              onClick={() => dispatch(toggleModificationsSidebar())}
            >
              <Icon alt={'edit'} icon={'edit'} />
            </div>
          </div>
        ) : (
          <div className="MatrixSidebar-scrollContainer">
            <div className="MatrixSidebar-section-header-container">
              <div
                className="SectionHeader-icon"
                onClick={() => dispatch(toggleModificationsSidebar())}
              >
                <Icon alt={'edit'} icon={'edit'} />
              </div>
              <div className="MatrixSidebar-header">
                <div className="MatrixSidebar-header-bold">Changes</div>
                <div className="MatrixSidebar-header-text">
                  {visibleChanges === 'session'
                    ? 'made this session'
                    : 'from base matrix'}
                </div>
                <ChangesToggle
                  onToggle={setVisibleChanges}
                  disabled={!experimentId}
                />
              </div>
            </div>
            {comparisonMatrix &&
            comparisonMatrix?.nodes &&
            baseMatrix &&
            baseMatrix?.nodes ? (
              <ModificationsSidebar
                matrix={baseMatrix}
                experiment={comparisonMatrix}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default MatrixSidebar;
