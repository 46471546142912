import {
  FOLDER_CREATE,
  FOLDER_ERROR_RESPONSE,
  FOLDER_RESPONSE,
  FOLDER_REQUEST,
  FOLDER_SAVE_REQUEST,
  FOLDER_SAVE_RESPONSE,
  FOLDER_SAVE_ERROR_RESPONSE,
  FOLDER_SET_CURRENT,
  FOLDER_REMOVE_REQUEST,
  FOLDER_REMOVE_RESPONSE,
  FOLDER_REMOVE_ERROR_RESPONSE,
  FOLDER_ADD_FAVORITE,
  FOLDER_REMOVE_FAVORITE,
} from 'constants/action_names';
import * as FolderService from 'services/folders';

export const createFolder = newFolder => ({
  type: FOLDER_CREATE,
  newFolder,
});

const fetchFolders = (fetcher, folderIdentifier) => {
  return dispatch => {
    dispatch({
      type: FOLDER_REQUEST,
      folderIdentifier,
    });

    return fetcher
      .then(json => {
        dispatch({
          type: FOLDER_RESPONSE,
          json,
          folderIdentifier,
        });
      })
      .catch(error => {
        dispatch({
          type: FOLDER_ERROR_RESPONSE,
          error,
          folderIdentifier,
        });
      });
  };
};

export const fetchUserFolders = (user, userData, folders) => {
  return fetchFolders(FolderService.listUser(user, userData, folders), 'user');
};

export const fetchFolder = folderId => {
  return fetchFolders(FolderService.get(folderId), folderId);
};

export const setCurrentFolder = folder => {
  return {
    type: FOLDER_SET_CURRENT,
    folder,
  };
};

export const saveFolder = folderId => {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const folder = {
      ...state.folders.folders.find(e => e.id === folderId),
    };

    dispatch({ type: FOLDER_SAVE_REQUEST });

    return FolderService.save(folder, user)
      .then(() => {
        dispatch({
          type: FOLDER_SAVE_RESPONSE,
        });
      })
      .catch(error => {
        console.warn(error);
        dispatch({
          type: FOLDER_SAVE_ERROR_RESPONSE,
          error,
        });
      });
  };
};

export const removeFolder = folderId => {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const folder = {
      ...state.folders.folders.find(e => e.id === folderId),
    };
    folder.isDeleted = true;

    dispatch({
      type: FOLDER_REMOVE_REQUEST,
      folderId,
    });

    return FolderService.save(folder, user)
      .then(() => {
        dispatch({
          type: FOLDER_REMOVE_RESPONSE,
          folderId,
        });
      })
      .catch(error => {
        console.warn(error);
        dispatch({
          type: FOLDER_REMOVE_ERROR_RESPONSE,
          error,
        });
      });
  };
};

export const addFavoriteFolder = (folderId, userId) => ({
  type: FOLDER_ADD_FAVORITE,
  folderId,
  userId,
});

export const removeFavoriteFolder = (folderId, userId) => ({
  type: FOLDER_REMOVE_FAVORITE,
  folderId,
  userId,
});
