import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getExperiment } from 'constants/matrices';
import MatrixSidebarConnections from 'components/MatrixSidebarConnections';
import ButtonNew from 'components/inputs/ButtonNew';
import icons from 'img/icons';
import './ConnectionsSidebar.scss';
import ConnectionsModal from 'components/ConnectionsModal';

const ConnectionsSidebar = ({ matrix, isPlayMode }) => {
  const [connectionsTableOpen, setConnectionsTableOpen] = useState(false);

  // See TODO before removing
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  // Show all connections checkbox on connections sidebar
  const [showAllConnections, setShowAllConnections] = useState(false);

  const { experimentId } = useParams();
  const matrices = useSelector(state => state.matrices.matrices);
  const experiment = getExperiment(matrices, matrix, experimentId);

  return (
    <div className="MatrixSidebar MatrixSidebar-Connections">
      <div className="MatrixSidebar-Connections-header">
        <div className="MatrixSidebar-Connections-header-label">
          VIEW CONNECTIONS
        </div>
        {!isPlayMode ? (
          <div className="MatrixSidebar-Connections-header-button">
            <ButtonNew
              label="Edit / analyze"
              buttonClasses="secondary-button-dark-small interactiveTourCreatingExperiment-9"
              onClick={() => setConnectionsTableOpen(!connectionsTableOpen)}
              iconLeft="connectionsTable"
            />
          </div>
        ) : null}
        {/* TODO does this instruction go anywhere? */}
        {/* <button
                className="MatrixSidebar-descriptionToggle"
                onClick={() => setDescriptionOpen(!descriptionOpen)}
              >
                {descriptionOpen ? (
                  <img src={icons.downCaret} alt="" />
                ) : (
                  <img src={icons.rightCaret} alt="" />
                )}{' '}
                How to view
              </button> */}
      </div>
      <div className="MatrixSidebar-scrollContainer">
        <div className="MatrixSidebar-section">
          {descriptionOpen ? (
            <>
              {showAllConnections ? null : (
                <div className="MatrixSidebar-sectionDescription">
                  <strong>Hover</strong> over a node to see which other nodes it
                  influences and is influenced by, as well as the connection
                  strength of those node relationships.
                </div>
              )}
              <svg width="100%" viewBox="0 -20 250 70">
                <image
                  x={0}
                  y={-15}
                  height={14}
                  href={icons.connectionSquare}
                />
                <text x={20} y={-7} dominantBaseline="middle" fontSize={12}>
                  Total connections for each node
                </text>
                <rect
                  x={(250 - 60) / 2}
                  y={(50 - 24) / 2}
                  height={24}
                  width={60}
                  fill="white"
                  rx={4}
                  ry={4}
                />
                <text
                  x={250 / 2}
                  y={50 / 2 + 3}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize={14}
                >
                  Node
                </text>

                {/* Arrows */}
                <g
                  stroke={showAllConnections ? '#eb346c' : '#303752'}
                  strokeWidth={1.5}
                >
                  <line
                    x1={0}
                    x2={(250 - 60) / 2 - 5}
                    y1={50 / 2}
                    y2={50 / 2}
                  />
                  <line
                    x1={(250 - 60) / 2 - 9}
                    x2={(250 - 60) / 2 - 5}
                    y1={50 / 2 - 4}
                    y2={50 / 2}
                  />
                  <line
                    x1={(250 - 60) / 2 - 9}
                    x2={(250 - 60) / 2 - 5}
                    y1={50 / 2 + 4}
                    y2={50 / 2}
                  />
                </g>

                <g
                  stroke={showAllConnections ? '#00c3ff' : '#303752'}
                  strokeWidth={1.5}
                >
                  <line
                    x1={250}
                    x2={(250 + 60) / 2 + 5}
                    y1={50 / 2}
                    y2={50 / 2}
                  />
                  <line x1={250 - 4} x2={250} y1={50 / 2 - 4} y2={50 / 2} />
                  <line x1={250 - 4} x2={250} y1={50 / 2 + 4} y2={50 / 2} />
                </g>

                <text
                  x={((250 - 60) / 2 - 5) / 2}
                  y={50 / 2 - 5}
                  textAnchor="middle"
                  fontSize={11}
                >
                  {showAllConnections ? 'negative influence' : 'influenced by'}
                </text>
                <text
                  x={((250 + 60) / 2 + 5 + 250) / 2}
                  y={50 / 2 - 5}
                  textAnchor="middle"
                  fontSize={11}
                >
                  {showAllConnections ? 'positive influence' : 'influences'}
                </text>
              </svg>
            </>
          ) : null}

          {/* <div className="MatrixSidebar-ConnectionsAll">
              <label htmlFor="all-connections-checkbox">
                Show all connections
              </label>
              <input
                id="all-connections-checkbox"
                type="checkbox"
                onChange={() => setShowAllConnections(!showAllConnections)}
                checked={showAllConnections}
              />
            </div> */}
        </div>

        <div className="MatrixSidebar-Connections-row">
          <div>
            <MatrixSidebarConnections
              nodes={(experiment && experiment.nodes) || matrix.nodes}
              showAllConnections={showAllConnections}
            />
          </div>
        </div>
      </div>
      {connectionsTableOpen ? (
        <ConnectionsModal
          isExperiment={!!experimentId}
          matrix={experiment ?? matrix}
          exitModal={() => setConnectionsTableOpen(false)}
        />
      ) : null}
    </div>
  );
};

export default ConnectionsSidebar;
