import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import classnames from 'classnames';
import text from 'constants/text';
import ButtonNew from 'components/inputs/ButtonNew';
import './SplashPage.scss';

const PictureCarousel = () => {
  const [prevStep, setPrevStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const slideShowInterval = useRef(null);
  const fadeInterval = useRef(null);

  const inactiveStep = useMemo(() => text.splashPage.carousel[prevStep], [
    prevStep,
  ]);
  const activeStep = useMemo(() => text.splashPage.carousel[currentStep], [
    currentStep,
  ]);

  const onChangeStep = useCallback(
    val => {
      if (currentStep !== prevStep) {
        clearTimeout(fadeInterval.current);
        setPrevStep(currentStep);
      }
      setCurrentStep(val);
    },
    [currentStep, prevStep]
  );

  useEffect(() => {
    clearTimeout(slideShowInterval.current);

    slideShowInterval.current = setTimeout(
      () =>
        setCurrentStep(
          () => (currentStep + 1) % text.splashPage.carousel.length
        ),
      5000
    );
  }, [currentStep]);

  useEffect(() => {
    const el = document.getElementsByClassName(
      'PictureCarousel-current-image'
    )[0];
    el.classList.remove('fade');
    el.classList.add('fade');

    fadeInterval.current = setTimeout(() => {
      setPrevStep(currentStep);
      el.classList.remove('fade');
    }, 1000);
  }, [currentStep]);

  return (
    <div className="PictureCarousel">
      <div className="PictureCarousel-image-container">
        <img
          className="PictureCarousel-prev-image"
          alt={inactiveStep.image}
          src={`${window.location.origin}/images/${inactiveStep.image}`}
        />
        <img
          className="PictureCarousel-current-image invisible"
          alt={activeStep.image}
          src={`${window.location.origin}/images/${activeStep.image}`}
        />
        <div className="PictureCarousel-content">
          <div className="PictureCarousel-text-container">
            <div className="PictureCarousel-header text-interface-medium">
              {activeStep.header}
            </div>
            <div className="PictureCarousel-description text-interface-small">
              {activeStep.description}
            </div>
          </div>
          <div className="PictureCarousel-progress-line">
            {text.splashPage.carousel.map((_, i) => (
              <div
                key={i}
                className={classnames('PictureCarousel-progress-node', {
                  selected: i === currentStep,
                })}
                onClick={() => onChangeStep(i)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const SplashPage = ({ setWhichTourModal }) => {
  return (
    <div className="SplashPage">
      <div className="SplashPage-content">
        <div className="SplashPage-left-column">
          <div className="SplashPage-header text-display-large">
            {text.splashPage.header}
          </div>
          <div className="SplashPage-description text-interface-medium">
            {text.splashPage.description}
          </div>
          <div className="SplashPage-bullets text-interface-medium">
            {text.splashPage.bullets.map((bullet, i) => (
              <div key={i} className="SplashPage-bullet">
                • {bullet}
              </div>
            ))}
          </div>
          <div className="SplashPage-button-container">
            <ButtonNew
              buttonClasses="primary-button-large"
              to="/gallery"
              label="Get started"
            />
            <ButtonNew
              buttonClasses="secondary-button-large"
              onClick={() => setWhichTourModal(v => !v)}
              label="How to use"
            />
          </div>
        </div>
        <div className="SplashPage-right-column">
          <PictureCarousel />
        </div>
      </div>
    </div>
  );
};

export default SplashPage;
