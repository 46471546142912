import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import classNames from 'classnames';
import { getAbundanceForValue, getValueForAbundance } from 'constants/fcm';
import './AbundanceSlider.scss';
import icons from 'img/icons';

const AbundanceSlider = ({ changeable, node, onChange, value, label }) => {
  const handleRef = useRef(null);
  const svgRef = useRef(null);
  const [abundance, setAbundance] = useState(value);
  const [width, setWidth] = useState(0);

  useEffect(() => setAbundance(value), [value, setAbundance]);

  useEffect(() => {
    setWidth(svgRef.current.parentElement.getBoundingClientRect().width);
  }, [svgRef]);

  const height = 64;
  const rectHeight = 6;
  const rectVerticalMargin = 22;
  const rectHorizontalMargin = 15;
  const rectWidth = Math.max(0, width - 2 * rectHorizontalMargin);

  const xScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([rectHorizontalMargin, rectHorizontalMargin + rectWidth])
    .clamp(true);

  useEffect(() => {
    const getUpdatedPosition = (x, width) => {
      if (x < rectHorizontalMargin) {
        return rectHorizontalMargin;
      }
      if (x > width + rectHorizontalMargin) {
        return width + rectHorizontalMargin;
      }
      return x;
    };

    if (changeable) {
      d3.select(handleRef.current).call(
        d3
          .drag()
          .on('drag', d => {
            const x = getUpdatedPosition(d3.event.x, rectWidth);
            setAbundance(xScale.invert(x));
          })
          .on('end', () => {
            const x = getUpdatedPosition(d3.event.x, rectWidth);
            onChange(xScale.invert(x).toFixed(3));
          })
      );
    }
  }, [changeable, handleRef, node, onChange, xScale, rectWidth]);

  const population = getValueForAbundance(node, abundance);
  const grabbableAreaWidth = 20;
  return (
    <div className="AbundanceSlider">
      {label ? (
        <div className={`text-interface-subtext  InputField-label`}>
          {label}
        </div>
      ) : null}

      <div className="AbundanceSlider-slider-container">
        <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
          <rect
            x={rectHorizontalMargin}
            y={rectVerticalMargin}
            width={rectWidth}
            height={rectHeight}
            className="current-population-line"
            rx={rectHeight / 2}
          />

          <g className="ticks">
            {Object.entries(node.membershipFunctions).map(
              ([funcName, func]) => {
                const population = func[1][0];
                const x = xScale(getAbundanceForValue(node, population));
                return (
                  <g
                    key={funcName}
                    className="tick"
                    transform={`translate(${x}, ${rectVerticalMargin + 15})`}
                  >
                    <line
                      x1={0}
                      x2={0}
                      y1={11}
                      y2={rectHeight}
                      stroke="white"
                    />
                    <text className="tick-text" y={rectHeight + 3}>
                      <tspan x={0}>
                        {+population === Math.round(population)
                          ? population
                          : +population.toFixed(1)}
                      </tspan>
                      <tspan x={0} dy="1em">
                        {funcName[0] + funcName.slice(1).toLocaleLowerCase()}
                      </tspan>
                    </text>
                  </g>
                );
              }
            )}
          </g>

          <g
            ref={handleRef}
            className={classNames('currentPopulation', {
              draggable: changeable,
            })}
            transform={`translate(${xScale(abundance)}, 0)`}
          >
            <circle
              className="current-population-line-endcap-outer"
              r="12"
              transform={`translate(0, ${rectVerticalMargin + rectHeight / 2})`}
            />
            <circle
              className="current-population-line-endcap-middle"
              r="9"
              transform={`translate(0, ${rectVerticalMargin + rectHeight / 2})`}
            />
            <circle
              className="current-population-line-endcap-inner"
              r="6"
              transform={`translate(0, ${rectVerticalMargin + rectHeight / 2})`}
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default AbundanceSlider;
