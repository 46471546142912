import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';

const BarChart = ({ bars, width, showErrorBars, tickFormat, yRange }) => {
  const svgRef = useRef(null);
  const rootRef = useRef(null);
  const [mousePosition, setMousePosition] = useState([]);
  const [tooltipContent, setTooltipContent] = useState(null);

  const height = 500;
  const margin = useMemo(
    () => ({ top: 50, left: 50, right: 50, bottom: 50 }),
    []
  );
  const chartHeight = useMemo(() => height - margin.top - margin.bottom, [
    height,
    margin,
  ]);
  const chartWidth = useMemo(() => width - margin.left - margin.right, [
    width,
    margin,
  ]);

  const x = useMemo(() => {
    return d3
      .scaleBand()
      .range([0, chartWidth])
      .domain(bars.map(d => d.x))
      .padding(0.2);
  }, [bars, chartWidth]);

  const y = useMemo(() => {
    return d3
      .scaleLinear()
      .domain(yRange)
      .range([chartHeight, 0]);
  }, [chartHeight, yRange]);

  const rects = useMemo(() => {
    return bars.map(bar => {
      return {
        ...bar,
        x: x(bar.x) + margin.left,
        y: y(bar.y) + margin.top,
        width: x.bandwidth(),
        height: chartHeight - y(bar.y),
      };
    });
  }, [bars, x, y, chartHeight, margin]);

  function drawErrorBars(context, obj) {
    const xCord = x(obj.x);
    const yTop = y(obj.upperPercentile);
    const yBottom = y(obj.lowerPercentile);
    const cropLines = x.bandwidth() / 4;

    context.moveTo(xCord + cropLines, yTop);
    context.lineTo(xCord + x.bandwidth() - cropLines, yTop);
    context.moveTo(xCord + x.bandwidth() / 2, yTop);
    context.lineTo(xCord + x.bandwidth() / 2, yBottom);
    context.moveTo(xCord + cropLines, yBottom);
    context.lineTo(xCord + x.bandwidth() - cropLines, yBottom);

    return context;
  }

  const errorBars = useMemo(() => {
    return bars.map(bar => {
      return {
        ...bar,
        d: drawErrorBars(d3.path(), bar).toString(),
        strokeWidth: 2,
        fill: 'none',
      };
    });
  }, [bars, x, y, width]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const group = d3.select(rootRef.current);

    group.select('.x-axis').remove();
    group
      .append('g')
      .classed('x-axis', true)
      .attr('transform', `translate(${margin.left}, ${height - margin.bottom})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)');

    const maxCharsCanFit = 8;
    const longestName = Math.max(...bars.map(b => b?.name?.length ?? 0));
    const heightIncrease = Math.max(longestName - maxCharsCanFit, 0) * 5;
    svg.attr('height', height + heightIncrease);

    group.select('.y-axis').remove();
    let axis = d3.axisLeft(y);
    if (tickFormat) {
      axis.tickFormat(tickFormat);
    }
    group
      .append('g')
      .classed('y-axis', true)
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(axis);
  }, [bars, margin, x, y, width, tickFormat]);

  return (
    <div
      className="BarChart"
      onMouseMove={e =>
        setMousePosition([e.clientX, e.clientY].filter(Boolean))
      }
      onMouseLeave={() => setMousePosition([])}
    >
      {tooltipContent && mousePosition.length ? (
        <div
          style={{
            position: 'fixed',
            left: mousePosition[0] + 10,
            top: mousePosition[1] + 10,
          }}
        >
          {tooltipContent}
        </div>
      ) : null}

      <svg ref={svgRef} width={width} height={height}>
        <g ref={rootRef}>
          {rects.map(rect => (
            <rect
              className="gray"
              key={rect.nodeId}
              x={rect.x}
              y={rect.y}
              height={rect.height}
              width={rect.width}
              fill={rect.fill}
            />
          ))}
          {showErrorBars
            ? errorBars.map(bar => (
                <path
                  key={bar.nodeId}
                  transform={`translate(${margin.left},${margin.top})`}
                  d={bar.d}
                  strokeWidth={bar.strokeWidth}
                  stroke="#102026"
                  fill={bar.fill}
                />
              ))
            : null}
        </g>
        {/* Invisible rect to have larger target for tooltip */}
        {rects.map(rect => (
          <rect
            key={rect.nodeId}
            x={rect.x}
            y={0}
            height={height}
            width={rect.width}
            fill={'transparent'}
            onMouseEnter={() => setTooltipContent(rect?.tooltip)}
            onMouseLeave={() => setTooltipContent(null)}
          />
        ))}
      </svg>
    </div>
  );
};

export default BarChart;
