import {
  FOLDER_SETTINGS_START_EDIT,
  FOLDER_SETTINGS_SET_FIELD,
  FOLDER_SETTINGS_CANCEL_EDIT,
  FOLDER_SETTINGS_FINISH_EDIT,
  FOLDER_SETTINGS_VALIDATE,
} from 'constants/action_names';

export const startEdit = folder => ({
  type: FOLDER_SETTINGS_START_EDIT,
  folder,
});

export const setField = (field, value) => ({
  type: FOLDER_SETTINGS_SET_FIELD,
  field,
  value,
});

export const cancelEdit = () => ({
  type: FOLDER_SETTINGS_CANCEL_EDIT,
});

export const finishEdit = folder => {
  return {
    type: FOLDER_SETTINGS_FINISH_EDIT,
    folder,
  };
};

export const validate = user => ({
  type: FOLDER_SETTINGS_VALIDATE,
  user,
});
