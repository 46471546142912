const tourSteps = (
  text,
  dispatch,
  openLoginPrompt,
  closeLoginPrompt,
  modificationsOpen,
  toggleModificationsSidebar,
  galleryTab,
  setGalleryTab
) => {
  return [
    // Getting started
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-0', //works reliably, but not flexible.
      header: text.interactiveTour.gettingStarted[0].title,
      content: text.interactiveTour.gettingStarted[0].content,
      moveTo: 'splash',
      //This is needed one step early, not entirely sure why
      action: () => {
        if (galleryTab.name !== 'public') {
          dispatch(setGalleryTab({ name: 'public', userInitiated: true }));
        }
      },
    },
    // Public gallery
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-1',
      header: text.interactiveTour.gettingStarted[1].title,
      content: text.interactiveTour.gettingStarted[1].content,
      moveTo: 'gallery',
      action: () => {
        if (galleryTab.name !== 'public') {
          dispatch(setGalleryTab({ name: 'public', userInitiated: true }));
        }
      },
    },
    // Education modules
    {
      selector: '.interactiveTourGettingStarted-2',
      header: text.interactiveTour.gettingStarted[2].title,
      content: text.interactiveTour.gettingStarted[2].content,
      moveTo: 'gallery',
      action: () => {
        if (galleryTab.name !== 'public') {
          dispatch(setGalleryTab({ name: 'public', userInitiated: true }));
        }
      },
      actionAfter: () => {
        // TODO this is not clicking, will require we switch back to public after if working
        const learningModuleButton = document.querySelector(
          '.interactiveTourGettingStarted-2 .MatrixListItem-main'
        );
        learningModuleButton.click();
      },
    },
    // Login
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-3',
      header: text.interactiveTour.gettingStarted[3].title,
      content: text.interactiveTour.gettingStarted[3].content,
      moveTo: 'gallery',
      action: () => {
        const signInButton = document.querySelector('.sign-in-button');
        if (signInButton) {
          dispatch(openLoginPrompt('Login', '/'));
        }
        if (galleryTab.name !== 'public') {
          dispatch(setGalleryTab({ name: 'public', userInitiated: true }));
        }
      },
      actionAfter: () => {
        dispatch(closeLoginPrompt());
      },
    },
    // MPG grasslands
    {
      disableInteract: true,
      header: text.interactiveTour.gettingStarted[4].title,
      content: text.interactiveTour.gettingStarted[4].content,
      moveTo: 'gallery',
      matrixFinder: publicMatrices =>
        publicMatrices.find(
          ({ name }) => name.toLowerCase() === 'MPG Grasslands'.toLowerCase()
        ),
      action: () => {
        if (galleryTab.name !== 'public') {
          dispatch(setGalleryTab({ name: 'public', userInitiated: true }));
        }
      },
    },
    // Nodes with green outlines
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-5',
      header: text.interactiveTour.gettingStarted[5].title,
      content: text.interactiveTour.gettingStarted[5].content,
      moveTo: 'matrix',
    },
    // Abundance slider
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-6',
      header: text.interactiveTour.gettingStarted[6].title,
      content: text.interactiveTour.gettingStarted[6].content,
      position: 'top',
      moveTo: 'matrix',
    },
    // Connections
    {
      selector: '.interactiveTourGettingStarted-7',
      header: text.interactiveTour.gettingStarted[7].title,
      content: text.interactiveTour.gettingStarted[7].content,
      moveTo: 'matrix',
    },
    // Run model play mode
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-8',
      header: text.interactiveTour.gettingStarted[8].title,
      content: text.interactiveTour.gettingStarted[8].content,
      moveTo: 'matrix',
    },
    // View results button
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-9',
      header: text.interactiveTour.gettingStarted[9].title,
      content: text.interactiveTour.gettingStarted[9].content,
    },
    // Reset button
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-10',
      header: text.interactiveTour.gettingStarted[10].title,
      content: text.interactiveTour.gettingStarted[10].content,
    },
    // Duplicate
    {
      disableInteract: true,
      selector: `.interactiveTourGettingStarted-11`,
      header: text.interactiveTour.gettingStarted[11].title,
      content: text.interactiveTour.gettingStarted[11].content,
    },
    // Share button
    {
      disableInteract: true,
      selector: `.interactiveTourGettingStarted-12`,
      header: text.interactiveTour.gettingStarted[12].title,
      content: text.interactiveTour.gettingStarted[12].content,
    },
    // Create experiment button
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-13',
      header: text.interactiveTour.gettingStarted[13].title,
      content: text.interactiveTour.gettingStarted[13].content,
    },
    // Leave feedback
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-14',
      header: text.interactiveTour.gettingStarted[14].title,
      content: text.interactiveTour.gettingStarted[14].content,
    },
    // About page
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-15',
      header: text.interactiveTour.gettingStarted[15].title,
      content: text.interactiveTour.gettingStarted[15].content,
    },
    // FAQs
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-16',
      header: text.interactiveTour.gettingStarted[16].title,
      content: text.interactiveTour.gettingStarted[16].content,
    },
    // How to use guide
    {
      disableInteract: true,
      selector: '.interactiveTourGettingStarted-17',
      header: text.interactiveTour.gettingStarted[17].title,
      content: text.interactiveTour.gettingStarted[17].content,
    },
  ];
};

export default tourSteps;
