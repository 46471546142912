import React from 'react';
import Icon from 'components/Icon';
import './Errors.scss';

const Error = ({ error }) => {
  return (
    <div className="error">
      <Icon alt="error" icon={'error'} />
      <span className="error-title">{error.title}</span>
      <span className="error-details">{error.details}</span>
    </div>
  );
};

const Errors = ({ errors }) => {
  return (
    <div className="error-list">
      {errors.map(error => (
        <Error key={error.type} error={error} />
      ))}
    </div>
  );
};

export default Errors;
