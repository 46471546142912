import React, { useState, useEffect, useMemo, useCallback } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import UncertaintyResultsBarChart from './UncertaintyResultsBarChart';
import ButtonNew from 'components/inputs/ButtonNew';
import { trophicLevels, nodeSortFunction } from 'constants/nodes';
import { getMatrix } from 'constants/matrices';
import { calculateNewAbundances } from 'constants/fcm';
import { exportCsvData, getFilename } from 'export/export_csv_data';
import { getValueForAbundance } from 'constants/fcm';
import './UncertaintyResults.scss';

const UncertaintyResults = ({ matrix, results, nodeFilter }) => {
  const isExperiment = useMemo(() => !!matrix.parentId, [matrix]);
  const matrices = useSelector(state => state.matrices.matrices);
  const baseMatrix = useMemo(() => {
    if (!isExperiment) return null;
    return getMatrix(matrices, matrix.parentId);
  }, [matrices, matrix, isExperiment]);

  const downloadImage = () => {
    const fileName = `${matrix.name}-uncertainty-analysis`;
    const charts = document.getElementById('UncertaintyResultsBarChart');

    html2canvas(charts).then(canvas => {
      saveAs(canvas.toDataURL(), `${fileName}.png`);
    });
  };

  const [chartWidth, setChartWidth] = useState(800);

  const sortedNodes = useMemo(
    () => Object.values(matrix.nodes).sort(nodeSortFunction),
    [matrix]
  );

  const getTooltipContent = useCallback(arr => {
    return (
      <div className="UncertaintyResults-tooltip-container">
        {arr.map((obj, i) => {
          const [name, value] = Object.entries(obj)[0];
          return (
            <div key={i} className="UncertaintyResults-tooltip-section">
              <div className="UncertaintyResults-tooltip-key">{name}:</div>
              <div className="UncertaintyResults-tooltip-value">{value}</div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const medianAbundance = useMemo(() => {
    const summarized = sortedNodes
      .filter(({ id }) => nodeFilter.includes(id))
      .map(node => {
        const { name, trophicLevel, id } = node;
        const median = d3.median(results, d => d[id]);

        const color = trophicLevels.find(l => l.name === trophicLevel).color;

        const upperPercentile = d3.quantile(
          results.map(d => d[id]).sort((a, b) => a - b),
          0.95
        );
        const lowerPercentile = d3.quantile(
          results.map(d => d[id]).sort((a, b) => a - b),
          0.05
        );

        return {
          nodeId: id,
          name,
          median,
          upperPercentile,
          lowerPercentile,
          x: name,
          y: median,
          color,
          tooltip: getTooltipContent([
            { 'Median relative abundance': median.toFixed(4) },
            {
              'Relative abundance lower confidence': lowerPercentile.toFixed(4),
            },
            {
              'Relative abundance upper confidence': upperPercentile.toFixed(4),
            },
            {
              'Median actual abundance': getValueForAbundance(
                node,
                median
              ).toFixed(4),
            },
            {
              'Actual abundance lower confidence': getValueForAbundance(
                node,
                lowerPercentile
              ).toFixed(4),
            },
            {
              'Actual abundance upper confidence': getValueForAbundance(
                node,
                upperPercentile
              ).toFixed(4),
            },
          ]),
        };
      });
    return summarized;
  }, [results, nodeFilter, sortedNodes, getTooltipContent]);

  const abundanceChange = useMemo(() => {
    if (!baseMatrix) return [];

    const matrixOldAbundance = Object.fromEntries(
      Object.values(baseMatrix.nodes).map(({ id, abundance }) => [
        id,
        abundance,
      ])
    );

    const beforeAbundances = calculateNewAbundances(
      baseMatrix,
      matrixOldAbundance
    );

    let next = medianAbundance.map(bar => {
      const beforeAbundance = beforeAbundances[bar.nodeId];

      let abundancePercentChange = 0;

      if (beforeAbundance !== 0) {
        abundancePercentChange =
          (bar.median - beforeAbundance) / beforeAbundance;
      } else if (bar.median > 0) {
        abundancePercentChange = 1;
      }

      // This must be sorted for quantile to work
      const percents = results
        .map(d => {
          const result = d[bar.nodeId];
          if (beforeAbundance === 0) return beforeAbundance;
          const next = (result - beforeAbundance) / beforeAbundance;
          return next;
        })
        .sort((a, b) => a - b);

      // d3.quantile doesn't always use an item in the actual array, to do that we could use percentile
      let upperPercentile = d3.quantile(percents, 0.95);
      let lowerPercentile = d3.quantile(percents, 0.05);

      // Add simple thresholds at 100%
      if (upperPercentile > 1) {
        upperPercentile = 1;
      }
      if (lowerPercentile < -1) {
        lowerPercentile = -1;
      }

      return {
        ...bar,
        median: abundancePercentChange,
        y: abundancePercentChange,
        upperPercentile,
        lowerPercentile,
        tooltip: getTooltipContent([
          {
            'Median abundance change': `${(
              abundancePercentChange * 100
            ).toFixed(2)}%`,
          },
          {
            'Abundance change lower confidence': `${(
              lowerPercentile * 100
            ).toFixed(2)}%`,
          },
          {
            'Abundance change upper confidence': `${(
              upperPercentile * 100
            ).toFixed(2)}%`,
          },
        ]),
      };
    });

    return next;
  }, [medianAbundance, baseMatrix, results, getTooltipContent]);

  const downloadData = useCallback(() => {
    const dataDownload = medianAbundance.map(item => {
      const node = matrix.nodes[item.nodeId];
      const change = abundanceChange.find(c => c.nodeId === item.nodeId);
      let obj = {
        Name: item.name,
        'Median relative abundance': item.median,
        'Relative abundance lower confidence': item.lowerPercentile,
        'Relative abundance upper confidence': item.upperPercentile,
        'Median actual abundance': getValueForAbundance(node, item.median),
        'Actual abundance lower confidence': getValueForAbundance(
          node,
          item.lowerPercentile
        ),
        'Actual abundance upper confidence': getValueForAbundance(
          node,
          item.upperPercentile
        ),
      };

      if (change) {
        obj = {
          ...obj,
          'Percent abundance change': change.median,
          'Percent abundance lower confidence': change.lowerPercentile,
          'Percent abundance upper confidence': change.upperPercentile,
        };
      }
      return obj;
    });

    const filename = getFilename(matrix, 'uncertainty_analysis');
    let columnOrder = [
      'Name',
      'Median relative abundance',
      'Relative abundance lower confidence',
      'Relative abundance upper confidence',
      'Median actual abundance',
      'Actual abundance lower confidence',
      'Actual abundance upper confidence',
    ];

    if (abundanceChange.length) {
      columnOrder = [
        ...columnOrder,
        'Percent abundance change',
        'Percent abundance lower confidence',
        'Percent abundance upper confidence',
      ];
    }

    exportCsvData(dataDownload, filename, columnOrder);
  }, [matrix, medianAbundance, abundanceChange]);

  useResizeObserver(
    document?.getElementsByClassName('UncertaintyResults')?.[0],
    e => {
      const { contentRect } = e;
      const { width } = contentRect;
      setChartWidth(width);
    }
  );

  useEffect(() => {
    const el = document?.getElementsByClassName('UncertaintyResults')?.[0];
    setChartWidth(el.offsetWidth);
  }, []);

  return (
    <div className="UncertaintyResults">
      <div className="UncertaintyResults-header-container">
        <div className="UncertaintyResults-label">Results</div>
        <div className="UncertaintyResults-button-container">
          <ButtonNew
            buttonClasses="text-button"
            label="Download chart"
            onClick={downloadImage}
          />
          <ButtonNew
            buttonClasses="text-button"
            label="Download data"
            onClick={downloadData}
          />
        </div>
      </div>

      <div className="UncertaintyResults-container">
        <UncertaintyResultsBarChart
          matrix={matrix}
          chartWidth={chartWidth}
          medianAbundance={medianAbundance}
          abundanceChange={abundanceChange}
        />
      </div>
    </div>
  );
};

export default UncertaintyResults;
