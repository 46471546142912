import React, { useMemo } from 'react';
import * as d3 from 'd3';
import BarChart from 'components/charts/BarChart';
import NegativeBarChart from 'components/charts/NegativeBarChart';
import './UncertaintyResultsBarChart.scss';

const UncertaintyResultsBarChart = ({
  matrix,
  chartWidth,
  medianAbundance,
  abundanceChange,
}) => {
  const isExperiment = useMemo(() => !!matrix.parentId, [matrix]);

  return (
    <div id="UncertaintyResultsBarChart" className="UncertaintyResultsBarChart">
      <div>
        <div className="UncertaintyResultsBarChart-title">Median abundance</div>
        <div className="UncertaintyResultsBarChart-description">
          Median value from all runs across the specified range of connection
          strengths
        </div>
        {/* TODO We should try to combine these charts, they aren't that dissimilar */}
        <BarChart
          bars={medianAbundance}
          width={chartWidth}
          showErrorBars={true}
          yRange={[0, d3.max(medianAbundance.map(b => b.upperPercentile))]}
        />
      </div>
      {isExperiment ? (
        <div>
          <div className="UncertaintyResultsBarChart-title">
            Percent abundance change
          </div>
          <div className="UncertaintyResultsBarChart-description">
            Median degree of change in the experiment from the original matrix
            from all runs across the specified range of connection strengths
          </div>
          <NegativeBarChart
            bars={abundanceChange}
            width={chartWidth}
            showErrorBars={true}
            tickFormat={x => `${Math.round(x * 100)}%`}
            yRange={[
              d3.max(
                abundanceChange
                  .map(c => Math.abs(c.lowerPercentile))
                  .concat(abundanceChange.map(c => Math.abs(c.upperPercentile)))
              ) * -1,
              d3.max(
                abundanceChange
                  .map(c => Math.abs(c.lowerPercentile))
                  .concat(abundanceChange.map(c => Math.abs(c.upperPercentile)))
              ),
            ]}
          />
        </div>
      ) : null}
    </div>
  );
};

export default UncertaintyResultsBarChart;
