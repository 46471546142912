import React from 'react';
import text from 'constants/text';
import icons from 'img/icons';
import './MPGLink.scss';

const MPGLink = () => {
  return (
    <div className="MPGLink">
      <div className="text-interface-plain description">
        <span>
          {' '}
          {text.MPGLink}
          <div className="MPGLink-logo-container">
            <a
              href="https://mpgranch.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={icons.MPGLogo} alt="The logo for MPG Ranch"></img>
            </a>
            <a
              href="https://stamen.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={icons.stamenLogo}
                alt="The logo for Stamen design"
              ></img>
            </a>
          </div>
        </span>
      </div>
    </div>
  );
};

export default MPGLink;
