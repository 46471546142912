import {
  AUTH_LOAD_USER_DATA_REQUEST,
  AUTH_LOAD_USER_DATA_RESPONSE,
  AUTH_LOAD_USER_DATA_ERROR_RESPONSE,
  AUTH_SAVE_USER_DATA_REQUEST,
  AUTH_SAVE_USER_DATA_RESPONSE,
  AUTH_SAVE_USER_DATA_ERROR_RESPONSE,
  AUTH_SET_USER,
} from 'constants/action_names';

const DEFAULT_STATE = {
  user: null,
  userData: {},
  _loadingUserData: false,
  _loadUserDataError: null,
  _savingUserData: false,
  _saveUserDataError: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case AUTH_SET_USER:
      const newState = {
        ...state,
        user: action.user,
      };
      if (!action.user) {
        newState.userData = DEFAULT_STATE.userData;
      }
      return newState;
    case AUTH_LOAD_USER_DATA_REQUEST:
      return {
        ...state,
        userData: {},
        _loadingUserData: true,
        _loadUserDataError: null,
      };
    case AUTH_LOAD_USER_DATA_RESPONSE:
      return {
        ...state,
        userData: action.userData,
        _loadingUserData: false,
        _loadUserDataError: null,
      };
    case AUTH_LOAD_USER_DATA_ERROR_RESPONSE:
      return {
        ...state,
        userData: {},
        _loadingUserData: false,
        _loadUserDataError: action.error,
      };
    case AUTH_SAVE_USER_DATA_REQUEST:
      return {
        ...state,
        _savingUserData: true,
        _saveUserDataError: null,
      };
    case AUTH_SAVE_USER_DATA_RESPONSE:
      return {
        ...state,
        _savingUserData: false,
        _saveUserDataError: null,
      };
    case AUTH_SAVE_USER_DATA_ERROR_RESPONSE:
      return {
        ...state,
        _savingUserData: false,
        _saveUserDataError: action.error,
      };
    default:
      return state;
  }
};
