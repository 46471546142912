import moment from 'moment';
import {
  activationFunctions,
  defaultActivationFunction,
} from 'constants/nodes';
import {
  defaultAbundanceExaggeration,
  defaultCurveSteepness,
  defaultLambda,
  defaultTolerance,
} from 'constants/matrices';

// Fix locally-hosted absolute URLs to start with PUBLIC_URL. This will likely
// only matter in development.
export const fixUrl = url => {
  if (!url) return null;
  return url.indexOf('/') === 0 ? `${process.env.PUBLIC_URL}${url}` : url;
};

const updateTrophicLevels = trophicLevel => {
  switch (trophicLevel) {
    case 'Management Actions':
      return 'Anthropogenic Factors';
    default:
      return trophicLevel;
  }
};

const membershipFunctionsFromFirestore = membershipFunctions => {
  const valuesToPairs = values => {
    let pairs = [];
    for (let i = 0; i < values.length; i += 2) {
      pairs.push([values[i], values[i + 1]]);
    }
    return pairs;
  };

  return Object.fromEntries(
    Object.entries(membershipFunctions).map(([level, values]) => {
      return [level, valuesToPairs(values)];
    })
  );
};

const nodeFromFirestore = node => {
  // Set to default activationFunction if nothing is set on the node
  let activationFunction = defaultActivationFunction;
  if (
    node.activationFunction &&
    activationFunctions
      .map(({ name }) => name)
      .includes(node.activationFunction)
  ) {
    activationFunction = node.activationFunction;
  }

  let abundanceExaggeration = defaultAbundanceExaggeration;
  if (node.abundanceExaggeration)
    abundanceExaggeration = node.abundanceExaggeration;

  return {
    ...node,
    abundanceExaggeration,
    activationFunction,
    displayImageUrl: fixUrl(node.imageUrl),
    trophicLevel: updateTrophicLevels(node.trophicLevel),
    membershipFunctions: membershipFunctionsFromFirestore(
      node.membershipFunctions
    ),
  };
};

export const fromFirestore = matrix => {
  // Some FCM model parameters are exposed here. See `fcm/fcm.js` and the R code
  // in the root of this project (`fcm/FCM2_notes.R`) for details.
  let curveSteepness = defaultCurveSteepness;
  if (matrix.curveSteepness) curveSteepness = matrix.curveSteepness;

  let lambda = defaultLambda;
  if (matrix.lambda) lambda = matrix.lambda;

  let tolerance = defaultTolerance;
  if (matrix.tolerance) tolerance = matrix.tolerance;

  return {
    ...matrix,
    curveSteepness,
    lambda,
    modified: moment(matrix.modified.toDate()),
    nodes:
      matrix.nodes &&
      Object.fromEntries(
        matrix.nodes.map(node => [node.id, nodeFromFirestore(node)])
      ),
    tolerance,
  };
};

const membershipFunctionsToFirestore = membershipFunctions => {
  const valuesFromPairs = pairs => {
    return pairs.reduce((current, nextPair) => current.concat(nextPair), []);
  };

  return Object.fromEntries(
    Object.entries(membershipFunctions).map(([level, pairs]) => {
      return [level, valuesFromPairs(pairs)];
    })
  );
};

const nodeToFirestore = node => {
  const toFirestore = {
    ...node,
    membershipFunctions: membershipFunctionsToFirestore(
      node.membershipFunctions
    ),
  };
  return Object.fromEntries(
    Object.entries(toFirestore).filter(
      ([key, value]) => key !== 'displayImageUrl'
    )
  );
};

export const toFirestore = matrixToSave => {
  // Remove nodes from matrix and add as a collection
  let matrix = {
    ...matrixToSave,
    modified: matrixToSave.modified.toDate(),
  };

  matrix = Object.fromEntries(
    Object.entries(matrix).filter(([key, value]) => key !== 'nodes')
  );

  return {
    matrix,
    nodes: Object.values(matrixToSave.nodes).map(node => nodeToFirestore(node)),
  };
};
