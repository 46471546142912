import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { setCurrentFolder } from 'actions/folders';
import MatrixList from 'components/matrices/MatrixList';
import { Route } from 'react-router';
import MatrixSettingsModal from 'components/matrices/MatrixSettingsModal';
import { setGalleryTab } from 'actions/viewStates';

const FolderView = ({ matrices, experiments }) => {
  const dispatch = useDispatch();

  const galleryTab = useSelector(state => state.viewStates.lastGalleryTabOpen);

  const folders = useSelector(state => state.folders.folders);
  const folderId = useParams().folder;
  const folder = useMemo(() => folders.find(f => f.id === folderId), [
    folders,
    folderId,
  ]);

  // If we're in a folder, switch to private gallery
  // Currently public gallery has no folders
  useEffect(() => {
    if (folderId && galleryTab.name === 'public') {
      dispatch(setGalleryTab({ name: 'private', userInitiated: false }));
    }
  }, [dispatch, galleryTab, folderId]);

  useEffect(() => {
    dispatch(setCurrentFolder(folderId));
    return () => dispatch(setCurrentFolder(null));
  }, [dispatch, folderId]);

  const folderExperiments = useMemo(() => {
    return experiments.filter(e => {
      return e.folder === folderId;
    });
  }, [experiments, folderId]);

  const folderMatrices = useMemo(() => {
    return matrices.filter(m => {
      return m.folder === folderId;
    });
  }, [matrices, folderId]);

  const parentMatrices = useMemo(() => {
    return matrices.filter(m => {
      return folderExperiments.map(({ parentId }) => parentId).includes(m.id);
    });
  }, [matrices, folderExperiments]);

  const experimentChildren = useMemo(() => {
    return experiments.filter(
      e =>
        folderMatrices.map(m => m.id).includes(e.parentId) &&
        (!e.folder || e.folder === folderId)
    );
  }, [experiments, folderMatrices, folderId]);

  const dedupe = arr => {
    return [...new Set(arr.map(item => item.id))].map(id =>
      arr.find(item => item.id === id)
    );
  };

  return (
    <>
      {folder ? (
        <div>
          <MatrixList
            matrices={dedupe(folderMatrices.concat(parentMatrices))}
            experiments={dedupe(folderExperiments.concat(experimentChildren))}
            folders={[]}
            folderId={folderId}
            galleryTab={galleryTab}
          />
        </div>
      ) : null}

      <Route exact path={`/gallery/folders/:folder/:matrixId/edit`}>
        <MatrixSettingsModal
          cancelUrl={`/gallery/folders/${folderId}`}
          saveUrl={`/gallery/folders/${folderId}`}
        />
      </Route>
    </>
  );
};

export default FolderView;
