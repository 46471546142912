import firebase from '../constants/firebase';

export const get = async folderId => {
  const db = firebase.firestore();

  let querySnapshot = db
    .collection('folders')
    .where('isDeleted', '==', false)
    .where('id', '==', folderId);

  const docs = [...(await querySnapshot.get()).docs];

  return docs;
};

const listUserFolders = async ({ user, userData }) => {
  const db = firebase.firestore();

  let docs = [];

  if (userData && userData.isAdmin) {
    docs = [
      ...(
        await db
          .collection('folders')
          .where('isDeleted', '==', false)
          .get()
      ).docs,
    ];
    return docs;
  }

  if (user) {
    let userFoldersSnapshot = db
      .collection('folders')
      .where('isDeleted', '==', false)
      .where(`roles.${user.claims.user_id}`, '==', 'owner');

    docs = [...docs, ...(await userFoldersSnapshot.get()).docs];
  }

  return docs;
};

export const listUser = async (user, userData) => {
  const docs = await listUserFolders({ user, userData });
  return Promise.all(
    docs.map(d => ({
      ...d.data(),
      id: d.id,
    }))
  );
};

export const save = async (folder, user) => {
  if (!user) return;
  const db = firebase.firestore();

  // Create or update folder
  const doc = await db.collection('folders').doc(folder.id);

  doc.set(folder);
};
