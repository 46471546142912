import React from 'react';
import Spinner from 'components/Spinner';
import './ExperimentResults.scss';
import AbundanceTable from './abundanceView/AbundanceTable';
import { allStats } from 'constants/stats';
import StatsPage from './statsView/StatsPage';

const ExperimentResults = ({ experiment, matrix, view, abundanceData }) => {
  return (
    <div className="ExperimentResults">
      <div className="ExperimentResults-content">
        {experiment && matrix ? (
          view === 'abundance' ? (
            <AbundanceTable
              nodes={abundanceData}
              experimentName={experiment?.name}
            />
          ) : view === 'statistics' ? (
            <StatsPage
              allStats={allStats(experiment ? experiment : matrix)}
              experimentName={experiment.name}
            />
          ) : null
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default ExperimentResults;
