import * as FoldersConnection from 'connections/folders';
import * as FoldersTransform from 'transforms/folders';

export const get = async folderId => {
  const folders = await FoldersConnection.get(folderId);
  return folders.map(FoldersTransform.fromFirestore);
};

export const listUser = async (user, userData, loadedFolders) => {
  const folders = await FoldersConnection.listUser(
    user,
    userData,
    loadedFolders
  );
  return folders.map(FoldersTransform.fromFirestore).map(folder => ({
    ...folder,
  }));
};

export const save = async (folderToSave, user) => {
  const { folder } = FoldersTransform.toFirestore(folderToSave);
  await FoldersConnection.save(folder, user);
};
