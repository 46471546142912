import React from 'react';
import classnames from 'classnames';
import './MiniModal.scss';

const MiniModal = ({ children, className, onExit, dark }) => {
  return (
    <div className={classnames('MiniModal', className)}>
      <div className="MiniModal-outside" onClick={onExit} />
      <div className={classnames('MiniModal-content', { dark })}>
        {children}
      </div>
    </div>
  );
};

export default MiniModal;
