import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import './BuildMatrixModal.scss';
import BuildMatrixFooterButtons from './BuildMatrixFooterButtons';
import Icon from 'components/Icon';
import ButtonNew from 'components/inputs/ButtonNew';
import { trophicLevels } from 'constants/nodes';
import NodeSettingsMembershipFunctionShapeGraph from 'components/nodes/NodeSettingsMembershipFunctionShapeGraph';
import AbundanceSlider from 'components/inputs/AbundanceSlider';
import NodePill from 'components/NodePill';
import classNames from 'classnames';
import { finishEdit as finishEditMatrix } from 'actions/matrixSettings';
import { saveMatrix } from 'actions/matrices';
import { deleteConnectionsTo } from 'constants/connections';
import { deleteNode } from 'constants/matrices';

const ReadOnlyConnections = ({ node, nodes }) => {
  const isInfluenced = nodes.reduce((acc, n) => {
    const entry = Object.entries(n.influences).find(([k, v]) => k === node.id);
    if (entry) {
      const [, value] = entry;
      const connection = { from: n, to: node, by: value };
      acc = acc.concat([connection]);
    }
    return acc;
  }, []);

  const doesInfluence = Object.entries(node.influences).reduce(
    (acc, [k, v]) => {
      const connection = { from: node, to: nodes.find(n => n.id === k), by: v };
      acc = acc.concat(connection);
      return acc;
    },
    []
  );

  return (
    <div className="ReadOnlyConnections">
      <div className="ReadOnlyConnections-section text-interface-small">
        <div className="ReadOnlyConnections-description">
          {`Nodes that influence ${node.name} abundance`}
        </div>
        {isInfluenced.map((connection, i) => (
          <div
            className="ReadOnlyConnections-connection-container"
            key={`${connection.from}${connection.to}${connection.by}${i}`}
          >
            <div className="ReadOnlyConnections-connection-section">
              <NodePill node={connection.from} />{' '}
            </div>
            <div className="ReadOnlyConnections-connection-section">to </div>
            <div className="ReadOnlyConnections-connection-section">
              <NodePill node={connection.to} />{' '}
            </div>
            <div className="ReadOnlyConnections-connection-section">by </div>
            <div
              className={classNames(
                'ReadOnlyConnections-connection-section ReadOnlyConnections-value',
                {
                  'ReadOnlyConnections-positive': connection.by >= 0,
                  'ReadOnlyConnections-negative': connection.by < 0,
                }
              )}
            >
              {connection.by}
            </div>
          </div>
        ))}
      </div>
      <div className="ReadOnlyConnections-section text-interface-small">
        <div className="ReadOnlyConnections-description">
          {`Nodes that are influenced by ${node.name} abundance`}
        </div>
        {doesInfluence.map((connection, i) => (
          <div
            className="ReadOnlyConnections-connection-container"
            key={`${connection.from}${connection.to}${connection.by}${i}`}
          >
            <div className="ReadOnlyConnections-connection-section">
              <NodePill node={connection.from} />{' '}
            </div>
            <div className="ReadOnlyConnections-connection-section">to </div>
            <div className="ReadOnlyConnections-connection-section">
              <NodePill node={connection.to} />{' '}
            </div>
            <div className="ReadOnlyConnections-connection-section">by </div>
            <div
              className={classNames(
                'ReadOnlyConnections-connection-section ReadOnlyConnections-value',
                {
                  'ReadOnlyConnections-positive': connection.by >= 0,
                  'ReadOnlyConnections-negative': connection.by < 0,
                }
              )}
            >
              {connection.by}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const BuiltNode = ({
  nodes,
  currentIndex,
  setEditNodeIndex,
  editNodeIndex,
  toEdit,
  matrix,
}) => {
  const dispatch = useDispatch();

  const node = nodes[currentIndex];
  if (!node) return null;

  const { name, displayImageUrl, description, trophicLevel, fixed } = node;
  const level = trophicLevels.find(tl => tl.name === trophicLevel);

  const removeNode = () => {
    let newMatrix = deleteNode(matrix, node.id);
    newMatrix = deleteConnectionsTo(newMatrix, node.id);

    dispatch(finishEditMatrix(newMatrix));
    dispatch(saveMatrix(newMatrix.id));
  };

  return (
    <div className="BuiltNode">
      <div className="BuiltNode-header text-display-medium-500">
        <div className="BuiltNode-name-container">
          {name}
          <div className="BuiltNode-button-container">
            <ButtonNew
              buttonClasses={'icon-button'}
              icon="pencilGray"
              onClick={toEdit}
            />

            <ButtonNew
              buttonClasses={'icon-button'}
              icon="trashGray"
              onClick={removeNode}
            />
          </div>
        </div>
        <div className="BuiltNode-button-container">
          <div
            className={classNames('BuiltNode-arrow-button', {
              'BuiltNode-button-disabled': editNodeIndex === 0,
            })}
            onClick={() => setEditNodeIndex(editNodeIndex - 1)}
          >
            <Icon className="Button-icon" alt={'left'} icon={'leftNodeCaret'} />
          </div>
          <div
            className={classNames('BuiltNode-arrow-button', {
              'BuiltNode-button-disabled': editNodeIndex === nodes.length - 1,
            })}
            onClick={() => setEditNodeIndex(editNodeIndex + 1)}
          >
            <Icon
              className="Button-icon"
              alt={'left'}
              icon={'rightNodeCaret'}
            />
          </div>
        </div>
      </div>
      <div className="BuiltNode-column-container">
        <div className="BuiltNode-column">
          <div className="BuiltNode-container">
            <div className="BuiltNode-image-container">
              <img src={displayImageUrl ? displayImageUrl : null} alt="" />
            </div>

            <div className="BuiltNode-info text-interface-medium">
              {description}
            </div>
          </div>
          <div className="BuiltNode-badge-container">
            <h4
              className="TrophicLevelLabel EditTrophicLevel-button text-interface-small"
              style={{ '--color': level?.color }}
            >
              <img
                className="TrophicLevelLabelIcon"
                src={level?.icon}
                alt={level?.name}
              />
              {level?.name}
            </h4>

            <div className="BuiltNode-button text-interface-small">
              {fixed ? 'Input' : 'Output'}
            </div>
          </div>
          <div className="BuiltNode-chart-container">
            <div className="BuiltNode-abundance-container">
              <AbundanceSlider node={node} value={node?.abundance} />
            </div>
            <NodeSettingsMembershipFunctionShapeGraph
              membershipFunctions={node.membershipFunctions}
              mean={node.mean}
              width={400}
              height={150}
              readOnly={true}
              label={'Abundance'}
            />
          </div>
        </div>
        <div className="BuiltNode-column">
          <ReadOnlyConnections node={node} nodes={nodes} />
        </div>
      </div>
    </div>
  );
};

const BuildMatrixReview = ({
  matrix,
  toPrev,
  toNext,
  setEditNodeIndex,
  editNodeIndex,
  toEdit,
}) => {
  const nodes = useMemo(
    () => (matrix?.nodes ? Object.values(matrix.nodes) : []),
    [matrix]
  );

  return (
    <>
      <div className="BuildMatrixReview">
        <div className="BuildMatrixReview-container">
          <BuiltNode
            nodes={nodes}
            currentIndex={editNodeIndex}
            setEditNodeIndex={setEditNodeIndex}
            editNodeIndex={editNodeIndex}
            toEdit={toEdit}
            matrix={matrix}
          />
        </div>
      </div>
      <BuildMatrixFooterButtons
        goNext={toNext}
        goBack={toPrev}
        nextText="Finish"
      />
    </>
  );
};

export default BuildMatrixReview;
