import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const StrengthsHistogram = ({ data, type, axisText }) => {
  const svgElement = useRef(null);

  useEffect(() => {
    //remove previous svg container if it exits
    d3.select(svgElement.current)
      .selectAll('.histogram-container')
      .remove();

    const svg = d3
      .select(svgElement.current)
      .append('svg')
      .classed('histogram-container', true);

    const { width, height } = svg.node().getBoundingClientRect();
    const margin = { top: 0, right: 10, bottom: 16, left: 5 };
    const insetLeft = 2;
    const insetRight = 2;

    const yMax = d3.max(data, function(d) {
      return d.length;
    });

    const y = d3
      .scaleLinear()
      .domain([-yMax, yMax])
      .rangeRound([height - margin.bottom, margin.top]);

    const x = d3
      .scaleLinear()
      .rangeRound([margin.left, width - margin.right])
      .domain([-1, 1]);
    const xWidth = x(1) - x(0.8);

    //bottom axis (no line)
    svg
      .append('g')
      .attr('transform', `translate(${0},${height - margin.bottom + 5})`)
      .attr('class', 'histogram-axis')
      .call(
        d3
          .axisBottom(x)
          .ticks(11, '.1f')
          .tickSizeOuter(0)
          .tickSize(0)
      )
      .call(g =>
        g
          .select('.domain') //removes the line from the axis
          .remove()
      );

    //mid axis (no tick/numbers)
    svg
      .append('g')
      .attr('transform', `translate(${0},${y(0) - 1})`)
      .attr('class', 'histogram-axis')
      .call(
        d3
          .axisBottom(x)
          .ticks(11, '.1f')
          .tickSizeOuter(0)
          .tickSize(0)
      )
      .call(g =>
        g
          .selectAll('.tick text') //removes the text from the axis
          .remove()
      );

    //bars
    svg
      .selectAll('rect')
      .data(data)
      .join('rect')
      .classed('histogram-bar', true)
      .attr('x', d => x(d.x0) + insetLeft)
      .attr('y', d => y(d.length))
      .attr('height', d => y(0) - y(d.length))
      .attr('width', d =>
        Math.max(0, x(d.x1) - x(d.x0) - insetLeft - insetRight)
      )
      .call(bar =>
        bar
          .filter(d => d.x0 < 0) // neg connection strength
          .attr('dy', -5)
          .classed('negative', true)
          .attr('y', 0 + y(0))
      )
      .call(bar =>
        bar
          .filter(d => d.x0 >= 0) // positive connection strength
          .attr('dy', -5)
          .classed('positive', true)
      );

    //bar labels
    svg
      .append('g')
      .attr('class', 'histogram-bar-label')
      .selectAll('text')
      .data(data)
      .join('text')
      .attr('x', d => x(d.x0))
      .attr('y', d => y(d.length))
      .attr('dx', +xWidth / 2)
      .attr('text-anchor', 'middle')
      .text(d => d.length)
      .call(bar =>
        bar
          .filter(d => d.x0 < 0) // neg connection strength
          .attr('dy', d => y(0) - y(d.length) - 5)
          .classed('negative-label', true)
          .attr('y', 0 + y(0))
      )
      .call(bar =>
        bar
          .filter(d => d.x0 >= 0) // positive connection strength
          .attr('dy', d => +13)
          .classed('positive-label', true)
      )
      .call(text =>
        text
          .filter(d => y(0) - y(d.length) < 15) // short bars
          .filter(d => d.x0 >= 0) // positive connection strength
          .attr('dy', -5)
          .classed('histogram-bar-label-short', true)
      )
      .call(text =>
        text
          .filter(d => y(0) - y(d.length) < 15) // short bars
          .filter(d => d.x0 < 0) // neg connection strength
          .attr('dy', d => y(0) - y(d.length) + 13)
          .classed('histogram-bar-label-short', true)
      );
  }, [data, svgElement]);

  return (
    <div className="histogram-wrapper">
      <div ref={svgElement}></div>
      <div className="histogram-axis-label">{axisText}</div>
    </div>
  );
};

export default StrengthsHistogram;
