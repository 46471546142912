import {
  MATRIX_SETTINGS_START_EDIT,
  MATRIX_SETTINGS_SET_FIELD,
  MATRIX_SETTINGS_CANCEL_EDIT,
  MATRIX_SETTINGS_FINISH_EDIT,
  MATRIX_SETTINGS_VALIDATE,
  MATRIX_SETTINGS_UPDATE,
} from 'constants/action_names';
import validateMatrix from 'validations/matrices';

const initialState = {
  matrix: null,
  valid: true,
  validationMessages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MATRIX_SETTINGS_START_EDIT:
      return {
        ...state,
        matrix: action.matrix,
        valid: true,
        validationMessages: [],
      };
    case MATRIX_SETTINGS_SET_FIELD:
      return {
        ...state,
        matrix: {
          ...state.matrix,
          [action.field]: action.value,
        },
      };
    case MATRIX_SETTINGS_UPDATE:
      return {
        ...state,
        matrix: {
          ...action.matrix,
        },
      };
    case MATRIX_SETTINGS_CANCEL_EDIT:
      return { ...initialState };
    case MATRIX_SETTINGS_FINISH_EDIT:
      return { ...initialState };
    case MATRIX_SETTINGS_VALIDATE:
      const { valid, messages } = validateMatrix(state.matrix, action.user);
      return {
        ...state,
        valid,
        validationMessages: messages,
      };
    default:
      return state;
  }
};
