import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { setNodeHover } from 'actions/viewStates';
import { isNodeFixed, getValueForAbundance } from 'constants/fcm';
import { getNodeAbundanceResults } from 'constants/nodes';
import ButtonNew from './inputs/ButtonNew';
import './PlayModeWhatChanged.scss';

const NodeBar = ({ node, fixed, hoverNode, onNodeHover }) => {
  const actualAbundance = useMemo(() => {
    return getNodeAbundanceResults(node);
  }, [node]);

  const prevNodeAbundance = useMemo(
    () => getValueForAbundance(node, node?.oldAbundance),
    [node]
  );
  const nextNodeAbundance = useMemo(
    () => getValueForAbundance(node, actualAbundance),
    [node, actualAbundance]
  );

  const percentDiff = useMemo(() => {
    return Math.abs(node?.oldAbundance - actualAbundance) * 100;
  }, [node, actualAbundance]);

  return (
    <div
      className={classnames('NodeBar', {
        'fade-out': hoverNode && node.id !== hoverNode,
      })}
      onMouseMove={onNodeHover(node.id)}
    >
      <div
        className={classnames('NodeBar-image', {
          fixed,
        })}
      >
        <img alt={node?.name} src={node?.imageUrl} />
      </div>
      <div className="NodeBar-data">
        <div className="NodeBar-data-name">{node?.name}</div>
        <div className="NodeBar-data-graph">
          {percentDiff > 0.01 ? (
            <div
              className={classnames('NodeBar-data-bar', {
                positive: !fixed && actualAbundance > node?.oldAbundance,
                negative: !fixed && actualAbundance < node?.oldAbundance,
                fixed,
              })}
              style={{ width: `${percentDiff}%` }}
            />
          ) : null}
          <div
            className={classnames('NodeBar-data-numbers', {
              positive: !fixed && actualAbundance > node?.oldAbundance,
              negative: !fixed && actualAbundance < node?.oldAbundance,
              fixed,
            })}
          >
            {prevNodeAbundance} → {nextNodeAbundance}
          </div>
        </div>
      </div>
    </div>
  );
};

const PlayModeWhatChanged = ({ goBack, nodes, nodeOverrides }) => {
  const dispatch = useDispatch();

  const hoverNode = useSelector(state => state.viewStates.hoverNode);

  const onNodeHover = nodeId => e => {
    e.stopPropagation();
    if (hoverNode !== nodeId) dispatch(setNodeHover(nodeId));
  };

  const sortedNodes = nodes.sort((a, b) => {
    const actualAbundanceA = a?.fixed
      ? Number(a?.abundance)
      : Number(a?.abundanceResult);
    const oldAbundanceA = a?.oldAbundance;

    const actualAbundanceB = b?.fixed
      ? Number(b?.abundance)
      : Number(b?.abundanceResult);
    const oldAbundanceB = b?.oldAbundance;

    const diffA = oldAbundanceA - actualAbundanceA;
    const diffB = oldAbundanceB - actualAbundanceB;

    return Math.abs(diffB) - Math.abs(diffA);
  });

  const fixedNodes = useMemo(() => {
    return nodes
      .filter(n => isNodeFixed(n, nodes, nodeOverrides))
      .map(n => {
        const actualAbundance = n?.fixed
          ? Number(n?.abundance)
          : Number(n?.abundanceResult);
        return {
          name: n.name,
          prev: getValueForAbundance(n, n?.oldAbundance),
          next: getValueForAbundance(n, actualAbundance),
        };
      });
  }, [nodes, nodeOverrides]);

  return (
    <div className="PlayModeWhatChanged">
      <div className="PlayModeWhatChanged-header">
        <div className="PlayModeWhatChanged-header-title-container">
          <div className="PlayModeWhatChanged-header-title">What changed?</div>
          <ButtonNew
            buttonClasses="primary-button-small"
            label="Go back"
            iconLeft="backArrow"
            onClick={goBack}
          />
        </div>
        <div className="PlayModeWhatChanged-header-description">
          Model inputs:{' '}
          {fixedNodes.map(n => `${n.name} (${n.prev} → ${n.next})`).join(', ')}
        </div>
      </div>
      <div
        className="PlayModeWhatChanged-content"
        onMouseLeave={onNodeHover(null)}
      >
        <div className="PlayModeWhatChanged-scroll-container">
          {sortedNodes.map(node => (
            <NodeBar
              key={node.id}
              node={node}
              fixed={isNodeFixed(node, nodes, nodeOverrides)}
              hoverNode={hoverNode}
              onNodeHover={onNodeHover}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlayModeWhatChanged;
