import React, { useState } from 'react';
import ButtonNew from '../components/inputs/ButtonNew';
import './SmallScreenWarning.scss';

function SmallScreenWarning() {
  const [hidden, setHidden] = useState(false);

  return (
    <div className={'SmallScreenWarning' + (hidden ? ' hidden' : '')}>
      <div className="dialog">
        <div className="info">
          MPG Matrix works best on devices with large screens. We suggest
          visiting on a computer or tablet for the best experience.
        </div>

        <ButtonNew
          buttonClasses="primary-button-small"
          label="Okay"
          onClick={() => {
            setHidden(true);
          }}
        />
      </div>
    </div>
  );
}

export default SmallScreenWarning;
