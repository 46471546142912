import React from 'react';
import { useHistory } from 'react-router';
import Icon from 'components/Icon';
import classNames from 'classnames';
import './MenuTabs.scss';

export const Tab = ({ alt, className, icon, label, onClick, to, selected }) => {
  const history = useHistory();

  function handleClick(e) {
    e.stopPropagation();
    if (to) {
      history.push(to);
    } else {
      onClick();
    }
  }

  return (
    <button
      onClick={handleClick}
      className={classNames('Tab', className, {
        selected: selected,
        hasLabel: label !== undefined,
        hasIcon: icon !== undefined,
      })}
      data-tour={label + '-tab'}
    >
      {icon ? <Icon className="Tab-icon" alt={alt} icon={icon} /> : null}
      {label ? <div className="Tab-label">{label}</div> : null}
    </button>
  );
};
