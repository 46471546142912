import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { runUncertaintyAnalysis } from 'analyses/uncertainty';
import UncertaintyInputs from './UncertaintyInputs';
import UncertaintyResults from './UncertaintyResults';
import NodePill from 'components/NodePill';
import ModalFooter from 'components/ModalFooter';
import { SettingsControlLabel, SettingsNumberField } from 'components/Settings';
import './UncertaintyAnalysis.scss';
import { useHistory } from 'react-router';

const NodeButtons = ({
  nodes,
  nodeFilter,
  setNodeFilter,
  resetNodeFilter,
  allFromNodes,
}) => {
  const setFilter = id => {
    let nextFilter = [...nodeFilter];
    if (nextFilter.includes(id)) {
      nextFilter = nextFilter.filter(nodeId => nodeId !== id);
    } else {
      nextFilter = nextFilter.concat([id]);
    }
    setNodeFilter(nextFilter);
  };

  const onSelectImpactedNodes = useCallback(() => {
    const nextNodes = nodes
      .map(n => n.id)
      .filter(id => !allFromNodes.includes(id));
    setNodeFilter(nextNodes);
  }, [nodes, setNodeFilter, allFromNodes]);

  return (
    <div className="NodeButtons">
      <div className="NodeButtons-info text-interface-small">
        <SettingsControlLabel label="Show nodes in results" />
        <div className="NodeButtons-buttons">
          <div className="NodeButtons-selected-text">
            {nodeFilter.length} selected
          </div>
          <div className="NodeButtons-button" onClick={resetNodeFilter}>
            Select all
          </div>
          <div className="NodeButtons-button" onClick={() => setNodeFilter([])}>
            Unselect all
          </div>
          <div className="NodeButtons-button" onClick={onSelectImpactedNodes}>
            Select only impacted nodes
          </div>
        </div>
      </div>
      <div className="NodeButtons-nodes">
        {nodes.map(node => (
          <div
            key={node.id}
            className="NodeButtons-pill"
            onClick={() => setFilter(node.id)}
          >
            <NodePill node={node} disabled={!nodeFilter.includes(node.id)} />
          </div>
        ))}
      </div>
    </div>
  );
};

const UncertaintyAnalysis = ({ matrix, closeUrl }) => {
  const history = useHistory();

  const [connectionsToVary, setConnectionsToVary] = useState([]);
  const [numberOfIterations, setNumberOfIterations] = useState(1000);
  const [results, setResults] = useState([]);

  const initialNodeFilterState = useMemo(() => {
    return Object.keys(matrix?.nodes ?? {});
  }, [matrix]);

  const [nodeFilter, setNodeFilter] = useState(initialNodeFilterState);

  const resetNodeFilter = () => setNodeFilter(initialNodeFilterState);

  const runAnalysis = () => {
    setResults(
      runUncertaintyAnalysis(
        matrix,
        connectionsToVary,
        numberOfIterations,
        nodeFilter
      )
    );
  };

  const numberOfIterationsValidationMessage = useMemo(() => {
    if (numberOfIterations <= 0) return 'Please select a positive number.';
    if (numberOfIterations > 50000)
      return 'Please select a number below 50,000.';
    return null;
  }, [numberOfIterations]);

  // TODO how to use this
  const allFromNodes = useMemo(
    () => [...new Set(connectionsToVary.map(c => c?.fromNode))],
    [connectionsToVary]
  );

  return (
    <div className="UncertaintyAnalysis">
      <div className="UncertaintyAnalysis-content">
        <div className="UncertaintyAnalysis-header">Sensitivity Analysis</div>
        <div className="UncertaintyAnalysis-description">
          Choose a target node to vary its interaction strengths to see how
          sensitive your matrix outputs are to the set parameter estimates. The
          analysis runs multiple times, randomly changing the strength of each
          of the selected connections below within the given range to display
          chart summaries for the equilibrium abundances for each node.
        </div>
        <UncertaintyInputs
          matrix={matrix}
          connectionsToVary={connectionsToVary}
          onConnectionsToVaryChange={setConnectionsToVary}
        />
        <div className="UncertaintyAnalysis-bottom-border" />
        <div className="iterations-input">
          <SettingsNumberField
            value={numberOfIterations}
            onChange={setNumberOfIterations}
            label="Number of runs"
            validationMessage={numberOfIterationsValidationMessage}
            description="We recommend choosing 1000 for most sensitivity analyses for best results"
          />
        </div>
        <NodeButtons
          nodes={Object.values(matrix?.nodes ?? {})}
          setNodeFilter={setNodeFilter}
          nodeFilter={nodeFilter}
          resetNodeFilter={resetNodeFilter}
          allFromNodes={allFromNodes}
        />

        {results.length ? (
          <UncertaintyResults
            matrix={matrix}
            results={results}
            nodeFilter={nodeFilter}
          />
        ) : null}
      </div>
      <ModalFooter
        secondaryAction={() => history.push(closeUrl)}
        secondaryActionLabel={'Close'}
        primaryAction={runAnalysis}
        primaryActionDisabled={
          !connectionsToVary.length || numberOfIterationsValidationMessage
        }
        primaryActionLabel={'Run analysis'}
      />
    </div>
  );
};

export default UncertaintyAnalysis;
