import React from 'react';
import classNames from 'classnames';
import icons from 'img/icons';
import './Icon.scss';

const Icon = ({ alt, className, icon }) => (
  <img alt={alt} className={classNames('Icon', className)} src={icons[icon]} />
);

export default Icon;
