import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import anonymousEditingKeys from './anonymousEditingKeys';
import auth from './auth';
import folders from './folders';
import folderSettings from './folderSettings';
import loginPrompt from './loginPrompt';
import matrices from './matrices';
import matrixSettings from './matrixSettings';
import viewStates from './viewStates';
import modal from './modal';
import nodeSettings from './nodeSettings';
import mail from './mail';

export default history =>
  combineReducers({
    anonymousEditingKeys,
    folders,
    folderSettings,
    loginPrompt,
    matrices,
    matrixSettings,
    viewStates,
    modal,
    nodeSettings,
    auth,
    mail,
    router: connectRouter(history),
  });
