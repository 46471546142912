import { ANONYMOUS_EDITING_KEYS_ADD } from 'constants/action_names';

const DEFAULT_STATE = {
  keys: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ANONYMOUS_EDITING_KEYS_ADD:
      const newState = {
        ...state,
        keys: {
          ...state.keys,
          [action.matrixId]: action.key,
        },
      };
      return newState;
    default:
      return state;
  }
};
