import {
  NODE_SETTINGS_CONNECTION_ADD,
  NODE_SETTINGS_CONNECTION_DELETE,
  NODE_SETTINGS_CONNECTION_UPDATE,
  NODE_SETTINGS_START_EDIT,
  NODE_SETTINGS_SET_FIELD,
  NODE_SETTINGS_CANCEL_EDIT,
  NODE_SETTINGS_FINISH_EDIT,
  NODE_SETTINGS_VALIDATE,
} from 'constants/action_names';

export const addConnection = (nodeFrom, nodeTo, value) => ({
  type: NODE_SETTINGS_CONNECTION_ADD,
  nodeFrom,
  nodeTo,
  value,
});

export const deleteConnection = (nodeFrom, nodeTo) => ({
  type: NODE_SETTINGS_CONNECTION_DELETE,
  nodeFrom,
  nodeTo,
});

export const updateConnection = (oldConnection, newConnection) => ({
  type: NODE_SETTINGS_CONNECTION_UPDATE,
  oldConnection,
  newConnection,
});

export const startEdit = (matrix, nodeId) => ({
  type: NODE_SETTINGS_START_EDIT,
  matrix,
  nodeId,
});

export const setField = (field, value) => ({
  type: NODE_SETTINGS_SET_FIELD,
  field,
  value,
});

export const cancelEdit = () => ({
  type: NODE_SETTINGS_CANCEL_EDIT,
});

export const finishEdit = matrix => ({
  type: NODE_SETTINGS_FINISH_EDIT,
  matrix,
});

export const validate = user => ({
  type: NODE_SETTINGS_VALIDATE,
  user,
});
