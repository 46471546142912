import React from 'react';
import classNames from 'classnames';
import StickySideModal from './StickySideModal';
import './SettingsModal.scss';

const SettingsModal = ({ className, children, onExit }) => (
  <StickySideModal
    className={classNames('SettingsModal', className)}
    onExit={onExit}
  >
    <div className="SettingsModal-main">{children}</div>
  </StickySideModal>
);

export default SettingsModal;
