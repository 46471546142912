import camera from './camera.svg';
import connections from './connections.svg';
import connectionSquare from './connection-square.svg';
import downArrowNegative from './down-arrow-negative.svg';
import folder from './folder.svg';
import fullMembership from './full-membership.svg';
import fuzzyMembership from './fuzzy-membership.svg';
import gear from './ICON-settings.svg';
import gearWhite from './gear-white.svg';
import plus from './plus.svg';
import rightArrowNegative from './right-arrow-negative.svg';
import rightArrowPositive from './right-arrow-positive.svg';
import show from './show.svg';
import trash from './ICON-delete.svg';
import typicalDensities from './typical-densities.svg';
import upArrowPositive from './up-arrow-positive.svg';
import addicon from './addicon.svg';
import reseticon from './reseticon.svg';
import inputicon from './input-icon.svg';
import outputicon from './output-icon.svg';
import trophicAbiotic from './trophic-abiotic.svg';
import trophicAnthropogenic from './trophic-anthropogenic.svg';
import trophicPrimaryConsumers from './trophic-primary-consumers.svg';
import trophicPrimaryProducers from './trophic-primary-producers.svg';
import trophicSecondaryConsumers from './trophic-secondary-consumers.svg';
import trophicDecomposers from './trophic-decomposers.svg';
import trophicLevel from './trophic-level.svg';
import abundance from './abundance.svg';
import statistics from './statistics.svg';
import newImage from './new-image.svg';
import toolTip from './info-icon.svg';
import edit from './edit.svg';
import error from './error.svg';
import downCaret from './down-caret.svg';
import rightCaret from './right-caret.svg';
import link from './link.svg';
import slider from './sliderDrag.svg';
import backArrow from './backArrow.svg';
import leftArrow from './left-arrow.svg';
import rightArrow from './right-arrow.svg';
import inspiredClassroomLogo from './inspiredClassroomLogo.png';
import matrixLogo from './matrixLogo.svg';
import MPGLogo from './mpg-ranch-logo.png';
import stamenLogo from './stamen-logo.png';
import baseMatrix from './baseMatrix.svg';
import check from './check.svg';
import rightNodeCaret from './right-node-caret.svg';
import leftNodeCaret from './left-node-caret.svg';
import pencilGray from './pencil-gray.svg';
import trashGray from './trash-gray.svg';
import userGuideIntro0 from './user-guide-intro-0.png';
import userGuideIntro1 from './user-guide-intro-1.png';
import userGuideIntro2 from './user-guide-intro-2.png';
import userGuideIntro3 from './user-guide-intro-3.png';
import graphBar from './graph-bar.svg';
import solidBackArrow from './solid-back-arrow.svg';
import solidStatistics from './statistics-solid.svg';
import connectionsArrow from './connections-arrow.svg';
import importIcon from './import.svg';
import exportIcon from './export.svg';
import connectionsTable from './connections-table.svg';
import duplicate from './duplicate.svg';
import play from './play.svg';
import barGraph from './bar-graph.svg';
import inputOutputInfo from './input-output-info.svg';
import editMode from './edit_mode.svg';
import share from './share.svg';
import questionMark from './question_mark.svg';
import duplicateMatrix from './dupe.svg';
import comparison from './comparison.svg';
import filledStar from './filled-star.svg';
import emptyStar from './empty-star.svg';
import convert from './convert.svg';
import howToCreatingAnExperiment from './how-to-creating-an-experiment.svg';
import howToGettingStarted from './how-to-getting-started.svg';
import defaultImageBg from './default-image-bg.svg';

export default {
  camera,
  connections,
  connectionSquare,
  downArrowNegative,
  folder,
  fullMembership,
  fuzzyMembership,
  gear,
  gearWhite,
  plus,
  rightArrowNegative,
  rightArrowPositive,
  show,
  trash,
  typicalDensities,
  upArrowPositive,
  addicon,
  reseticon,
  inputicon,
  outputicon,
  trophicAbiotic,
  trophicAnthropogenic,
  trophicPrimaryConsumers,
  trophicPrimaryProducers,
  trophicSecondaryConsumers,
  trophicDecomposers,
  trophicLevel,
  abundance,
  statistics,
  newImage,
  toolTip,
  edit,
  error,
  downCaret,
  rightCaret,
  link,
  slider,
  backArrow,
  leftArrow,
  rightArrow,
  inspiredClassroomLogo,
  matrixLogo,
  baseMatrix,
  MPGLogo,
  stamenLogo,
  check,
  rightNodeCaret,
  leftNodeCaret,
  pencilGray,
  trashGray,
  userGuideIntro0,
  userGuideIntro1,
  userGuideIntro2,
  userGuideIntro3,
  graphBar,
  solidBackArrow,
  solidStatistics,
  connectionsArrow,
  importIcon,
  exportIcon,
  connectionsTable,
  duplicate,
  play,
  barGraph,
  inputOutputInfo,
  editMode,
  share,
  questionMark,
  duplicateMatrix,
  comparison,
  filledStar,
  emptyStar,
  convert,
  howToCreatingAnExperiment,
  howToGettingStarted,
  defaultImageBg,
};
