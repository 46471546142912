import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { openLoginPrompt } from 'actions/loginPrompt';
import Icon from 'components/Icon';
import './Button.scss';

const Button = ({
  alt,
  className,
  destruct,
  disabled,
  icon,
  iconLeft,
  img,
  imgLeft,
  label,
  onClick,
  requiresLogin,
  requiresLoginMessage,
  requiresLoginUrl,
  invert,
  small,
  tiny,
  to,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  function handleClick(e) {
    e.stopPropagation();
    if (requiresLogin && !user) {
      dispatch(openLoginPrompt(requiresLoginMessage, requiresLoginUrl));
      return;
    }
    if (to) {
      history.push(to);
    }
    if (onClick) {
      onClick();
    }
  }

  const displayIcon = (
    <Icon
      className={classNames('Button-icon', { 'Button-icon-left': iconLeft })}
      alt={alt}
      icon={iconLeft ? iconLeft : icon}
    />
  );
  const displayImg = (
    <img className="Button-img" alt={alt} src={imgLeft ? imgLeft : img} />
  );

  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={classNames('Button', className, {
        destruct,
        invert,
        small,
        tiny,
        hasLabel: label !== undefined,
        hasIcon: icon !== undefined,
      })}
    >
      {iconLeft ? displayIcon : null}
      {imgLeft ? displayImg : null}
      {label ? <div className="Button-label">{label}</div> : null}
      {icon ? displayIcon : null}
      {img ? displayImg : null}
    </button>
  );
};

export default Button;
