import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getExperiment, shouldFetchMatrix } from 'constants/matrices';
import { fetchMatrix } from '../actions/matrices';
import AbundanceView from 'components/abundanceView/AbundanceView';
import { StatsExperimentView, StatsMatrixView } from './statsView/StatsView';
import ButtonSet from './inputs/ButtonSet';
import ButtonNew from './inputs/ButtonNew';
import * as urls from 'constants/urls';
import './DetailsPage.scss';

const Stats = ({ matrix, isExperiment }) => {
  if (!matrix) return null;
  return (
    <div id="charts-download">
      {isExperiment ? (
        <StatsExperimentView matrix={matrix} />
      ) : (
        <StatsMatrixView matrix={matrix} />
      )}
    </div>
  );
};

// This one does have the modification sidebar
const DetailsPage = ({ matrix }) => {
  const dispatch = useDispatch();
  const { experimentId } = useParams();
  const downloadRef = useRef(null);
  const [toggle, setToggle] = useState(experimentId ? 'abundance' : 'stats');

  const matrices = useSelector(state => state.matrices.matrices);
  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);
  const experiment = getExperiment(matrices, matrix, experimentId);

  useEffect(() => {
    if (
      experimentId &&
      shouldFetchMatrix(
        experimentId,
        experiment,
        matricesLoaded,
        matricesLoading
      )
    ) {
      dispatch(fetchMatrix(experimentId));
    }
  }, [dispatch, experiment, experimentId, matricesLoaded, matricesLoading]);

  const backUrl = useMemo(() => {
    if (!experimentId && matrix) {
      return urls.matrix(matrix);
    }
    if (!!experimentId && experiment) {
      return urls.experiment(experiment);
    }
    return null;
  }, [experimentId, experiment, matrix]);

  const onDownload = () => {
    if (downloadRef.current) {
      downloadRef.current.downloadData();
    }
  };

  const options = [
    {
      label: 'Abundance',
      value: 'abundance',
      className: 'interactiveTourCreatingExperiment-14',
    },
    {
      label: 'Statistics',
      value: 'stats',
      className: 'interactiveTourCreatingExperiment-15',
    },
  ];

  return (
    <div id="details-page" className="DetailsPage">
      <div className="DetailsPage-top-bar">
        <div className="DetailsPage-top-bar-left">
          <div className="DetailsPage-header">
            {!!experimentId ? 'Compare to base matrix' : 'Statistics'}
          </div>
          {!!experimentId ? (
            <div className="DetailsPage-buttonset-container">
              <ButtonSet
                onChange={setToggle}
                value={toggle}
                options={options}
                disabled={!experimentId}
              />
            </div>
          ) : null}

          <ButtonNew
            buttonClasses="tertiary-button interactiveTourCreatingExperiment-15-2 interactiveTourCreatingExperiment-16-1"
            label="Go back"
            to={`${backUrl}`}
            iconLeft="backArrow"
          />
        </div>
        {toggle === 'abundance' ? (
          <div className="DetailsPage-top-bar-left">
            <ButtonNew
              buttonClasses="tertiary-button"
              label="Download data"
              onClick={onDownload}
              iconLeft={'importIcon'}
            />
          </div>
        ) : null}
      </div>
      <div className="DetailsPage-content">
        {toggle === 'stats' ? (
          <Stats matrix={matrix} isExperiment={!!experimentId} />
        ) : null}
        {!!experiment && toggle === 'abundance' ? (
          <AbundanceView matrix={matrix} ref={downloadRef} />
        ) : null}
      </div>
    </div>
  );
};

export default DetailsPage;
