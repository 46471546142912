import { calculateNewAbundances } from 'constants/fcm';

const getRandomNumberInRange = (min, max) => {
  let next = Math.random() * (Number(max) - Number(min)) + Number(min);
  return `${next}`;
};

/*
 * Return a copy of the given matrix with the given connections varied within
 * the specified range.
 */
const varyConnections = (matrix, connectionsToVary) => {
  const newMatrix = JSON.parse(JSON.stringify(matrix));

  connectionsToVary.forEach(connection => {
    newMatrix.nodes[connection.fromNode].influences[
      connection.toNode
    ] = getRandomNumberInRange(...connection.strengthRange);
  });
  return newMatrix;
};

export const runUncertaintyAnalysis = (
  matrix,
  connectionsToVary,
  numberOfSimulations,
  nodeFilter
) => {
  const oldAbundances = Object.fromEntries(
    Object.values(matrix.nodes).map(({ id, abundance }) => [id, abundance])
  );
  const results = [];

  for (let i = 0; i < numberOfSimulations; i++) {
    const newMatrix = varyConnections(matrix, connectionsToVary);
    let result = calculateNewAbundances(newMatrix, oldAbundances);
    if (nodeFilter) {
      result = Object.keys(result).reduce((acc, id) => {
        if (nodeFilter.includes(id)) {
          acc[id] = result[id];
        }
        return acc;
      }, {});
    }
    results.push(result);
  }

  return results;
};
