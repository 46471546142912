import {
  AUTH_LOAD_USER_DATA_REQUEST,
  AUTH_LOAD_USER_DATA_RESPONSE,
  AUTH_LOAD_USER_DATA_ERROR_RESPONSE,
  AUTH_SET_USER,
  AUTH_SAVE_USER_DATA_REQUEST,
  AUTH_SAVE_USER_DATA_RESPONSE,
  AUTH_SAVE_USER_DATA_ERROR_RESPONSE,
} from 'constants/action_names';
import { defaultUserData } from 'constants/auth';
import firebase from 'constants/firebase';
import * as UsersService from 'services/users';

export const setUser = user => {
  return dispatch => {
    dispatch({
      type: AUTH_SET_USER,
      user,
    });
  };
};

export const logout = () => {
  firebase.auth().signOut();
};

export const loadUserData = userId => {
  return (dispatch, getState) => {
    const state = getState();
    const { auth } = state;
    const requestingUser = auth.user;
    const requestingUserData = auth.userData;

    dispatch({
      type: AUTH_LOAD_USER_DATA_REQUEST,
    });

    return UsersService.get(userId, requestingUser, requestingUserData)
      .then(userData => {
        let userDataToUse = userData;
        // If there is no userData, give the user the default
        if (!userData) {
          dispatch(saveUserData(userId, defaultUserData));
          userDataToUse = defaultUserData;
        }
        dispatch({
          type: AUTH_LOAD_USER_DATA_RESPONSE,
          userData: userDataToUse,
        });
      })
      .catch(error => {
        console.warn(error);
        dispatch({
          type: AUTH_LOAD_USER_DATA_ERROR_RESPONSE,
          error,
        });
      });
  };
};

export const saveUserData = (userId, userData) => {
  return (dispatch, getState) => {
    const state = getState();
    const { auth } = state;
    const requestingUser = auth.user;
    const requestingUserData = auth.userData;

    dispatch({
      type: AUTH_SAVE_USER_DATA_REQUEST,
    });

    return UsersService.save(
      userId,
      userData,
      requestingUser,
      requestingUserData
    )
      .then(() => {
        dispatch({
          type: AUTH_SAVE_USER_DATA_RESPONSE,
        });
      })
      .catch(error => {
        console.warn(error);
        dispatch({
          type: AUTH_SAVE_USER_DATA_ERROR_RESPONSE,
          error,
        });
      });
  };
};
