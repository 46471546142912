import React, { useState } from 'react';
import './BuildMatrixModal.scss';
import classNames from 'classnames';

const BuildMatrixEditNodesProgressLine = ({
  editStep,
  buildSteps,
  setEditStep,
}) => {
  const constructEditStepName = (step, subStep) => {
    return `${step}-${subStep}`;
  };

  const deconstructEditStepName = str => {
    const [step, subStep = null] = str.split('-');
    return { step, subStep };
  };

  const currentStep = editStep?.subStep
    ? constructEditStepName(editStep.step, editStep.subStep)
    : `${editStep.step}`;

  const genericSteps = buildSteps.reduce((acc, step) => {
    const { id, subSteps } = step;
    if (subSteps) {
      acc = acc.concat(subSteps.map(s => constructEditStepName(id, s)));
    } else {
      acc.push(id);
    }
    return acc;
  }, []);

  const getTooltip = stepString => {
    let { step, subStep } = deconstructEditStepName(stepString);
    const camelCaseToText = str => {
      const regex = /(?:[a-zA-Z])(?=[A-Z])/g;
      const charMatch = str.match(regex);

      const nextStr = str
        .replace(regex, `${charMatch} `)
        .split(' ')
        .map(
          string =>
            `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`
        )
        .join(' ');

      return nextStr;
    };
    step = camelCaseToText(step);

    if (subStep) {
      subStep = camelCaseToText(subStep);
      return `${step}: ${subStep}`;
    }
    return step;
  };

  const [mousePosition, setMousePosition] = useState([]);

  return (
    <div className="BuildMatrixEditNodesProgressLine">
      {genericSteps.map(step => (
        <div
          key={step}
          title={getTooltip(step)}
          className={classNames('step', {
            selected: currentStep === step,
          })}
          onClick={() => setEditStep(deconstructEditStepName(step))}
          onMouseMove={e =>
            setMousePosition({
              tooltipText: getTooltip(step),
              mouseX: e.clientX,
              mouseY: e.clientY,
            })
          }
          onMouseLeave={() =>
            setMousePosition({
              tooltipText: null,
              mouseX: null,
              mouseY: null,
            })
          }
        />
      ))}
      {mousePosition?.tooltipText &&
      mousePosition?.mouseX &&
      mousePosition?.mouseY ? (
        <div
          className="Tooltip-text BuildMatrixEditNodesProgressLine-tooltip"
          style={{
            left: mousePosition.mouseX + 10,
            top: mousePosition.mouseY + 10,
          }}
        >
          {mousePosition?.tooltipText}
        </div>
      ) : null}
    </div>
  );
};

export default BuildMatrixEditNodesProgressLine;
