import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'firebase/auth';
import firebase from 'constants/firebase';
import { closeLoginPrompt } from 'actions/loginPrompt';
import PrivacyPolicy from 'components/About/PrivacyPolicy';
import ButtonNew from 'components/inputs/ButtonNew';
import './LoginPopoverContent.scss';
import Checkbox from './inputs/Checkbox';
import text from 'constants/text';
import { useEffect } from 'react';

const LoginPopoverContent = () => {
  const [accountVerifications, setAccountVerifications] = useState({
    isNotStudent: false,
    isNotChild: false,
  });
  const [allowLogin, setAllowLogin] = useState(false);

  const dispatch = useDispatch();
  const { message } = useSelector(state => state.loginPrompt);

  const closePrompt = () => dispatch(closeLoginPrompt());

  const handleChange = event => {
    const updatedValue = { [event.target.id]: event.target.checked };
    setAccountVerifications(accountVerifications => ({
      ...accountVerifications,
      ...updatedValue,
    }));
  };

  useEffect(() => {
    setAllowLogin(Object.values(accountVerifications).every(value => value));
  }, [accountVerifications]);

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account',
        },
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // Whether the display name should be displayed in the Sign Up page.
        requireDisplayName: true,
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: closePrompt,
    },
  };

  return (
    <div className="LoginPopoverContent">
      <div className="LoginPopoverContent-close" onClick={closePrompt}>
        &times;
      </div>
      <div className="text-interface-description Popover-section">
        {message}
      </div>
      <div className="Popover-section">
        <Checkbox
          label={text.loginModal.studentCheckbox}
          value={accountVerifications.student}
          id="isNotStudent"
          onChange={handleChange}
        />
        <Checkbox
          label={text.loginModal.ageCheckbox}
          value={accountVerifications.child}
          id="isNotChild"
          onChange={handleChange}
        />
      </div>
      <div className="Popover-section">
        <PrivacyPolicy />
      </div>
      <div className="Popover-section">
        <div className={allowLogin ? 'enabled' : 'disabled'}>
          {/* This fieldset blocks user access to the login buttons until it is enabled, even via the tab key  */}
          <fieldset disabled={!allowLogin}>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </fieldset>
        </div>
      </div>
      <ButtonNew
        buttonClasses="pill-button-selected border"
        label="Continue without signing in"
        onClick={closePrompt}
      />
    </div>
  );
};

export default LoginPopoverContent;
