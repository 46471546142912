import * as d3 from 'd3';

// These are functions used to compute various statistics for a given matrix.

//Count the nodes in a matrix
export const nodeCount = matrix => {
  const nodesList = Object.keys(matrix.nodes);
  return nodesList.length;
};

//returns a list of interaction strengths for a node if not 0 strength
const nodeInteractions = node => {
  if (node.influences) {
    const connections = Object.values(node.influences).filter(c => c !== 0);
    return connections;
  } else {
    return [];
  }
};

//Support function, return a list of all interaction strengths in a matrix
const matrixInteractions = matrix => {
  let matrixInteractions = [];
  Object.values(matrix.nodes).forEach(node => {
    matrixInteractions = matrixInteractions.concat(nodeInteractions(node));
  });
  return matrixInteractions;
};

//Count of Connections for a Node
//Dont count it if the connection strength is 0.
//Only count connections once per matrix, count it for the node that influences. e.x. A -> B  A:1 and B:0
export const connectionsCountNode = node => {
  return nodeInteractions(node).length;
};

const connectionsCountByNode = matrix => {
  const connectionsList = [];
  for (const node of Object.values(matrix.nodes)) {
    connectionsList.push(connectionsCountNode(node));
  }
  return connectionsList;
};

export const connectionsCountTotal = (matrix, hist = false) => {
  return connectionsCountByNode(matrix).reduce(
    (partialSum, a) => partialSum + a,
    0
  );
};

//Questions on Total Count of Connections in the Matrix:
//Dont count it if the connection strength is 0.
//Only count connections once
export const FreqDistConnectionCount = matrix => {
  const connectionsList = connectionsCountByNode(matrix);

  const max = connectionsList.reduce(function(a, b) {
    return Math.max(a, b);
  }, -Infinity);

  var histogram = d3
    .histogram()
    .value(d => d)
    .domain([0, max + 1])
    .thresholds(max + 1);
  var bins = histogram(connectionsList);

  return bins;
};

//Questions:
//Only count connections once
//e.x. A -> B  -- Is 0.5 per node
export const avgConnectionsPerNode = matrix => {
  return connectionsCountTotal(matrix) / nodeCount(matrix);
};

//Take absolute value before calculating, matching above function
//combine with above function?
export const stdevConnectionsPerNode = (
  matrix,
  connectionCounts = null,
  mean = null
) => {
  if (!connectionCounts) {
    connectionCounts = connectionsCountByNode(matrix);
  }
  if (!mean) {
    mean = avgConnectionsPerNode(matrix);
  }
  connectionCounts = connectionCounts.map(k => {
    return (k - mean) ** 2;
  });

  // Calculating the sum of updated array
  const sum = connectionCounts.reduce((acc, curr) => acc + curr, 0);

  // Calculating the variance
  const variance = sum / connectionCounts.length;

  // Returning the Standered deviation
  return Math.sqrt(variance);
};

//In general use Directed Connectance over Undirected
export const connectanceDirected = matrix => {
  const nodeN = nodeCount(matrix);
  const connectionsN = connectionsCountTotal(matrix);
  const connectance = connectionsN / nodeN ** 2;
  return connectance;
};

//Take absolute value before averaging
export const avgInteractionStrength = (matrix, strengths = null) => {
  if (!strengths) {
    strengths = matrixInteractions(matrix);
  }

  return (
    strengths.reduce((a, b) => Math.abs(a) + Math.abs(b), 0) / strengths.length
  );
};

//Take absolute value before calculating, matching above function
//combine with above function?
export const stdevInteractionStrength = (
  matrix,
  strengths = null,
  mean = null
) => {
  strengths = strengths ? strengths : matrixInteractions(matrix);
  mean = mean ? mean : avgInteractionStrength(matrix, strengths);

  strengths = strengths.map(k => {
    return (k - mean) ** 2;
  });

  // Calculating the sum of updated array
  const sum = strengths.reduce((acc, curr) => acc + curr, 0);

  // Calculating the variance
  const variance = sum / strengths.length;

  // Returning the Standered deviation
  return Math.sqrt(variance);
};

//Do not take absolute value of interaction strengths here
export const FreqDistInteractionStrength = (matrix, strengths = null) => {
  strengths = strengths ? strengths : matrixInteractions(matrix);

  const nBins = 10;

  const min = -1;
  const max = 1;

  var histogram = d3
    .histogram()
    .value(d => d)
    .domain([min, max])
    .thresholds(nBins);

  var bins = histogram(strengths);

  return bins;
};

//here we are looking at pair modularity for all pairs, not just pairs that are linked with a connection
const pairModularity = (matrix, pair) => {
  let mutual = 0;

  const nodeA = matrix.nodes[pair[0]];
  const nodeB = matrix.nodes[pair[1]];
  const connectionsA = Object.keys(nodeA.influences);
  const connectionsB = Object.keys(nodeB.influences);

  const setA = new Set(connectionsA);
  const setB = new Set(connectionsB);

  for (const connection of setA) {
    if (setB.has(connection)) {
      mutual += 1;
    }
  }

  const either = new Set([...setA, ...setB]).size;
  const pairModularity = mutual / either;
  return pairModularity;
};

//get list of all possible node pairs for a matrix
const nodePairs = matrix => {
  const nodesList = Object.keys(matrix.nodes);

  //creates non-repeateing pairs of nodes
  const pairs = nodesList
    .map((v, i) => nodesList.slice(i + 1).map(w => [v, w]))
    .flat();

  return pairs;
};

export const modularity = (matrix, pairs = null, nodeN = null) => {
  const modularities = [];
  for (const pair of pairs ? pairs : nodePairs(matrix)) {
    modularities.push(pairModularity(matrix, pair));
  }

  const sum = modularities.reduce((partialSum, a) => partialSum + a, 0);
  const n = nodeN ? nodeN : nodeCount(matrix);
  const modularity = sum / ((n ** 2 - n) / 2);
  return modularity;
};

//Return infinity for characteristic path length if nodes are not connected
//allow movement along both direction
//pathlength is always 1 between nodes, ignore connection strengh
//Was there something about self connections here or in modularity that was important?
//should I be getting 0 distance for self connections instead of 1? I think so. I'm not asking for those as they are not in pairs
//this is crashing on Yellowstone and Red Lake on production
export const avgPathLength = (matrix, pairs = null) => {
  pairs = pairs ? pairs : nodePairs(matrix);

  const pathLengths = [];
  const graph = createGraph(matrix);

  for (const pair of pairs) {
    const dist = findShortestPath(
      matrix,
      matrix.nodes[pair[0]].id,
      matrix.nodes[pair[1]].id,
      graph
    )['distance'];
    //if node pairs are not connected stop and return "infinity"
    if (typeof dist !== 'string') {
      pathLengths.push(dist);
    } else {
      return 'Infinity';
    }
  }

  const avgPathLength =
    pathLengths.reduce((a, b) => a + b, 0) / pathLengths.length;
  return avgPathLength;

  //dont allow searching in reverse direction.
};

// shortest path algorithims pulled from https://github.com/noamsauerutley/shortest-path/blob/master/shortestPath.js

const shortestDistanceNode = (distances, visited) => {
  let shortest = null;

  for (let node in distances) {
    let currentIsShortest =
      shortest === null || distances[node] < distances[shortest];
    if (currentIsShortest && !visited.includes(node)) {
      shortest = node;
    }
  }
  return shortest;
};

//drop connections if strenght 0
//i use nodeid the opposite of how i used it earlier here
export const createGraph = matrix => {
  let graph = {};
  for (const nodeid in matrix.nodes) {
    if (Object.hasOwnProperty.call(matrix.nodes, nodeid)) {
      const node = matrix.nodes[nodeid];
      const dist = {};
      for (const id of Object.keys(node.influences)) {
        //dont allow self-connections
        if (id !== node.id) {
          dist[id] = 1;
          //add in reverse direction connection also
          graph[id] = graph[id] || {};
          graph[id][node.id] = 1;
        }
      }
      graph[node.id] = dist;
    }
  }
  return graph;
};

//simplify this code a bunch if we dont care about interaction strength
const findShortestPath = (matrix, startNode, endNode, graph_in = null) => {
  // establish object for recording distances from the start node
  const graph = graph_in ? graph_in : createGraph(matrix);
  let distances = {};
  distances[endNode] = 'Infinity';
  distances = Object.assign(distances, graph[startNode]);

  // track paths
  let parents = { endNode: null };
  for (let child in graph[startNode]) {
    parents[child] = startNode;
  }

  // track nodes that have already been visited
  let visited = [];

  // find the nearest node
  let node = shortestDistanceNode(distances, visited);

  // for that node
  while (node) {
    // find its distance from the start node & its child nodes
    let distance = distances[node];
    let children = graph[node];
    // for each of those child nodes
    for (let child in children) {
      // make sure each child node is not the start node
      if (String(child) === String(startNode)) {
        continue;
      } else {
        // save the distance from the start node to the child node
        let newdistance = distance + children[child];
        // if there's no recorded distance from the start node to the child node in the distances object
        // or if the recorded distance is shorter than the previously stored distance from the start node to the child node
        // save the distance to the object
        // record the path
        if (!distances[child] || distances[child] > newdistance) {
          distances[child] = newdistance;
          parents[child] = node;
        }
      }
    }
    // move the node to the visited set
    visited.push(node);
    // move to the nearest neighbor node
    node = shortestDistanceNode(distances, visited);
  }

  // using the stored paths from start node to end node
  // record the shortest path
  let shortestPath = [endNode];
  let parent = parents[endNode];
  while (parent) {
    shortestPath.push(parent);
    parent = parents[parent];
  }
  shortestPath.reverse();

  // return the shortest path from start node to end node & its distance
  let results = {
    distance: distances[endNode],
    path: shortestPath,
  };
  return results;
};

//More efficent to render stats as groups if all are needed

//basic stats should be computed and stored on matrix save
export const basicStats = matrix => {
  return {
    numberNodes: nodeCount(matrix),
    numberConnections: connectionsCountTotal(matrix),
  };
};

export const advancedStats = matrix => {
  //add in some reduced processing calculations here
  return {
    connectance: connectanceDirected(matrix),
    modularity: modularity(matrix),
    characteristicPathLength: avgPathLength(matrix),
  };
};

export const connectionStats = matrix => {
  const histogram = FreqDistConnectionCount(matrix);
  const avg = avgConnectionsPerNode(matrix);
  const stdev = stdevConnectionsPerNode(matrix);
  return { histogram, avg, stdev };
};

export const interactionStrengthStats = matrix => {
  const strengths = matrixInteractions(matrix);
  const histogram = FreqDistInteractionStrength(matrix, strengths);
  const avg = avgInteractionStrength(matrix, strengths);
  const stdev = stdevInteractionStrength(matrix, strengths, avg);
  return { histogram, avg, stdev };
};

export const allStats = matrix => {
  return {
    basic: basicStats(matrix),
    advanced: advancedStats(matrix),
    connection: connectionStats(matrix),
    interactionStrength: interactionStrengthStats(matrix),
  };
};

// when to update stats - node added or removed, node restored, connection added or removed, connection strength modified
