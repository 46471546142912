import * as d3 from 'd3';
import { fcm, fuzzify } from 'fcm/fcm';

import { populationUnits } from 'constants/nodes';

/*
 * These functions provide an interface between the data structure of the app
 * (matrices, experiments, nodes, etc) and the FCM functions, which are kept
 * separate.
 */

/*
 * Turn a matrix's nodes into a 2-dimensional array representing the
 * influence matrix of the nodes.
 */
const computeMatrix = (
  inputMatrix,
  currentAbundance,
  abundanceExaggeration
) => {
  const nodes = Object.keys(inputMatrix.nodes);
  const matrix = [];

  for (let i = 0; i < nodes.length; i++) {
    for (let j = 0; j < nodes.length; j++) {
      if (j === 0) {
        matrix[i] = [];
      }

      matrix[i][j] = inputMatrix.nodes[nodes[j]].influences[nodes[i]] || 0;
    }
  }

  const abundance = nodes.map(
    node => currentAbundance[node] * abundanceExaggeration[node]
  );

  return { nodes, matrix, abundance };
};

/*
 * Use the fuzzy cognitive model to determine the resulting abundances of the
 * given matrix.
 */
export const calculateNewAbundances = (
  inputMatrix,
  currentAbundance,
  fixedNodeOverrides
) => {
  const fixedAbundance = Object.fromEntries(
    Object.entries(currentAbundance).map(([k, v]) => [k, +v])
  );

  // In some cases we exaggerate a node's abundance, grab these values here.
  //
  // NB: This is not supposed to be a "normal" case, however, it's crucial for
  // getting appropriate results with the Yellowstone Lake matrix. We assume that
  // it will similarly be useful with other matrices.
  const abundanceExaggeration = Object.fromEntries(
    Object.entries(inputMatrix.nodes).map(([k, v]) => [
      k,
      +v.abundanceExaggeration,
    ])
  );

  const { nodes, matrix, abundance } = computeMatrix(
    inputMatrix,
    fixedAbundance,
    abundanceExaggeration
  );

  let fixedNodes =
    fixedNodeOverrides && fixedNodeOverrides.length
      ? fixedNodeOverrides
      : nodes.map(nodeId => inputMatrix.nodes[nodeId].fixed);

  const fixedMatrix = matrix.map(r => r.map(d => +d));
  const abundanceVector = fcm(abundance, fixedMatrix, fixedNodes, {
    activationFunctions: nodes.map(
      nodeId => inputMatrix.nodes[nodeId].activationFunction
    ),
    curveSteepness: inputMatrix.curveSteepness,
    lambda: inputMatrix.lambda,
    tolerance: inputMatrix.tolerance,
  });
  const newAbundance = nodes.reduce(
    (acc, cur, i) => ({
      [cur]: abundanceVector[i],
      ...acc,
    }),
    {}
  );
  return newAbundance;
};

const round = (number, digits) => {
  return Math.round(number * 10 ** digits) / 10 ** digits;
};

/*
 * Get a numeric, displayable value for abundance
 */
export const getValueForAbundance = (node, abundance) => {
  const getValue = abundance => {
    if (abundance === 0.5) return node.mean;
    let scale;
    const min = node.membershipFunctions.LOW[0][0];
    const max = node.membershipFunctions.HIGH[2][0];
    const mean = node.mean ? node.mean : (min + max) / 2;

    if (abundance < 0.5) {
      scale = d3
        .scaleLinear()
        .domain([0, 0.5])
        .range([min, mean]);
    } else {
      scale = d3
        .scaleLinear()
        .domain([0.5, 1])
        .range([mean, max]);
    }

    return scale(abundance);
  };

  const precision =
    populationUnits.filter(({ name }) => name === node.populationUnit)[0]
      ?.precision ?? 0;

  return round(getValue(abundance), precision);
};

export const getFuzzyCategory = (node, abundance) => {
  const fuzzified = fuzzify(node.membershipFunctions, [abundance], {
    rescale: true,
    mean: node.mean,
  })[0];

  let fuzzy = Object.entries(fuzzified)
    .sort((a, b) => a[1] - b[1])
    .slice(-1)[0][0];

  if (fuzzy === 'LOW' && abundance < 0.05) fuzzy = 'VERY LOW';
  if (fuzzy === 'HIGH' && abundance > 0.95) fuzzy = 'VERY HIGH';

  return fuzzy;
};

/*
 * Inverse of getValueForAbundance--get an abundance value [0..1] for a
 * population value
 */
export const getAbundanceForValue = (node, value) => {
  const bisectAbundance = d3.bisector(d => getValueForAbundance(node, d)).left;
  const abundances = d3.range(0, 1.01, 0.01);
  const index = bisectAbundance(abundances, value);
  return index < abundances.length ? abundances[index] : 1;
};

// Determines whether a node is fixed or not.
export const isNodeFixed = (node, nodes, nodeOverrides) => {
  // filter out the nodes that are already set as input
  const filteredNodeOverrides = nodeOverrides.filter(
    override => !nodes.find(n => n.id === override.id)?.fixed
  );
  const hasFilteredNodeOverride = filteredNodeOverrides.length > 0;

  // If there is a single fixed node override that was originally an output, we use
  // all the set nodeOverrides, otherwise we use the filtered NodeOverrides.
  const computedNodeOverrides = hasFilteredNodeOverride
    ? nodeOverrides
    : filteredNodeOverrides;

  // If this node is inside nodeOverrides, then we set it to fixed.
  return (
    !!computedNodeOverrides.find(override => override.id === node.id) ||
    node.fixed
  );
};

export const addAbundancesToNodes = (matrix, nodeOverrides, prevNodes) => {
  // Get an array of all the nodes
  let nodes = [...Object.values(matrix.nodes)]
    // If the node has been overridden in nodeOverrides, use that node instead
    .map(
      node =>
        nodeOverrides.find(override => override.id === node.id) ||
        prevNodes.find(override => override.id === node.id) ||
        node
    );

  const originalAbundances = Object.fromEntries(
    nodes.map(({ id, abundance }) => [id, abundance])
  );

  // Generates an array of booleans in the same order of nodes
  // which dictates whether a nodeOverride has changed it to "fixed"
  // for this calculation.
  let fixedNodeOverrides = nodes.map(n => isNodeFixed(n, nodes, nodeOverrides));

  const newAbundances = calculateNewAbundances(
    matrix,
    originalAbundances,
    fixedNodeOverrides
  );
  const anyChange = Object.keys(originalAbundances).some(
    k => originalAbundances[k] !== newAbundances[k]
  );
  const anyMissingResult = nodes.some(n => n.abundanceResult === undefined);

  if (!anyChange && !anyMissingResult) return nodes;

  return nodes.map(n => ({
    ...n,
    oldAbundance:
      (nodeOverrides.find(m => m.id === n.id) || {}).oldAbundance ||
      ((prevNodes.find(override => override.id === n.id) || n).fixed
        ? n.abundance
        : n.abundanceResult),
    abundanceResult: newAbundances[n.id],
  }));
};
