import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finishEdit, cancelEdit } from 'actions/matrixSettings';
import { saveMatrix } from 'actions/matrices';
import classNames from 'classnames';
import StickySideModal from './StickySideModal';
import ConnectionsView from 'components/connectionsView/ConnectionsView';
import ModalFooter from './ModalFooter';
import './ConnectionsModal.scss';

const ConnectionsModal = ({ className, isExperiment, matrix, exitModal }) => {
  const dispatch = useDispatch();
  const editingMatrix = useSelector(state => state.matrixSettings.matrix);

  const onCancel = useCallback(() => {
    dispatch(cancelEdit());
    exitModal();
  }, [dispatch, exitModal]);

  const onSave = useCallback(() => {
    dispatch(finishEdit(editingMatrix));
    dispatch(saveMatrix(editingMatrix.id));

    exitModal();
  }, [editingMatrix, dispatch, exitModal]);

  return (
    <StickySideModal
      className={classNames('SettingsModal', className)}
      onExit={onSave}
    >
      <div className="ConnectionsModal-main">
        <ConnectionsView
          isExperiment={isExperiment}
          matrix={matrix}
          exitModal={exitModal}
          onSave={onSave}
        />
        <ModalFooter
          secondaryAction={onCancel}
          secondaryActionLabel={'Cancel'}
          primaryAction={onSave}
          primaryActionLabel={'OK'}
        />
      </div>
    </StickySideModal>
  );
};

export default ConnectionsModal;
