import {
  MATRIX_SET_NODE_HOVER,
  MATRIX_TOGGLE_CONNECTIONS_SIDEBAR,
  MATRIX_TOGGLE_MODIFICATIONS_SIDEBAR,
  GALLERY_SET_TAB,
  GALLERY_SET_SORTBY,
  NODE_SET_FIXED_WARNING,
} from 'constants/action_names';
import { sortTypes } from 'constants/sort_options';

const defaultSortType = sortTypes.find(obj => obj.by === 'order');

const INITIAL_STATE = {
  hoverNode: null,
  connectionsSidebarOpen: false,
  modificationsSidebarOpen: false,
  lastGalleryTabOpen: {
    name: 'public',
    userInitiated: false,
  },
  sortBy: {
    private: defaultSortType,
    public: defaultSortType,
    all: defaultSortType,
  },
  nodeFixedWarningSeen: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MATRIX_SET_NODE_HOVER:
      return {
        ...state,
        hoverNode: action.hoverNode,
      };
    case MATRIX_TOGGLE_CONNECTIONS_SIDEBAR:
      return {
        ...state,
        connectionsSidebarOpen: !state.connectionsSidebarOpen,
      };
    case MATRIX_TOGGLE_MODIFICATIONS_SIDEBAR:
      return {
        ...state,
        modificationsSidebarOpen: !state.modificationsSidebarOpen,
      };
    case GALLERY_SET_TAB:
      return {
        ...state,
        lastGalleryTabOpen: action.tab,
      };
    case GALLERY_SET_SORTBY: {
      const nextSortBy = {
        ...state.sortBy,
        [action.visibility]: sortTypes.find(obj => {
          return obj.by === action.sortBy;
        }),
      };
      return {
        ...state,
        sortBy: nextSortBy,
      };
    }
    case NODE_SET_FIXED_WARNING: {
      return {
        ...state,
        nodeFixedWarningSeen: action.userDeclined,
      };
    }
    default:
      return state;
  }
};
