export const toDecimalPlace = (num, maxDecimals) => {
  if (isNaN(num)) {
    console.error('First argument must be a number.');
    return num;
  }
  let currentDecimalPlaces = `${num}`.split('.')?.[1];
  if (!currentDecimalPlaces || currentDecimalPlaces.length < maxDecimals) {
    return num;
  }
  return num.toFixed(maxDecimals);
};
