import React, { useState } from 'react';
import './BuildMatrixModal.scss';
import text from 'constants/text';
import Icon from 'components/Icon';
import Button from 'components/inputs/Button';
import ButtonNew from 'components/inputs/ButtonNew';

const BuildMatrixIntro = ({ finishStep }) => {
  // Based on mocks, needs fleshing out
  const steps = [
    {
      step: 0,
      instruction: text.buildAMatrixGuide.intro['step0'],
      image: 'userGuideIntro0',
    },
    {
      step: 1,
      instruction: text.buildAMatrixGuide.intro['step1'],
      image: 'userGuideIntro1',
    },
    {
      step: 2,
      instruction: text.buildAMatrixGuide.intro['step2'],
      image: 'userGuideIntro2',
    },
    {
      step: 3,
      instruction: text.buildAMatrixGuide.intro['step3'],
      image: 'userGuideIntro3',
    },
    // Currently commented out pending images
    // {
    //   step: 4,
    //   instruction: text.buildAMatrixGuide.intro['step4'],
    // },
    // {
    //   step: 5,
    //   instruction: text.buildAMatrixGuide.intro['step5'],
    // },
  ];

  const getStep = num => {
    return steps.find(s => s.step === num);
  };

  const [introStep, setIntroStep] = useState(getStep(0));

  return (
    <div className="BuildMatrixIntro">
      <div className="BuildMatrixIntro-header">
        {introStep.step !== steps[0]?.step ? (
          <Button
            className="BuildMatrixIntro-arrows"
            icon="leftArrow"
            onClick={() => setIntroStep(getStep(introStep.step - 1))}
          />
        ) : (
          <div />
        )}
        <div className="BuildMatrixIntro-instruction">
          {introStep.instruction}
        </div>
        {introStep.step !== steps[steps.length - 1]?.step ? (
          <Button
            className="BuildMatrixIntro-arrows"
            icon="rightArrow"
            onClick={() => setIntroStep(getStep(introStep.step + 1))}
          />
        ) : (
          <div />
        )}
      </div>
      <div className="BuildMatrixIntro-body">
        <Icon className="BuildMatrixIntro-image" icon={introStep?.image} />
      </div>
      <div className="BuildMatrixIntro-footer">
        <ButtonNew
          buttonClasses="secondary-button-medium"
          onClick={finishStep}
          label="Skip"
        />
        {introStep.step === getStep(steps.length - 1)?.step ? (
          <ButtonNew
            buttonClasses="primary-button-medium"
            onClick={finishStep}
            label="Let's get started!"
          />
        ) : null}
      </div>
    </div>
  );
};

export default BuildMatrixIntro;
