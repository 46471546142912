import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { update as updateMatrix } from 'actions/matrixSettings';
import { setNodeHover } from 'actions/viewStates';
import { nodeSortFunction } from 'constants/nodes';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ConnectionCellInput from './ConnectionCellInput';
import NodePill from 'components/NodePill';
import './ConnectionsTable.scss';

const ConnectionCell = ({
  highlightedColumn,
  highlightedRow,
  influencerNode,
  influencedNode,
  setHighlightedColumn,
  setHighlightedRow,
  isExperiment,
  matrix,
}) => {
  const highlighted =
    influencerNode.id === highlightedRow ||
    influencedNode.id === highlightedColumn;

  return (
    <Cell
      key={influencedNode.id}
      className={classNames({ highlighted })}
      onMouseEnter={() => {
        setHighlightedRow(influencerNode.id);
        setHighlightedColumn(influencedNode.id);
      }}
      onMouseLeave={() => {
        setHighlightedRow(null);
        setHighlightedColumn(null);
      }}
    >
      <ConnectionCellInput
        highlightedColumn={highlightedColumn}
        highlightedRow={highlightedRow}
        influencerNode={influencerNode}
        influencedNode={influencedNode}
        matrix={matrix}
      />
    </Cell>
  );
};

const ConnectionsRow = ({
  node,
  nodes,
  highlightedColumn,
  highlightedRow,
  setHighlightedColumn,
  setHighlightedRow,
  isExperiment,
  matrix,
}) => {
  return (
    <Row>
      <Cell
        className={classNames('node-name', {
          highlighted: node.id === highlightedRow,
        })}
        onMouseEnter={() => {
          setHighlightedRow(node.id);
        }}
        onMouseLeave={() => {
          setHighlightedRow(null);
        }}
        title={node.name}
      >
        <NodePill node={node} backgroundColor="transparent" disabled={true} />
      </Cell>
      {nodes.map(n => (
        <ConnectionCell
          key={n.id}
          influencerNode={node}
          influencedNode={n}
          highlightedColumn={highlightedColumn}
          highlightedRow={highlightedRow}
          setHighlightedColumn={setHighlightedColumn}
          setHighlightedRow={setHighlightedRow}
          isExperiment={isExperiment}
          matrix={matrix}
        />
      ))}
    </Row>
  );
};

const ConnectionsTable = ({ matrix, isExperiment, setDescriptor }) => {
  const dispatch = useDispatch();

  const sortedNodes = useMemo(
    () => Object.values(matrix.nodes).sort(nodeSortFunction),
    [matrix]
  );

  const [highlightedColumn, setHighlightedColumn] = useState(null);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [mousePosition, setMousePosition] = useState([]);

  const influencerNode = matrix.nodes[highlightedRow];
  const influencedNode = matrix.nodes[highlightedColumn];
  const value = influencerNode?.influences?.[influencedNode?.id];

  useEffect(() => {
    dispatch(setNodeHover(highlightedRow));
  }, [dispatch, highlightedRow]);

  useEffect(() => {
    let descriptor = null;
    if (influencerNode && influencedNode) {
      descriptor = value
        ? `${influencerNode.name} influences ${influencedNode.name} by ${value}`
        : `${influencerNode.name} doesn't influence ${influencedNode.name}`;
    } else if (influencerNode && !influencedNode) {
      descriptor = `Nodes that ${influencerNode.name} influences`;
    } else if (!influencerNode && influencedNode) {
      descriptor = `Nodes that influence ${influencedNode.name}`;
    }

    setDescriptor(descriptor);
  }, [influencedNode, influencerNode, setDescriptor, value]);

  return (
    <div
      className="ConnectionsTable interactiveTourCreatingExperiment-10"
      onMouseMove={e => setMousePosition([e.clientX, e.clientY])}
      onMouseLeave={() => setMousePosition([])}
    >
      <StickyTable>
        <Row>
          <Cell key={'placeholder'} className="node-name"></Cell>
          {sortedNodes.map(n => (
            <Cell
              key={n.id}
              className={classNames('node-name', {
                highlighted: n.id === highlightedColumn,
              })}
              onMouseEnter={() => setHighlightedColumn(n.id)}
              onMouseLeave={() => setHighlightedColumn(null)}
            >
              <NodePill
                node={n}
                backgroundColor="transparent"
                disabled={true}
              />
            </Cell>
          ))}
        </Row>
        {sortedNodes.map(n => {
          return (
            <ConnectionsRow
              key={n.id}
              node={n}
              nodes={sortedNodes}
              highlightedColumn={highlightedColumn}
              setHighlightedColumn={setHighlightedColumn}
              highlightedRow={highlightedRow}
              setHighlightedRow={setHighlightedRow}
              isExperiment={isExperiment}
              matrix={matrix}
            />
          );
        })}
      </StickyTable>
    </div>
  );
};

export default ConnectionsTable;
