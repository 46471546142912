import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addKey } from 'actions/anonymousEditingKeys';
import { createExperiment, saveMatrix } from 'actions/matrices';
import { toggleModificationsSidebar } from 'actions/viewStates';
import { createExperiment as createNewExperiment } from 'constants/matrices';
import { useCanEdit } from 'hooks/matrices';

/*
 * Get a function that will create a new experiment based on a matrix
 */
const useNewExperiment = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const modificationsOpen = useSelector(
    state => state.viewStates.modificationsSidebarOpen
  );

  return useMemo(() => {
    return matrix => {
      const experiment = createNewExperiment(matrix, user);

      if (experiment.key) {
        // Store key so user can retrieve it
        dispatch(addKey(experiment.id, experiment.key));
      }

      dispatch(createExperiment(experiment));
      dispatch(saveMatrix(experiment.id));

      // Open the modifications sidebar if it is not open
      if (!modificationsOpen) dispatch(toggleModificationsSidebar());

      return experiment;
    };
  }, [dispatch, modificationsOpen, user]);
};

/*
 * Get a function that will create a new experiment if the given matrix or
 * experiment is not editable by the given user
 */
const useEditableExperiment = () => {
  const canEdit = useCanEdit();
  const createNewExperiment = useNewExperiment();

  return useMemo(() => {
    return matrix => {
      let experiment = matrix;

      if (!matrix.parentId || !canEdit(matrix)) {
        experiment = createNewExperiment(matrix);
      }

      return experiment;
    };
  }, [canEdit, createNewExperiment]);
};

export { useEditableExperiment, useNewExperiment };
