import React, { useCallback, useEffect, useMemo } from 'react';
import ButtonNew from 'components/inputs/ButtonNew';
import Icon from 'components/Icon';

import { useDispatch, useSelector } from 'react-redux';
import { startEdit, finishEdit, setField } from 'actions/matrixSettings';
import Dropdown from 'components/inputs/Dropdown';
import {
  convertExperimentToMatrix,
  createMatrix,
  saveMatrix,
  fetchMatrix,
  removeMatrix,
} from 'actions/matrices';
import {
  convertExperimentToMatrix as convertToMatrix,
  duplicateMatrix,
} from 'constants/matrices';
import { SettingsButtonSet } from 'components/Settings';
import { shouldFetchMatrix } from 'constants/matrices';

import './QuickSettingsPopoverContent.scss';

const QuickSettingsPopoverContent = ({ url, matrix, onCancel }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { folders } = useSelector(state => state.folders);
  const { valid: matrixIsValid } = useSelector(state => state.matrixSettings);
  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);

  useEffect(() => {
    if (shouldFetchMatrix(matrix.id, matrix, matricesLoaded, matricesLoading)) {
      dispatch(fetchMatrix(matrix.id));
    }
  }, [matrix, dispatch, matricesLoaded, matricesLoading]);

  const isOwner = useMemo(() => {
    const userId = user?.claims?.user_id;
    return matrix?.roles?.[userId] === 'owner';
  }, [user, matrix]);

  const copyUrlToClipboard = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);

  const folderOptions = useMemo(() => {
    return [{ label: 'None', value: null }].concat(
      folders.map(f => ({ label: f.name, value: f.id }))
    );
  }, [folders]);

  const getFolderName = id => {
    if (!id) return '';
    return folders.find(f => f.id === id)?.name ?? '';
  };

  const onDuplicateMatrix = useCallback(() => {
    const duplicate = duplicateMatrix(matrix, user);
    dispatch(finishEdit(matrix));
    dispatch(createMatrix(duplicate));
    dispatch(saveMatrix(duplicate.id));
    onCancel();
  }, [onCancel, dispatch, matrix, user]);

  const onFolder = useCallback(
    value => {
      const nextMatrix = { ...matrix, folder: value };
      dispatch(setField('folder', value));
      dispatch(finishEdit(nextMatrix));
      if (matrixIsValid) {
        nextMatrix.savedByUser = true;
        dispatch(saveMatrix(nextMatrix.id));
      }
      onCancel();
    },
    [dispatch, matrixIsValid, matrix, onCancel]
  );

  const onSetViewOnly = useCallback(
    value => {
      const nextMatrix = { ...matrix, isViewOnly: value === 'viewOnly' };
      dispatch(setField('isViewOnly', value === 'viewOnly'));
      dispatch(finishEdit(nextMatrix));
      if (matrixIsValid) {
        nextMatrix.savedByUser = true;
        dispatch(saveMatrix(nextMatrix.id));
      }
    },
    [dispatch, matrixIsValid, matrix]
  );

  const handleConvertToMatrix = useCallback(() => {
    const newMatrix = convertToMatrix(matrix, user);
    dispatch(finishEdit(matrix));
    dispatch(convertExperimentToMatrix(newMatrix));
    dispatch(saveMatrix(newMatrix.id));

    if (matrix?.parentId) {
      dispatch(removeMatrix(matrix.id));
    }

    onCancel();
  }, [onCancel, dispatch, user, matrix]);

  useEffect(() => {
    if (matrix) {
      dispatch(startEdit(matrix));
    }
  }, [dispatch, matrix]);

  return (
    <div className="QuickSettingsPopoverContent">
      <div className="QuickSettingsPopoverContent-row">
        <div className="QuickSettingsPopoverContent-label">Share</div>
        <div className="Popover-link" onClick={copyUrlToClipboard}>
          <div className="Popover-link-text">{url}</div>
          <Icon
            alt="shareLink"
            icon="link"
            className="Popover-link-icon icon-white"
          />
        </div>
      </div>
      <div className="QuickSettingsPopoverContent-row">
        <div className="QuickSettingsPopoverContent-label">Move to</div>
        <Dropdown
          theme="dark"
          disabled={!folders.length || !matrix.nodes}
          onChange={onFolder}
          options={folderOptions}
          value={matrix?.folder ? getFolderName(matrix.folder) : null}
        />
      </div>

      <div className="QuickSettingsPopoverContent-row">
        <div className="QuickSettingsPopoverContent-label">Type</div>
        <SettingsButtonSet
          disabled={!isOwner || !matrix.nodes}
          onChange={onSetViewOnly}
          options={[
            { value: 'editable', label: 'Editable' },
            { value: 'viewOnly', label: 'View-only' },
          ]}
          value={matrix?.isViewOnly ? 'viewOnly' : 'editable'}
        />
      </div>

      <div className="QuickSettingsPopoverContent-row">
        <ButtonNew
          label={'Duplicate'}
          disabled={!matrix.nodes}
          onClick={onDuplicateMatrix}
          buttonClasses="secondary-button-dark-small QuickSettingsPopoverContent-button"
          iconLeft="duplicateMatrix"
        />
      </div>

      <div className="QuickSettingsPopoverContent-row">
        <ButtonNew
          label={'Convert to base matrix'}
          disabled={!matrix.nodes}
          onClick={handleConvertToMatrix}
          buttonClasses="secondary-button-dark-small QuickSettingsPopoverContent-button"
          iconLeft="baseMatrix"
        />
      </div>
    </div>
  );
};

export default QuickSettingsPopoverContent;
