import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import MatrixMenu from 'components/menus/MatrixMenu';
import MatrixSettingsModal from 'components/matrices/MatrixSettingsModal';
import MatrixChartContainer from 'components/MatrixChartContainer';
import ExperimentSettingsModal from 'components/matrices/ExperimentSettingsModal';
import MatrixSidebar from 'components/MatrixSidebar';
import BuildMatrixModal from 'components/guide/BuildMatrixModal';
import Spinner from 'components/Spinner';
import UncertaintyModal from 'components/matrices/UncertaintyModal';
import { fetchMatrix, selectMatrix } from 'actions/matrices';
import { getMatrix, shouldFetchMatrix } from 'constants/matrices';
import DetailsPage from 'components/DetailsPage';
import './MatrixView.scss';
import ConnectionsSidebar from 'components/sidebars/ConnectionsSidebar';

const MatrixView = () => {
  const dispatch = useDispatch();
  const matrixId = useParams().matrix;

  const [viewMode, setViewMode] = useState('play');

  useEffect(() => {
    dispatch(selectMatrix(matrixId));
  }, [dispatch, matrixId]);

  const matrices = useSelector(state => state.matrices.matrices);
  const matrix = useMemo(() => getMatrix(matrices, matrixId), [
    matrixId,
    matrices,
  ]);
  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);

  useEffect(() => {
    if (shouldFetchMatrix(matrixId, matrix, matricesLoaded, matricesLoading)) {
      dispatch(fetchMatrix(matrixId));
    }
  }, [dispatch, matrix, matrixId, matricesLoaded, matricesLoading, matrices]);

  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}/new`}>
          {matrix ? <BuildMatrixModal matrix={matrix} /> : null}
        </Route>
        <Route>
          <div className="MatrixView">
            {matrix && matrix.nodes ? (
              <div className="MatrixView-Content">
                <Switch>
                  <Route path={`${path}/experiments/:experimentId`}>
                    <MatrixMenu
                      matrix={matrix}
                      isExperiment={true}
                      viewMode={viewMode}
                      setViewMode={setViewMode}
                    />
                  </Route>
                  <Route>
                    <MatrixMenu
                      matrix={matrix}
                      viewMode={viewMode}
                      setViewMode={setViewMode}
                    />
                  </Route>
                </Switch>
                <div className="MatrixView-Container">
                  <div className={'MatrixView-Main'}>
                    <div className="MatrixView-Overflow">
                      {/* Modals */}

                      <Switch>
                        {/* Primary view */}
                        <Route exact path={`${path}/experiments/:experimentId`}>
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                        </Route>

                        <Route
                          exact
                          path={`${path}/experiments/:experimentId/key/:anonymousEditingKey`}
                        >
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                        </Route>

                        <Route path={`${path}/overrides`}>
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                        </Route>

                        <Route
                          path={`${path}/experiments/:experimentId/overrides`}
                        >
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                        </Route>

                        {/* Details (stats and abundance chart) */}
                        <Route path={`${path}/details`}>
                          <DetailsPage matrix={matrix} />
                        </Route>

                        <Route
                          path={`${path}/experiments/:experimentId/details`}
                        >
                          <DetailsPage matrix={matrix} />
                        </Route>

                        {/* Uncertainty */}
                        <Route path={`${path}/uncertainty`}>
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />

                          <UncertaintyModal matrix={matrix} />
                        </Route>

                        <Route
                          path={`${path}/experiments/:experimentId/uncertainty`}
                        >
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                          <UncertaintyModal matrix={matrix} />
                        </Route>

                        {/* Edit matrix settings */}
                        <Route path={`${path}/experiments/:experimentId/edit`}>
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                          <ExperimentSettingsModal matrix={matrix} />
                        </Route>

                        <Route path={`${path}/edit`}>
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                          <MatrixSettingsModal
                            cancelUrl={`/matrices/${matrix.id}`}
                            matrix={matrix}
                            saveUrl={`/matrices/${matrix.id}`}
                          />
                        </Route>

                        {/* Default view (at bottom to avoid exact path) */}
                        <Route path={`${path}`}>
                          <MatrixChartContainer
                            matrix={matrix}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                          />
                        </Route>
                      </Switch>
                    </div>
                  </div>

                  {/* We want to show ConnectionsSidebar any time we are not looking at details */}
                  <Switch>
                    <Route path={`${path}/experiments/:experimentId/details`}>
                      {null}
                    </Route>
                    <Route path={`${path}/details`}>{null}</Route>
                    <Route path={`${path}/experiments/:experimentId`}>
                      <ConnectionsSidebar
                        matrix={matrix}
                        isPlayMode={viewMode === 'play'}
                      />
                    </Route>
                    <Route>
                      <ConnectionsSidebar
                        matrix={matrix}
                        isPlayMode={viewMode === 'play'}
                      />
                    </Route>
                  </Switch>
                </div>{' '}
              </div>
            ) : (
              <div className="MatrixView-spinner-container">
                <Spinner />
              </div>
            )}
            {matrix && matrix.nodes && viewMode === 'edit' ? (
              <Switch>
                <Route path={`${path}/experiments/:experimentId`}>
                  <MatrixSidebar matrix={matrix} />
                </Route>
                <Route>
                  <MatrixSidebar matrix={matrix} />
                </Route>
              </Switch>
            ) : null}
          </div>
        </Route>
      </Switch>
    </>
  );
};

export default MatrixView;
