import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Button from 'components/inputs/Button';
import ButtonNew from 'components/inputs/ButtonNew';
import { finishEdit } from 'actions/nodeSettings';
import { saveMatrix } from 'actions/matrices';
import { compareMatrices } from 'constants/matrices';
import classNames from 'classnames';
import * as urls from 'constants/urls';
import { useCanEdit } from 'hooks/matrices';
import './ModificationsSidebar.scss';
import NodePill from './NodePill';
import { nodeSortFunction } from 'constants/nodes';

const formatOverrideLabel = (override, matrix, experiment) => {
  const labelMapping = {
    abundance: 'Abundance',
    abundanceExaggeration: 'Abundance Exaggeration',
    activationFunction: 'Activation function',
    fixed: 'Node type',
    imageUrl: 'Image',
    mean: 'Mean',
    membership: 'Membership functions',
    name: 'Name',
    populationUnit: 'Population unit',
    trophicLevel: 'Trophic level',
    connection: 'Connection',
  };

  return labelMapping[override.type] || override.type;
};

export const formatOverride = (override, matrix, experiment) => {
  switch (override.type) {
    case 'connection':
      return {
        ...override,
        connectionDescription:
          '(' +
          (
            matrix.nodes[override.connection.from] ||
            experiment.nodes[override.connection.from]
          ).name +
          ' to ' +
          (
            matrix.nodes[override.connection.to] ||
            experiment.nodes[override.connection.to]
          ).name +
          ')',
      };
    case 'fixed':
      return {
        to: override.to ? 'Input' : 'Output',
        from: override.from ? 'Input' : 'Output',
      };
    case 'imageUrl':
      return {
        to: <img alt="" src={override.to} />,
        from: <img alt="" src={override.from} />,
      };
    default:
      return override;
  }
};

const OverridesListElement = ({ children, editable, node, overrides }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isNew = overrides.length > 0 ? overrides[0].type === 'new' : false;
  const isDel = overrides.length > 0 ? overrides[0].type === 'removed' : false;

  return (
    <React.Fragment>
      <li className={classNames('OverridesListElement', { deleted: isDel })}>
        <div className="OverridesListElement-title">
          <NodePill node={node} backgroundColor="transparent" />
          {isNew ? (
            <div className="OverridesListElement-new">New Node</div>
          ) : null}
        </div>
        <div className="OverridesListElement-content">
          <div className="OverridesListElement-element">
            <div className="OverridesListElement-details"></div>
            <div className="OverridesListElement-override-details">
              {editable && isNew ? (
                <Button
                  className="modifications-button"
                  iconLeft="trash"
                  label="Remove New Node"
                  onClick={() => {
                    dispatch(finishEdit(overrides[0].revert));
                    dispatch(saveMatrix(overrides[0].revert.id));
                  }}
                />
              ) : editable && isDel ? (
                <Button
                  className="modifications-button"
                  iconLeft="reseticon"
                  label="Restore Deleted Node"
                  onClick={() => {
                    dispatch(finishEdit(overrides[0].revert));
                    dispatch(saveMatrix(overrides[0].revert.id));
                    history.push(urls.experiment(overrides[0].revert));
                  }}
                />
              ) : null}
            </div>
          </div>
          {!isNew && !isDel && overrides.length > 0 ? (
            <div className="OverridesListElement-overrides">{children}</div>
          ) : null}
        </div>
      </li>
    </React.Fragment>
  );
};

const OverrideElement = ({ editable, override, experiment, matrix }) => {
  const dispatch = useDispatch();

  return (
    <div className="OverrideElement">
      <div className="OverrideElement-property">
        {formatOverrideLabel(override, matrix, experiment)}
      </div>
      <div className="OverrideElement-breadcrumbs">
        {override.type !== 'new' && override.type !== 'removed' ? (
          <div className="OverrideElement-description">
            <div className="OverrideElement-connection">
              {
                formatOverride(override, matrix, experiment)
                  .connectionDescription
              }
            </div>
            <div className="OverrideElement-fromToArrow">
              <div className="OverrideElement-from">
                {formatOverride(override, matrix, experiment).from}
              </div>
              <div className="OverrideElement-arrow">→</div>
              <div className="OverrideElement-to">
                {formatOverride(override, matrix, experiment).to}
              </div>
            </div>
            <div className="OverrideElement-units">
              {formatOverride(override, matrix, experiment).unit}
            </div>
          </div>
        ) : null}
      </div>
      <div className="OverrideElement-actions">
        {editable ? (
          <ButtonNew
            buttonClasses="icon-button-extra-small"
            icon="trash"
            onClick={() => {
              dispatch(finishEdit(override.revert));
              dispatch(saveMatrix(experiment.id));
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const ModificationsSidebar = ({ experiment, matrix }) => {
  let allNodes = { ...matrix.nodes, ...experiment.nodes };

  const overrides = compareMatrices(matrix, experiment);
  const canEdit = useCanEdit();
  const editable = canEdit(experiment);

  // Sorts the nodes by trophic level
  const nodesSorted = Object.values(allNodes)
    .filter(node => overrides[node.id].length > 0)
    .sort((a, b) => nodeSortFunction(a, b));

  return (
    <div className="ModificationsSidebar">
      <ul className="ModificationsSidebar-nodeList">
        {nodesSorted.map(node => (
          <OverridesListElement
            key={node.id}
            editable={editable}
            node={node}
            overrides={overrides[node.id]}
          >
            {overrides[node.id].map((override, i) => (
              <OverrideElement
                key={i}
                editable={editable}
                override={override}
                experiment={experiment}
                matrix={matrix}
              />
            ))}
          </OverridesListElement>
        ))}
      </ul>
    </div>
  );
};

export default ModificationsSidebar;
