import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setField } from 'actions/nodeSettings';
import { rescale, descale, minmax } from 'fcm/fcm.js';
import * as d3 from 'd3';
import './NoteSettingsSectionMembershipFunctionShapes.scss';

const MembershipFunctionShapeGraph = ({
  membershipFunctions,
  mean,
  readOnly,
  width,
  height,
}) => {
  const PADDING = 20;
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(false);
  const { min, max } = minmax(membershipFunctions);

  const scaled = rescale(membershipFunctions, mean);
  let modifiedScaled = rescale(membershipFunctions, mean);

  if (dragging) {
    modifiedScaled[dragging.category][dragging.index] = [
      dragging.category === 'MOD' && dragging.index === 0
        ? Math.min(
            scaled[dragging.category][1][0],
            Math.max(
              scaled['LOW'][0][0],
              scaled[dragging.category][dragging.index][0] + dragging.dx
            )
          )
        : dragging.index === 0
        ? Math.min(
            scaled[dragging.category][1][0],
            scaled[dragging.category][dragging.index][0] + dragging.dx
          )
        : dragging.category === 'MOD' && dragging.index === 2
        ? Math.max(
            scaled[dragging.category][1][0],
            Math.min(
              scaled['HIGH'][2][0],
              scaled[dragging.category][dragging.index][0] + dragging.dx
            )
          )
        : dragging.index === 1
        ? Math.min(
            scaled[dragging.category][2][0],
            Math.max(
              scaled[dragging.category][0][0],
              scaled[dragging.category][dragging.index][0] + dragging.dx
            )
          )
        : Math.max(
            scaled[dragging.category][1][0],
            scaled[dragging.category][dragging.index][0] + dragging.dx
          ),
      (dragging.category === 'LOW' && dragging.index === 1) ||
      (dragging.category === 'HIGH' && dragging.index === 1)
        ? Math.min(
            1,
            Math.max(
              0,
              scaled[dragging.category][dragging.index][1] + dragging.dy
            )
          )
        : scaled[dragging.category][dragging.index][1],
    ];
  }

  const scale = v => {
    return v < 0.5
      ? v * 2 * mean + (1 - v * 2) * min
      : (v - 0.5) * 2 * max + (1 - (v - 0.5) * 2) * mean;
  };

  const xscale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([PADDING, width - PADDING]);

  const yscale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([height - PADDING * 2, PADDING]);

  const line = d3
    .line()
    .x(v => xscale(v[0]))
    .y(v => yscale(v[1]));

  const startDrag = (e, category, index) => {
    setDragging({ category, index, x: e.screenX, y: e.screenY, dx: 0, dy: 0 });
  };

  const endDrag = e => {
    if (readOnly) return;
    if (!dragging) {
      return;
    }

    let descaled = descale(modifiedScaled, mean, min, max);

    dispatch(setField('membershipFunctions', descaled));

    setDragging(false);
  };

  const drag = e => {
    if (readOnly) return;
    if (!dragging) {
      return;
    }

    let dx = xscale.invert(e.screenX) - xscale.invert(dragging.x);
    let dy = yscale.invert(e.screenY) - yscale.invert(dragging.y);

    setDragging({ ...dragging, dx, dy });
  };

  return (
    <div className="NodeSettingsSectionMembershipFunctions">
      <div className="legend" style={{ width }}>
        <div className="LOW">Fuzzy low</div>
        <div className="MOD">Fuzzy moderate</div>
        <div className="HIGH">Fuzzy high</div>
      </div>
      <svg
        width={width}
        height={height}
        className="MembershipFunctionShapeGraph"
        onMouseUp={endDrag}
        onMouseMove={drag}
        onMouseLeave={endDrag}
      >
        <g className="legend">
          {[0, 0.25, 0.5, 0.75, 1].map(v => (
            <line
              key={v}
              x1={xscale(v)}
              x2={xscale(v)}
              y1={PADDING}
              y2={height - PADDING * 2}
            />
          ))}
          <line
            x1={PADDING}
            x2={width - PADDING}
            y1={yscale(0)}
            y2={yscale(0)}
          />
          <line
            x1={PADDING}
            x2={width - PADDING}
            y1={yscale(1)}
            y2={yscale(1)}
          />
        </g>

        {Object.keys(modifiedScaled).map(category => {
          const max = scale(modifiedScaled['HIGH'][2][0]);
          return (
            <g className={category} key={category}>
              <path d={line(modifiedScaled[category])} />
              {modifiedScaled[category].map((v, i) => (
                <g className="point" key={i}>
                  <circle
                    cx={xscale(v[0])}
                    cy={yscale(v[1])}
                    r={4}
                    onMouseDown={e => {
                      if (readOnly) return;
                      if (category === 'HIGH' && i === 2) return;
                      if (category === 'LOW' && i === 0) return;
                      startDrag(e, category, i);
                    }}
                  />
                  <text
                    x={xscale(v[0])}
                    y={yscale(v[1]) + 6}
                    dx={v[0] < 0.5 ? 10 : -10}
                    dy={v[1] < 0.5 ? -10 : 10}
                    textAnchor={v[0] < 0.5 ? 'start' : 'end'}
                  >
                    {scale(v[0]).toFixed(max > 10 ? 1 : 2)}
                  </text>
                </g>
              ))}
            </g>
          );
        })}
        <g className="xaxis">
          <text x={xscale(0)} y={height - 20} textAnchor="middle">
            {min}
          </text>
          <text x={xscale(0.5)} y={height - 20} textAnchor="middle">
            {mean}
          </text>
          <text x={xscale(1)} y={height - 20} textAnchor="middle">
            {max}
          </text>
        </g>
        <g className="xaxis">
          <text x={xscale(0)} y={height - 2} textAnchor="middle">
            {'Min'}
          </text>
          <text x={xscale(0.5)} y={height - 2} textAnchor="middle">
            {'Mode'}
          </text>
          <text x={xscale(1)} y={height - 2} textAnchor="middle">
            {'Max'}
          </text>
        </g>
      </svg>
    </div>
  );
};

export default MembershipFunctionShapeGraph;
