import React from 'react';
import TextField from './TextField';

const NumberField = ({
  disabled,
  isFocused,
  label,
  onBlur,
  onChange,
  placeholder,
  step,
  value,
  validationMessage,
  ...restProps
}) => (
  <TextField
    className="NumberField text-interface-medium"
    disabled={disabled}
    isFocused={isFocused}
    label={label}
    onBlur={inputValue => (onBlur ? onBlur(inputValue) : null)}
    onChange={inputValue => onChange(inputValue)}
    placeholder={placeholder}
    type="number"
    step={step}
    value={value}
    validationMessage={validationMessage}
    {...restProps}
  />
);

export default NumberField;
