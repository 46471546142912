import * as d3 from 'd3';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import slugify from 'slugify';

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: false,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  showColumnHeaders: true,
};

export const getFilename = (matrix, suffix) => {
  const date = d3.timeFormat('%Y%m%d')(new Date());
  return `matrix_export_${date}_${slugify(matrix.name).slice(0, 50)}_${suffix}`;
};

export const exportCsvData = (arr, filename, columnOrder) => {
  const options = {
    ...csvOptions,
    filename,
    ...(columnOrder && { columnHeaders: columnOrder }),
  };

  const csvConfig = mkConfig(options);

  const csv = generateCsv(csvConfig)(arr);

  return download(csvConfig)(csv);
};
