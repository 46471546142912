import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setField } from 'actions/matrixSettings';
import {
  Settings,
  SettingsTextField,
  SettingsViewOnlyText,
  SettingsImage,
  SettingsDescription,
  SettingsButtonSet,
  SettingsNumberField,
} from 'components/Settings';
import MatrixDeletePopoverWrapper from 'components/matrices/MatrixDeletePopoverWrapper';
import { getMatrix, getExperiments } from 'constants/matrices';
import { useValidateMatix } from 'hooks/matrices';
import './MatrixSettingsSection.scss';

const MatrixAdvancedSettings = ({ matrix }) => {
  const dispatch = useDispatch();
  const { validationMessages } = useSelector(state => state.matrixSettings);
  const validateMatrix = useValidateMatix();

  useEffect(() => {
    validateMatrix();
  }, [matrix, validateMatrix]);

  return (
    <div className="MatrixSettings">
      <div className="MatrixSettings-row">
        <SettingsNumberField
          label="Curve"
          onChange={value => dispatch(setField('curveSteepness', value))}
          validationMessage={validationMessages.curveSteepness}
          step="0.1"
          value={matrix.curveSteepness}
          description="Curve steepness affects the steepness of the curve of the activation functions for each node in this matrix. (Default recommendation: 1)"
        />
      </div>
      <div className="MatrixSettings-row">
        <SettingsNumberField
          label="Created Lambda"
          onChange={value => dispatch(setField('lambda', value))}
          validationMessage={validationMessages.lambda}
          step="0.01"
          value={matrix.lambda}
          description="Lambda is a relaxation parameter that speeds convergence of the fuzzy cognitive map. (Default recommendation: 0.5)"
        />
      </div>
      <div className="MatrixSettings-row">
        <SettingsNumberField
          label="Tolerance"
          onChange={value => dispatch(setField('tolerance', value))}
          validationMessage={validationMessages.tolerance}
          step="0.001"
          value={matrix.tolerance}
          description="Tolerance is the value used to determine whether a node’s relative abundance has changed from one iteration to the next. Smaller values will make the model run for more iterations as more nodes will appear to have changed in an iteration. (Default recommendation: 0.001)"
        />
      </div>
    </div>
  );
};

const MatrixBasicSettings = ({ matrix, base }) => {
  const dispatch = useDispatch();
  const { validationMessages } = useSelector(state => state.matrixSettings);
  const { userData, user } = useSelector(state => state.auth);

  const { matrices } = useSelector(state => state.matrices);

  const isOwner = useMemo(() => {
    const userId = user?.claims?.user_id;
    return matrix?.roles?.[userId] === 'owner';
  }, [user, matrix]);

  const canChangeIsPublic = (matrix, matrices, userData) => {
    if (!userData.isAdmin) return false;

    // If parent matrix is not public, no changing this experiment's publicity
    if (matrix.parentId && !getMatrix(matrices, matrix.parentId)?.isPublic) {
      return false;
    }

    // If matrix has any public children, no changing this matrix's publicity
    // (would leave some public children with a private parent.
    if (getExperiments(matrices, matrix).some(m => m.isPublic)) {
      return false;
    }

    return true;
  };

  const validateMatrix = useValidateMatix();

  useEffect(() => {
    validateMatrix();
  }, [matrix, validateMatrix]);

  return (
    <div className="MatrixSettings">
      <div className="MatrixSettings-row">
        <div className="MatrixSettings-grow">
          <SettingsTextField
            label={`${!!matrix?.parentId ? 'Experiment' : 'Matrix'} name`}
            onChange={value => dispatch(setField('name', value))}
            placeholder="Enter a name"
            validationMessage={validationMessages.name}
            value={matrix.name}
          />
        </div>
        <div className="MatrixSettings-shrink">
          <SettingsViewOnlyText label="Created by" text={user?.claims?.name} />
        </div>
      </div>
      {base ? (
        <div className="MatrixSettings-row">
          <SettingsViewOnlyText label="Base matrix" text={base?.name} />
        </div>
      ) : null}
      <div className="MatrixSettings-row">
        <SettingsImage
          disabled={!user}
          onChange={value => dispatch(setField('imageUrl', value))}
          value={matrix.imageUrl}
          description="Recommended photo size is no less than 450 x 300px"
        />
      </div>
      <div className="MatrixSettings-row">
        <SettingsDescription
          label="Description"
          onChange={value => dispatch(setField('description', value))}
          value={matrix.description}
          validationMessage={validationMessages.description}
          placeholder={`Add a short description of your ${
            matrix.parentId !== null ? 'experiment' : 'matrix'
          }.`}
        />
      </div>
      <div className="MatrixSettings-row">
        <div className="MatrixSettings-row MatrixSettings-half">
          <SettingsButtonSet
            disabled={!isOwner}
            label="Type"
            onChange={value => {
              dispatch(setField('isViewOnly', value === 'viewOnly'));
            }}
            options={[
              { value: 'editable', label: 'Editable' },
              { value: 'viewOnly', label: 'View-only' },
            ]}
            value={matrix?.isViewOnly ? 'viewOnly' : 'editable'}
            description={
              'View-only files cannot be edited unless you turn this quality off in the gallery view though users may still duplicate the file'
            }
          />
        </div>
        {canChangeIsPublic(matrix, matrices, userData) ? (
          <div className="MatrixSettings-row MatrixSettings-half">
            <SettingsButtonSet
              disabled={!canChangeIsPublic(matrix, matrices, userData)}
              label="Location"
              onChange={value => {
                dispatch(setField('isPublic', value === 'yes'));
              }}
              options={[
                { value: 'yes', label: 'Public' },
                { value: 'no', label: 'Private' },
              ]}
              value={matrix.isPublic ? 'yes' : 'no'}
              description={
                'Public matrices are available in the public gallery to all users.'
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MatrixFooter = () => {
  return (
    <div>
      Not sure which settings to choose? Learn more about how the MPG matrix
      works in our{' '}
      <a
        href="https://docs.google.com/document/d/1HGGWkx9PZrsdBR3CNIeZvDfOc0IhHvV7jFi56v4PGjg/edit"
        target="_blank"
      >
        FAQs
      </a>
    </div>
  );
};

const MatrixSettingsSection = ({
  className,
  matrix,
  base,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [settingsState, setSettingsState] = useState('basic');

  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);

  const menuOptions = [
    {
      label: 'Basic',
      value: 'basic',
      content: (
        <MatrixBasicSettings
          className={className}
          matrix={matrix}
          base={base}
          onSave={onSave}
          onCancel={onCancel}
        />
      ),
    },
    {
      label: 'Advanced',
      value: 'advanced',
      content: <MatrixAdvancedSettings matrix={matrix} />,
    },
  ];

  return (
    <Settings
      menuOptions={menuOptions}
      setMenuOption={setSettingsState}
      activeMenuOption={settingsState}
      header={`${!!matrix?.parentId ? 'Experiment' : 'Matrix'} settings`}
      footer={<MatrixFooter />}
      deletePopoverTitle={`Delete ${
        matrix?.parentId ? 'experiment' : 'matrix'
      }`}
      deletePopoverContent={
        <MatrixDeletePopoverWrapper
          matrixId={matrix.id}
          onDelete={() => {
            setDeletePopoverOpen(false);
            onDelete();
          }}
          onCancel={() => setDeletePopoverOpen(false)}
        />
      }
      deletePopoverOpen={deletePopoverOpen}
      setDeletePopoverOpen={setDeletePopoverOpen}
    />
  );
};

export default MatrixSettingsSection;
