import React from 'react';
import classNames from 'classnames';
import './Menu.scss';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import * as urls from 'constants/urls';

export const MatrixMenuName = ({ baseMatrix, matrixName, experimentName }) => {
  const baseMatrixUrl = baseMatrix ? urls.matrix(baseMatrix) : null;
  return (
    <div className="Menu-name interactiveTourCreatingExperiment-2">
      {experimentName ? (
        <Link to={baseMatrixUrl} className="Menu-name-secondary">
          {matrixName}
        </Link>
      ) : (
        <div className="Menu-name-primary">{matrixName}</div>
      )}

      {experimentName ? (
        <div className="Menu-caret-container">
          <Icon alt={'rightCaret'} icon={'rightCaret'} className="Menu-caret" />
          <div className="Menu-name-primary">{experimentName}</div>
        </div>
      ) : null}
    </div>
  );
};

export const GalleryMenuName = ({ folderName, onClick }) => {
  return (
    <div className="Menu-name">
      {folderName ? (
        onClick ? (
          <div onClick={onClick} className="Menu-name-secondary">
            Matrix Gallery
          </div>
        ) : (
          <Link to={urls.gallery()} className="Menu-name-secondary">
            Matrix Gallery
          </Link>
        )
      ) : (
        <div className="Menu-name-primary">Matrix Gallery</div>
      )}

      {folderName ? (
        <div className="Menu-caret-container">
          <Icon alt={'rightCaret'} icon={'rightCaret'} className="Menu-caret" />
          <div className="Menu-name-primary">{folderName}</div>
        </div>
      ) : null}
    </div>
  );
};

export const MenuDescription = ({ description }) => (
  <div className="Menu-description">{description}</div>
);

export const MenuText = ({ children }) => (
  <div className="Menu-text">{children}</div>
);

export const MenuActions = ({ children }) => (
  <div className="Menu-actions">{children}</div>
);

export const Menu = ({ children, className }) => (
  <div className={classNames('Menu', className)}>{children}</div>
);

export const MenuSection = ({ children }) => (
  <div className="Menu-section">{children}</div>
);
