import React from 'react';
import InputField from 'components/inputs/InputField';
import './TextArea.scss';

const TextArea = ({
  label,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  validationMessage,
  ...rest
}) => (
  <InputField label={label} validationMessage={validationMessage}>
    <textarea
      className="TextArea text-interface-medium"
      onBlur={e => (onBlur ? onBlur(e.target.value) : null)}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      type={type}
      value={value == null ? '' : value}
      {...rest}
    />
  </InputField>
);

export default TextArea;
