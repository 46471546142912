import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import {
  getExperiment,
  duplicateMatrix,
  convertExperimentToMatrix as convertToMatrix,
} from 'constants/matrices';
import MatrixErrors from 'components/MatrixErrors';
import {
  Menu,
  MenuActions,
  MatrixMenuName,
  MenuSection,
  MenuText,
} from './Menu';
import './MatrixMenu.scss';
import { useCanEdit } from 'hooks/matrices';
import Popover from '../Popover';
import Icon from '../Icon';
import ButtonNew from 'components/inputs/ButtonNew';
import classNames from 'classnames';
import { useNewExperiment } from 'hooks/experiments';
import { finishEdit } from 'actions/matrixSettings';
import {
  createMatrix,
  saveMatrix,
  convertExperimentToMatrix,
  removeMatrix,
} from 'actions/matrices';

const GenericPopoverContent = ({
  text,
  primaryAction,
  primaryActionLabel,
  secondaryAction,
  secondaryActionLabel,
}) => {
  return (
    <div className="MatrixMenu-GenericPopoverContent">
      <div className="Popover-section">{text}</div>

      <div className="MatrixMenu-GenericPopoverContent-buttons-container">
        <ButtonNew
          buttonClasses="secondary-button-dark-small"
          onClick={secondaryAction}
          label={secondaryActionLabel}
        />

        <ButtonNew
          label={primaryActionLabel}
          onClick={primaryAction}
          buttonClasses="primary-button-small interactiveTourCreatingExperiment-0-1"
        />
      </div>
    </div>
  );
};

const SharePopoverContent = ({ url, onCancel, isExperiment, noEdit }) => {
  const copyUrlToClipboard = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);

  const matrixOrExperiment = isExperiment ? 'experiment' : 'matrix';

  const shareModalText = noEdit
    ? `Copy a link of this ${matrixOrExperiment} for anyone to view.`
    : `Copy a link of this ${matrixOrExperiment} for anyone to view. Any changes you save to
  this ${matrixOrExperiment} will update in this link.`;

  return (
    <div className="MatrixMenu-GenericPopoverContent">
      <div className="Popover-section">{shareModalText}</div>
      <div className="Popover-section">
        <div className="Popover-link" onClick={copyUrlToClipboard}>
          <div className="Popover-link-text">{url}</div>
          <Icon
            alt="shareLink"
            icon="link"
            className="Popover-link-icon icon-white"
          />
        </div>
      </div>
      <div className="MatrixMenu-GenericPopoverContent-buttons-container">
        <ButtonNew
          buttonClasses="secondary-button-dark-small"
          onClick={onCancel}
          label={'Cancel'}
        />

        <ButtonNew
          label={'Copy'}
          onClick={copyUrlToClipboard}
          buttonClasses="primary-button-small"
        />
      </div>
    </div>
  );
};

const MatrixMenu = ({ matrix, isExperiment, viewMode, setViewMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getNewExperiment = useNewExperiment();
  let { url } = useRouteMatch();
  const { experimentId } = useParams();
  const { user } = useSelector(state => state.auth);
  const saving = useSelector(state => state.matrices.saving);
  const [isSaving, setIsSaving] = useState(false);

  // Keep badge up for 3 seconds on save
  useEffect(() => {
    if (saving) {
      setIsSaving(true);
      setTimeout(() => setIsSaving(false), 3000);
    }
  }, [saving]);

  const shareRef = useRef(null);
  const duplicateRef = useRef(null);
  const experimentRef = useRef(null);
  const convertRef = useRef(null);

  const [openPopovers, setOpenPopovers] = useState({
    share: false,
    duplicate: false,
    experiment: false,
    convert: false,
  });

  const switchPopovers = useCallback((key, value) => {
    let initialState = {
      share: false,
      duplicate: false,
      experiment: false,
      convert: false,
    };

    setOpenPopovers({
      ...initialState,
      [key]: value,
    });
  }, []);

  const { matrices } = useSelector(state => state.matrices);
  const experiment = getExperiment(matrices, matrix, experimentId);
  const { errors } = useSelector(state => state.matrices);

  const canEdit = useCanEdit();
  const editable = canEdit(isExperiment ? experiment : matrix);

  const onCreateExperiment = useCallback(() => {
    const newExperiment = getNewExperiment(isExperiment ? experiment : matrix);
    const { parentId, id } = newExperiment;

    history.push(`/matrices/${parentId}/experiments/${id}/edit`);
  }, [getNewExperiment, history, isExperiment, experiment, matrix]);

  const onDuplicateMatrix = useCallback(() => {
    const toDuplicate = isExperiment ? experiment : matrix;
    const duplicate = duplicateMatrix(toDuplicate, user);
    dispatch(finishEdit(toDuplicate));
    dispatch(createMatrix(duplicate));
    dispatch(saveMatrix(duplicate.id));

    const urlPath = isExperiment
      ? `/matrices/${duplicate?.parentId}/experiments/${duplicate?.id}/edit`
      : `/matrices/${duplicate?.id}/edit`;

    history.push(urlPath);
  }, [dispatch, history, user, matrix, isExperiment, experiment]);

  const handleConvertToMatrix = useCallback(() => {
    if (!experiment) return;
    const newMatrix = convertToMatrix(experiment, user);
    dispatch(finishEdit(experiment));
    dispatch(convertExperimentToMatrix(newMatrix));
    dispatch(saveMatrix(newMatrix.id));

    history.push(`/matrices/${newMatrix?.id}/edit`);

    if (experiment?.parentId) {
      dispatch(removeMatrix(experiment.id));
    }
  }, [history, dispatch, user, experiment]);

  const isViewOnly = useMemo(() => {
    const toCheck = isExperiment ? experiment : matrix;
    return !!toCheck?.isViewOnly;
  }, [isExperiment, experiment, matrix]);

  const downloadDetails = useCallback(async () => {
    const detailsPage = document.getElementById('details-page');
    detailsPage.classList.add('for-image-download');

    const fileName = `${matrix.name}-details`;
    const charts = document.getElementById('charts-download');

    const imageEls = document.getElementsByClassName('NodePill-icon');

    const originalHrefs = [];

    // Convert svg image tags to base64 due to html2canvas bug
    // See https://github.com/niklasvh/html2canvas/issues/2104
    for (const imageEl of imageEls) {
      const hrefUrl = imageEl?.href?.baseVal;
      originalHrefs.push(hrefUrl);

      const svgStr = await fetch(
        `${window.location.origin}${hrefUrl}`
      ).then(response => response.text());

      const base64Str = window.btoa(svgStr);

      imageEl.removeAttribute('href');

      imageEl.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `data:image/svg+xml;base64,${base64Str}`
      );
    }

    html2canvas(charts).then(canvas => {
      saveAs(canvas.toDataURL(), `${fileName}.png`);
    });

    // Cleanup back to original
    for (let i = 0; i < imageEls.length; i++) {
      const imageEl = imageEls[i];
      imageEl.removeAttribute('xlink:href');

      imageEl.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'href',
        originalHrefs[i]
      );
    }

    detailsPage.classList.remove('for-image-download');
  }, []);

  const location = history.location.pathname.replace(url, '');

  useEffect(() => {
    const isDetails = location.includes('details');
    if (isDetails && viewMode === 'play') {
      history.push(url);
    }
  }, [location, viewMode, history, url]);

  if (isExperiment && !experiment) return null;

  return (
    <Menu className="MatrixMenu">
      <MenuSection>
        <div className="MatrixMenu-menu-container">
          <div className="MatrixMenu-name-container">
            <MenuText>
              <MatrixMenuName
                baseMatrix={matrix}
                matrixName={matrix ? matrix.name : null}
                experimentName={isExperiment ? experiment.name : null}
              />
            </MenuText>
            {editable ? (
              <ButtonNew
                buttonClasses="icon-button interactiveTourCreatingExperiment-1-1"
                to={`${url}/edit`}
                icon="gear"
              />
            ) : null}
          </div>
        </div>

        <div className="MatrixMenu-buttons-container">
          {/* Sorry for the nested ternary */}
          {isSaving ? (
            <div className="MatrixMenu-saving-badge interactiveTourCreatingExperiment-6">
              <Icon alt="check" icon="check" className="icon-green" /> New
              changes autosaved
            </div>
          ) : viewMode === 'edit' ? (
            <div className="MatrixMenu-editing-badge">
              Editing {isExperiment ? 'experiment' : 'matrix'}
            </div>
          ) : null}

          {isViewOnly ? (
            <div className="MatrixMenu-editing-badge">View-only</div>
          ) : null}
          {editable && !isViewOnly ? (
            <div className="MatrixMenu-button">
              <ButtonNew
                buttonClasses={classNames(
                  'EditModeButton icon-button interactiveTourCreatingExperiment-3',
                  {
                    selected: viewMode === 'edit',
                  }
                )}
                onClick={() =>
                  setViewMode(viewMode === 'play' ? 'edit' : 'play')
                }
                icon="editMode"
              />
            </div>
          ) : null}
          {user && isExperiment ? (
            <div ref={convertRef} className="MatrixMenu-button">
              <ButtonNew
                buttonClasses={classNames(
                  'icon-button interactiveTourCreatingExperiment-16',
                  { selected: openPopovers['convert'] }
                )}
                onClick={() =>
                  switchPopovers('convert', !openPopovers['convert'])
                }
                icon="convert"
              />
            </div>
          ) : null}
          {user ? (
            <div
              ref={duplicateRef}
              className="MatrixMenu-button interactiveTourGettingStarted-11"
            >
              <ButtonNew
                buttonClasses={classNames('icon-button', {
                  selected: openPopovers['duplicate'],
                })}
                onClick={() =>
                  switchPopovers('duplicate', !openPopovers['duplicate'])
                }
                icon="duplicateMatrix"
              />
            </div>
          ) : null}
          <div
            ref={shareRef}
            className="MatrixMenu-button interactiveTourGettingStarted-12"
          >
            <ButtonNew
              buttonClasses={classNames('ShareButton icon-button', {
                selected: openPopovers['share'],
              })}
              onClick={() => switchPopovers('share', !openPopovers['share'])}
              icon="link"
            />
          </div>
          <MenuActions>
            <Switch>
              <Route path={`${url}/details`}>
                <ButtonNew
                  buttonClasses="primary-button-small"
                  label="Download"
                  onClick={downloadDetails}
                />
              </Route>
              <Route>
                <div ref={experimentRef}>
                  <ButtonNew
                    buttonClasses="CreateExperiment primary-button-small interactiveTourGettingStarted-13 interactiveTourCreatingExperiment-0"
                    label="Create experiment"
                    onClick={() =>
                      switchPopovers('experiment', !openPopovers['experiment'])
                    }
                  />
                </div>
              </Route>
            </Switch>
          </MenuActions>
        </div>
      </MenuSection>

      {errors.length > 0 ? <MatrixErrors /> : null}
      <Popover
        title={`Share ${isExperiment ? 'experiment' : 'matrix'}`}
        content={
          <SharePopoverContent
            noEdit={!editable || isViewOnly}
            isExperiment={isExperiment}
            url={`${window.location.origin}/#${url}`}
            onCancel={() => setOpenPopovers(v => ({ ...v, share: false }))}
          />
        }
        isOpen={openPopovers['share']}
        parentRef={shareRef.current}
      />
      <Popover
        title={`Duplicate ${isExperiment ? 'experiment' : 'matrix'}`}
        content={
          <GenericPopoverContent
            text={`Make an editable copy of this ${
              isExperiment ? 'experiment' : 'matrix'
            } to your own files. You can access the duplicate from your private gallery.`}
            primaryAction={() => {
              onDuplicateMatrix();
              switchPopovers('duplicate', false);
            }}
            primaryActionLabel={'Duplicate'}
            secondaryAction={() => switchPopovers('duplicate', false)}
            secondaryActionLabel={'Cancel'}
          />
        }
        isOpen={openPopovers['duplicate']}
        parentRef={duplicateRef.current}
      />
      <Popover
        title="Create experiment"
        content={
          <GenericPopoverContent
            text={
              'Create a new experiment of this matrix to test different hypotheses. This will create a new file saved to your private gallery. Changes made to your current file are saved automatically.'
            }
            primaryAction={() => {
              onCreateExperiment();
              switchPopovers('experiment', false);
            }}
            primaryActionLabel={'Create'}
            secondaryAction={() => switchPopovers('experiment', false)}
            secondaryActionLabel={'Cancel'}
          />
        }
        isOpen={openPopovers['experiment']}
        parentRef={experimentRef.current}
      />
      <Popover
        title="Convert experiment to base matrix"
        content={
          <GenericPopoverContent
            text={
              'Create a new experiment of this matrix to test a new hypothesis. This will create a new file saved to your private gallery. Changes made to your current file are saved automatically.'
            }
            primaryAction={() => {
              handleConvertToMatrix();
              switchPopovers('convert', false);
            }}
            primaryActionLabel={'Convert'}
            secondaryAction={() => switchPopovers('experiment', false)}
            secondaryActionLabel={'Cancel'}
          />
        }
        isOpen={openPopovers['convert']}
        parentRef={convertRef.current}
      />
    </Menu>
  );
};

export default MatrixMenu;
