import React from 'react';
import Methodology from './Methodology';
import PrivacyPolicy from './PrivacyPolicy';
import Faq from './Faq';
import './InfoPage.scss';
import MPGLink from './MPGLink';

const AboutPage = () => {
  return (
    <div className="InfoPage">
      <div className="content">
        <section>
          <Methodology />
        </section>
        <section>
          <div className="text-display-large title">Privacy Policy</div>
          <PrivacyPolicy />
        </section>
        <section>
          <MPGLink />
        </section>
      </div>
    </div>
  );
};

export default AboutPage;
