import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { enterModal, exitModal } from 'actions/modal';
import { getMatrix } from 'constants/matrices';
import { fetchUserMatrices } from 'actions/matrices';
import MatrixDeletePopoverContent from 'components/matrices/MatrixDeletePopoverContent';
import * as urls from 'constants/urls';
import './MatrixDeletePopoverWrapper.scss';

// NB: matrixId may be for a matrix or an experiment
const MatrixDeletePopoverWrapper = ({ matrixId, onDelete, onCancel }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const userId = useMemo(() => auth.user?.claims?.user_id ?? null, [auth.user]);
  const matricesLoading = useSelector(state => state.matrices._loading);
  const matricesLoaded = useSelector(state => state.matrices._loaded);

  const allMatricesAndExperiments = useSelector(
    state => state.matrices.matrices
  ).filter(({ savedByUser }) => savedByUser);

  useEffect(() => {
    if (
      auth.user &&
      Object.keys(auth.userData).length > 0 &&
      !matricesLoading.user &&
      !matricesLoaded.user
    ) {
      dispatch(
        fetchUserMatrices(auth.user, auth.userData, allMatricesAndExperiments)
      );
    }
  }, [
    dispatch,
    auth,
    matricesLoaded,
    matricesLoading,
    allMatricesAndExperiments,
  ]);

  const experiments = useMemo(
    () => allMatricesAndExperiments.filter(({ parentId }) => parentId !== null),
    [allMatricesAndExperiments]
  );

  const userExperiments = useMemo(() => {
    if (!userId) return [];
    return experiments.filter(({ roles }) => roles[userId] === 'owner');
  }, [experiments, userId]);

  const children = useMemo(() => {
    return userExperiments.filter(item => item?.parentId === matrixId);
  }, [userExperiments, matrixId]);

  useEffect(() => {
    dispatch(enterModal());

    return () => {
      dispatch(exitModal());
    };
  }, [dispatch]);

  const matrices = useSelector(state => state.matrices.matrices);
  if (!matrices.length) return null;

  const matrix = getMatrix(matrices, matrixId);
  if (!matrix) return null;

  return (
    <div className="MatrixDeletePopoverWrapper">
      <MatrixDeletePopoverContent
        matrix={matrix}
        children={children}
        onDelete={onDelete}
        onCancel={onCancel}
      />
    </div>
  );
};

export default MatrixDeletePopoverWrapper;
