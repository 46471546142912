import React from 'react';
import ButtonNew from 'components/inputs/ButtonNew';
import classNames from 'classnames';

//Component for # of steps and prev/next buttons
export const TourNavigationComponent = ({
  steps,
  setCurrentStep,
  currentStep,
  setIsOpen,
  nextButton,
  hideButtons,
  disableAll,
}) => {
  const stepsLength = steps.length;

  const Button = ({ onClick, kind = 'next', className, label }) => {
    function clickHandler() {
      if (!disableAll) {
        if (onClick && typeof onClick === 'function') {
          onClick();
        } else {
          if (kind === 'next') {
            if (currentStep === stepsLength - 1) {
              setIsOpen(false);
            } else {
              setCurrentStep(Math.min(currentStep + 1, stepsLength - 1));
            }
          } else {
            setCurrentStep(Math.max(currentStep - 1, 0));
          }
        }
      }
    }

    return (
      <ButtonNew
        buttonClasses={classNames(kind, className, {
          disabled: disableAll
            ? disableAll
            : kind === 'next'
            ? stepsLength === currentStep
            : currentStep === 0,
        })}
        onClick={clickHandler}
        aria-label={`Go to ${kind} step`}
        label={label}
      ></ButtonNew>
    );
  };

  return (
    <div className="Popover-navigation">
      <div className="step-indicator">
        Step {currentStep + 1} of {steps.length}
      </div>
      <div className="action-buttons">
        <div>
          {!hideButtons ? (
            currentStep !== 0 ? (
              <Button
                className="secondary-button-dark-small"
                kind="prev"
                label="Previous"
              ></Button>
            ) : null
          ) : null}
        </div>
        <div>
          {!hideButtons ? (
            nextButton && typeof nextButton === 'function' ? (
              nextButton({
                Button,
                setCurrentStep,
                currentStep,
                stepsLength,
                setIsOpen,
              })
            ) : (
              <Button
                className="primary-button-small"
                kind="next"
                label={currentStep === steps.length - 1 ? 'Finish' : 'Next'}
              ></Button>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
