export const LOGIN_PROMPT_CLOSE = 'LOGIN_PROMPT_CLOSE';
export const LOGIN_PROMPT_OPEN = 'LOGIN_PROMPT_OPEN';

export const MATRIX_ADD_NODE = 'MATRIX_ADD_NODE';
export const MATRIX_CONVERT_EXPERIMENT_TO_MATRIX =
  'MATRIX_CONVERT_EXPERIMENT_TO_MATRIX';
export const MATRIX_CREATE = 'MATRIX_CREATE';
export const MATRIX_CREATE_EXPERIMENT = 'MATRIX_CREATE_EXPERIMENT';
export const MATRIX_DELETE_NODE = 'MATRIX_DELETE_NODE';
export const MATRIX_SELECT = 'MATRIX_SELECT';
export const MATRIX_RESET = 'MATRIX_RESET';
export const MATRIX_ADD_FAVORITE = 'MATRIX_ADD_FAVORITE';
export const MATRIX_REMOVE_FAVORITE = 'MATRIX_REMOVE_FAVORITE';

export const MATRIX_SET_NODE_HOVER = 'MATRIX_SET_NODE_HOVER';
export const MATRIX_TOGGLE_CONNECTIONS_SIDEBAR =
  'MATRIX_TOGGLE_CONNECTIONS_SIDEBAR';
export const MATRIX_TOGGLE_MODIFICATIONS_SIDEBAR =
  'MATRIX_TOGGLE_MODIFICATIONS_SIDEBAR';
export const GALLERY_SET_TAB = 'GALLERY_SET_TAB';
export const GALLERY_SET_SORTBY = 'GALLERY_SET_SORTBY';

export const NODE_SET_FIXED_WARNING = 'NODE_SET_FIXED_WARNING';

export const MATRIX_ERRORS_CLEAR = 'MATRIX_ERRORS_CLEAR';

export const MATRIX_SETTINGS_START_EDIT = 'MATRIX_SETTINGS_START_EDIT';
export const MATRIX_SETTINGS_SET_FIELD = 'MATRIX_SETTINGS_SET_FIELD';
export const MATRIX_SETTINGS_CANCEL_EDIT = 'MATRIX_SETTINGS_CANCEL_EDIT';
export const MATRIX_SETTINGS_FINISH_EDIT = 'MATRIX_SETTINGS_FINISH_EDIT';
export const MATRIX_SETTINGS_UPDATE = 'MATRIX_SETTINGS_UPDATE';
export const MATRIX_SETTINGS_VALIDATE = 'MATRIX_SETTINGS_VALIDATE';

export const MATRIX_REMOVE_REQUEST = 'MATRIX_REMOVE_REQUEST';
export const MATRIX_REMOVE_RESPONSE = 'MATRIX_REMOVE_RESPONSE';
export const MATRIX_REMOVE_ERROR_RESPONSE = 'MATRIX_REMOVE_ERROR_RESPONSE';

export const MATRIX_SAVE_REQUEST = 'MATRIX_SAVE_REQUEST';
export const MATRIX_SAVE_RESPONSE = 'MATRIX_SAVE_RESPONSE';
export const MATRIX_SAVE_ERROR_RESPONSE = 'MATRIX_SAVE_ERROR_RESPONSE';

export const MATRIX_REQUEST = 'MATRIX_REQUEST';
export const MATRIX_RESPONSE = 'MATRIX_RESPONSE';
export const MATRIX_ERROR_RESPONSE = 'MATRIX_ERROR_RESPONSE';

export const MATRIX_ADD_SESSION_STATE = 'MATRIX_ADD_SESSION_STATE';

export const MODAL_ENTER = 'MODAL_ENTER';
export const MODAL_EXIT = 'MODAL_EXIT';

export const NODE_SETTINGS_CONNECTION_ADD = 'NODE_SETTINGS_CONNECTION_ADD';
export const NODE_SETTINGS_CONNECTION_DELETE =
  'NODE_SETTINGS_CONNECTION_DELETE';
export const NODE_SETTINGS_CONNECTION_UPDATE =
  'NODE_SETTINGS_CONNECTION_UPDATE';
export const NODE_SETTINGS_START_EDIT = 'NODE_SETTINGS_START_EDIT';
export const NODE_SETTINGS_SET_FIELD = 'NODE_SETTINGS_SET_FIELD';
export const NODE_SETTINGS_CANCEL_EDIT = 'NODE_SETTINGS_CANCEL_EDIT';
export const NODE_SETTINGS_FINISH_EDIT = 'NODE_SETTINGS_FINISH_EDIT';
export const NODE_SETTINGS_VALIDATE = 'NODE_SETTINGS_VALIDATE';

export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_LOAD_USER_DATA_REQUEST = 'AUTH_LOAD_USER_DATA_REQUEST';
export const AUTH_LOAD_USER_DATA_RESPONSE = 'AUTH_LOAD_USER_DATA_RESPONSE';
export const AUTH_LOAD_USER_DATA_ERROR_RESPONSE =
  'AUTH_LOAD_USER_DATA_ERROR_RESPONSE';
export const AUTH_SAVE_USER_DATA_REQUEST = 'AUTH_SAVE_USER_DATA_REQUEST';
export const AUTH_SAVE_USER_DATA_RESPONSE = 'AUTH_SAVE_USER_DATA_RESPONSE';
export const AUTH_SAVE_USER_DATA_ERROR_RESPONSE =
  'AUTH_SAVE_USER_DATA_ERROR_RESPONSE';

export const ANONYMOUS_EDITING_KEYS_ADD = 'ANONYMOUS_EDITING_KEYS_ADD';

export const FOLDER_CREATE = 'FOLDER_CREATE';
export const FOLDER_ERROR_RESPONSE = 'FOLDER_ERROR_RESPONSE';
export const FOLDER_RESPONSE = 'FOLDER_RESPONSE';
export const FOLDER_REQUEST = 'FOLDER_REQUEST';
export const FOLDER_SET_CURRENT = 'FOLDER_SET_CURRENT';
export const FOLDER_SAVE_ERROR_RESPONSE = 'FOLDER_SAVE_ERROR_RESPONSE';
export const FOLDER_SAVE_RESPONSE = 'FOLDER_SAVE_RESPONSE';
export const FOLDER_SAVE_REQUEST = 'FOLDER_SAVE_REQUEST';
export const FOLDER_ADD_FAVORITE = 'FOLDER_ADD_FAVORITE';
export const FOLDER_REMOVE_FAVORITE = 'FOLDER_REMOVE_FAVORITE';

export const FOLDER_SETTINGS_START_EDIT = 'FOLDER_SETTINGS_START_EDIT';
export const FOLDER_SETTINGS_SET_FIELD = 'FOLDER_SETTINGS_SET_FIELD';
export const FOLDER_SETTINGS_CANCEL_EDIT = 'FOLDER_SETTINGS_CANCEL_EDIT';
export const FOLDER_SETTINGS_FINISH_EDIT = 'FOLDER_SETTINGS_FINISH_EDIT';
export const FOLDER_SETTINGS_VALIDATE = 'FOLDER_SETTINGS_VALIDATE';

export const FOLDER_REMOVE_REQUEST = 'FOLDER_REMOVE_REQUEST';
export const FOLDER_REMOVE_RESPONSE = 'FOLDER_REMOVE_RESPONSE';
export const FOLDER_REMOVE_ERROR_RESPONSE = 'FOLDER_REMOVE_ERROR_RESPONSE';

export const CONNECTIONS_IMPORT_ERROR_RESPONSE =
  'CONNECTIONS_IMPORT_ERROR_RESPONSE';

export const MAIL_CREATE = 'MAIL_CREATE';
export const MAIL_SAVE_REQUEST = 'MAIL_SAVE_REQUEST';
export const MAIL_SAVE_RESPONSE = 'MAIL_SAVE_RESPONSE';
export const MAIL_SAVE_ERROR_RESPONSE = 'MAIL_SAVE_ERROR_RESPONSE';
export const MAIL_CLEAR = 'MAIL_CLEAR';
export const MAIL_RESET_SENT_FLAG = 'MAIL_RESET_SENT_FLAG';
