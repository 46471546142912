import uuidv4 from 'uuid/v4';

export const createNewMail = (type, message, user) => {
  const mail = {
    id: uuidv4().slice(0, 6),
    createdBy: user.claims.name,
    to: 'matrix@mpgranch.com',
    replyTo: user.claims.email,
    message: {
      subject: `Feedback about ${type} <${user.claims.user_id}>`,
      html: message,
    },
  };

  return mail;
};
