import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { startEdit } from 'actions/folderSettings';
import ButtonNew from 'components/inputs/ButtonNew';
import { canDelete, shouldFetchFolder } from 'constants/folders';
import { removeFolder, fetchFolder } from 'actions/folders';
import './FolderDeletePopoverContent.scss';

import { canDelete as canDeleteMatrix } from 'constants/matrices';
import { finishEdit as finishEditMatrix } from 'actions/matrixSettings';
import { saveMatrix } from 'actions/matrices';

const FolderDeletePopoverContent = ({
  completionUrl,
  folder,
  closePopover,
}) => {
  const dispatch = useDispatch();

  const folderMatricesAndExperiments = useSelector(
    state => state.matrices.matrices
  )
    .filter(({ savedByUser }) => savedByUser)
    .filter(m => m.folder === folder.id);

  useEffect(() => {
    dispatch(startEdit(folder));
  }, [dispatch, folder]);

  const { user, userData } = useSelector(state => state.auth);
  const editingFolder = useSelector(state => state.folderSettings.folder);
  const deletable = canDelete(folder, user, userData);
  const history = useHistory();

  useEffect(() => {
    if (shouldFetchFolder(folder.id, folder)) {
      dispatch(fetchFolder(folder.id));
    }
  }, [dispatch, folder]);

  if (!editingFolder) return null;

  const handleDelete = () => {
    if (!deletable) return;
    dispatch(removeFolder(folder.id));
    for (const matrix of folderMatricesAndExperiments) {
      // Not deleteing, using this as a permissions check
      if (canDeleteMatrix(matrix, user, userData)) {
        const nextMatrix = { ...matrix };
        delete nextMatrix.folder;
        dispatch(finishEditMatrix(nextMatrix));
        dispatch(saveMatrix(nextMatrix.id));
      }
    }
    history.push(completionUrl);
  };

  return (
    <div className="FolderDeletePopoverContent">
      <div className="Popover-section">This action cannot be undone.</div>
      <div className="FolderDeletePopoverContent-buttons-container">
        <ButtonNew
          buttonClasses="secondary-button-dark-small"
          onClick={closePopover}
          label="Cancel"
        />
        {deletable ? (
          <ButtonNew
            label="Delete"
            onClick={handleDelete}
            buttonClasses="destructive-button-small"
          />
        ) : null}
      </div>
    </div>
  );
};

export default FolderDeletePopoverContent;
