import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { setGalleryTab, setGallerySortBy } from 'actions/viewStates';
import {
  Menu,
  MenuActions,
  GalleryMenuName,
  MenuText,
} from 'components/menus/Menu';
import ButtonNew from 'components/inputs/ButtonNew';
import ButtonSet from 'components/inputs/ButtonSet';
import Dropdown from 'components/inputs/Dropdown';
import MatrixErrors from 'components/MatrixErrors';
import text from 'constants/text';
import { createMatrix as createNewMatrix } from 'constants/matrices';
import icons from 'img/icons';
import { createMatrix, saveMatrix } from 'actions/matrices';
import './GalleryMenu.scss';
import { sortOptions } from 'constants/sort_options';

const GalleryMenu = ({ galleryTab }) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { errors } = useSelector(state => state.matrices);
  const history = useHistory();
  const { isAdmin } = useSelector(state => state.auth.userData);

  const currentFolderId = useSelector(state => state.folders.selected);
  const folders = useSelector(state => state.folders.folders);

  const currentFolder = useMemo(() => {
    return folders.find(f => f.id === currentFolderId);
  }, [folders, currentFolderId]);

  const currentSort = useSelector(
    state => state.viewStates.sortBy?.[galleryTab?.name]
  );

  const onSortBy = value => {
    dispatch(
      setGallerySortBy({
        visibility: galleryTab.name,
        sortBy: value,
      })
    );
  };

  const sortDropdownStyles = {
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: '500',
      fontSize: '1em',
    }),
    control: (provided, state) => {
      const overrides = {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderColor: '#DDDDDD',
        width: '100%',
        borderRadius: '20px',
        minHeight: 33,
        fontSize: '0.875em',
        padding: '0.5rem 0.75rem',
      };
      if (state.isFocused || state.menuIsOpen) {
        overrides.borderColor = '#DDDDDD';
      }
      if (state.menuIsOpen) {
        overrides.borderRadius = '12px 12px 0 0';
        overrides.borderBottom = 'none';
      }
      return {
        ...provided,
        ...overrides,
        ':hover': {
          ...provided[':hover'],
          borderColor: '#999999',
        },
      };
    },
    dropdownIndicator: (provided, state) => {
      const overrides = {
        color: '#DDDDDD',
        padding: 0,
        paddingRight: 8,
      };
      if (state.isDisabled) {
        overrides.display = 'none';
      }
      return {
        ...provided,
        ...overrides,
      };
    },
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided, state) => ({
      ...provided,
      borderWidth: '0',
      borderColor: '#DDDDDD',
      borderRadius: '0 0 20px 20px',
      borderStyle: 'solid',
      marginTop: 0,
    }),
    option: (provided, state) => {
      const overrides = {
        fontSize: '0.75em',
      };
      if (state.isFocused || state.isSelected) {
        overrides.backgroundColor = '#f4f7f7';
      }
      if (state.isSelected) {
        overrides.color = 'inherit';
      }
      return {
        ...provided,
        ...overrides,
        whiteSpace: 'nowrap', // Don't wrap
        overflow: 'hidden', // Hide overflow
        textOverflow: 'ellipsis', // with a ...
      };
    },
    singleValue: provided => ({
      color: 'black',
    }),
    valueContainer: provided => ({
      ...provided,
      flexWrap: 'nowrap',
      padding: 0,
      paddingLeft: 12,
      whiteSpace: 'nowrap', // Don't wrap
      overflow: 'hidden', // Hide overflow
      textOverflow: 'ellipsis', // with a ...
    }),
  };

  return (
    <Menu className="GalleryMenu">
      <MenuText>
        {galleryTab.name === 'education' ? (
          <GalleryMenuName
            folderName={'Education modules'}
            onClick={() =>
              dispatch(setGalleryTab({ name: 'public', userInitiated: true }))
            }
          />
        ) : (
          <GalleryMenuName folderName={currentFolder?.name} />
        )}
      </MenuText>
      <MenuActions>
        <div className="MatrixVisibilityPicker">
          {auth.user ? (
            <div className="GalleryMenu-private-public-toggle-container">
              <ButtonSet
                options={[
                  { value: 'public', label: 'Public' },
                  { value: 'private', label: 'Private' },
                ]}
                onChange={value => {
                  history.push(`/gallery`);
                  dispatch(setGalleryTab({ name: value, userInitiated: true }));
                }}
                value={
                  galleryTab.name === 'education' ? 'public' : galleryTab.name
                }
              />
            </div>
          ) : null}

          {auth.user && galleryTab.name === 'private' ? (
            <>
              <ButtonNew
                buttonClasses="tertiary-button"
                iconLeft="pencilGray"
                onClick={() => {
                  const newMatrix = createNewMatrix(auth.user);
                  if (currentFolderId) {
                    newMatrix.folder = currentFolderId;
                  }
                  dispatch(createMatrix(newMatrix));
                  dispatch(saveMatrix(newMatrix.id));
                  history.push(`/matrices/${newMatrix.id}/new`);
                }}
                label="New blank matrix"
                requiresLogin
                requiresLoginMessage="Sign in to build a gallery of your own private matrices. Continue without signing in and you may retrieve sharable links to your work."
              />
            </>
          ) : null}

          {/* TODO Do we need the "all" toggle? */}
          {isAdmin ? (
            <ButtonNew
              label="ALL"
              buttonClasses={classNames('', {
                'pill-button-selected': galleryTab.name === 'all',
                'pill-button-unselected': galleryTab.name !== 'all',
              })}
              onClick={() =>
                dispatch(setGalleryTab({ name: 'all', userInitiated: true }))
              }
            />
          ) : null}

          {galleryTab.name === 'education' ? (
            <ButtonNew
              buttonClasses="tertiary-button education-button"
              imgClasses="button-image-sized"
              imgLeft={icons.inspiredClassroomLogo}
              label="Inspired Classroom Materials"
              alt="Button to go to Inspired Classroom Materials"
              onClick={event =>
                window.open(
                  text.gallery.inspiredClassroomLink,
                  '_blank',
                  'noopener'
                )
              }
            />
          ) : null}
        </div>
        {galleryTab.name === 'all' || galleryTab.name === 'private' ? (
          <Dropdown
            classNamePrefix="Dropdown-sort"
            onChange={onSortBy}
            styles={sortDropdownStyles}
            options={
              galleryTab === 'all'
                ? sortOptions
                : sortOptions.filter(option => option.label !== 'User')
            }
            value={currentSort?.label}
            menuPortalTarget={document.body}
          />
        ) : null}
      </MenuActions>

      {errors.length > 0 ? <MatrixErrors /> : null}
    </Menu>
  );
};

export default GalleryMenu;
