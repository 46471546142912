import React from 'react';
import classNames from 'classnames';
import './ButtonSet.scss';

const ButtonSet = ({ disabled, onChange, options, value }) => {
  return (
    <div className="ButtonSet">
      {options.map(option => (
        <div
          className={classNames(`ButtonSet-option ${option.className}`, {
            'pill-button-selected': option.value === value,
            'pill-button-unselected': option.value !== value,
            disabled,
          })}
          key={option.value}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default ButtonSet;
