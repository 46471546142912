import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Tour, TourContext } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { closeLoginPrompt, openLoginPrompt } from 'actions/loginPrompt';
import * as urls from 'constants/urls';
import { setField } from 'actions/nodeSettings';
import text from 'constants/text';
import { toggleModificationsSidebar } from 'actions/viewStates';
import './MatrixTourProvider.scss';
import tourSteps from 'tour/tourSteps';
import experimentTourSteps from 'tour/tour-steps-make-experiment';
import { TourContentComponent } from './tour/TourContentComponent';
import { TourNavigationComponent } from './tour/TourNavigationComponent';
import { setGalleryTab } from 'actions/viewStates';

// Draws arrows on the edge of popover
function doArrow(position, verticalAlign, horizontalAlign) {
  if (!position || position === 'custom') {
    return {};
  }

  const oppositeSide = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right',
  };

  const popoverPadding = 10;

  const width = 16;
  const height = 12;
  const color = '#102026';
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = popoverPadding;
  const border = `${width / 2}px solid transparent`;
  const borderPosition = `${position[0].toUpperCase()}${position.substring(1)}`;
  const restProps = {
    [isVertical ? 'borderLeft' : 'borderTop']: border,
    [isVertical ? 'borderRight' : 'borderBottom']: border,
    [`border${borderPosition}`]: `${height}px solid ${color}`,
    [isVertical ? oppositeSide[horizontalAlign] : verticalAlign]:
      height + spaceFromSide, // space from side
    [oppositeSide[position]]: -height + 2,
  };

  return {
    '&::after': {
      content: "''",
      position: 'absolute',
      borderColor: color,
      ...restProps,
    },
  };
}

export const MatrixTourProvider = ({
  children,
  defaultOpen = false,
  startAt = 0,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const [currentStep, setCurrentStep] = useState(startAt);

  const [whichTour, setWhichTour] = useState(null);

  const [disabledActions, setDisabledActions] = useState(false);
  const [disableInteraction, setDisableInteraction] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const modificationsOpen = useSelector(
    state => state.viewStates.modificationsSidebarOpen
  );
  const connectionsOpen = useSelector(
    state => state.viewStates.connectionsSidebarOpen
  );
  const galleryTab = useSelector(state => state.viewStates.lastGalleryTabOpen);

  //Per step padding
  const [padding, setPadding] = useState({
    mask: 0,
    popover: [10, 10],
    wrapper: 0,
  });

  const matrices = useSelector(state => state.matrices.matrices);
  const publicMatrices = useSelector(state => {
    return state.matrices.matrices.filter(
      ({ parentId, isPublic }) => isPublic && parentId === null
    );
  });
  const loadedMatrices = useSelector(state => state.matrices._loaded);

  //Load the definitions for the steps in the tour.
  const defaultSteps = useMemo(() => {
    // TODO make this better
    const stepFn =
      whichTour === 'make-experiment' ? experimentTourSteps : tourSteps;

    return stepFn(
      text,
      dispatch,
      openLoginPrompt,
      closeLoginPrompt,
      modificationsOpen,
      toggleModificationsSidebar,
      galleryTab,
      setGalleryTab
    ).filter(
      //removes signing steps if logged in
      step => !(auth.user !== null && step.showLoggedIn === false)
    );
  }, [auth, dispatch, modificationsOpen, galleryTab, whichTour]);

  const mapObservers = step => {
    return {
      ...step,
      highlightedSelectors: [step.selector],
      mutationObservables: [step.selector],
      resizeObservables: [step.selector],
    };
  };

  //Reprocess steps if they change, i.e. if logged in / out
  useEffect(() => {
    setSteps(defaultSteps);
  }, [defaultSteps]);

  const [steps, setSteps] = useState(defaultSteps);

  const [tourMatrix, setTourMatrix] = useState();
  const [tourNode, setTourNode] = useState();
  const [tourExperimentId, setTourExperimentId] = useState();
  const [tourExperiment, setTourExperiment] = useState();
  const [tourExperimentCreated, setTourExperimentCreated] = useState(false);
  const [tourExperimentEdited, setTourExperimentEdited] = useState(false);

  const handleSetCurrentStep = useCallback(
    async stepIndex => {
      const {
        disableInteract,
        stepPadding,
        fixedPosition,
        matrixFinder,
        nodeSelectorFinder,
        allowCreateExperiment,
        createTourExperiment,
        editTourExperiment,
        saveNodeSettings,
        moveTo,
      } = steps[stepIndex];

      //**Inital Settings
      let blockProgress = false;
      setDisableInteraction(false);

      //**Styling
      if (stepPadding) {
        setPadding({ ...padding, ...stepPadding });
      }

      if (disableInteract) {
        setDisableInteraction(true);
      }

      if (fixedPosition) {
        steps[stepIndex].position = [
          window.innerWidth * fixedPosition[0],
          fixedPosition[1],
        ];
      }

      //**Actions
      if (matrixFinder) {
        if (publicMatrices) {
          const matrix = matrixFinder(publicMatrices);
          if (matrix?.id) {
            setTourMatrix(matrix);
            steps[stepIndex].selector = `.id${matrix.id}`;
          } else {
            console.log("Didn't find matrix.id, matrix not loaded yet");
            blockProgress = true;
          }
        } else {
          console.log("Didn't find matrix, no public matrices");
          blockProgress = true;
        }
      }

      if (nodeSelectorFinder) {
        if (tourMatrix && loadedMatrices[tourMatrix.id]) {
          const tourMatrixWithNodes = publicMatrices.find(
            ({ id }) => id === tourMatrix.id
          );
          setTourMatrix(tourMatrixWithNodes);
          const node =
            nodeSelectorFinder(tourMatrixWithNodes) ||
            tourMatrixWithNodes.nodes[0];
          const nodeSelector = node.id;
          setTourNode(node);

          // TODO this needs to be cleaned up
          steps[stepIndex].selector = `.TrophicNodePlay.id${nodeSelector}`;
        } else {
          console.log("Didn't select node, no tour matrix nodes", tourMatrix);
          blockProgress = true;
        }
      }

      if (allowCreateExperiment) {
        setTourExperiment(null);
        setTourExperimentCreated(false);
        setTourExperimentEdited(false);
      }

      if (createTourExperiment) {
        console.log(
          'attempting create experiment',
          tourExperiment,
          tourExperimentCreated
        );

        if (tourNode) {
          if (!tourExperimentCreated) {
            const createExperimentButton = document.querySelector(
              '.CreateExperiment'
            );
            createExperimentButton.click();

            // TODO this should be its own step
            const editButton = document.querySelector(`.EditModeButton`);
            editButton.click();

            const nodeSettingsButton = document.querySelector(
              `.TrophicNode.id${tourNode.id} .TrophicNode-settings-button`
            );

            nodeSettingsButton.click();

            const experimentId = history.location.pathname.split('/')[4];
            setTourExperimentId(experimentId);
            setTourExperimentCreated(true);
          }
        } else {
          console.log("Didn't create experiment, no tour nodes");
          blockProgress = true;
        }
      }

      if (editTourExperiment) {
        if (tourExperimentId) {
          if (!tourExperimentEdited) {
            const experiment = matrices.find(
              ({ id }) => id === tourExperimentId
            );
            setTourExperiment(experiment);
            dispatch(setField('abundance', 0.85));
            setTourExperimentEdited(true);
          }
        } else {
          console.log("Didn't edit experiment, no experimentID");
          blockProgress = true;
        }
      }

      if (saveNodeSettings) {
        const saveChangesButton = document.querySelector(
          'div.NodeSettingsSection-actions-save-discard > button.Button.hasLabel'
        );
        if (saveChangesButton) {
          saveChangesButton.click();
        }
      }

      //**Navigation
      //Navigation Functions
      const moveIfNecessary = destination => {
        if (destination && location.pathname !== destination) {
          history.push(destination);
        }
      };

      const checkRequirements = requirements => {
        let valid = true;
        if (requirements?.length >= 1) {
          requirements.forEach(element => {
            if (!element) {
              valid = false;
            }
          });
        }
        return valid;
      };

      const goTo = (type, place, requirements, view) => {
        if (checkRequirements(requirements)) {
          moveIfNecessary(urls[type](place, view));
        } else {
          blockProgress = true;
          console.log(
            `Failed to go to ${place}, one of requiremnts not true: ${requirements}`
          );
        }
      };

      //Navigation Switch
      switch (moveTo) {
        case 'splash':
          goTo('splash');
          break;
        case 'gallery':
          goTo('gallery');
          break;
        case 'matrix':
          goTo('matrix', tourMatrix, [tourMatrix]);
          break;
        case 'experiment': {
          goTo('experiment', tourExperiment, [tourMatrix, tourExperiment]);
          break;
        }
        case 'experimentNodeById':
          goTo(
            'experimentNodeById',
            [tourMatrix, tourExperimentId, tourNode],
            [tourExperimentCreated, tourExperimentId, tourNode]
          );
          break;
        case 'experimentDetails':
          goTo(
            'experimentView',
            tourExperiment,
            [tourMatrix, tourExperimentId],
            'details'
          );
          break;
        default:
          break;
      }

      //**Final Processing
      //map observers/selectors after all other step processing
      steps[stepIndex] = mapObservers(steps[stepIndex]);

      if (!blockProgress) {
        setCurrentStep(stepIndex);
      } else {
        console.log('Progress Blocked');
      }
    },
    [
      history,
      location.pathname,
      publicMatrices,
      steps,
      setCurrentStep,
      dispatch,
      matrices,
      loadedMatrices,
      tourExperiment,
      tourMatrix,
      tourNode,
      tourExperimentCreated,
      tourExperimentId,
      tourExperimentEdited,
      padding,
    ]
  );

  //Clicking the highlighted area will move to the next step, or close the tour if it's the last step.
  const handleMaskClick = ({ setCurrentStep, currentStep, setIsOpen }) => {
    if (currentStep === steps.length - 1) {
      setIsOpen(false);
    }
    setCurrentStep(currentStep === steps.length - 1 ? 0 : currentStep + 1);
  };

  //Disables scrolling the page during the tour. The highlighting of the node in trophic view can't follow scrolling.
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const value = {
    isOpen,
    setIsOpen,
    currentStep,
    setCurrentStep: handleSetCurrentStep,
    setWhichTour,
    steps,
    setSteps,
    disabledActions,
    setDisabledActions,
    disableInteraction,
    onClickMask: handleMaskClick,
    components: {
      Content: TourContentComponent,
      Navigation: TourNavigationComponent,
    },
    beforeOpen: enableBody,
    afterOpen: disableBody,
    showBadge: false,
    showDots: false,
    smoothscroll: true,
    padding,
    inViewThreshold: { x: -20, y: -10000 }, //prevents tour auto-scrolling

    styles: {
      popover: (base, state) => ({
        ...base,
        ...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
        background: '#102026',
        borderRadius: 10,
        color: 'white',
        fontFamily: '"Public Sans", sans-serif',
      }),
      maskArea: base => ({ ...base, rx: 10 }),
      maskWrapper: base => ({ ...base, fillOpacity: 0.2 }),
      close: (base, state) => ({
        ...base,
        height: 12,
        color: state.disabled ? 'black' : '#999999',
        '&:hover': {
          color: state.disabled ? 'black' : '#999999',
        },
      }),
    },
    ...props,
  };

  return (
    <TourContext.Provider value={value}>
      {children}
      {isOpen ? <Tour {...value} /> : null}
    </TourContext.Provider>
  );
};
