import React, { useState } from 'react';
import { useHistory } from 'react-router';
import './BuildMatrixModal.scss';
import BuildMatrixIntro from './BuildMatrixIntro';
import BuildMatrixCreateMatrix from './BuildMatrixCreateMatrix';

const buildSteps = ['intro', 'createMatrix'];

const BuildMatrixModal = ({ matrix }) => {
  const [buildStep, setBuildStep] = useState({
    step: 0,
  });

  const history = useHistory();

  const returnComponentForStep = step => {
    switch (step) {
      case 'intro': {
        return (
          <BuildMatrixIntro finishStep={() => setBuildStep({ step: 1 })} />
        );
      }
      case 'createMatrix': {
        return (
          <BuildMatrixCreateMatrix
            matrix={matrix}
            finishStep={() => history.push(`/matrices/${matrix.id}/edit`)}
            goBackStep={() => setBuildStep({ step: 0 })}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className="BuildMatrixModal">
      {returnComponentForStep(buildSteps[buildStep.step])}
    </div>
  );
};

export default BuildMatrixModal;
