import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'actions/folderSettings';

const useValidateFolder = () => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  return useMemo(() => {
    return () => dispatch(validate(user));
  }, [dispatch, user]);
};

export { useValidateFolder };
