import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const config = {
  production: {
    apiKey: 'AIzaSyBxXjljMoKSaFGZRJZtkI01K3VVRtA9Bpc',
    authDomain: 'mpg-matrix.firebaseapp.com',
    projectId: 'mpg-matrix',
    storageBucket: 'mpg-matrix.appspot.com',
  },
  development: {
    apiKey: 'AIzaSyAj01p35t5SxOiKk7XaVosCBSrp3K3IsfI',
    authDomain: 'mpg-matrix-dev.firebaseapp.com',
    projectId: 'mpg-matrix-dev',
    storageBucket: 'mpg-matrix-dev.appspot.com',
  },
};

firebase.initializeApp(config[process.env.REACT_APP_FIREBASE_ENV]);

export default firebase;
