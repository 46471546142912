import React from 'react';
import './MenuTabs.scss';

export const checkIfSelected = (view, history) => {
  return view === history.location.pathname;
};

export const checkIfInModal = history => {
  const pathComponents = history.location.pathname.split('/');

  // Look at last three components since the url may be
  //  .../overrides/<node_id>/advanced
  const isInOverride = pathComponents.includes('overrides', -3);

  // Just look at last element
  const isInShare = pathComponents.includes('share', -1);

  const isInEdit = pathComponents.includes('edit', -2);

  return isInOverride || isInShare || isInEdit;
};

export const MenuTabs = ({ children }) => {
  return <div className="Menu-tabs">{children}</div>;
};
