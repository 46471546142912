import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { openLoginPrompt } from 'actions/loginPrompt';
import Icon from 'components/Icon';
import './Checkbox.scss';

const Checkbox = ({
  alt,
  destructive,
  icon,
  label,
  onChange,
  value,
  id,
  requiresLogin,
  requiresLoginMessage,
  requiresLoginUrl,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  function handleChange(event) {
    if (requiresLogin && !user) {
      dispatch(openLoginPrompt(requiresLoginMessage, requiresLoginUrl));
      return;
    } else if (onChange) {
      onChange(event);
    }
  }

  return (
    <div className="Checkbox-label-container">
      <input
        type="checkbox"
        className={classNames('Checkbox', {
          hasLabel: label !== undefined,
          hasIcon: icon !== undefined,
          destructive: destructive,
        })}
        onChange={handleChange}
        value={value}
        id={id}
      ></input>
      {label ? (
        <label htmlFor={id} className="Checkbox-label">
          {label}
        </label>
      ) : null}
      {icon ? <Icon className="Checkbox-icon" alt={alt} icon={icon} /> : null}
    </div>
  );
};

export default Checkbox;
