import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import * as d3 from 'd3';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setNodeHover } from 'actions/viewStates';
import { getDistances } from 'constants/matrices';
import { getValueForAbundance } from 'constants/fcm';
import NodeDescriptionModal from './NodeDescriptionModal';
import OutputToInputWarningModal from './OutputToInputWarningModal';
import './TrophicNodePlay.scss';

const animationTimer = 2;

const NodeAbundanceSlider = ({
  node,
  abundance,
  cascadeHidden,
  onAbundanceChange,
  fixed,
}) => {
  // This is more stable than abundance because of cascading logic
  const actualAbundance = useMemo(() => {
    return node.fixed ? Number(node.abundance) : Number(node.abundanceResult);
  }, [node]);

  const currentValue = useMemo(() => {
    return Number(cascadeHidden ? node.oldAbundance : abundance);
  }, [node, abundance, cascadeHidden]);

  const [animationIsRunning, setAnimationIsRunning] = useState(1);

  // Initial node value
  const initialValue = useRef(actualAbundance);
  // Active value of changing the setting range
  const [tempValue, setTempValue] = useState(actualAbundance);
  // Gets set by incoming node's abundance only when user set
  const [userSetValue, setUserSetValue] = useState(null);
  // Sets when automatically set
  const [autoValue, setAutoValue] = useState(actualAbundance);
  // Last value
  const [lastValue, setLastValue] = useState(actualAbundance);

  useEffect(() => {
    if (animationIsRunning === 0) {
      const fullWidth = Math.abs(lastValue - currentValue);
      const baseSpeed = fullWidth * animationTimer;
      const int = fullWidth * 100;
      const fullTime = baseSpeed * 1000;
      let div = fullTime / int;
      if (isNaN(div)) div = 1;

      const interval = setInterval(() => {
        setAnimationIsRunning(n => n + 1 / div);
      }, int);

      setTimeout(() => clearInterval(interval), fullTime);
    }
  }, [animationIsRunning, lastValue, currentValue]);

  useEffect(() => {
    // autoValue only affects non-fixed!!!
    const prev = fixed ? userSetValue : autoValue;
    if (prev !== actualAbundance) {
      setAnimationIsRunning(0);
      setLastValue(prev);
    }
  }, [fixed, autoValue, userSetValue, actualAbundance]);

  // Get the node abundance value
  const nodeAbundance = getValueForAbundance(node, tempValue);

  const onChange = useCallback(
    value => {
      setTempValue(Number(value));
      onAbundanceChange(Number(value));
    },
    [onAbundanceChange]
  );

  // Pre-run value CSS
  const tempValueCss = useMemo(() => {
    const lesserVal = Math.min(tempValue, initialValue.current);
    const greaterVal = Math.max(tempValue, initialValue.current);

    const marginLeft = `${lesserVal * 100}%`;
    const widthPercent = (greaterVal - lesserVal) * 100;
    const width = `calc(${widthPercent}%)`;

    return {
      marginLeft,
      width,
    };
  }, [tempValue]);

  // Results bar CSS
  const resultsCssPositive = useMemo(() => {
    const lesserVal = initialValue.current;
    const greaterVal = Math.max(currentValue, initialValue.current);

    const widthPercent = (greaterVal - lesserVal) * 100;

    const marginLeft = `${lesserVal * 100}%`;
    const width = `calc(${widthPercent}%)`;

    const fullWidth = Math.abs(lastValue - currentValue);
    const baseSpeed = fullWidth * animationTimer;

    let transitionSpeed = baseSpeed;
    let delaySpeed = 0;

    // If starting negative and going positive
    if (
      lastValue < initialValue.current &&
      currentValue > initialValue.current
    ) {
      const pos = (currentValue - initialValue.current) / fullWidth;
      const neg = (initialValue.current - lastValue) / fullWidth;
      transitionSpeed = Math.abs(baseSpeed * pos);
      delaySpeed = Math.abs(baseSpeed * neg);
    }

    // If starting positive and going negative
    if (
      lastValue > initialValue.current &&
      currentValue < initialValue.current
    ) {
      const pos = (lastValue - initialValue.current) / fullWidth;
      transitionSpeed = Math.abs(baseSpeed * pos);
    }

    const ignoreFixed = !fixed ? false : tempValue !== actualAbundance;

    if (ignoreFixed) {
      return {
        marginLeft,
        width,
        transition: 'none',
      };
    }

    if (currentValue < initialValue.current) {
      return {
        marginLeft,
        left: 0,
        width: 0,
        transition: `width ${transitionSpeed}s linear ${delaySpeed}s`,
      };
    }

    return {
      marginLeft,
      width,
      transition: `width ${transitionSpeed}s linear ${delaySpeed}s`,
    };
  }, [currentValue, lastValue, actualAbundance, tempValue, fixed]);

  const resultsCssNegative = useMemo(() => {
    const lesserVal = Math.min(currentValue, initialValue.current);
    const greaterVal = initialValue.current;

    const widthPercent = (greaterVal - lesserVal) * 100;

    const marginLeft = `${lesserVal * 100 + widthPercent}%`;
    const width = `${widthPercent}%`;

    const fullWidth = Math.abs(lastValue - currentValue);
    const baseSpeed = fullWidth * animationTimer;
    let transitionSpeed = baseSpeed;
    let delaySpeed = 0;

    // If starting positive and going negative
    if (
      lastValue > initialValue.current &&
      currentValue < initialValue.current
    ) {
      const pos = (lastValue - initialValue.current) / fullWidth;
      const neg = (initialValue.current - currentValue) / fullWidth;
      delaySpeed = Math.abs(baseSpeed * pos);
      transitionSpeed = Math.abs(baseSpeed * neg);
    }
    // If starting negative and going positive
    if (
      lastValue < initialValue.current &&
      currentValue > initialValue.current
    ) {
      const neg = (initialValue.current - lastValue) / fullWidth;
      transitionSpeed = Math.abs(baseSpeed * neg);
    }

    const ignoreFixed = !fixed ? false : tempValue !== actualAbundance;

    if (ignoreFixed) {
      return {
        marginLeft,
        width,
        left: `${widthPercent * -1}%`,
        transition: 'none',
      };
    }

    if (currentValue > initialValue.current) {
      return {
        marginLeft,
        left: 0,
        width: 0,
        transition: `left ${transitionSpeed}s linear ${delaySpeed}s, width ${transitionSpeed}s linear ${delaySpeed}s`,
      };
    }

    return {
      marginLeft,
      left: `${widthPercent * -1}%`,
      width,
      transition: `left ${transitionSpeed}s linear ${delaySpeed}s, width ${transitionSpeed}s linear ${delaySpeed}s`,
    };
  }, [currentValue, lastValue, fixed, tempValue, actualAbundance]);

  // Slider bubble CSS
  const bubbleCssFixed = useMemo(() => {
    let bubbleWidth = 36;

    const rangeWidth = document.getElementById(`${node.id}-range`)?.offsetWidth;

    let marginLeft = `calc(${tempValue * 100}% - ${bubbleWidth / 2}px`;

    if (rangeWidth - bubbleWidth / 2 <= rangeWidth * tempValue) {
      marginLeft = `${rangeWidth - bubbleWidth}px`;
    }

    if (bubbleWidth / 2 >= rangeWidth * tempValue) {
      marginLeft = '0px';
    }

    return {
      marginLeft,
    };
  }, [node, tempValue]);

  const bubbleCssUnfixed = useMemo(() => {
    let bubbleWidth = 36;

    const rangeWidth = document.getElementById(`${node.id}-range`)?.offsetWidth;

    let marginLeft = `calc(${currentValue * 100}% - ${bubbleWidth / 2}px`;

    if (rangeWidth - bubbleWidth / 2 <= rangeWidth * currentValue) {
      marginLeft = `${rangeWidth - bubbleWidth}px`;
    }

    if (bubbleWidth / 2 >= rangeWidth * currentValue) {
      marginLeft = '0px';
    }

    const fullWidth = Math.abs(lastValue - currentValue);
    const baseSpeed = fullWidth * animationTimer;

    return {
      marginLeft,
      transition: `all ${baseSpeed}s linear`,
    };
  }, [node, currentValue, lastValue]);

  // Slider handle CSS
  const sliderHandleCssFixed = useMemo(() => {
    const marginLeft = `calc(${tempValue * 100}% - ${2 * tempValue}px`;

    const fullWidth = Math.abs(lastValue - currentValue);
    const baseSpeed = fullWidth * animationTimer;

    return {
      marginLeft,
      ...(tempValue === currentValue && {
        transition: `all ${baseSpeed}s linear`,
      }),
    };
  }, [currentValue, tempValue, lastValue]);

  const sliderHandleCssUnfixed = useMemo(() => {
    const marginLeft = `calc(${currentValue * 100}% - ${2 * currentValue}px`;

    const fullWidth = Math.abs(lastValue - currentValue);
    const baseSpeed = fullWidth * animationTimer;

    return {
      marginLeft,
      transition: `all ${baseSpeed}s linear`,
    };
  }, [currentValue, lastValue]);

  // True when nodes receive new values after NOT being changed (reactionary)
  const isReceivingValue = useMemo(() => {
    return actualAbundance !== autoValue && tempValue === autoValue;
  }, [tempValue, actualAbundance, autoValue]);

  // True when resetting (resets those that user set)
  const isResettingValue = useMemo(() => {
    return (
      tempValue === userSetValue &&
      userSetValue !== actualAbundance &&
      actualAbundance === initialValue.current
    );
  }, [tempValue, userSetValue, actualAbundance]);

  // Because of cascading, we need to use actual node.abundance rather
  // than abundance to set a stable userSetValue to check
  useEffect(() => {
    if (actualAbundance !== userSetValue && actualAbundance === tempValue) {
      setUserSetValue(actualAbundance);
    }
  }, [actualAbundance, tempValue, userSetValue]);

  useEffect(() => {
    if (isReceivingValue) {
      setTempValue(actualAbundance);
      setAutoValue(actualAbundance);
    }

    if (isResettingValue) {
      setTempValue(actualAbundance);
      setUserSetValue(actualAbundance);
      setAutoValue(actualAbundance);
    }
  }, [actualAbundance, isResettingValue, isReceivingValue]);

  const displayAbundance = useMemo(() => {
    const lastAbundance = getValueForAbundance(node, lastValue);
    const nextAbundance = getValueForAbundance(node, actualAbundance);
    const scale = d3.scaleLinear([0, 1], [lastAbundance, nextAbundance]);
    const decimalPlaces = `${nextAbundance}`.split('.')?.[1]?.length ?? 0;
    return scale(animationIsRunning).toFixed(decimalPlaces);
  }, [node, actualAbundance, lastValue, animationIsRunning]);

  return (
    <>
      <div className="NodeAbundanceSlider">
        <div id={`${node.id}-range`} className="NodeAbundanceSlider-range">
          <div
            id={`${node.id}-bubble`}
            className="NodeAbundanceSlider-bubble"
            style={fixed ? bubbleCssFixed : bubbleCssUnfixed}
          >
            {fixed ? nodeAbundance : displayAbundance}
          </div>
          <input
            className="NodeAbundanceSlider-slider"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={tempValue}
            onChange={e => onChange(e.target.value)}
          />
          <div
            className={classnames('NodeAbundanceSlider-results-value positive')}
            style={resultsCssPositive}
          />
          <div
            className={classnames('NodeAbundanceSlider-results-value negative')}
            style={resultsCssNegative}
          />

          {fixed && tempValue !== currentValue ? (
            <div
              className="NodeAbundanceSlider-temp-value"
              style={tempValueCss}
            />
          ) : null}
          <div
            className="NodeAbundanceSlider-slider-handle interactiveTourGettingStarted-6"
            style={fixed ? sliderHandleCssFixed : sliderHandleCssUnfixed}
          />
        </div>
      </div>
    </>
  );
};

const TrophicNodePlay = ({
  node,
  matrix,
  nodeOverrides,
  trophicCascadeLevel,
  trophicCascadeLevels,
  addNodeOverride,
  removeNodeOverride,
  fixed,
  trophicLevelInfo,
}) => {
  const dispatch = useDispatch();
  const [nodeInfoModalOpen, setNodeInfoModalOpen] = useState(false);
  const [showFixedWarning, setShowFixedWarning] = useState(false);
  const fixedOnMount = useRef(!!fixed);

  const nodeFixedWarningDeclined = useSelector(
    state => state.viewStates.nodeFixedWarningSeen
  );

  useEffect(() => {
    if (!nodeFixedWarningDeclined && !fixedOnMount.current && fixed) {
      setShowFixedWarning(true);
    }
  }, [nodeFixedWarningDeclined, fixed]);

  const hoverNode = useSelector(state => state.viewStates.hoverNode);
  const hoverNodeConnected =
    hoverNode &&
    [
      ...Object.keys(matrix.nodes[hoverNode]?.influences || {}),
      ...Object.values(matrix?.nodes || {}).reduce(
        (acc, n) =>
          Object.keys(n?.influences || {}).indexOf(hoverNode) >= 0
            ? [...acc, n.id]
            : acc,
        []
      ),
    ].indexOf(node.id) >= 0;

  // Gets the distance between two nodes
  const getDistance = (originNodeId, destinationNodeId) =>
    getDistances(matrix, originNodeId)[destinationNodeId];

  const nodeDistance = Math.min(
    ...nodeOverrides.map(d => getDistance(d.id, node.id))
  );

  let currentDistance = trophicCascadeLevels.indexOf(trophicCascadeLevel) + 1;
  currentDistance =
    currentDistance === trophicCascadeLevels.length
      ? Infinity
      : currentDistance;

  const cascadeHidden = nodeOverrides.length && nodeDistance > currentDistance;

  // Uses a local state for the abundance value that shows when dragging
  const [abundance, setAbundance] = useState(
    node.fixed ? Number(node.abundance) : Number(node.abundanceResult)
  );

  // Determine if the abundance increased or decreased
  const cascadeChange =
    nodeOverrides.length && node.oldAbundance !== abundance
      ? node.oldAbundance < abundance
        ? 'increase'
        : 'decrease'
      : '';

  // Only shows cascadeChange when it is the current distance
  const nodeCascadeChange =
    nodeOverrides.length &&
    currentDistance === nodeDistance &&
    currentDistance !== Infinity
      ? cascadeChange
      : '';

  // Make sure the abundances are changed when the node is updated from parent
  useEffect(
    () => setAbundance(node.fixed ? node.abundance : node.abundanceResult),
    [node]
  );

  // Called when abundance changes via slider
  const onAbundanceChange = value => {
    const newAbundance = parseFloat(value);
    setAbundance(newAbundance);
    addNodeOverride({ ...node, abundance: newAbundance });
  };

  const onNodeHover = nodeId => e => {
    e.stopPropagation();
    if (hoverNode !== nodeId) dispatch(setNodeHover(nodeId));
  };

  const onNodeClick = useCallback(
    e => {
      // Ignore slider clicks
      if (
        !e?.target?.className ||
        e?.target?.className.includes('NodeAbundanceSlider')
      )
        return;

      setNodeInfoModalOpen(!nodeInfoModalOpen);
    },
    [nodeInfoModalOpen]
  );

  const onCancelWarningModal = useCallback(() => {
    removeNodeOverride(node);
    setShowFixedWarning(false);
  }, [removeNodeOverride, node]);

  return (
    <>
      <div
        className={classnames(
          'TrophicNodePlay interactiveTourGettingStarted-5',
          `id${node.id}`, //id tag is for the tour
          nodeCascadeChange,
          nodeCascadeChange,
          hoverNode && (hoverNode === node.id ? 'hover' : 'fadedOut'),
          hoverNodeConnected && 'hoverConnected',
          {
            'TrophicNodePlay-fixed-border': fixed,
          }
        )}
        onMouseMove={onNodeHover(node.id)}
        onClick={onNodeClick}
      >
        <div className="TrophicNodePlayImageWrap">
          {node.imageUrl ? (
            <img
              className="TrophicNodePlayImage"
              src={node.imageUrl}
              alt={node.name}
            />
          ) : null}
        </div>
        <div className="TrophicNodePlayInner">
          <NodeAbundanceSlider
            node={node}
            abundance={abundance}
            cascadeHidden={cascadeHidden}
            onAbundanceChange={onAbundanceChange}
            fixed={fixed}
          />
          <div className="TrophicNodePlayInner-name-container">
            <div
              className={classnames('TrophicNodePlayInner-name', {
                positive: node?.oldAbundance < abundance,
                negative: node?.oldAbundance > abundance,
              })}
            >
              {node.name}
            </div>
          </div>
        </div>
      </div>
      {nodeInfoModalOpen ? (
        <NodeDescriptionModal
          node={node}
          imageUrl={node?.imageUrl}
          name={node?.name}
          description={node?.description}
          trophicLevelInfo={trophicLevelInfo}
          onExit={() => setNodeInfoModalOpen(false)}
        />
      ) : null}
      {showFixedWarning ? (
        <OutputToInputWarningModal
          onExit={() => setShowFixedWarning(false)}
          onCancel={onCancelWarningModal}
        />
      ) : null}
    </>
  );
};

export default TrophicNodePlay;
