import * as MatricesConnection from 'connections/matrices';
import * as MatricesTransform from 'transforms/matrices';

export const get = async matrixId => {
  const matrices = await MatricesConnection.get(matrixId);
  return matrices.map(MatricesTransform.fromFirestore);
};

export const listPublic = async loadedMatrices => {
  const matrices = await MatricesConnection.listPublic(loadedMatrices);
  return matrices.map(MatricesTransform.fromFirestore).map(matrix => ({
    ...matrix,
    nodes:
      loadedMatrices &&
      (loadedMatrices.find(d => d.id === matrix.id) || {}).nodes,
  }));
};

export const listUser = async (user, userData, loadedMatrices) => {
  const matrices = await MatricesConnection.listUser(
    user,
    userData,
    loadedMatrices
  );
  return matrices.map(MatricesTransform.fromFirestore).map(matrix => ({
    ...matrix,
    nodes:
      loadedMatrices &&
      (loadedMatrices.find(d => d.id === matrix.id) || {}).nodes,
  }));
};

export const save = async (matrixToSave, user) => {
  const { matrix, nodes } = MatricesTransform.toFirestore(matrixToSave);
  await MatricesConnection.save(matrix, nodes, user);
};
