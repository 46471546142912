import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import classnames from 'classnames';
import useResizeObserver from '@react-hook/resize-observer';
import useScrollPosition from '@react-hook/window-scroll';
import './Popover.scss';

const Popover = ({ parentRef, title, content, isOpen }) => {
  const ref = useRef(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // We should get a better solution here, this is jumpy
  const scrollPos = useScrollPosition(60);

  const setPosition = useCallback(() => {
    const popoverRef = ref?.current;
    if (!parentRef || !popoverRef) return;
    const parent = parentRef.getBoundingClientRect();
    const popover = popoverRef.getBoundingClientRect();

    let yPos = parent.y + parent.height;
    const totalHeight = window.innerHeight;

    if (yPos + popover.height > totalHeight) {
      yPos = `calc(${parent.y - popover.height}px - 0.5rem)`;
    } else {
      yPos = `calc(${yPos}px + 0.5rem)`;
    }

    setOffset({
      x: `${Math.max(parent.x - (popover.width - parent.width), 0)}px`,
      y: yPos,
    });
  }, [ref, parentRef]);

  const hidePopover = useMemo(() => {
    return offset.x === 0 && offset.y === 0;
  }, [offset]);

  useEffect(() => {
    if (isOpen) {
      setPosition();
    }
  }, [isOpen, setPosition]);

  useEffect(() => {
    setPosition();
  }, [setPosition, scrollPos]);

  useResizeObserver(document?.getElementsByClassName('App')?.[0], setPosition);

  if (!isOpen) return null;

  return (
    <div
      ref={ref}
      className={classnames('Popover', {
        show: !hidePopover,
      })}
      style={{
        transform: `translate(${offset.x},${offset.y})`,
      }}
    >
      {title ? <div className="Popover-title">{title}</div> : null}
      <div className="Popover-content">{content}</div>
    </div>
  );
};

export default Popover;
