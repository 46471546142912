import firebase from '../constants/firebase';

const saveAnonymousMatrix = firebase
  .functions()
  .httpsCallable('saveAnonymousMatrix');

export const get = async matrixId => {
  const db = firebase.firestore();

  let querySnapshot = db
    .collection('matrices')
    .where('isDeleted', '==', false)
    .where('id', '==', matrixId);

  const docs = [...(await querySnapshot.get()).docs];

  // Get all ancestors of this matrix, too
  const toVisit = [...docs];
  while (toVisit.length) {
    const visiting = toVisit.shift();

    if (visiting.parentId) {
      let parent = (
        await db
          .collection('matrices')
          .where('isDeleted', '==', false)
          .where('id', '==', visiting.parentId)
      ).docs[0];
      docs.push(parent);
      toVisit.push(parent);
    }
  }

  return getNodesForMatrices(docs);
};

const listPublicMatrices = async () => {
  const db = firebase.firestore();

  let publicQuerySnapshot = db
    .collection('matrices')
    .where('isDeleted', '==', false)
    .where('isPublic', '==', true);

  return (await publicQuerySnapshot.get()).docs;
};

const listUserMatrices = async ({ user, userData }) => {
  const db = firebase.firestore();

  let docs = [];

  if (userData && userData.isAdmin) {
    docs = [
      ...(
        await db
          .collection('matrices')
          .where('isDeleted', '==', false)
          .get()
      ).docs,
    ];
    return docs;
  }

  if (user) {
    let userMatricesSnapshot = db
      .collection('matrices')
      .where('isDeleted', '==', false)
      .where(`roles.${user.claims.user_id}`, '==', 'owner');

    docs = [...docs, ...(await userMatricesSnapshot.get()).docs];
  }

  return docs;
};

const getNodesForMatrices = async matrices => {
  return Promise.all(
    matrices.map(async d => {
      const nodes = await d.ref.collection('nodes').get();
      return {
        ...d.data(),
        id: d.id,
        nodes: nodes.docs.map(n => ({
          ...n.data(),
          id: n.id,
        })),
      };
    })
  );
};

export const listPublic = async (user, userData) => {
  const matrixDocs = await listPublicMatrices();
  return Promise.all(
    matrixDocs.map(d => ({
      ...d.data(),
      id: d.id,
    }))
  );
};

export const listUser = async (user, userData) => {
  const matrixDocs = await listUserMatrices({ user, userData });
  return Promise.all(
    matrixDocs.map(d => ({
      ...d.data(),
      id: d.id,
    }))
  );
};

export const save = async (matrix, nodes, user) => {
  if (!user && matrix.key) {
    return await saveAnonymousMatrix({ matrix, nodes });
  }

  const db = firebase.firestore();

  // Create or update matrix
  const doc = await db.collection('matrices').doc(matrix.id);

  doc.set(matrix);

  // Create or update every node in matrix
  await Promise.all(
    nodes.map(async node => {
      await doc
        .collection('nodes')
        .doc(node.id)
        .set(node);
    })
  );

  // Delete any node no longer in matrix
  const nodeIdsToKeep = nodes.map(n => n.id);
  const nodesRef = await doc.collection('nodes').get();
  let nodeDeletions = [];
  nodesRef.forEach(nodeDoc => {
    if (nodeIdsToKeep.indexOf(nodeDoc.id) < 0) {
      nodeDeletions.push(nodeDoc.ref.delete());
    }
  });

  await Promise.all(nodeDeletions);
};
