import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, useRouteMatch } from 'react-router';
import debounce from 'lodash/debounce';
import { createNode, trophicLevels } from 'constants/nodes';
import text from 'constants/text';
import { setNodeHover } from 'actions/viewStates';
import TrophicNode from 'components/TrophicNode';
import TrophicNodePlay from 'components/TrophicNodePlay';
import Tooltip from 'components/Tooltip';
import ButtonNew from './inputs/ButtonNew';
import { isNodeFixed, addAbundancesToNodes } from 'constants/fcm';
import { addNode, resetMatrix } from 'actions/matrices';
import { useEditableExperiment } from 'hooks/experiments';
import './NodesView.scss';
import classNames from 'classnames';
import PlayModeWhatChanged from './PlayModeWhatChanged';

const trophicCascadeLevels = [
  'Primary',
  'Secondary',
  'Tertiary',
  'Equilibrium',
];

const NodesView = ({ matrix, viewMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hoverNode = useSelector(state => state.viewStates.hoverNode);
  const resetMatrixState = useSelector(state => state.matrices.resetToInitial);
  const getEditableExperiment = useEditableExperiment();
  const { user } = useSelector(state => state.auth);
  let { url } = useRouteMatch();

  const [currentViewMode, setCurrentViewMode] = useState(viewMode);

  const isViewOnly = useMemo(() => {
    return !!matrix?.isViewOnly;
  }, [matrix]);

  const isPlayMode = useMemo(() => {
    return viewMode === 'play';
  }, [viewMode]);

  // This is always 'nodes' or 'graphs'
  const [chartView, setChartView] = useState(false);

  // Uses a state to keep track of the current abundance
  // override settings
  const [nodeOverrides, setNodeOverrides] = useState([]);

  // Uses a state to keep track of the current trophicCascadeLevel
  const [trophicCascadeLevel, setTrophicCascadeLevel] = useState(
    trophicCascadeLevels[trophicCascadeLevels.length - 1]
  );

  // the cascadeRunning interval
  const [cascadeRunning, setCascadeRunning] = useState([]);

  // Starts a cascade animation
  const startCascade = () => {
    // Clear any running cascade animations
    if (cascadeRunning) clearInterval(cascadeRunning);

    // Starts at the first level
    let trophicCascadeLevel = trophicCascadeLevels[0];
    setTrophicCascadeLevel(trophicCascadeLevel);

    // override it locally because of setInterval idiosyncrasies
    const cascadeRunningLocal = setInterval(() => {
      trophicCascadeLevel =
        trophicCascadeLevels[
          trophicCascadeLevels.indexOf(trophicCascadeLevel) + 1
        ];
      if (trophicCascadeLevel) setTrophicCascadeLevel(trophicCascadeLevel);
      if (
        !trophicCascadeLevel ||
        trophicCascadeLevels.indexOf(trophicCascadeLevel) ===
          trophicCascadeLevels.length - 1
      ) {
        clearInterval(cascadeRunningLocal);
        setCascadeRunning(null);
      }
    }, 1000);
    setCascadeRunning(cascadeRunningLocal);
  };

  // Get an array of all the nodes
  let nodes = [...Object.values(matrix.nodes)];

  // Get the trophic levels in this matrix
  const matrixTrophicLevels = Array.from(
    new Set(nodes.map(node => node.trophicLevel))
  );

  // filter all possible trophic levels by the ones that are
  // actually used in this matrix.
  const usedTrophicLevels = trophicLevels.filter(
    l => matrixTrophicLevels.indexOf(l.name) >= 0
  );

  // Keep track of the previous node abundances
  const [prevNodes, setPrevNodes] = useState([]);

  // Adds / updates the abundance values
  const currentNodes = addAbundancesToNodes(matrix, nodeOverrides, prevNodes);

  // when resetMatrix is passed, clear all nodeOverrides
  useEffect(() => {
    setPrevNodes([]);
    setNodeOverrides([]);
    setNodesToOverride([]);
    // Reset the reset flag
    dispatch(resetMatrix(false));
  }, [resetMatrixState, dispatch]);

  // Responds to the new node button click
  const handleCreateNode = useCallback(
    trophicLevel => () => {
      if (!matrix?.parentId && user) {
        const node = createNode(trophicLevel);
        dispatch(addNode(matrix?.id, node));

        history.push(`/matrices/${matrix?.id}/overrides/${node.id}`);
      } else {
        const editableExperiment = getEditableExperiment(matrix);
        const { parentId, id } = editableExperiment;

        const node = createNode(trophicLevel);
        dispatch(addNode(id, node));

        history.push(
          `/matrices/${parentId}/experiments/${id}/overrides/${node.id}`
        );
      }
    },
    [dispatch, user, getEditableExperiment, history, matrix]
  );

  // A function to update the node abundance settings whenever a
  // slider is dragged or node is locked / unlocked.
  const addNodeOverride = node => {
    if (prevNodes.length === 0) setPrevNodes(currentNodes);

    // Adds node to nodesOverride if it doesn't already exist
    const prevNode = prevNodes.find(n => n.id === node.id) || {};
    setNodeOverrides([
      ...nodeOverrides.filter(n => n.id !== node.id),
      {
        ...node,
        // Get the old abundance value from prevNodes
        oldAbundance:
          (prevNode.fixed ? prevNode.abundance : prevNode.abundanceResult) ||
          node.oldAbundance,
        fixed: true,
      },
    ]);
    startCascade();
  };

  // LIVE VERSION -----------------------------------------------------------------
  // A debounced version for sliders
  const addNodeOverrideDebounced = debounce(addNodeOverride, 1000);

  // Remove the node from NodeOverride
  const removeNodeOverride = id => {
    if (prevNodes.length === 0) setPrevNodes(currentNodes);
    setNodeOverrides([...nodeOverrides.filter(n => n.id !== id)]);
    startCascade();
  };

  const handleReset = () => {
    // Clear any running cascade animations
    if (cascadeRunning) clearInterval(cascadeRunning);
    setCascadeRunning(null);
    setTrophicCascadeLevel(
      trophicCascadeLevels[trophicCascadeLevels.length - 1]
    );
    dispatch(resetMatrix(true));
  };

  // ------------------------------------------------------------------------------

  // When using the "run model" button, make all changes not live
  const [nodesToOverride, setNodesToOverride] = useState([]);

  // Explicit version of above
  const addNodeOverridesExplicit = useCallback(() => {
    setPrevNodes(currentNodes);

    let nextNodes = [...nodeOverrides];

    for (const node of nodesToOverride) {
      // Adds node to nodesOverride if it doesn't already exist
      const prevNode = prevNodes.find(n => n.id === node.id) || {};

      nextNodes = [
        ...nextNodes.filter(n => n.id !== node.id),
        {
          ...node,
          // Get the old abundance value from prevNodes
          oldAbundance:
            (prevNode.fixed ? prevNode.abundance : prevNode.abundanceResult) ||
            node.oldAbundance,
          fixed: true,
        },
      ];
    }

    setNodeOverrides(nextNodes);

    startCascade();
  }, [prevNodes, currentNodes, nodesToOverride, nodeOverrides, startCascade]);

  useEffect(() => {
    // Make sure we reset things if editing
    if (viewMode !== currentViewMode) {
      if (viewMode === 'edit' && currentViewMode === 'play') {
        handleReset();
        setChartView(false);
      }
      setCurrentViewMode(viewMode);
    }
  }, [currentViewMode, handleReset, viewMode, url, matrix]);

  useEffect(() => {
    // Make sure we reset things if editing or going back to incompatible page
    const pagePathEnd = url.split('/').pop();
    const isIncompatiblePage = pagePathEnd !== matrix?.id;

    if (isIncompatiblePage && chartView) {
      setChartView(false);
    }
  }, [chartView, url, matrix]);

  return (
    <div className="NodesView">
      <div className="NodesView-content">
        <div className="NodesView-header">
          <div className="NodesView-header-title">Adjust abundances</div>

          {/* -------------------------------------------------------- */}
          {isPlayMode ? (
            <div className="NodesView-header-button-container">
              <ButtonNew
                buttonClasses={classNames(
                  'secondary-button-small interactiveTourGettingStarted-9',
                  {
                    disabled: !nodeOverrides.length,
                  }
                )}
                onClick={() => setChartView(true)}
                label="View results"
                iconLeft="barGraph"
              />
              <ButtonNew
                buttonClasses="secondary-button-small interactiveTourGettingStarted-10"
                onClick={handleReset}
                label="Reset"
                iconLeft="reseticon"
              />
              <ButtonNew
                buttonClasses={classNames(
                  'primary-button-small interactiveTourGettingStarted-8',
                  {
                    disabled: _.isEqual(nodesToOverride, nodeOverrides),
                  }
                )}
                onClick={addNodeOverridesExplicit}
                label="Run model"
                iconLeft="play"
              />
            </div>
          ) : (
            <div className="NodesView-header-button-container">
              {!isViewOnly ? (
                <ButtonNew
                  buttonClasses="secondary-button-small interactiveTourCreatingExperiment-8"
                  onClick={handleCreateNode(usedTrophicLevels?.[0]?.name)}
                  label="New Node"
                  iconLeft="plus"
                />
              ) : null}

              <ButtonNew
                buttonClasses="secondary-button-small interactiveTourCreatingExperiment-13"
                to={`${url}/details`}
                label={
                  matrix?.parentId ? 'Compare to base matrix' : 'Statistics'
                }
              />
            </div>
          )}
        </div>

        {usedTrophicLevels.map(({ name: level, color, icon }) => (
          <div key={level}>
            <div className="TrophicLevelMeta">
              <h4 className="TrophicLevelLabel">
                <img className="TrophicLevelLabelIcon" src={icon} alt={level} />
                {level.toUpperCase()}
              </h4>
              {text.trophicLevels[level] ? (
                <Tooltip>{text.trophicLevels[level]}</Tooltip>
              ) : null}
            </div>
            <div
              className="TrophicLevelNodes"
              onMouseMove={() => {
                if (hoverNode) dispatch(setNodeHover());
              }}
            >
              {currentNodes
                .filter(node => node.trophicLevel === level)
                .map(node =>
                  isPlayMode ? (
                    <TrophicNodePlay
                      key={`${node.id}=${matrix.id}`}
                      node={node}
                      matrix={matrix}
                      // Actual node overrides
                      nodeOverrides={nodeOverrides}
                      trophicCascadeLevel={trophicCascadeLevel}
                      trophicCascadeLevels={trophicCascadeLevels}
                      // Pending node overrides
                      addNodeOverride={n =>
                        setNodesToOverride([
                          ...nodesToOverride.filter(node => node.id !== n.id),
                          n,
                        ])
                      }
                      // Remove pending node override
                      removeNodeOverride={n =>
                        setNodesToOverride([
                          ...nodesToOverride.filter(node => node.id !== n.id),
                        ])
                      }
                      // Pending node overrides
                      fixed={isNodeFixed(node, nodes, nodesToOverride)}
                      trophicLevelInfo={{ name: level, color, icon }}
                    />
                  ) : (
                    <TrophicNode
                      key={node.id}
                      node={node}
                      matrix={matrix}
                      fixed={isNodeFixed(node, nodes, nodesToOverride)}
                      isViewOnly={isViewOnly || isPlayMode}
                    />
                  )
                )}
            </div>
          </div>
        ))}
      </div>

      {chartView ? (
        <div className="NodesView-content-cover">
          <PlayModeWhatChanged
            matrix={matrix}
            nodes={currentNodes}
            nodeOverrides={nodeOverrides}
            goBack={() => setChartView(false)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NodesView;
