import React, { useMemo, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import NodePill from 'components/NodePill';
import icons from 'img/icons';
import Tooltip from 'components/Tooltip';
import text from 'constants/text';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import * as d3 from 'd3';
import './AbundanceView.scss';

const AbundanceArrow = ({ node, change }) => {
  const arrowHeadWidth = 6;

  const markerWidth = arrowHeadWidth;

  const svgElement = useRef(null);
  const svgCell = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [arrow, setArrow] = useState({
    start: change === 'node-added' ? 0 : node.matrixAbundance,
    end: change === 'node-deleted' ? 0 : node.experimentAbundance,
  });

  useEffect(() => {
    setArrow({
      start: change === 'node-added' ? 0 : node.matrixAbundance,
      end: change === 'node-deleted' ? 0 : node.experimentAbundance,
    });

    //Watches for table resizing
    const observer = new ResizeObserver(entries => {
      if (!entries.length) {
        return;
      }
      handleResize(entries);
    });
    observer.observe(svgCell.current);

    d3.select(svgElement.current)
      .selectAll('g')
      .remove();

    const svgHeight = 6;

    const svg = d3.select(svgElement.current);
    svg.style('height', svgHeight + 'px').append('g');

    const circleRadius = svgHeight / 2;

    //more margin if it is a circle so they dont get cutoff
    const hMargin = (node, change, circleMargin, defaultMargin) => {
      if (change === 'no-change') {
        return circleMargin;
      }

      if (change === 'node-added' && node.experimentPopulation === 0) {
        return circleMargin;
      }

      if (change === 'node-deleted' && node.matrixPopulation === 0) {
        return circleMargin;
      }

      return defaultMargin;
    };

    let horizontalMargin = hMargin(node, change, circleRadius, 1);

    const verticalMargin = 0;
    const frameWidth = width - horizontalMargin * 2;
    const frameHeight = svgHeight - verticalMargin * 2;

    var xScale = d3.scaleLinear().range([horizontalMargin, frameWidth]);
    xScale.domain([0, 1]);

    const y_value = frameHeight / 2;

    if (node.matrixPopulation === node.experimentPopulation) {
      svg
        .select('g')
        .append('circle')
        .classed('no-change-dot', true)
        .attr('cx', d => xScale(node.matrixAbundance))
        .attr('cy', y_value)
        .attr('r', circleRadius);
    } else {
      //extra margin to keep arrow in cell for small increase from 0 or small decrease from max
      let addedMargin =
        xScale(arrow.end) < markerWidth && change === 'increase'
          ? markerWidth - xScale(arrow.end)
          : 0;
      let subtractedMargin =
        xScale(arrow.end) > frameWidth - markerWidth && change === 'decrease'
          ? markerWidth - (frameWidth - xScale(arrow.end))
          : 0;

      svg
        .select('g')
        .append('line')
        .attr('x1', xScale(arrow.start) + addedMargin - subtractedMargin)
        .attr('y1', y_value)
        .attr('x2', xScale(arrow.end) + addedMargin - subtractedMargin)
        .attr('y2', y_value)
        .attr('stroke-width', svgHeight)
        .attr('stroke-linecap', 'round');

      svg.attr(
        'marker-end',
        `url(#arrow_${arrow.start > arrow.end ? 'left' : 'right'})`
      );
    }

    return () => {
      observer.disconnect();
    };

    function handleResize(entries) {
      for (const entry of entries) {
        setHeight(entry.contentRect.height);
        setWidth(entry.contentRect.width);
      }
    }
  }, [svgCell, width, height, change, node, markerWidth]);

  return (
    <Cell ref={svgCell} className="change-abundance">
      <div className="AbundanceArrow-svg-wrap">
        <svg ref={svgElement}></svg>
      </div>
    </Cell>
  );
};

const AbundanceRow = ({ node, headers, fixed }) => {
  const modifiedAbundanceChange = (matrixPopulation, experimentPopulation) => {
    if (matrixPopulation === experimentPopulation) {
      return null;
    } else if (matrixPopulation > experimentPopulation) {
      return 'modDecrease';
    } else if (matrixPopulation < experimentPopulation) {
      return 'modIncrease';
    }
  };

  return (
    <Row className={classNames('abundance-row', node.change)}>
      <Cell className="name">
        {/* Just disabled to get gray color */}
        <NodePill node={node} backgroundColor="transparent" disabled={true} />
      </Cell>
      {<AbundanceArrow node={node} change={node.change}></AbundanceArrow>}
      <Cell className={headers[2].class}>
        {node.matrixPopulation ? node.matrixPopulation : 0}
      </Cell>
      <Cell
        className={classNames(
          headers[3].class,
          modifiedAbundanceChange(
            node.matrixPopulation,
            node.experimentPopulation
          )
        )}
      >
        {node.experimentPopulation || node.experimentPopulation === 0
          ? node.experimentPopulation
          : 0}
      </Cell>
      <Cell className={headers[4].class}>{node.populationUnit}</Cell>
      <Cell
        className={classNames(
          headers[5].class,
          node.matrixCategory === node.experimentCategory
            ? 'not-significant'
            : null
        )}
      >
        {node.changeDisplay}
      </Cell>
      <Cell className={classNames(headers[6].class)}>
        {node.fixed ? <div className="fixed">input</div> : 'output'}
      </Cell>
    </Row>
  );
};

const AbundanceTable = ({ nodes }) => {
  const headers = [
    {
      display: 'Node name',
      class: 'name',
      tooltip: text.tooltip.abundanceView.name,
      tooltipAlign: 'right',
    },
    { display: 'Abundance change', class: 'change-abundance' },
    { display: 'Original abundance', class: 'original-abundance' },
    {
      display: 'New abundance',
      class: 'modified-abundance',
      tooltip: text.tooltip.abundanceView.modifiedAbundance,
      tooltipAlign: 'left',
    },
    { display: 'Units', class: 'units' },
    {
      display: 'Fuzzy change',
      class: 'fuzzy-change',
      tooltip: text.tooltip.abundanceView.fuzzyChange,
      tooltipAlign: 'left',
    },
    {
      display: 'Status',
      class: 'status',
    },
  ];

  return (
    <div className="AbundanceTable">
      <StickyTable id="charts-download">
        <Row>
          {headers.map(heading => (
            <Cell key={heading.display} className={`heading ${heading.class}`}>
              <div className="name-tooltip">
                {heading.display}
                {heading.tooltip ? (
                  <Tooltip left={heading.tooltipAlign === 'left'}>
                    {heading.tooltip}
                  </Tooltip>
                ) : null}
              </div>
            </Cell>
          ))}
        </Row>

        {nodes.map(node => (
          <AbundanceRow
            key={node.id}
            node={node}
            headers={headers}
            fixed={node.fixed}
          ></AbundanceRow>
        ))}
      </StickyTable>
    </div>
  );
};

export default AbundanceTable;
