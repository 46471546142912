import firebase from 'firebase/compat/app';
import moment from 'moment';

const timeFormat = 'YYYYMMDDHHmmss';

const makeFilename = (file, folder, user) => {
  return [
    'images',
    user.claims.user_id,
    folder,
    `${moment().format(timeFormat)}_${file.name}`,
  ].join('/');
};

export const upload = (file, folder, user) => {
  const storageRef = firebase.storage().ref();
  const filename = makeFilename(file, folder, user);
  const imageRef = storageRef.child(filename);
  return imageRef.put(file);
};
