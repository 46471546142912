import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { validate } from 'actions/matrixSettings';
import { canEdit } from 'constants/matrices';

const useCanEdit = () => {
  const { keys } = useSelector(state => state.anonymousEditingKeys);
  const { user, userData } = useSelector(state => state.auth);

  return useMemo(() => {
    return matrix => {
      return canEdit(matrix, user, userData, keys[matrix?.id]);
    };
  }, [keys, user, userData]);
};

const useValidateMatix = () => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  return useMemo(() => {
    return () => dispatch(validate(user));
  }, [dispatch, user]);
};

export { useCanEdit, useValidateMatix };
